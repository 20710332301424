@font-face {
    font-family: 'Museo Sans 500';
    src: url('../fonts/MuseoSans-500Italic.eot');
    src: url('../fonts/MuseoSans-500Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MuseoSans-500Italic.woff2') format('woff2'),
        url('../fonts/MuseoSans-500Italic.woff') format('woff'),
        url('../fonts/MuseoSans-500Italic.ttf') format('truetype'),
        url('../fonts/MuseoSans-500Italic.svg#MuseoSans-500Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Museo Sans 700';
    src: url('../fonts/MuseoSans-700.eot');
    src: url('../fonts/MuseoSans-700.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MuseoSans-700.woff2') format('woff2'),
        url('../fonts/MuseoSans-700.woff') format('woff'),
        url('../fonts/MuseoSans-700.ttf') format('truetype'),
        url('../fonts/MuseoSans-700.svg#MuseoSans-700') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans 700';
    src: url('../fonts/MuseoSans-700Italic.eot');
    src: url('../fonts/MuseoSans-700Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MuseoSans-700Italic.woff2') format('woff2'),
        url('../fonts/MuseoSans-700Italic.woff') format('woff'),
        url('../fonts/MuseoSans-700Italic.ttf') format('truetype'),
        url('../fonts/MuseoSans-700Italic.svg#MuseoSans-700Italic') format('svg');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Museo Sans 100';
    src: url('../fonts/MuseoSans-100.eot');
    src: url('../fonts/MuseoSans-100.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MuseoSans-100.woff2') format('woff2'),
        url('../fonts/MuseoSans-100.woff') format('woff'),
        url('../fonts/MuseoSans-100.ttf') format('truetype'),
        url('../fonts/MuseoSans-100.svg#MuseoSans-100') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans 100';
    src: url('../fonts/MuseoSans-100Italic.eot');
    src: url('../fonts/MuseoSans-100Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MuseoSans-100Italic.woff2') format('woff2'),
        url('../fonts/MuseoSans-100Italic.woff') format('woff'),
        url('../fonts/MuseoSans-100Italic.ttf') format('truetype'),
        url('../fonts/MuseoSans-100Italic.svg#MuseoSans-100Italic') format('svg');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Museo Sans 300';
    src: url('../fonts/MuseoSans-300Italic.eot');
    src: url('../fonts/MuseoSans-300Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MuseoSans-300Italic.woff2') format('woff2'),
        url('../fonts/MuseoSans-300Italic.woff') format('woff'),
        url('../fonts/MuseoSans-300Italic.ttf') format('truetype'),
        url('../fonts/MuseoSans-300Italic.svg#MuseoSans-300Italic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Museo Sans 500';
    src: url('../fonts/MuseoSans-500.eot');
    src: url('../fonts/MuseoSans-500.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MuseoSans-500.woff2') format('woff2'),
        url('../fonts/MuseoSans-500.woff') format('woff'),
        url('../fonts/MuseoSans-500.ttf') format('truetype'),
        url('../fonts/MuseoSans-500.svg#MuseoSans-500') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans 300';
    src: url('../fonts/MuseoSans-300.eot');
    src: url('../fonts/MuseoSans-300.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MuseoSans-300.woff2') format('woff2'),
        url('../fonts/MuseoSans-300.woff') format('woff'),
        url('../fonts/MuseoSans-300.ttf') format('truetype'),
        url('../fonts/MuseoSans-300.svg#MuseoSans-300') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Museo 700';
    src: url('../fonts/Museo-700.eot');
    src: url('../fonts/Museo-700.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Museo-700.woff2') format('woff2'),
        url('../fonts/Museo-700.woff') format('woff'),
        url('../fonts/Museo-700.ttf') format('truetype'),
        url('../fonts/Museo-700.svg#Museo-700') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans 900';
    src: url('../fonts/MuseoSans-900.eot');
    src: url('../fonts/MuseoSans-900.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MuseoSans-900.woff2') format('woff2'),
        url('../fonts/MuseoSans-900.woff') format('woff'),
        url('../fonts/MuseoSans-900.ttf') format('truetype'),
        url('../fonts/MuseoSans-900.svg#MuseoSans-900') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans 900';
    src: url('../fonts/MuseoSans-900Italic.eot');
    src: url('../fonts/MuseoSans-900Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MuseoSans-900Italic.woff2') format('woff2'),
        url('../fonts/MuseoSans-900Italic.woff') format('woff'),
        url('../fonts/MuseoSans-900Italic.ttf') format('truetype'),
        url('../fonts/MuseoSans-900Italic.svg#MuseoSans-900Italic') format('svg');
    font-weight: bold;
    font-style: italic;
}
/* @font-face {
    font-family: 'dotsfont';
    src: url('../dotsfont-master/dist/dotsfont.eot');
    src: url('../dotsfont-master/dist/dotsfont.eot?#iefix') format('embedded-opentype'),
         url('../dotsfont-master/dist/dotsfont.woff') format('woff'),
         url('../dotsfont-master/dist/dotsfont.ttf') format('truetype'),
         url('../dotsfont-master/dist/dotsfont.svg#dotsfontregular') format('svg');
  } */
@media only screen and (min-width: 2230px){
	.same_status_section.codes_section.codes-scaaner_two .codes-image_section img {
		max-width: 455px;
	}
	.codes_right .codes-image_section {
		width: 500px;
	}
	}

@media only screen and (min-width: 1930px){
		.same_status_section.codes_section.codes-scaaner_two .codes-image_section img {
			max-width: 405px;
		}
		.codes_right .codes-image_section {
			width: 450px;
		}
		}
@media only screen and (max-width: 1850px){
	.subscription_table table tbody .table-price td .free_subscription_text::before {
		right: 10px;
	}
}

@media only screen and (max-width:1750px){
	.container {
		max-width: 1500px;
	}
	.sign_in, .sign_up_page {
		padding-bottom: 208px;
		padding-top: 130px;
	}
	.sign_up_page.sign_up h2 {
		margin-bottom: 85px;
	}
	form.sign_up_form.block_element label.container-s {
		margin-bottom: 20px;
	}
	.sign_up_form.block_element .forgot_password.block_element {
		margin-bottom: 30px;
	}
	.sign_up_page h2 {
		font-size: 60px;
	}
	.sign_up_page.forgot_page h2 {
		margin-bottom: 18px;
	}
	.forgot_page .write_down_email, .icelandic_body .forgot_page .write_down_email {
		font-size: 26px;
	}
	.forgot_page .form-group {
		margin-bottom: 35px;
	}
	.my_pass_main_section.slider_with_pending_temp section.banner-slider {
		width: calc(100% - 500px - 29px);
	}
	.passes_more_details .passess-details:nth-child(2), .passes_full_more_details .passess-details:nth-child(2) {
		padding: 34px 36px 29px 50px;
	}
}

@media only screen and (max-width:1699px){
	.Dummy-Stats .status_graph.same_status_section img {
		width: 100%;
	}
	.passes_more_details .passess-details:last-child, .passes_full_more_details .passess-details:last-child {
		padding: 34px 36px 29px 55px;
	}
	.subscription_table table tbody .table-price td .free_subscription_text::before {
		right: 0px;
	}
	.signinEmailErrorMsg {
		top: 176px;
		right: 100px;
	}
	.order_fornew_passes .add_order_disable.order_pass {
		padding: 126px 0 102px;
	}
	#password.same_profile_tab_style form h3 {
		margin-bottom: 80px;
	}
	.subscription_table table.subscription_single_table tr td:first-child {
		padding: 14px 30px 14px 22px;
	}
	
}
@media only screen and (max-width:1600px){
	.container {
		max-width: 1300px;
	}
	#termsCondition .modal-header h4.modal-title {
		font-size: 32px;
		line-height: 50px;
	}
	#termsCondition .modal-body p {
		font-size: 18px;
	}
	#termsCondition h2 {
		font-size: 26px;
	}
	#termsCondition .close {
		top: 55px;
	}
	.main_logo_heading_padding #UpgradeSubscription h3 {
		font-size: 34px;
		line-height: 45px;
		margin: 0px 0 12px;
	}
	.sending_passes_heading span {
		font-size: 22px;
	}
	.modal .subscription_table table tbody tr.table-price, .modal .subscription_table table tbody tr.table-price div{min-height: 104px;}
	#contactUsConfirm .modal-dialog .modal-content.bg_color_section img, #addUserConfirm .modal-dialog .modal-content.bg_color_section img, #sendPassConfirm .modal-dialog .modal-content.bg_color_section img, #inviteUserConfirm.modal-dialog .modal-content.bg_color_section img, #addLicenseHolderConfirm .modal-dialog .modal-content.bg_color_section .modal-body img, #sendLicenseConfirm .modal-dialog .modal-content.bg_color_section .modal-body img {
		max-width: 150px;
	}
	#addLicenseHolderConfirm .modal-dialog .modal-content.bg_color_section .modal-body span, #sendLicenseConfirm .modal-dialog .modal-content.bg_color_section .modal-body span {
		font-size: 34px;
		line-height: 42px;
	}
	#addUserConfirm .modal-dialog .modal-content.bg_color_section .modal-body span {
		margin-top: 26px;
		font-size: 34px;
	}
	#addLicenseHolderConfirm .modal-dialog, #sendLicenseConfirm .modal-dialog {
		max-width: 444px;
	}
	#addLicenseHolderConfirm .modal-header,  #sendLicenseConfirm  .modal-header{
		padding: 50px 60px 0px;
	}
	#addUserConfirm .modal-footer {
		padding-bottom: 30px;
	}
	#addUserConfirm .modal-header {
		padding: 50px 60px 0px;
	}
	#addUserConfirm .modal-dialog, #sendPassConfirm .modal-dialog {
		max-width: 444px;
	}
	.subscription_sec .padding_container table {
		max-width: 100%;
	}
	.subscription_table table.subscription_single_table tr td:first-child {
		padding: 14px 20px;
	}
	.subscription_table table td {
		font-size: 15px;
	}
	.subscription_table table th {
		font-size: 25px;
		padding: 18px 20px 22px;
	}
	.subscription_table table tbody .table-price td .free_subsription_btns {
		padding: 10px 10px;
		font-size: 25px;
		min-width: 160px;
	}
	#subscription .subscription_table table tbody .table-price:last-child td {
		padding-left: 30px;
	}
	.subscription_table table tbody .table-price td .price_cutoff {
		font-size: 16px;
	}
	.subscription_table table tbody .table-price td .price_kr {
		font-size: 27px;
	}
	.subscription_table table tbody .table-price td .month_validity {
		font-size: 14px;
	}
	#subscription .subscription_table table tbody .table-price:last-child td:first-child {
		padding-left: 15px;
	}
	.subscription_table table tbody .table-price td .free_subscription_text {
		font-size: 20.5px;
	}

}

@media only screen and (min-width: 1200px) and (max-width:1499px){
.my_pass_main_section.slider_with_pending_temp section.banner-slider div#main-slider .owl-item {
    max-width: 375px !important;
}
}
@media only screen and (max-width: 1500px){
	.main_distribution_btn .distribution_btns {
		margin-bottom: 20px;
	}
	.header-right-alignment .user_account .user_current span {
		white-space: nowrap;
		text-overflow: ellipsis;
		width: 150px;
		overflow: hidden;
	}
	.icelandic_body .main_distribution_btn .distribution_btns {
    padding: 14px 20px;
	}
	.order_fornew_passes .add_order_disable.order_pass {
		padding: 136px 0 94px;
	}
	.subscription_table table tbody .table-price td .free_subscription_text::before {
		right: -20px;
	}
	.template-stripSection , .template-stripSection.template-stripSectionPunchPass {
		height: 100px !important ;
	}
	.modal.select_subscription .modal-dialog {
		padding: 0 2px;
	}
	.my_pass_templates.no-dashboard .order_fornew_passes .pass-design-layout .pass-template-hover span {
		font-size: 40px;
		line-height: 47px;
	}
	.passes-design-table p {
		font-size: 11px;
	}
	.passes-design-table p span {
		font-size: 16px;
	}
	.pass-design-layout.pass_design_universal .passes-design-table p {
		margin-bottom: 5px;
		line-height: 15px;
	}
	.pass-design-layout.pass_design_universal .passes-design-table span {
		font-size: 17px;
		line-height: 18px;
	}
	.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
		display: block !important;
	}
	.my_pass_main_section.my_passtwo_main_section section.banner-slider {
		padding-left: 60px;
	}
	.my_pass_templates .order_pass p {
		font-size: 18px;
		line-height: 23px;
	}
	.my_passtwo_main_section .pass-design-banner_boxes .pass_design_box {
		width: 15%;
	}
	.banner-slider .swiper-button-prev {
		background-size: 27px 12px;
		height: 45px;
		width: 45px;
		background-position: 9px 15px;
	}
	.banner-slider .swiper-button-next {
		background-size: 27px 12px;
		background-repeat: no-repeat;
		height: 45px;
		width: 45px;
		background-position: 9px 15px;
	}
	.swiper-container::after {
		box-shadow: 10px -4px 20px 88px #fbfbfb;
	}
	.bg_color_section.block_element .nav.nav-tabs li a {
		font-size: 28px;
	}
	.bg_color_section.block_element.main_logo_heading_padding.my_pass_templates.my_profile_section.padding_container {
		padding-bottom: 8px;
	}
	#UpgradeSubscription.select_subscription.modal .modal-body {
		padding: 0 30px 40px;
	}
	.my_pass_templates .order_pass.no-template p {
		font-size: 36px;
	}
	.passess-details .passes_counting .pass_number {
		font-size: 40px;
		line-height: 50px;
	}
	.main_logo_heading_padding h3 {
		margin: 70px 0 20px;
		font-size: 34px;
	}
	#password.same_profile_tab_style form h3 {
		margin-bottom: 70px;
		font-size: 34px;
		line-height: 42px;
	}
	#password .profile-form_btn {
		padding-top: 0px;
	}
	.growing_section .growing_heading {
		font-size: 34px;
		line-height: 40px;
	}
	.growing_section .template_types span {
		font-size: 24px;
	}
	.subscription_sec .subsription_heading.padding_container h3 {
		font-size: 36px;
		padding-bottom: 30px;
	}
	.main_logo_heading_padding .same_status_section.codes_section h3 {
		margin: 0 0 20px;
		font-size: 34px;
		line-height: 34px;
	}
	.my_scanner_page .my_scanner.start_ordering_scanner .tab_background h3 {
	margin: 10px 0 20px;
}
.my_scanner_page .my_scanner.start_ordering_scanner .tab_background {
	padding: 24px 40px 55px;
}
#addScannerForm .form_btn_group {
	margin-top: 89px;
	padding-left: 115px;
}
#addScannerForm .form_btn_group .addscanner_cancel_btn, #scanner .empty_add_scanner_tab .no_scanner_listed .form_btn_group .addscanner_cancel_btn {
	font-size: 31px;
	height: 75px;
	width: 205px;
}
#addScannerForm .form_btn_group .addscanner_add_btn {
	font-size: 31px;
	height: 75px;
	width: 306px;
}
    }
 @media only screen and (max-width:1499px){
        .my_pass_templates .order_pass.ordered_pending_passes span.add_order {
            margin-top: 0px;
        }
        .owl-nav .owl-next {
            height: 45px;
            width: 45px;
            background-position: 0px 12px !important;
            right: -33px;
            background-color: #fff !important;
            background-size: contain !important;
		}
		.order_pass {
			padding: 116px 0 64px;
		}
        .my_pass_main_section section.banner-slider.bigger-slider {
            padding-left: 40px;
        }
        .my_pass_main_section section.banner-slider.bigger-slider .owl-nav .owl-prev {
            left: -50px;
		}
		.active_user_account .multi_user_section .upgrade_subscription_btn {
			height: 64px;
		}
		.tab-content .active_user_account .multi_user_section button.upgrade_subscription_btn {
			height: 80px;
		}
        .owl-nav .owl-prev {
            height: 45px;
            width: 45px;
            background-position: 1px 12px !important;
            left: -42px;
            background-size: contain !important;
        }
        .my_pass_main_section.slider_with_pending_temp section.banner-slider {
            width: calc(100% - 450px - 29px);
        }
        .owl-nav .owl-next {
            height: 45px;
            width: 45px;
            background-position: 0px 12px !important;
            right: -33px;
            background-size: contain !important;
        }
        .my_pass_main_section.slider_with_pending_temp .order_pass.ordered_pending_passes {
            width: 230px;
        }
        .owl-item .item .pass_layout {
            height: 450px;
        }
        .my_pass_templates .order_pass span.add_order {
            margin-top: -40px;
		}
		.order_fornew_passes .add_order_disable.order_pass {
			padding: 172px 0 94px;
		}
		.header-right-alignment .user_account {
			padding-right: 0;
		}
		.bg_color_section.block_element.main_logo_heading_padding.my_pass_templates.my_profile_section.padding_container {
			padding-left: 70px;
			padding-right: 70px;
		}
		.sign_up_form .form-group-btn input[type="submit"] {
			padding: 10px 30px;
			max-width: 250px;
		}
		#termsCondition.term-popup .modal-footer button {
			font-size: 27px;
			line-height: 1.2;
			padding: 10px 30px;
		}
		#contactus-form .form-group-btn input[type="submit"] {
			max-width: 230px;
			font-size: 27px;
		}
		.user_account .users {
			right: 0;
		}
		.profile-form_btn button, .profile-form_btn input[type="submit"] {
			font-size: 27px;
			padding: 15px 30px;
			line-height: 1.2;
		}
		.subscription_table table tbody .table-price td .month_validity {
			font-size: 13px;
		}
		.subscription_table table tbody .table-price td .free_subsription_btns {
			font-size: 21px;
			min-width: 145px;
		}
		.subscription_table table tbody .table-price td .price_kr {
			font-size: 24px;
		}
		.subscription_table table.subscription_single_table tr td {
			padding: 14px 10px 14px 10px;
		}
		.subscription_table table.subscription_single_table tr td:first-child {
			padding:14px 5px 14px 15px;
		}
		.subscription_table table th {
			font-size: 23px;
			padding: 18px 15px 15px;
		}
		.tollTipTrigger {
			-webkit-transform: translateY(-13px);
			transform: translateY(-13px);
		}
		.passes_more_details .passess-details:nth-child(2), .passes_full_more_details .passess-details:nth-child(2) {
			padding: 34px 36px 29px 47px;
		}
		.subscription_table table tbody .table-price td .free_subscription_text::before {
			right: -3px;
		}
		#subscription .subscription_table table tbody .table-price:last-child td:first-child {
			padding-left: 0px;
		}
		.standard-fee-note p {
			font-size: 24px;
		}
		.standard-fee-note {
			margin: 50px auto 30px;
		}
		#addScannerForm .form-group input#nameofScannerInput, #updateScannerForm .form-group input#nameofScannerInput {
			width: 655px;
		}
		#updateScannerForm .form_btn_group {
			margin-top: 60px;
		}
    }
@media only screen and (max-width: 1400px){
	.container {
		max-width: 1200px;
	}
	.sign_in, .sign_up_page {
		padding-bottom: 175px;
		padding-top: 100px;
	}
	.sign_up_page h2, .icelandic_body .sign_up_page h2 {
		font-size: 40px;
		line-height: 50px;
	}
	.status_main table td {
		font-size: 16px;
	}
	.status_main table th {
		font-size: 16px !important;
	}
	.distribution._section .user-listing {
		margin-top: 22px;
	}
	.codeLinkSection, .statisticsUpgrade {
		padding: 50px 0 0px;
	}
	.bg_color_section {
		padding-bottom: 120px;
	}
	.header_login.padding_container.bg_color_section {
		padding-bottom: 20px;
	}
	.header_login {
		padding-top: 20px;
	}
	.header_login.sticky {
		padding-top: 20px;
	}
	body {
		padding-top: 110px;
	}
	.bg_color_section.block_element.main_logo_heading_padding.my_pass_templates.my_profile_section.padding_container {
		padding-top: 30px;
	}
	#addScannerForm .form-group input#nameofScannerInput, #updateScannerForm .form-group input#nameofScannerInput {
		width: 100%;
	}
}

@media only screen and (max-width: 1365px){
	.padding_container.subscription_table {
		padding: 0;
	}
	.codes_section .codes_left span br {
		display: none;
	}
	.same_status_section.codes_section .codes_left {
		width: 50%;
	}
	#subscription.tab-pane.subscription_sec div > .subsription_heading.padding_container {
		max-width: 960px;
	}
	#termsCondition .modal-dialog .modal-body {
		padding: 0 85px 10px 85px;
	}
	.signinEmailErrorMsg {
		width: 355px;
		padding: 40px 30px 60px;
		top: 176px;
	}
	.signinEmailErrorMsg span {
		font-size: 22px;
	}
	.subscription_sec .subscription_table {
		overflow-x: auto;
		padding-bottom: 20px;
	}
	.status_of_passses .status_gauge {
		float: left;
		width: 38%;
		margin: 0 auto 0px;
		max-width: 100%;
	}
	
	.my_pass_templates.no-dashboard .order_fornew_passes .pass-design-layout .pass-template-hover span {
		font-size: 35px;
		line-height: 45px;
	}
	.status_of_passses .status_graph {
        width: 60%;
	}
    .owl-nav .owl-prev {
        height: 41px;
        width: 41px;
        left: -33px;
        background-size: 32px !important;
    }
    .owl-nav .owl-next {
        height: 41px;
        width: 41px;
        right: -33px;
        background-size: 32px !important;
	}
	.same_status_section.codes_section .codes_right {
		padding-left: 10px;
	}
	.same_status_section.codes_section .codes_right {
		max-width: 370px;
	}
	.my_pass_templates .dashboard-order.order_pass.no-template, .my_pass_templates.no-dashboard .order_pass.ordered_pending_passes, .pass-design-layout {
		min-height: 406px;
	}
	.dummy_data-content .dummy_data-content-logo {
		padding: 90px 10px 67px;
	}
	.main_logo_heading_padding h3 {
		font-size: 34px;
	}
	.same_profile_tab_style form {
		max-width: 960px;
	}    
	.subscription_sec .subsription_heading.padding_container {
		max-width: 1000px;
	}
	#password.same_profile_tab_style form h3 {
		margin-bottom: 70px;
	}
	.sign_up_page h2 {
		font-size: 50px;
	}
	.sign_up_page h2 {
		margin-bottom: 80px;
	}
	.sign_up_page {
		padding-top: 110px;
		padding-bottom: 220px;
	}
	form.sign_up_form.block_element label.container-s {
		margin-bottom: 30px;
	}
	#termsCondition .modal-header h4.modal-title {
		font-size: 36px;
	}
	.forgot_page .write_down_email {
		margin-bottom: 70px;
		font-size: 26px;
	}
	.thanku_page h2 {
		font-size: 50px;
	}
	.growing_section .remaining_days {
		font-size: 36px;
	}
	.upgrade_subsirption button.cancel_subscription {
		font-size: 27px;
	}
	.bg_color_section.block_element.main_logo_heading_padding.my_pass_templates.my_profile_section.padding_container h3 {
		font-size: 35px;
	}
	#password.same_profile_tab_style form h3 {
		font-size: 36px;
	}
	.dummy_data-content .dummy_data-content-logo img {
		max-width: 100px;
	}
	
	.thanku_page {
		text-align: center;
		padding: 140px 0;
	}
	.passes_more_details .passess-details {
		flex: 0 0 33.3%;
	}
	.passes_more_details {
		max-width: 100%;
	}
	.status_of_passses.block_element .status_gauge.same_status_section {
		margin-left: 0;
	}
	.bg_color_section.block_element .nav.nav-tabs li a {
		font-size: 24px;
		line-height: 30px;
	}
	.main_logo_heading_padding .same_status_section.codes_section h3 {
		font-size: 32px;
		margin-bottom: 14px;
	}
	.codes_section .codes_left span {
		font-size: 18px;
	}
	.front_layout.home .tab-content.tab_background .tab-pane.fade.distribution._section.in.active.show .no_user_listed.no_and_search_samestyle {
		margin-top: 0;
	}
	.no_user_listed .main_distribution_btn .distribution_btns {
		margin-bottom: 10px;
		margin-right: 10px;
	}
	.passess-details .passes_counting .pass_number {
		font-size: 42px;
		line-height: 50px;
	}
	/* #users.show.distribution .search_section {
		display: none;
	} */
	.sign_up_page h2, .icelandic_body .sign_up_page h2 {
		font-size: 45px;
	}
	.passess-details {
		width: 48%;
		margin-right: 2%;
	}
	.my_scanner_page .my_scanner.start_ordering_scanner .tab_background #updateScannerForm {
		flex: 0 0 50%;
	}
	.scanner_tab_delete_btn .scanner_btn.delete_scanner {
		font-size: 33px;
		height: 74px;
		width: 320px;
	}
	.main_logo_heading_padding.start_ordering_scanner.my_scanner.padding_container .same_status_section.codes_section .codes_right {
		padding: 0;
	}
	.main_logo_heading_padding.start_ordering_scanner.my_scanner.padding_container .slider_with_pending_temp #banner-slider .swiper-slide.item {
		width: 360px !important;
	}
	}

	@media only screen and (max-width: 1300px){
		.container{
			width:1140px;
		}
		footer .footer_content {
			flex-wrap: nowrap;
			padding-top: 20px;
		}
		.suportedAreaFooter{
			margin-right: 80px;
		}
		.suportedAreaFooter.suportedAreaFooterIs {
			margin-right: 25px;
		}
		footer.padding_container .footer_block {
			max-width: 1140px;
		}
		form.sign_up_form.block_element label.container-s {
			margin-bottom: 20px;
		}
	}

@media only screen and (max-width: 1199px){
	.statisticsUpgrade span {
		font-size: 30px;
	}
	.suportedAreaFooter{
		flex-direction: column;
		margin-right: 125px;
	}
	.suportedAreaFooter span {
		margin-right: 114px;
	}

	.suportedAreaFooter.suportedAreaFooterIs {
		margin-right: 40px;
	}

	.header-right-alignment .user_account .user_current span {
		width: 105px;
	}
	.same_profile_tab_style form .form-group.payment_api_section {
		margin-bottom: 60px;
	}
	.modal .subscription_table table tbody .table-price td .month_validity{
		font-size: 11px;
	}
	#userPermission h4 {
		font-size: 34px;
		line-height: 43px;
		margin-bottom: 45px;
	}
	.icelandic_body #UpgradeSubscription .subscription_table table tbody tr.table-action-tab td.active-plan span {
	padding: 9px 20px;
}
.modal.select_subscription table tbody tr.table-price td > div.extra_space{
	padding: 30px 5px 0px 10px;
}
.have-subscription .subscription_table table tbody .table-price td .price_kr.active-plan_span{
    padding-top:13px;
}
.modal.select_subscription table tbody tr.without_disscount.table-price td > div.extra_space{
	padding: 37px 5px 0px 10px;
}
.icelandic_body #UpgradeSubscription .subscription_table table tbody .table-price td .free_subsription_btns {
	font-size: 17px;
}
	.my_scanner_page .my_scanner.start_ordering_scanner .tab_background #updateScannerForm {
		flex: 0 0 60%;
	}
	.tab-content .active_user_account .multi_user_section button.upgrade_subscription_btn {
		height: 64px;
		font-size: 26px;
		max-width: 320px;
	}
	.passes_more_details p {
		font-size: 20px;
	}
	.user_detail .user_name h6, .user_detail .user_email h6 {
		font-size: 20px;
		line-height: 24px;
	}
	.user_detail .user_name span, .user_detail .user_email span {
		font-size: 20px;
		line-height: 24px;
	}
	.user_permissions td, .user_permissions th {
		font-size: 20px;
	}
	.user_permissions td {
		padding: 3px 0;
		font-size: 20px;
	}
	.passes_more_details .passess-details:last-child, .passes_full_more_details .passess-details:last-child {
		padding: 34px 36px 29px 42px;
	}
	.passes_more_details .passess-details:nth-child(2), .passes_full_more_details .passess-details:nth-child(2) {
		padding: 34px 36px 29px 35px;
	}
	#deleteScanner .modal-dialog .modal-header h4, #templateTypeLimitPopup .modal-dialog .modal-header h4 {
		font-size: 34px;
		line-height: 43px;
		margin-bottom: 25px;
	}
	#templateTypeLimitPopup .modal-dialog .modal-footer .button_upgrade, #templateTypeLimitPopup .modal-dialog .modal-footer .button_order {
	font-size: 24px;
	min-height: 70px;
	min-width: 200px;
}
	.form-group-container {
		font-size: 27px;
		line-height: 33px;
	}
	.form-group-container .checkmark {
		height: 37px;
		width: 37px;
	}
	.form-group-container .checkmark::after {
		width: 13px;
		height: 25px;
		border-width: 0 5px 5px 0;
	}
	h2 {
		font-size: 40px;
	}
	.passes_full_more_details .passess-details p {
		font-size: 20px;
	}
	.active_user_account {
		padding-bottom: 150px;
	}
	.header_login.sticky {
		padding-top: 20px;
	}
	.multi_user_section .user_invitation_container .form-group-btn input {
		font-size: 26px;
		max-width: 300px;
		height: 64px;
	}
	.multi_user_section h2 {
		font-size: 34px;
		line-height: 40px;
		margin-bottom: 20px;
	}
	.multi_user_section h4 {
		font-size: 26px;
		line-height: 30px;
	}
	.assocated_delete_btn {
		margin-left: 10px;
	}
	.profile_sec.same_profile_tab_style .profile-form_btn input[type="submit"] {
		font-size: 26px;
		height: 64px;
		max-width: 260px;
		min-width: 260px;
		padding: 14px 30px;
	}
	.multi_user_section .user_invitation_container .form-group-btn input {
		max-width: 260px;
	}
	.associated_user_list .associated_user_list_table td {
		font-size: 26px;
		line-height: 24px;
		padding-bottom: 26px;
	}
	.sign_up_page .sign_up_form .form-group.confPassGroup {
		margin-bottom: 25px;
	}
	form.sign_up_form.block_element label.container-s {
		margin-bottom: 20px;
	}
	.header_login {
		padding-top: 30px;
	}
	.Dummy-Stats .status_of_passses .status_gauge .status_upgrade {
		font-size: 13px;
	}
	.sign_up_page.sign_up h2 {
		margin-bottom: 55px;
	}
	.sign_up_page .sign_up_form .form-group.passwordformGroup {
		margin-bottom: 25px;
	}
	.my_pass_templates .order_pass p {
		font-size: 21px;
		line-height: 25px;
	}
	#addLicenseHolder .container {
		width: 100%;
	}	
	.myOrderPopup .btn.btn-default.submit_btn, #OrderConfirmation1 .btn.btn-default.submit_btn, #OrderConfirmation2 .btn.btn-default.submit_btn, #OrderConfirmation3 .btn.btn-default.submit_btn, #OrderConfirmation4 .btn.btn-default.submit_btn {
		font-size: 26px;
		max-width: 228px;
	}
	#loader img {
		width: 120px;
		height: 120px;
		top: 45%;
		left: 0;
		right: 0;
		margin: 0 auto;
	}
	.container {
        max-width: 960px;
    }
	.my_pass_templates .order_pass.no-template p {
		font-size: 30px;
		line-height: 30px;
	}
	#deleteUser .modal-footer #deletePopupDelete {
		font-size: 25px;
	}
	#deleteUser .modal-footer #deleteUserBtn {
		font-size: 25px;
	}
	#deleteUser .modal-footer #cancelDeleteUser {
		font-size: 25px;
	}
	.sign_up_form .inputText {
		font-size: 26px;
	}
	.subscription_table table .check_service {
		width: 24px;
		height: 24px;
	}
	.subscription_table table .check_service i {
		font-size: 17px;
	}
	img.cardTypePng {
		bottom: 7px;
	}
	.payment_method_form.sign_up_form button.upgrade_subscription_btn {
		padding: 12px 25px;
		font-size: 27px;
	}
	#members div#sendUser .modal-dialog .modal-header label, #sendLicense .modal-dialog .modal-header label {
	font-size: 32px;
	font-family: 'Museo Sans 100';
	color: #061F39;
	margin-bottom:0;
}
	.senduser .modal-body form input[type="text"], .senduser .modal-body form textarea {
		margin-bottom: 30px;
	}
	 #sendLicense .modal-body form textarea#mailtextarea {
		height: 243px !important;
	}
	.my_pass_main_section.slider_with_pending_temp section.banner-slider.bigger-slider div#main-slider .owl-item {
		max-width: inherit !important;
	}
	footer.padding_container .footer_block {
		padding: 0 30px;
	}
	.main_logo_heading_padding h3 {
    font-size: 32px;
    line-height: 38px;
    font-family: 'Museo Sans 300' !important;
}
	.adduser .modal-dialog .modal-body h2 {
		margin-bottom: 25px;
	}
	.adduser .modal-header h1 {
		font-size: 35px;
		font-family: 'Museo Sans 100';
	}
	.adduser .modal-body form {
		padding-top: 0px;
	}
	.my_pass_templates.no-dashboard .order_fornew_passes .pass-design-layout .pass-template-hover span {
		font-size: 33px;
		line-height: 43px;
	}
	.my_pass_templates .order_pass.no-template {
		margin-top: 7px;
		margin-bottom: 26px;
	}
	.order_fornew_passes .pass-design-layout .pass-template-hover span {
		font-size: 35px;
		line-height: 40px;
	}
	.select_subscription .modal-body {
		overflow-y: auto;
	}
    .my_pass_templates .tab_background .tab-pane {
        padding: 0;
    }

	.sign_up_page.forgot_page h2 {
		margin-bottom: 15px;
    }
    .sign_up_form .inputText {
        width: 100%;
    }
	.sign_up_page {
		padding-top: 60px;
		padding-bottom: 150px;
	}
	.wrapper.block_element {
		min-height: 500px;
	}
	.sign_up_page h2 , .icelandic_body .sign_up_page h2{
		font-size: 40px;
	}
	.forgot_page .write_down_email, .icelandic_body .forgot_page .write_down_email {
		margin-bottom: 40px;
		font-size: 18px;
    }
    .comming_soon .topleft img {
        max-width: 500px;
    }
    .owl-nav .owl-prev {
        background-size: contain !important;
        height: 40px;
        width: 40px;
        background-position: 1px 10px !important;
        left: -70px;
    }
	.pass-design-layout .pass-design-title .pass-design-content h3 {
		font-size: 45px;
	}
	.owl-nav .owl-prev {
		left: -30px;
	}
	.my_pass_main_section.my_passtwo_main_section section.banner-slider .owl-nav button.owl-prev {
		left: -50px;
	}
	.my_pass_main_section.my_passtwo_main_section section.banner-slider {
		padding-left: 40px;
	}
	.my_pass_main_section.my_passtwo_main_section section.banner-slider {
		width: 52%;
	}
	.my_pass_two_sections {
		width: 48%;
	}
	.owl-nav .owl-next {
        background-size: contain !important;
        height: 40px;
        width: 40px;
        background-position: 1px 10px !important;
        right: -24px;
    }
	.my_pass_templates .order_pass span.add_order i {
		font-size: 22px;
	}
	.my_pass_templates .order_pass.ordered_pending_passes span.order_dated {
		font-size: 14px;
	}
	.modal .modal-body .subscription_table {
		padding-bottom: 8px;
	}
	.modal .subscription_table table tbody .table-price td .month_validity {
		padding-top: 0px;
	}
	.have-subscription .subscription_table table tbody .table-price td .price_cutoff {
		padding-bottom: 3px;
	}
	#UpgradeSubscription .subscription_table table tbody tr.table-action-tab td.active-plan span {
		padding: 9px 40px;
	}
	.landing_page header .header_top_btns button.sign_in_btn {
		padding: 9px 17px;
		margin-left: 20px;
	}
	.landing_page header .header_top_btns button {
		font-size: 16px;
		padding: 6px 31px;
		line-height: 18px;
	}
	.landing_page header .navbar {
		padding: 35px 0 0;
	}
	.landing_content p {
		font-size: 24px;
		line-height: 32px;
		margin: 30px 0 50px;
	}
	.landing_content button {
		font-size: 25px;
		padding: 11px 34px;
	}
	h1 {
		font-size: 45px;
		line-height: 57px;
	}
	.landing_page header {
		padding-top: 20px;
	}
	.main_distribution_btn .distribution_btns, .icelandic_body .main_distribution_btn .distribution_btns {
		padding: 8px 15px;
		font-size: 14px;
	}
	.header_login {
		padding-bottom: 20px;
	}
	.status_main {
		float: left;
		width: 100%;
		overflow-x: auto;
		padding-bottom: 10px;
	}
	.status_main .status_table {
		max-width: 1024px;
		width: 1024px;
	}
	.my_pass_templates .tab_background {
		padding: 30px;
	}
	.passess-details {
		width: 48%;
		padding: 25px 25px 20px;
		margin-right: 4%;
	}
	.passes_more_details {
		max-width:100%;
	}
	/* .passes_more_details .passess-details {
		width: 50%;
	} */
	.passess-details .passes_counting .pass_number {
		font-size: 30px;
		line-height: 38px;
	}
	.passess-details p {
		font-size: 15px;
		margin-bottom: 2px;
	}
	.passess-details .passes_counting .pass_percantage {
		padding-left: 8px;
		font-size: 18px;
	}
	.same_status_section {
		padding: 25px;
		min-height: 350px;
	}
	.status_of_passses .status_gauge .status_upgrade {
		font-size: 16px;
		line-height: 21px;
	}
	.header_login {
		padding-top: 20px;
	}
	.my_pass_templates .tab-content {
		margin-bottom: 60px;
	}
	.subscription_table table tbody .table-price td .free_subscription_text::before {
		border-top: 10px solid transparent;
		border-bottom: 10px solid transparent;
		border-left: 10px solid #8ec976;
		right: 10px;
		top: 12px;
	}
	.subscription_sec .subsription_heading.padding_container {
		padding: 30px 0 40px 0;
	}
	.growing_section .template_types span {
		font-size: 16px;
	}
	.growing_section .template_types {
		margin-bottom: 15px;
	}
	.growing_section .remaining_days {
		font-size: 20px;
		margin-bottom: 10px;
	}
	.payment_method_form.sign_up_form .form-group-btn input[type="submit"] {
		padding: 7px 23px;
	}
	.growing_section .growing_heading {
		font-size: 25px;
		margin-bottom: 5px;
		line-height: 30px;
	}
	.subscription_exceeded span {
		font-size: 18px;
	}
	.upgrade_subsirption .upgrade_subscription_btn {
		padding: 7px 18px;
	}
	.payment_method_form.sign_up_form button.upgrade_subscription_btn {
		padding: 15px 25px;
	}
	.distribution_limit p {
		font-size: 15px;
		margin-bottom: 8px;
	}
	.subscription_table table tbody .table-price td .free_subscription_text {
		font-size: 20px;
		line-height: 22px;
	}
	.subscription_table table td {
		font-size: 15px;
	}
	.subscription_table table tbody .table-price td .price_kr {
		font-size: 22px;
		line-height: 33px;
	}
	.subscription_table table tbody .table-price td .price_cutoff {
		font-size: 16px;
	}
	.subscription_table table tbody .table-price td .month_validity {
		font-size: 13px;
		line-height: 17px;
	}
	.subscription_table table tbody .table-price td .free_subsription_btns {
		padding: 3px 22px;
		font-size: 20px;
	}
	.subscription_table table th {
		font-size: 18px;
		padding: 15px 30px;
	}
	.subscription_table table .check_service {
		width: 28px;
		height: 28px;
	}
	.subscription_sec .subsription_heading.padding_container h3 {
		font-size: 25px;
		padding-bottom: 12px;
	}

	.subscription_sec .subscription_table {
		overflow-x: auto;
		padding-bottom: 20px;
	}
	.same_profile_tab_style form h3 {
		margin-bottom: 50px;
	}
	.profile-form_btn button {
		padding: 7px 28px;
		font-size: 22px;
	}
	.forgot_page .sign_up_form .form-group-btn input[type="submit"] {
		margin-top: 15px;
	}
	.thanku_page {
		padding: 160px 0;
	}
	.same_profile_tab_style form {
		margin: 30px auto 50px;
	}
	.thanku_page h2 {
		margin-top: 30px;
		margin-bottom: 5px;
	}
	.thanku_page img {
		max-width: 100px;
	}
	.confirmation.text p {
		margin-top: 30px;
	}
	.sign_up_form .form-group-btn input[type="submit"] {
		padding: 6px 40px;
		font-size: 30px;
	}
	footer .footer_content .footer-left {
		float: left;
		width: 50%;
	}
	.sign_up_page h2 {
		margin-bottom: 60px;
	}
	.order_pass {
		padding: 128px 0 62px;
	}
	.my_pass_templates .order_pass p {
		margin-top: -64px;
	}
	.order_pass p {
		padding-top: 20px;
		font-size: 28px;
		line-height: 34px;
	}
	.order_pass span.add_order {
		height: 70px;
		width: 70px;
	}
	.start_ordering_passes .welocme_order_msg {
		padding-top: 40px;
		padding-bottom: 40px;
		margin-bottom: 60px;
    }
    .sign_up_page h2 {
        margin-bottom: 40px;
    }
    .sign_up_page .sign_up_form .form-group {
        margin-bottom: 55px;
    }
    .sign_up_form .form-group-btn input[type="submit"] {
        max-width: 180px;
    }
    .sign_up_form .form-group-btn input[type="submit"] {
        margin-top: 15px;
    }
	footer .footer_content .footer_rgt {
		float: left;
		width: 50%;
		padding-left: 360px;
    }
    .my_pass_main_section.slider_with_pending_temp section.banner-slider div#main-slider .owl-item {
        max-width: 350px !important;
	}
	
	.banner-section .owl-nav .owl-prev, .banner-section .owl-nav .owl-next  {
		top: 50%;
	}
	.main_logo_heading_padding h3 {
		margin: 40px 0 20px;
	}
	span.apexcharts-legend-text {
		width: 90%;
	}
	.sign_up_form {
		/* max-width: 400px; */
	}

	#termsCondition {
		max-width: 94%;
		margin: 0 auto;
	}
	#termsCondition h2 {
		font-size: 22px;
	}
	#termsCondition h3 {
		font-size: 18px;
	}

	.term-popup .modal-header .modal-title {
		font-size: 17px;
		padding-top: 5px;
	}

	.main_logo_heading_padding .same_status_section.codes_section h3 {
		margin: 0 0 15px;
		font-size: 25px;
	}
	#password.same_profile_tab_style form h3 {
		font-size: 30px;
		margin-bottom: 50px;
	}
	.subscription_sec .subsription_heading.padding_container h3 {
		font-size: 30px;
	}
	#subscription.tab-pane.subscription_sec div > .subsription_heading.padding_container {
		padding: 27px 0 50px;
	}
	.bg_color_section.block_element .nav.nav-tabs li a {
		font-size: 26px;
	}
	.profile-form_btn button, .profile-form_btn input[type="submit"], .upgrade_subsirption button.cancel_subscription {
		font-size: 30px;
	}
	.same_profile_tab_style form .form-group {
		margin-bottom: 50px;
	}
	.same_profile_tab_style form .form-group input {
		font-size: 26px;
	}
	.same_profile_tab_style form label {
		font-size: 26px;
		top: 8px;
	}
	.floating-label_group input:not(:placeholder-shown) ~ label, .floating-label_group input:focus ~ label, .floating-label_group input:not(:placeholder-shown) ~ label {
		top: -34px;
		font-size: 18px;
	}
	.profile-form_btn button, .profile-form_btn input[type="submit"], .upgrade_subsirption button.cancel_subscription {
		font-size: 26px;
		min-width: 300px;
	}
	.same_profile_tab_style form .form-group {
		margin-bottom: 45px;
	}
	.profile-form_btn {
		padding-top: 0px;
	}
	footer .footer_content {
		line-height: 1.75;
		width: 100%;
		display: flex;
		-moz-box-pack: justify;
		justify-content: space-between;
		margin-top: 20px;
	}
	footer .footer_content .footer_rgt {
		float: none;
		width: auto;
		padding-left: 0;
	}
	.thankyou_back {
		padding: 30px 30px;
	}
	.thanku_page {
		padding: 90px 0;
	}
	.passess-details {
		width: 49%;
		margin-right: 2%;
	}
	.main_distribution_btn .choosefile .choosefilebtn {
		padding: 8px 15px;
		line-height: 24px;
		font-size: 14px;
	}
	.main_distribution_btn .choosefile {
		margin-right: 10px;
	}
	.user-listing {
		margin-top: 20px;
	}
	.my_pass_templates .nav.nav-tabs {
		margin-bottom: 50px;
	}
	.my_pass_templates .nav.nav-tabs li a {
		margin-right: 50px;
	}
	.headerInside {
	padding: 0 0px;
}
.sign_up_form.block_element .forgot_password.block_element {
	margin-bottom: 35px;
}
.order_fornew_passes .add_order_disable.order_pass {
    padding: 182px 0 94px;
}
.dummy_data-content .dummy_data-content-logo {
    padding: 50px 10px 50px;
    width: 70%;
}
.upgrade_subsirption .cancel_subscription {
    margin-right: 20px;
    font-size: 23px;
    min-width: 260px;
}
#password .profile-form_btn {
    padding-top: 0px;
}
.codes_right .codes-image_section, .codes_right .self-code {
	width: 380px;
}
.codes_section .codes_left span {
	font-size: 17px;
}
.main_logo_heading_padding .same_status_section.codes_section h3 {
	margin: 0 0 10px;
}
footer.padding_container .footer_block {
	max-width: 960px;
	padding: 0 15px;
}
.passesDetailLimit.passess-details p, .pass_single_detail .passess-details p {
	font-size: 20px;
}
.template-secondary {
	height: 40px;
}
.select_subscription .have-subscription {
	max-width: 1000px;
}
.subscription_table table tbody tr td {
	width: 18%;
}
.modal.select_subscription table tbody tr.table-price td > div {
	padding: 0px 5px 0px 10px;
}
.subscription_table table {
	margin: 2px auto;
}
#UpgradeSubscription.select_subscription.modal .modal-body {
	padding: 0 30px 0px;
}
#subscription.tab-pane.subscription_sec div > .subsription_heading.padding_container {
    margin-bottom: 10px;
}
.payment_method_form.sign_up_form {
    margin-top: 50px;
    display: block;
    float: left;
}
#subscription.tab-pane.subscription_sec div > .subsription_heading.padding_container {
    padding: 27px 0 10px;
}
.modal.cancel_subscription .modal-body .modal_body_haeding {
    font-size: 34px;
    line-height: 43px;
    margin-bottom: 25px;
}
.same_status_section.codes_section .codes_right {
	max-width: 50%;
}
.subscription_table table tbody .table-price td .free_subsription_btns {
	padding: 11px 11px;
	font-size: 20px;
	min-width: 135px;
}
.subscription_table table tbody .table-price td .price_kr {
	font-size: 20px;
	line-height: 31px;
}
.subscription_table table tbody .table-price td .month_validity {
	font-size: 12px;
	line-height: 17px;
}
.subscription_table table tbody .table-price td .free_subscription_text {
	font-size: 17px;
	line-height: 22px;
}
.subscription_table table tbody .table-price td .free_subscription_text::before {
	right: 3px;
	top: 4px;
}
.subscription_table table.subscription_single_table tr td {
	padding: 14px 2px 14px 5px;
}
.standard-fee-note p {
	font-size: 22px;
}
.no-dashboard .order_fornew_passes .demo-pass-template {
	width: 369px;
}
.dummy_data-content .dummy_data-content-logo h3 {
	margin: 50px 0 34px;
	font-size: 34px;
	margin-bottom: 10px;
}
#addScannerForm .form_btn_group .addscanner_add_btn, #updateScannerForm .form_btn_group .addscanner_add_btn, .scanner_tab_delete_btn .scanner_btn.delete_scanner {
	font-size: 28px;
	height: 65px;
	width: 265px;
}
.my_scanner_page .my_scanner.start_ordering_scanner .tab_background #updateScannerForm {
	padding-top: 15px;
}
	}

@media only screen and (min-width:768px) and (max-width: 991px){
	.modal-open-again {
		position: fixed;
	}
	
}
@media only screen and (max-width: 1023px){
.select_subscription .have-subscription {
    max-width: 95%;
}
.modal .subscription_table table tbody .table-price td .month_validity{
	font-size: 10px;
}
.icelandic_body #UpgradeSubscription .subscription_table table tbody tr.table-action-tab td.active-plan span {
	font-size: 18px;
}
.passes_more_details .passess-details:nth-child(2), .passes_full_more_details .passess-details:nth-child(2) {
	padding: 20px 20px 15px 28px;
}
.passes_more_details .passess-details:last-child, .passes_full_more_details .passess-details:last-child {
	padding: 20px 20px 15px 28px;
}
.main_logo_heading_padding.start_ordering_scanner.my_scanner.padding_container .slider_with_pending_temp #banner-slider .codes_right .codes-image_section {
	width: 273px;
}
.main_logo_heading_padding.start_ordering_scanner.my_scanner.padding_container .slider_with_pending_temp .same_status_section.codes_section .codes_left {
	padding: 0;
}
.main_logo_heading_padding.start_ordering_scanner.my_scanner.padding_container .slider_with_pending_temp .same_status_section.codes_section h3 {
	font-size: 22px;
	top: 18px;
}
.main_logo_heading_padding.start_ordering_scanner.my_scanner.padding_container .slider_with_pending_temp #banner-slider .swiper-slide.item {
	width: 340px !important;
}
.form-group-container {
	padding-bottom: 10px;
	margin-bottom: 15px;
	padding-left: 45px;
}
#addScannerForm .form_btn_group {
	margin-top: 69px;
	padding-left: 0;
}
#addScannerForm .form_btn_group .addscanner_cancel_btn, #scanner .empty_add_scanner_tab .no_scanner_listed .form_btn_group .addscanner_cancel_btn {
	font-size: 24px;
	height: 62px;
	width: 180px;
	margin-right: 20px;
}
#addScannerForm .form_btn_group .addscanner_add_btn {
	font-size: 24px;
	height: 62px;
	width: 246px;
}
#addScannerForm .form-group input#nameofScannerInput {
	width: 100%;
}
.form-group-container .checkmark {
	top: -3px;
}
.my_scanner_page .main_logo_heading_padding.start_ordering_scanner.my_scanner > h3 {
	margin: 50px 0 45px;
}
#addScannerForm .form-group input#nameofScannerInput, #updateScannerForm .form-group input#nameofScannerInput {
	font-size: 25px;
	line-height: 33px;
	padding-bottom: 6px;
}
.my_scanner_page .my_scanner.start_ordering_scanner .tab_background #updateScannerForm {
	padding-top: 0;
}
.form-group-container {
	font-size: 24px;
}
.modal .subscription_table table tbody .table-price td .month_validity {
    font-size: 11px;
}
.passes_full_more_details .passess-details p {
	font-size: 16px;
}
.my_scanner_page .start_ordering_scanner.my_scanner .nav.nav-tabs li {
	padding-right: 25px;
} 
.start_ordering_scanner.my_scanner .order_scanner {
	margin-bottom: 20px;
}
.my_scanner .order_scanner.no-scanner p {
	font-size: 36px;
	line-height: 50px;
}
.order_fornew_scanner .start_ordering_scanner.my_scanner  {
	height: 326px;
	padding: 73px 0 70px;
	margin-bottom: 20px;
}
.form-group-container:first-child {
	margin-top: 30px;
	margin-bottom: 30px;
}
}
@media only screen and (max-width: 991px){
	.suportedAreaFooter{
		flex-direction: column;
		margin-right: 66px;
	}
	.suportedAreaFooter img {
        max-width: 225px;
	}
	.suportedAreaFooter span {
		margin-right: 85px;
	}
	.suportedAreaFooter.suportedAreaFooterIs {
		margin-right: 25px;
	}
	footer .footer_logo img {
		width: 335px;
	}
	footer .footer_content .footer-left {
		width: 75%;
	}
	.modal-open-again .modal, .modal-open .modal {
			padding-left: 0 !important;
		}
		#deleteUser .modal-dialog, #AssociatedDelete .modal-dialog, .adduser .modal-dialog {
			max-width: 90%;
		}
		.mobile-right .mobile_toggle_section {
	width: 30px;
}
.mobile-right {
	display: flex;
}
header.header_login .language_selector .users li img {
	max-width: 28px;
	cursor: pointer;
	height: 18px;
}
header.header_login .language_selector .current_language img {
	max-width: 28px;
	height: 18px;
}
.language_selector .users {
	left: 7px;
}
		#AssociatedDelete .modal-footer #cancelDeleteUser, .adduser .modal-footer #cancelDeleteUser {
			height: 61px;
		}
		 #AssociatedDelete .modal-title {
			font-size: 34px;
			padding-right: 50px;
			line-height: 43px;
			margin-bottom: 26px;
			font-family: 'Museo Sans 300';
		}
		#scanner .scanner_update_form {
			flex-direction: column;
		}
		#deleteUser .modal-footer #cancelDeleteUser, #AssociatedDelete .modal-footer #cancelDeleteUser, .adduser .modal-footer #cancelDeleteUser {
	height: 60px;
}
		.scanner_tab_delete_btn {
			margin-top: 20px;
		}
		.menu_untoggled.mobile_header_toggle_menu {
	float: right;
}
.language_selector {
	top: 8px;
}
		.my_scanner_page .my_scanner.start_ordering_scanner .tab_background #updateScannerForm {
			flex: 0 0 50%;
		}
		.passes_more_details p {
			font-size: 16px;
		}
		#deleteScanner .modal-dialog .modal-header h4 {
			padding-right: 50px;
		}
		body {
			padding-top: 127px;
		}
		.associated_user_list .associated_user_list_table td {
			font-size: 20px;
			line-height: 20px;
			padding-bottom: 22px;
		}
		#sendLicense #sendForm .form-group-cont, #sendUser #sendForm .form-group-cont {
			min-height: 250px;
		}
		.my_scanner_page .my_scanner.start_ordering_scanner .tab_background #updateScannerForm .form-group {
			max-height: 328px;
		}
		.status_main table td button.active {
			padding: 0 10px;
		}
		.container {
			max-width: 720px;
		}
		.banner-slider {
			margin-bottom: 30px;
		}
		#deleteUser .modal-footer #deletePopupDelete {
			padding: 18px 17px;
		}
		.active_user_account {
			padding-bottom: 120px;
		}
		.multi_user_section span {
			font-size: 18px;
		}
		
		.header_login {
			display: none;
		}
		.header_login.mobile_header {
			display: block;
		}
		.body_mobile_menu_show {
			overflow: hidden !important;
			height: 100vh;
			position: fixed;
		}
		.mobile_menu_list.show {
			padding: 75px 30px 35px;
			position: fixed;
			inset: 110px 0px 0px;
			z-index: 5;
			background: rgb(255, 252, 247) none repeat scroll 0% 0%;
			display: block;
			-webkit-transition: all 0.4s ease 0s;
			-moz-transition: all 0.4s ease 0s;
			transition: all 0.4s ease 0s;
			opacity: 1;
			pointer-events: auto;
			-webkit-transform: translate3d(0px, 0px, 0px);
			-moz-transform: translate3d(0px, 0px, 0px);
			transform: translate3d(0px, 0px, 0px);
			max-width: 99999em;
			visibility: visible;
			width: 100%;
			height: 100vh;
		}
		.mobile_menu_list .mobile_menu_tab_list ul li {
			padding-left: 0px;
			margin-bottom: 11px;
		}
		.mobile_menu_list .mobile_menu_tab_list ul {
			padding: 0;
			margin: 0;
			list-style: none;
		}
		.mobile_menu_list .mobile_menu_tab_list ul li a {
			color: #061F39;;
			font-size: 24px;
			line-height: 36px;
			text-decoration: none;
		}
		.mobile_menu_footer_list ul {
			margin: 0;
			padding: 0;
			list-style: none;
		}
		.mobile_menu_footer_list ul li a, .mobile_menu_footer_list ul li {
			color: #051F38;;
			font-size: 20px;
			line-height: 36px;
			margin-bottom: 15px;
			text-decoration: none;
		}
		.mobile_menu_signout button a {
			color: #FFFCF7;
			text-decoration: none;;
		}
		.mobile_menu_signout button {
			background: rgb(5, 31, 56) none repeat scroll 0% 0%;
			border: 1px solid rgb(87, 192, 217);
			box-sizing: border-box;
			border-radius: 30px;
			font-size: 20px;
			line-height: 24px;
			color: rgb(255, 252, 247);
			padding: 17px 20px;
			width: 100%;
			transition: all .5s;
		}
		.mobile_menu_signout button:hover {
			background: transparent;
			border: 1px solid rgb(5, 31, 56);
			color:rgb(5, 31, 56);
		}
		.mobile_menu_signout button:hover a {
			color:rgb(5, 31, 56);
		}
		.mobile_menu_signout {
			margin: 49px 0 64px;
		}

	.status_of_passses .status_gauge svg.speedometer {
		height: 177px!important;
	}
	.multi_user_section form input#email {
		height: 54px;
		margin-bottom: 5px;
	}
	.statisticsUpgrade span {
			font-size: 25px;
		}
		.banner-slider .template_issue_date {
			bottom: 6px;
			font-size: 14px;
		}
		#sendLicense .send-right .form-btn-btm {
			padding-left: 20px;
		}
	.same_status_section.codes_section .codes_left + .react-contextmenu-wrapper {
		width: 50%;
	}
	#subscription.tab-pane.subscription_sec div > .subsription_heading.padding_container {
		padding: 27px 0 10px;
		margin-bottom: 20px;
	}
	#termsCondition .modal-header h4.modal-title {
		font-size: 28px;
		line-height: 35px;
	}
	.bg_color_section.block_element.main_logo_heading_padding.my_pass_templates.my_profile_section.padding_container {
		padding-bottom: 0px;
	}
	.bg_color_section.block_element.main_logo_heading_padding.my_pass_templates.my_profile_section.padding_container {
		padding-left: 30px;
		padding-right: 30px;
	}
	.standard-fee-note p {
		font-size: 19px;
		line-height: 29px;
	}
	.standard-fee-note p br {
		display: none;
	}
	#subscription .subscription_table table tbody .table-price:last-child td {
		padding-left: 14px;
	}
	.subscription_table table tbody .table-price td .free_subsription_btns {
		padding: 11px 9px;
		font-size: 17px;
		min-width: 120px;
	}
	.modal-header {
		padding: 60px 60px 0px;
	}
	.banner-slider .swiper-wrapper .swiper-slide.template_without_image .pass_layout {
		max-height: 375px;
	}
	#termsCondition .modal-body p {
		font-size: 17px;
		line-height: 21px;
		margin-bottom: 16px;
	}
	.signinEmailErrorMsg {
		width: 285px;
		padding: 30px 20px 50px;
		top: 126px;
		right: 40px;
	}
	.signinEmailErrorMsg span {
		font-size: 17px;
	}
	.standard-fee-note p {
		font-size: 19px;
	}
	.modal #addUserForm .modal_form_field input {
		line-height: 20px;
	}
	#deleteUser .modal-dialog {
		max-width: 90%;
	}
	#deleteUser .modal-footer #cancelDeleteUser {
		margin: 0px 10px 0 0;
	}
	#deleteUser .modal-title, .adduser .modal-title, #deleteHolderList .modal-dialog .modal-header h4 {
		padding-right: 110px;
		line-height: 34px;
		margin-bottom: 20px;
	}
	#deleteUser .modal-title, .adduser .modal-title, #deleteHolderList .modal-dialog .modal-header h4 {
		font-size: 34px;
		padding-right: 110px;
	}
	#deleteUser .modal-footer #deleteUserBtn, #deleteUser .modal-footer #cancelDeleteUser {
		padding: 18px 30px;
	}
	#deleteScanner .modal-dialog .modal-footer #cancelDeleteUser, #deleteHolderList .modal-dialog .modal-footer #cancelDeleteUser, #deleteScanner .modal-dialog .modal-footer .deleteBtns #deleteUserBtn, #deleteHolderList .modal-dialog .modal-footer .deleteBtns #deleteUserBtn, #deleteUser .modal-footer #cancelDeleteUser, #AssociatedDelete .modal-footer #cancelDeleteUser, .adduser .modal-footer #cancelDeleteUser, #deleteHolder.adduser .deleteBtns #deleteUserBtn, #changeHolderStatus.adduser .deleteBtns #deleteUserBtn {
		font-size: 20px;
		padding: 18px 30px;
	}
	.adduser .modal-footer #cancelDeleteUser, #deleteHolder.adduser .deleteBtns #deleteUserBtn, #changeHolderStatus.adduser .deleteBtns #deleteUserBtn{margin-right:15px;}
	#deleteScanner .modal-dialog .modal-footer #cancelDeleteUser, #deleteHolderList .modal-dialog .modal-footer #cancelDeleteUser {
		margin-right: 15px;
	}
	#deleteScanner .modal-dialog .modal-footer .deleteBtns #deleteUserBtn, #deleteHolderList .modal-dialog .modal-footer .deleteBtns #deleteUserBtn {
		min-width: 150px;
	}
	
	#deleteUser .modal-footer #deletePopupDelete {
		font-size: 20px;
	}
	.senduser .modal-body form input[type="text"], .senduser .modal-body form textarea {
		height: 50px;
	}
	#deleteUser .modal-footer #cancelDeleteUser {
		font-size: 20px;
	}
	#deleteUser .modal-footer #deleteUserBtn {
		font-size: 20px;
	}
	.senduser .modal-body form input[type="text"], .senduser .modal-body form textarea {
		margin-bottom: 12px;
	}
	.MUIDataTablePagination-root-194:last-child {
		padding: 4px;
	}
	div#sendUser .send-right .form-btn-btm input.submit_btn {
		border-radius: 8px !important;
	}
	div#sendUser .modal-dialog .send-left .form-group .custom-errorMsg {
		bottom: -8px;
	}
	p.card_number_text_value {
		font-size: 30px;
	}

	.modal.myOrderPopup p.card_number_text_value button {
		font-size: 18px;
		padding: 9px 15px;
	}
	span.card_number_value {
		margin-top: -19px;
	}
	.modal .template_build_inner span {
		font-size: 18px;
	}
	.modal .toatl_vat_price span {
		font-size: 24px;
	}
	.modal.myOrderPopup .modal_form_field button {
		top: -6px;
	}
	#members div#sendUser .modal-dialog .modal-header label {
		font-size: 32px;
	}
	.MUIDataTablePagination-root-194:last-child {
		padding: 0px 0px 0px 0px;
	}
	.sign_up_form .form-group-btn input[type="submit"] {
		padding: 7px 32px;
		font-size: 30px;
	}
	.adduser .modal-dialog, .addLicenseHolderModal .modal-dialog{
		max-width: 90%;
	}
	.adduser .modal-dialog .modal-body h3, .addLicenseHolderModal .modal-dialog .modal-body h3{
		font-size: 18px;
	}
	.browsedCsvContainer {
		padding: 10px 0 6px;
	}
	.adduser .modal-header h1,  .addLicenseHolderModal .modal-header h1  {
		font-size: 32px;
	}

	.adduser.modal .modal-body h4,  .addLicenseHolderModal.modal .modal-body h4 {
		font-size: 18px;
	}
	.my_pass_templates.no-dashboard .order_fornew_passes .pass-design-layout .pass-template-hover span {
		font-size: 22px;
		line-height: 33px;
	}
	.no-dashboard .order_fornew_passes .demo-pass-template .pass-design-layout {
		min-height: 360px;
	}
    .my_pass_main_section.slider_with_pending_temp .order_pass.ordered_pending_passes {
		width: 206px;
		margin-right: 2%;
    }

    .my_pass_main_section.slider_with_pending_temp section.banner-slider {
        width: calc(100% - 370px - 4%);
	}
	.banner-section .owl-item .item {
		max-width: 290px;
	}
    .my_pass_templates .order_pass {
        width: 160px;
	}
	#UpgradeSubscription .sending_passes_heading h3 {
		font-size: 28px;
		line-height: 25px;
	}
	.sending_passes_heading span {
		font-size: 17px;
		line-height: 20px;
	}
    .owl-nav .owl-prev {
        height: 35px;
        width: 35px;
        left: -8px;
    }
    .owl-nav .owl-next {
        height: 35px;
        width: 35px;
        right: -19px;
    }
    .owl-item .item .pass_layout {
        height: 420px;
    }
    .my_pass_templates .order_pass span.add_order {
        margin-top: 0;
    }
    .sign_up_page.forgot_page {
        min-height: 615px;
    }
	header .header_logo_section .logo img {
		width: 260px;
	}
	.my_pass_templates .order_pass p {
		font-size: 15px;
		line-height: 21px;
	}
	.passesDetailLimit.passess-details p, .pass_single_detail .passess-details p {
		font-size: 16px;
	}
	.landing_page header .navbar ul li {
		padding-right: 25px;
	}
	.landing_content p {
		font-size: 22px;
		line-height: 30px;
		margin: 22px 0 40px;
	}
	h1 {
		font-size: 38px;
		line-height: 48px;
	}
	.landing_content button {
		font-size: 22px;
		padding: 9px 26px;
	}
	.landing_page header .navbar ul li a {
		font-size: 15px;
	}
	.landing_page header .navbar ul li:last-child {
		padding-right: 0;
	}
	footer {
		padding-top: 40px;
	}
	.my_pass_templates .tab_background {
		padding: 25px;
	}
	.passess-details {
		padding: 20px 20px 15px;
	}
	.passess-details .passes_counting .pass_number {
		font-size: 25px;
		line-height: 36px;
	}
	.passess-details .passes_counting .pass_percantage {
		padding-left: 6px;
		font-size: 16px;
	}
	.passes_more_details {
		margin-top: 20px;
	}

	.same_status_section p {
		font-size: 16px;
		line-height: 19px;
	}
	footer.padding_container .footer_block {
		max-width: 720px;
	}
	.status_of_passses.block_element {
		padding-top: 20px;
	}
	.same_status_section {
		padding: 20px;
	}
	.status_of_passses .status_gauge .status_upgrade {
		font-size: 15px;
		line-height: 20px;
	}
	.same_profile_tab_style form {
		margin: 20px auto 50px;
	}
	.profile-form_btn button {
		padding: 7px 22px;
		font-size: 20px;
	}
	.same_profile_tab_style form {
		max-width: 720px;
	}
	.my_pass_templates .nav.nav-tabs li a {
		padding-bottom: 15px;
		font-size: 16px;
		margin-right: 50px;
	}
	.my_pass_templates.my_profile_section .nav.nav-tabs {
		margin-bottom: 11px;
	}
	.user_account .user_current i {
		font-size: 16px;
	}
	h3{
		font-size: 22px;
	}
	.user_account .user_current img {
		width: 20px;
		margin-right: 6px;
	}
	.same_profile_tab_style form h3 {
		margin-bottom: 35px;
	}
	.user_account .user_current span {
		font-size: 16px;
		padding-right: 12px;
	}
	.confirmation.text span {
		font-size: 20px;
	}
	.status_main {
		float: left;
		width: 100%;
		overflow-x: hidden;
		padding-bottom: 10px;
	}
	.confirmation.text p {
		margin-top: 20px;
	}
	.order_pass {
		padding: 71px 0 49px;
	}
	.my_pass_templates .order_pass p {
		margin-top: -82px;
	}
	.my_pass_templates .order_pass.ordered_pending_passes p {
		font-size: 21px;
	}
	.order_pass span.add_order {
		height: 60px;
		width: 60px;
	}
	.welocme_order_msg p {
		font-size: 25px;
		line-height: 32px;
	}
	.welocme_order_msg span {
		font-size: 15px;
	}
	.thanku_page img {
		max-width: 80px;
	}
	.confirmation.text .circle {
		width: 28px;
		height: 28px;
	}
	h2 {
		font-size: 30px;
		line-height: 40px;
	}
	.header_login {
		padding-top: 20px;
	}
	.create_account {
		padding-top: 30px;
	}
	.sign_up_form .form-group-btn input[type="submit"] {
		margin-top: 15px;
	}
	.forgot_page .write_down_email {
		margin-bottom: 40px;
		font-size: 16px;
	}
	.thanku_page h2 {
		font-size: 44px;
	}
	.thanku_page {
		padding: 90px 0;
		height: 100vh;
	}
	.forgot_page .sign_up_form .form-group-btn input[type="submit"] {
		margin-top: 0;
	}
	.confirmation.text span {
		font-size: 26px;
		margin-top: 17px;
	}
	.start_ordering_passes .welocme_order_msg {
		padding-top: 30px;
		padding-bottom: 30px;
	}
	header.header_login .header_logo img, .thankyou_back img {
		max-width: 260px;
	}
	.sign_up_page.forgot_page h2 {
		margin-bottom: 5px;
	}
	.order_pass p {
		padding-top: 15px;
		font-size: 25px;
		line-height: 34px;
	}
	.main_distribution_btn > .distribution_btns[data-target="#sendUser"] {
		margin: 10px auto 25px;
	}
	.codes_section .codes_left {
		float: left;
		width: 50%;
		padding-right: 15px;
	}
	.main_logo_heading_padding .same_status_section.codes_section h3 {
		margin: 0 0 12px;
	}
	.codes_section h3 {
		font-size: 20px;
	}
	.my_pass_templates .nav.nav-tabs {
		margin-bottom: 40px;
	}
	.codes_section {
		margin-bottom: 20px;
	}
	.codes_section .codes_right {
		float: left;
	}
	.main_logo_heading_padding h3 {
		margin: 10px 0 10px;
	}
	div#sendUser .modal-dialog,  #sendLicense .modal-dialog {
		max-width: 95%;
	}
	div#sendUser .modal-dialog .pass_layout, #sendLicense .modal-dialog .pass_layout{
		width: 100%;
	}
	div#sendUser .modal-dialog .send-right {
		padding-left: 0px;
	}
	.send-right .form-btn-btm {
		display: flex;
	}
	div#sendUser .send-right .form-btn-btm input.submit_btn.no_to_passes {
		margin-right: 4%;
	}
	div#sendUser .send-right .form-btn-btm input.submit_btn {
		margin-right: 0;
	}
	div#sendUser  .modal form {
		padding-top: 30px;
    }
    .my_pass_main_section.slider_with_pending_temp section.banner-slider div#main-slider .owl-item {
        max-width: 300px !important;
	}
	section.uploadUser-sec {
		padding: 20px;
	}
	.codes_section .codes_left {
		float: left;
		width: 100%;
		padding-right: 0;
		padding-bottom: 15px;
	}
	.codes_section .codes_left span br {
		display: none;
	}
	.same_status_section.codes_section .react-contextmenu-wrapper {
		margin: 0 auto;
	}
	.same_status_section.codes_section .codes_right {
		padding-left: 0;
		margin: 0 auto;
	}
	.choosefile button.choosefilebtn {
		font-size: 14px;
		line-height: 27px;
	}
.main_distribution_btn button.pass_limit_reached.distribution_btns {
    margin-top: 0;
    margin-right: 15px;
    margin-left: 0;
}
.main_distribution_btn {
    display: flex;
    flex-wrap: wrap;
}
.main_distribution_btn button.pass_limit_reached.distribution_btns {
    margin-top: 0;
    margin-right: 15px;
    margin-left: 0;
    line-height: 26px;
}
#AssociatedDelete .modal-footer #cancelDeleteUser, .adduser .modal-footer #cancelDeleteUser,  #AssociatedDelete .modal-footer #deleteUserBtn, .adduser .modal-footer #deleteUserBtn, #AssociatedDelete .modal-footer #cancelDeleteUser, .adduser .modal-footer #cancelDeleteUser {
	font-size: 27px;
	padding: 15px 40px;
}
#AssociatedDelete .modal-footer {
	padding-left: 30px;
	padding-right: 30px;
}
#AssociatedDelete .modal-header .close {
	right: 27px;
	top: 30px;
}

.modal-dialog {
	max-width: 90%;
}
.modal-dialog .modal-content p.modal_body_haeding {
	padding-right: 50px;
}
.modal-header .close {
	right: 27px;
	top: 11px;
}
#OrderConfirmation1 .modal-dialog .modal-content p.modal_body_haeding, #OrderConfirmation2 .modal-dialog .modal-content p.modal_body_haeding, #OrderConfirmation3 .modal-dialog .modal-content p.modal_body_haeding, #OrderConfirmation4 .modal-dialog .modal-content p.modal_body_haeding, .modal-dialog .modal-content p.modal_body_haeding {
	font-size: 36px;
	line-height: 42px;
	margin-bottom: 20px;
}
.myOrderPopup .btn.btn-default.submit_btn, #OrderConfirmation1 .btn.btn-default.submit_btn, #OrderConfirmation2 .btn.btn-default.submit_btn, #OrderConfirmation3 .btn.btn-default.submit_btn, #OrderConfirmation4 .btn.btn-default.submit_btn {
	font-size: 28px;
	max-width: 220px;
	width: 100%;
	height: 66px;
}
#OrderConfirmation2 .receipt_section .thakyou_msg, .modal .receipt_section .thakyou_msg, #OrderConfirmation1 .modal-dialog .modal-content p.thakyou_msg, #OrderConfirmation2 .modal-dialog .modal-content p.thakyou_msg, #OrderConfirmation3 .modal-dialog .modal-content p.thakyou_msg, #OrderConfirmation4 .modal-dialog .modal-content p.thakyou_msg {
	font-size: 36px
}
.my_pass_templates .nav.nav-tabs li a {
	padding-bottom: 15px;
	font-size: 16px;
	margin-right: 30px;
}
.bg_color_section.block_element .nav.nav-tabs li a {
	font-size: 20px;
	line-height: 17px;
}
.main_distribution_btn > .distribution_btns[data-target="#sendUser"] {
	margin: 10px auto 10px;
}
.status_of_passses .status_gauge .status_upgrade {
	font-size: 13px;
}
.main_distribution_btn > .distribution_btns[data-target="#sendUser"] {
	margin: 0px 10px 10px 0px;
}
#addUserForm .floating-label_group select {
	padding: 8px 10px;
	font-size: 22px;
}
#myOrderPopup1 .isNotActive, #myOrderPopup1 .isActive, #myOrderPopup1 input.btn-danger {
	height: 66px;
	min-width: 210px;
	font-size: 28px;
}
#myOrderPopup1 input.btn-danger {
	margin-right: 10px;
}
.my_pass_templates.no-dashboard .order_pass.ordered_pending_passes {
    width: 191px;
}
.no-dashboard .order_fornew_passes .demo-pass-template .pass-design-layout {
    min-height: 295px;
}
.my_pass_templates .dashboard-order.order_pass.no-template, .my_pass_templates.no-dashboard .order_pass.ordered_pending_passes, .pass-design-layout {
    min-height: 296px;
}
.no-dashboard .order_fornew_passes .demo-pass-template {
    width: 250px;
    margin-top: 35px;
    padding: 20px;
}
.no-dashboard .order_fornew_passes .demo-pass-template .pass-design-layout img {
	height: 375px;
}
.order_fornew_passes .add_order_disable.order_pass {
    padding: 60px 0 60px;
}
.my_pass_templates .order_pass.ordered_pending_passes {
    padding: 48px 0 70px;
}
.my_pass_templates .order_pass.ordered_pending_passes p {
    font-size: 20px;
    margin-top: 47px;
}
.modal-header .close {
    right: 47px;
    top: 31px;
}
.profile-form_btn button, .profile-form_btn input[type="submit"], .upgrade_subsirption button.cancel_subscription {
    font-size: 24px;
    min-width: 300px;
}
.upgrade_subsirption .upgrade_subscription_btn {
    padding: 15px 14px;
}
button.upgrade_subscription_btn {
    font-size: 24px;
}
#password.same_profile_tab_style form .form-group {
    margin-bottom: 50px;
}
.same_profile_tab_style form {
    max-width: 100%;
}
.codes_right .codes-image_section {
    width: 240px;
    min-height: initial;
}
.codes_right .self-code {
	min-width: 240px;
	width:100%;
}
#members .no_listing_text {
	margin-top: 5px;
}
.main_logo_heading_padding h3 {
	font-size: 28px;
	line-height: 32px;
}
.front_layout.home .tab-content.tab_background.tab_background_usertab {
	padding-bottom: 30px;
}
#users .no_and_search_samestyle span, #members .no_and_search_samestyle span {
	font-size: 18px;
}
.same_status_section.codes_section .codes_right {
    max-width: 260px;
}
.codeLinkSection, .statisticsUpgrade {
    padding: 50px 0 0px;
}
.my_pass_main_section.slider_with_pending_temp section.banner-slider.bigger-slider {
    width: calc(100% - 160px - 29px);
}
.my_pass_templates .order_pass {
	height: 375px;
}
.banner-slider .swiper-button-prev {
	background-size: 27px 12px;
	height: 45px;
	width: 45px;
	background-position: 9px 15px;
}
.banner-slider .swiper-button-next {
	background-size: 27px 12px;
	background-repeat: no-repeat;
	height: 45px;
	width: 45px;
	background-position: 9px 15px;
}
.my_pass_templates .order_pass p {
    font-size: 20px;
    line-height: 22px;
}
.my_pass_templates .slider_with_pending_temp .order_pass.ordered_pending_passes {
    padding: 53px 0 50px;
}
.my_pass_main_section.slider_with_pending_temp section.banner-slider {
    width: calc(100% - 386px - 29px);
}
.my_pass_main_section.slider_with_pending_temp .add_order_disable.dashboard-order.order_pass {
    width: 171px;
}
.my_pass_templates .order_pass.no-template p {
	font-size: 20px;
}
.my_pass_templates .dashboard-order.order_pass.no-template {
	width: 180px;
}
.order_fornew_passes .order_pass {
	margin-right: 29px;
}
.order_fornew_passes .demo-pass-template {
	width: 337px;
}
.banner-slider img {
	max-height: 325px;
}
.banner-slider .swiper-wrapper .swiper-slide {
    width: 273px !important;
    padding: 31px 0;
}
.swiper-container::after {
	display: none;
}
.modal.myOrderPopup .modal_form_field button {
	top: 8px;
}
.order_fornew_passes {
	margin-bottom: 70px;
}
.Dummy-Stats .status_graph.same_status_section img {
	margin-top: 0;
}
.main_logo_heading_padding .same_status_section.codes_section h3 {
	line-height: 30px;
}
.my_pass_main_section.slider_with_pending_temp .order_pass {
	margin-right: 23px;
}
.banner-slider .swiper-wrapper .swiper-slide .template_image_coupon::after, .banner-slider .swiper-wrapper .swiper-slide .template_image_coupon::before {
	max-width: 224px;
}
.banner-slider .swiper-wrapper .swiper-slide.template_without_image .pass_layout.generic-pass-layout {
	max-height: 375px;
}
.profile-form_btn button, .profile-form_btn input[type="submit"] {
	padding: 15px 30px;
}
.template-headerSection.d-flex.justify-content-between .template-logo {
	width: 180px;
}
.template-headerSection.d-flex.justify-content-between .template-logo img {
	width: 100%;
}
.pass_layout.generic-pass-layout::before, .template-background.generic-pass-layout::before {
	bottom: -2px;
}
.pass_layout.generic-pass-layout::after, .template-background.generic-pass-layout::after {
	top: -3px;
}
.template-barcode {
	max-width: 182px;
}
.template-altText {
	font-size: 11px;
}
.codes_right .codes-image_section {
    width: 290px;
}
.same_status_section.codes_section .codes_right {
	max-width: 290px;
	margin-right: 0;
}
.same_status_section.codes_section .codes_left {
    width: 100%;
}
.same_status_section.codes_section {
    flex-direction: column;
}
.same_status_section.codes_section .codes_right {
    max-width: 100%;
    margin-right: auto;
}
.codes_right .codes-image_section {
    width: 100%;
}
.same_status_section.codes_section .codes_left + .react-contextmenu-wrapper {
    width: 100%;
}
.same_status_section.codes_section.codes-scaaner_two .codes-image_section img {
	max-width: 100%;
	width: 100%;
}
.same_status_section.codes_section .react-contextmenu-wrapper {
	width: 100%;
}
.status_of_passses .status_gauge .status_upgrade {
	margin: 30px auto 0;
}
.modal .modal-body .subscription_table {
	max-width: 1000px;
	width: 1000px;
	padding-right: 30px;
}

.select_subscription .modal-header {
	padding-left: 30px;
	padding-top: 43px;
}
.subscription_table table td {
	font-size: 13px;
}
.subscription_table table tbody .table-price td .price_kr {
	font-size: 18px;
	line-height: 29px;
}
.subscription_table table tbody .table-price td .price_cutoff {
	font-size: 14px;
}
.subscription_sec .padding_container table {
	width: 800px;
	float: left;
	max-width: 800px;
}
.privacy_policy_page .container > span {
	font-size: 36px;
	line-height: 43px;
	margin-bottom: 22px;
}
.privacy_policy_page h2 {
	font-size: 24px;
	line-height: 29px;
	margin-bottom: 15px;
}
.privacy_policy_page p, .privacy_policy_page  ul li, .privacy_policy_page  ol li {
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 35px;
}
.privacy_policy_page  ul, .privacy_policy_page  ol {
	margin-bottom: 35px;
}
.privacy_policy_page  ul li, .privacy_policy_page  ol li {
	margin-bottom: 5px;
}
.privacy_policy_page  table td {
	font-size: 18px;
	line-height: 22px;
}
.privacy_policy_page {
	padding: 83px 15px 0;
}
.menu_untoggled.mobile_header_toggle_menu {
	cursor: pointer;
}
#addLicenseHolder .container .addLicenseHolderForm.sign_up_form.block_element {
	max-width: 100%;
}
#addLicenseHolder .container {
	max-width: 100%;
}
#addLicenseHolder .form-group:nth-last-child(2) {
	margin-bottom: 20px;
}
.senduser .modal-body form textarea#mailtextarea {
	height: 278px;
}
#sendUser .modal-body form input[type="text"]{
	height: 55px;
}
div#sendUser .modal-dialog .send-left .send_pass_with_image {
	padding-left: 20px;
}
div#sendUser .modal-dialog .send-right .form-btn-btm {
	padding-left: 20px;
}
div#sendUser input#sendCancel {
	margin-right: 4%;
	width: 48% !important;
}
#deleteHolder.adduser .deleteBtns #deleteUserBtn, #changeHolderStatus.adduser .deleteBtns #deleteUserBtn {
	max-width: 160px;
}
	}


		@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation : landscape){
			.mobile_menu_list.show {
				height: 100%;
			}
			.mobile_menu_list.show, .mobile_menu_list {
				overflow-y: scroll;
				padding-bottom: 120px;
			}
			.mobile_menu_footer_list {
				margin-bottom: 30px;
			}
		}
		
		@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : landscape){
			.mobile_menu_list.show {
				height: 100%;
			}
			.mobile_menu_list.show, .mobile_menu_list {
				overflow-y: scroll;
				padding-bottom: 120px;
			}
			.mobile_menu_footer_list {
				margin-bottom: 30px;
			}
		}
		@media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : landscape){
			.mobile_menu_list.show {
				height: 100%;
			}
			.mobile_menu_list.show, .mobile_menu_list {
				overflow-y: scroll;
				padding-bottom: 120px;
			}
			.mobile_menu_footer_list {
				margin-bottom: 30px;
			}
		}

@media only screen and (max-width: 767px){
	*::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}
	.icelandic_body .pachageArea .AreaFull {
	font-size: 14px;
	padding: 15px 12px;
}
footer .footer_content {
	padding-top: 0px;
	margin-top: 0px;
}
#paymentFailed .modal-dialog .modal-content .modal-body p{
	font-size: 18px;
}
.suportedAreaFooter {
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	margin-bottom: 10px;
	float: none;
	display: block;
}

.suportedAreaFooter span {
	font-size: 18px;
	line-height: 50px;
	margin-right: 0px;
}

.suportedAreaFooter img {
	width: 280px!important;
}
.modal.myOrderPopup .modal_form_field button {
	top: 10px;
}
header.header_login .language_selector .users li img {
	max-width: 28px;
	cursor: pointer;
	height: 18px;
}
.same_profile_tab_style form .form-group.payment_api_section h2 {
	font-size: 24px;
	line-height: 30px;
}
.same_profile_tab_style form .form-group.payment_api_section span {
	font-size: 16px;
	line-height: 20px;
	display:inline-block;
}
.profile_form_group_select .floating-label_group select {
	font-size: 21px;
	line-height: 25px;
	padding:6px 0px;
}
.mobile_toggle_section {
	float: left;
	width: 31px;
	text-align: right;
}
.menu_untoggled.mobile_header_toggle_menu {
	cursor: pointer;
	float: right;
}
.language_selector .users {
	padding: 0 5px 4px;
}
#deleteUser .modal-footer #deleteUserBtn {
	max-width: 100%;
}
.modal-footer {
	padding-left: 30px;
	padding-right: 30px;
}
.icelandic_body .send-right .allSelectedMailList div .MuiToolbar-root.MuiToolbar-regular {
	padding: 0;
}
.icelandic_body #addUser #addBtn {
	padding: 20px 15px !important;
}
.icelandic_body #addUser #uploadCancel {
	padding: 20px 12px !important;
}
.icelandic_body .standard-fee-note p {
	font-size: 23px;
}
p.alert.alert-danger.expiry-note {
	font-size: 18px;
}
.pachageArea .AreaFull .ValueMain.NegotiableVlaueMain {
	font-size: 14px;
}
		.my_scanner_page .my_scanner.start_ordering_scanner .tab_background #updateScannerForm p{
			font-size: 22px;
		}
		.scanner_tab_delete_btn {
			margin-top: 0px;
		}
		.mobile_header .mobile_menu_list.show .mobile_menu_footer_list ul li {
			cursor: pointer;
		}
		.active-plan > div span {
			color: #1b3f71;
		}
		.modal .have-subscription .modal-body .subscription_table{
			display: none;
		}
		.modal .unsbscribed_account .modal-body .subscription_table{
			display: none;
		}
		.mobile_header .mobile_menu_list.show .mobile_menu_footer_list ul li {
			cursor: pointer;
		}
		.passes_more_details .passess-details:nth-child(2), .passes_full_more_details .passess-details:nth-child(2) {
			width: 100%;
		}
		.adduser .modal-footer #cancelDeleteUser {
			height: auto;
		}
		.tab-content-subscription.tab_background.block_element{
			background-color: #fffcf7;
		}
		.associated_user_list .associated_user_list_table tr {
			display: flex;
			flex-direction: column;
		}
		.associated_user_list .associated_user_list_table td {
			font-size: 20px;
			line-height: 20px;
			padding-bottom: 22px;
			width: 100%;
		}
		#AssociatedDelete .modal-footer #cancelDeleteUser, .adduser .modal-footer #cancelDeleteUser {
			height: 55px;
		}
		#AssociatedDelete .modal-footer #deleteUserBtn, .adduser .modal-footer #deleteUserBtn {
			max-width: 48%;
			width: 100%;
			margin-left: 4%;
			margin-right: 0;
			font-size: 21px;
		}
		#AssociatedDelete .modal-footer #cancelDeleteUser {
			max-width: 48%;
			width: 100%;
			margin: 0;
			font-size: 21px;
		}
		#userPermission .modal-footer {
			padding-left: 30px;
			padding-right: 30px;
		}
		#userPermission .deleteBtns .btn.btn-danger {
			max-width: 48%;
			font-size: 21px;
			padding: 15px 40px !important;
			width: 100%;
			margin-left: 0;
			margin-right: 4%;
			min-width: 50%;
		}
		#userPermission h4 {
			font-size: 26px;
			line-height: 30px;
			margin-bottom: 10px;
		}
		.associated_user_list {
			position: relative;
			z-index: 1;
		}
		#userPermission .deleteBtns button {
			max-width: 48%;
			font-size: 21px;
			padding: 15px 40px !important;
			width: 100%;
			border-radius: 50px;
			min-width: 50%;
		}
		#userPermission .deleteBtns {
			display: flex;
			flex-wrap: unset;
		}
		.user_permissions tr th:nth-child(2) > span {
			margin-right: 10px;
		}
		.userPermission .modal-dialog .modal-content .modal-body .user_permissions .switch {
			width: 50px;
			height: 29px;
		}
		.userPermission .modal-dialog .modal-content .modal-body .user_permissions input:checked + .slider::before {
			-webkit-transform: translateX(16px);
			transform: translateX(16px);
		}
		.user_detail .user_name span, .user_detail .user_email span, .user_detail .user_name h6, .user_detail .user_email h6 {
			font-size: 18px;
		}
		.user_permissions td, .user_permissions th {
			font-size: 18px;
		}
		.user_permissions td {
			padding: 5px 0;
			font-size: 16px;
		}
		.associated_user_list {
			padding-bottom: 10px;
			margin-bottom: 30px;
		}
		.multi_user_section form input#email {
			font-size: 14.75px;
		}
		.active_user_account {
			padding: 0 15px;
		}
		.active_user_account .multi_user_section span:nth-child(3) {
			margin-bottom: 20px;
		}
		.associated_user_list .associated_user_list_table td:last-child {
			text-align: left;
			width: 100%;
		}
		.statisticsUpgrade button {
			max-width: 280px;
			width: 100%;
			font-size: 22px;
			height: auto;
		}
		.passes_more_details .passess-details:nth-child(2), .passes_full_more_details .passess-details:nth-child(2), .passes_more_details .passess-details:last-child, .passes_full_more_details .passess-details:last-child {
			padding: 20px 20px 15px;
		}
		.statisticsUpgrade span {
			margin-right: 0;
		}
		.pass_single_detail {
			flex-direction: column;
		}
		div#sendUser .modal-dialog .modal-content.bg_color_section, #sendLicense .modal-dialog .modal-content.bg_color_section {
			min-height: initial;
		}
		#scanner .form-group:nth-child(2) {
			max-height: 320px;
			overflow-y: auto;
			position: relative;
			z-index: 1;
		}
		#sendLicense #sendForm .form-group-cont, #sendUser #sendForm .form-group-cont {
			min-height: 100px;
		}
		div#sendUser .modal-dialog .send-left .form-group-cont {
			width: 100%;
		}
		.mobile_menu_list.show {
			padding: 10px 0px 0px;
		}
		.mobile_menu_list .mobile_menu_nav {
			padding: 0px 30px 35px;
			overflow-y: auto;
			height:79%;;
		} 
		#deleteScanner .modal-dialog .modal-body p, #deleteHolderList .modal-dialog .modal-body p, #deleteHolder .modal-dialog .modal-body p {
			margin-bottom: 5px;
		}
		#changeHolderStatus .modal-body, #deleteHolder .modal-body {
			margin-bottom: 0px;
		}
		#deleteScanner .modal-dialog .modal-body, #deleteHolderList .modal-dialog .modal-body {
			padding-bottom: 20px;
		}
		div#sendUser .modal-dialog .send-left .send_pass_with_image {
			width: 100%;
			padding-left: 0;
		}
		div#sendUser .modal-dialog .send-left .send_pass_with_image img {
			width: 100%;
			max-height: initial;
			max-width: 260px;
			margin: 0 auto;
			display: flex;
		}
		#deleteHolderList .modal-footer, #deleteScanner {
			padding-left: 30px;
			padding-right: 30px;
		}
		#sendLicense .modal-body form input[type="text"] {
			max-width: 100%;
		}
		#deleteScanner .modal-dialog .modal-footer .deleteBtns #deleteUserBtn, #deleteHolderList .modal-dialog .modal-footer .deleteBtns #deleteUserBtn {
			min-width: initial;
			width: 100%;
			max-width: 100%;
			padding: 10px 25px !important;
			margin: 0 0 8px;
		}
		#deleteScanner .modal-dialog .modal-footer #cancelDeleteUser, #deleteHolderList .modal-dialog .modal-footer #cancelDeleteUser {
			margin-right: 0px;
		}
		
		#sendLicense .send-right .form-btn-btm {
			padding-left: 0;
			width: 100%;
			margin-top: 30px;
		}
		.pass_single_detail .passess-details {
			width: 100%;
		}
		.form-group-container .checkmark {
			height: 30px;
			width: 30px;
		}
		.passes_more_details {
			flex-direction: column;
		}
		#deleteScanner .modal-dialog .modal-body p, #templateTypeLimitPopup .modal-dialog .modal-body p {
			font-size: 18px;
		}
		#templateTypeLimitPopup .modal-dialog .modal-body {
		max-width: 100%;
		padding-right: 15px;
}
 #templateTypeLimitPopup .modal-dialog .modal-body {
    padding-bottom: 40px;
}
#templateTypeLimitPopup .modal-dialog .modal-footer {
	padding-right: 30px;
	padding-left: 30px;
}
#templateTypeLimitPopup .modal-dialog .modal-footer .button_upgrade, #templateTypeLimitPopup .modal-dialog .modal-footer .button_order {
	font-size: 20px;
	min-height: 50px;
	min-width: 200px;
	width: 100%;
	margin: 0 0 10px;
}

		#deleteScanner .modal-dialog .modal-footer {
			padding: 0 30px 30px;
		}
		#deleteScanner .modal-dialog .modal-footer .deleteBtns {
			display: flex;
			justify-content: space-between;
		}
		#deleteScanner .modal-dialog .modal-footer #cancelDeleteUser {
			max-width: 48%;
			width: 100% !important;
			margin-right: 0;
		}
		#deleteScanner .modal-dialog .modal-footer .deleteBtns #deleteUserBtn {
			max-width: 100%;
			width: 100%;
		}
		.form-group-container .checkmark::after {
			width: 11px;
			height: 21px;
			border-width: 0 4px 4px 0;
			left: 10px;
			top: 3px;
		}
		.form-group-container .checkmark {
			top: 1px;
		}
		.form-group-container {
			margin-bottom: 6px;
		}
		#addScannerForm .form_btn_group {
			margin-top: 39px;
		}
		#addScannerForm .form_btn_group .addscanner_cancel_btn, #scanner .empty_add_scanner_tab .no_scanner_listed .form_btn_group .addscanner_cancel_btn {
			height: 55px;
			width: 135px;
			margin-right: 15px;
			font-size: 22px;
		}
		#addScannerForm .form_btn_group .addscanner_add_btn {
			font-size: 22px;
			height: 55px;
			width: 190px;
		}
		.form-group-container:first-child {

			margin-bottom: 21px;
		}
		.form-group-container {
			font-size: 20px;
			padding-left:37px;
		}
		.my_scanner_page .main_logo_heading_padding.start_ordering_scanner.my_scanner > h3 {
			margin: 70px 0 30px;
		}
		.my_scanner_page {
			padding: 0 30px;
		}
		.main_logo_heading_padding.start_ordering_scanner.my_scanner.padding_container .slider_with_pending_temp {
			display: flex;
			flex-direction: column;
		}
		.main_logo_heading_padding.start_ordering_scanner.my_scanner.padding_container .slider_with_pending_temp .dashboard-order.order_scanner {
			width: 100%;
			margin-right: 0;
			margin-top: 25px;
		}
		.main_logo_heading_padding.start_ordering_scanner.my_scanner.padding_container .slider_with_pending_temp #banner-slider .swiper-slide.item {
			width: 340px !important;
		}
		.main_logo_heading_padding.start_ordering_scanner.my_scanner.padding_container .slider_with_pending_temp #banner-slider {
			width: calc(100% - 142px - 10px);
			padding-top:30px;
		}
		.my_scanner_page .start_ordering_scanner.my_scanner .nav.nav-tabs li a {
			margin-right: 0;
		}
		.my_scanner .nav.nav-tabs {
			margin-bottom: 30px;
		}
		.order_fornew_scanner {
			margin-bottom: 20px;
		}
		.my_scanner_page .my_scanner.start_ordering_scanner .tab_background {
			padding: 20px 20px 45px;
		}
		.order_fornew_scanner .order_scanner {
			height: 277px;
			padding: 50px 0 50px;
		}
		div#toolTip_4.tollTipTextSection{
			max-width: 100%;
			width: 100%;
		}
		.my_scanner .order_scanner.no-scanner p {
			font-size: 30px;
			line-height: 30px;
		}
		.my_scanner_page .start_ordering_scanner.my_scanner .nav.nav-tabs li {
			padding-right: 5px;
		}
		.my_scanner_page .my_scanner.start_ordering_scanner .tab_background .no_and_search_samestyle span {
			letter-spacing: 0;
			font-size:18px;
		}
		.tollTipMainSection {
			position: static;
		}
		.pachageArea .AreaFull .HeadingMain {
			position: relative;
		}
		header.bg_color_section {
			position: fixed;
			top: 0;
		}
		.footer_content span a {
			font-size: 20px;
			line-height: 36px;
		}
		.statisticsUpgrade span {
			font-size: 22px;
			margin-bottom: 10px;
			display: block;
		}
		.mobile_header .header_logo {
			padding: 13px 30px 13px;
		}
		.active_user_account .multi_user_section .upgrade_subscription_btn {
			height: 52px;
			font-size: 20px;
		}
		.tab-pane.subscription_sec form.sign_up_form.block_element .form-group-btn {
			margin-top: -10px;
		}
		.payment_method_form.sign_up_form {
			margin: 0 auto 100px;
		}
		body {
			padding-top: 80px;
		}
		.Dummy-Data .distribution._section .user-listing {
			margin-top: 13px;
		}
		.template-headerSection .template-logo {
  		  max-width: 100px;
		}
		.template-headerSection .template-logo img {
			width: 100%;
		}
		.multi_user_section h2 {
			font-size: 30px;
			line-height: 33px;
			margin-bottom: 16px;
			padding-bottom: 10px;
		}
		.modal.myOrderPopup #orderform .modal_form_field label {
			font-size: 23px;
		}
		.codeLinkSection span br {
			display: none;
		}
		.codeLinkSection span{
			font-size: 16px;
			line-height: 19px;
		}
		.codeLinkSection, .statisticsUpgrade {
			padding: 20px 0 0px;
			line-height: 1.2;
		}
		.my_pass_templates .order_pass.ordered_pending_passes p {
			margin-top: 23px;
		}
		#deleteUser .modal-header .close,  #deleteScanner  .modal-header .close, .adduser .modal-header .close{
			right: 24px;
		}
		#single-form-group .floating-label_group .image-input label {
			top: -28px;
			font-size: 15px;
		}
		.same_status_section.codes_section .codes_left {
			width: 100%;
		}
		.banner-slider .swiper-wrapper .swiper-slide.template_without_image .pass_layout.generic-pass-layout {
			max-height: 406px;
		}
		div#sendUser .modal-dialog .send-right .send_pass_with_image.template_image_coupon::before, div#sendUser .modal-dialog .send-right .send_pass_with_image.template_image_coupon::after {
			background-repeat: repeat;
		}
		#deleteUser .modal-header .modal-title, #deleteScanner .modal-dialog .modal-header h4 {
			width: calc(100% - 27px);
		}
		.modal.myOrderPopup #orderform .modal_form_field input:focus ~ label, .modal.myOrderPopup #orderform .modal_form_field input:not(:placeholder-shown) ~ label {
			font-size: 16px;
		}
		#myOrderPopup0.modal.myOrderPopup #orderform .modal_form_field:first-child label {
			font-size: 16px;
		}
		.upgrade_subscription_btn + .modal.myOrderPopup .modal_form_field label {
			font-size: 24px;
		}
		.passes_more_details .passess-details:last-child {
			padding-left: 20px;
		}
		.header_login.sticky {
			padding-top: 14px;
		}
		.confirmation.text span {
			margin-top: 10px;
		}
		.sign_up_page.sign_up h2 {
			margin-bottom: 20px;
		}
		.upgrade_subscription_btn + #myOrderPopup #orderform .modal_form_field.form-group:nth-child(3) {
			margin-bottom: 29px;
		}
		.myOrderPopup form#orderform input[type="submit"] {
			font-size: 18px;
			padding: 8px 35px;
		}
		#termsCondition.term-popup .modal-footer button {
			font-size: 20px;
		}
		.MuiTablePagination-selectIcon-204 {
			top: 3px;
			right: -3px;
		}
		.sending_passes_heading h3 {
			font-size: 30px;
		}
		.owl-stage-outer {
			padding-bottom: 10px;
		}
		.modal_main_field.form-group.valid-thru-field {
			margin-bottom: 43px;
		}
		#termsCondition .modal-header h4.modal-title {
			font-size: 24px;
			line-height: 30px;
		}
		.banner-slider {
			margin-bottom: 10px;
		}
		.main_logo_heading_padding.start_ordering_passes.my_pass_templates.padding_container .adduser h3 {
			margin-top: 10px;
			margin-bottom: 10px;
		}
		.adduser .modal-dialog .modal-body h2 {
			margin-bottom: 15px;
			margin-top: 15px;
		}
		.browsedCsvContainer input#browsedCsvName {
			width: 100%;
			margin-right: 0;
			height: 47px;
			margin-bottom: 10px;
		}
		.modal .modal_form_field input {
			font-size: 23px;
		}
		.my_pass_templates .order_pass.no-template p {
			font-size: 24px;
			line-height: 40px;
		}
		.banner-slider .swiper-button-prev {
			background-size: 27px 12px;
			height: 45px;
			width: 45px;
			background-position: 9px 15px;
		}
		.banner-slider .swiper-button-next {
			background-size: 27px 12px;
			background-repeat: no-repeat;
			height: 45px;
			width: 45px;
			background-position: 9px 15px;
		}
		.modal #addUserForm .modal_form_field input {
			line-height: 25px;
		}
		#OrderConfirmation1 .modal-dialog .modal-content .thakyou_msg {
			line-height: 30px;
		}
		.sign_up_form.block_element .floating-label_group input:focus ~ label, .sign_up_form.block_element .floating-label_group input:not(:placeholder-shown) ~ label {
			top: -17px;
		}
		.signinEmailErrorMsg {
			padding: 30px 20px 10px;
			position: static;
			width: 100%;
			float: left;
			margin-top: 30px;
		}
		.select_subscription .modal-header {
			padding-left: 30px;
			padding-right: 30px;
			padding-top: 30px;
		}
		#UpgradeSubscription.select_subscription.modal .modal-body {
			padding: 0 30px 40px;
			height: 240px;
			overflow-y: auto;
		}
		.same_profile_tab_style form .form-group input {
			font-size: 21px;
			padding-bottom: 0px;
		}
		#addUserConfirm .modal-dialog .modal-content.bg_color_section img, #sendPassConfirm .modal-dialog .modal-content.bg_color_section img {
			max-width: 150px;
		}
		#addUserConfirm .modal-dialog .modal-content.bg_color_section span, #sendPassConfirm .modal-dialog .modal-content.bg_color_section span {
			font-size: 30px;
			margin-bottom: 20px;
			width: 100%;
			float: left;
		}
		.bg_color_section.block_element .nav.nav-tabs li a {
			line-height: 35px;
		}
		#termsCondition .close {
			right: 30px;
			top: 36px;
		}
		#addUserConfirm .modal-dialog, #sendPassConfirm .modal-dialog {
			margin: 0 auto;
			max-width: 90%;
		}
		#addLicenseHolderConfirm .modal-dialog, #sendLicenseConfirm .modal-dialog {
			margin: 0 auto;
			max-width: 90%;
		}
		#addLicenseHolderConfirm .modal-dialog .modal-content.bg_color_section .modal-body img, #sendLicenseConfirm .modal-dialog .modal-content.bg_color_section .modal-body img {
			max-width: 100px;
		}
		#deleteUser .modal-footer .deleteBtns {
			margin: 0;
		}
		#deleteUser .modal-footer #deleteUserBtn {
			width: 100%;
			float: left;
			padding-left: 0;
			padding-right: 0;
			margin: 0 0 8px;
		}
		#deleteUser .modal-footer #cancelDeleteUser {
			width: 100%;
			padding-left: 0;
			text-align: center;
			padding-right: 0;
			margin-right: 0;
		}
		.mobile_menu_list.show, .mobile_menu_list {
			inset: 104px 0px 0px;
		}
		.order_pass {
			padding: 45px 0 55px;
		}
		::-webkit-scrollbar-button {
			background:#888
		}
		::-webkit-scrollbar-track-piece {
			background: #888
		}
		::-webkit-scrollbar-thumb {
			background: #000
		}​
		.user-listing .status_main table tfoot tr td {
			padding: 0px 4px 0px 4px!important;
		}
		.main_logo_heading_padding.start_ordering_passes.my_pass_templates.padding_container .adduser h3 {
			font-size: 18px;
			line-height: 1.2;
		}
		.modal-header {
			padding: 30px 30px 10px;
		}
		#myOrderPopup #orderform .isNotActive {
			max-width: 228px;
			height: 60px;
		}
		div#sendUser .modal-dialog .modal-header button.close {
			right: 24px;
			top: 25px;
		}
		
		#sendUser .modal-header div {
			width: 100%;
		}
		#members div#sendUser .modal-dialog .modal-header label {
			width: calc(100% - 30px);
		}
		.my_pass_templates .order_pass.ordered_pending_passes p {
			font-size: 21px;
		}
		#addUser .modal-dialog {
			top: 30px;
			-webkit-transform: translateY(0%) !important;
			-moz-transform: translateY(0) !important;
			transform: translateY(0%) !important;
		}
        .status_main table tfoot td div > div + span {
            display: none;
        }
		.adduser .image-input input {
			padding-left: 0;
		}
		.adduser .modal-header {
			padding: 60px 15px 11px 15px;
		}
		.adduser .browsedCsvContainer input#addbrowsedCsvName {
			margin-right: 20px;
			width: calc(100% - 80px);
		}
		.status_main table tfoot tr td > div {
			margin-left: 0px;
			padding-left: 4px;
		}
		.standard-fee-note p {
			line-height: 29px;
			font-size:24px;
		}
		.standard-fee-note p br {
			display: none;
		}
		.same_profile_tab_style form .form-group:nth-last-child(2) {
			margin-bottom: 10px;
		}
		div#sendUser .modal-dialog .modal-header label {
			font-size: 22px;
		}
		div#members .modal form table tfoot td {
			padding: 0 10px 0 10px!important;
		}
		div#sendUser .modal-dialog .modal-header button.close {
			font-size: 25px;
			font-weight: bold;
		}
		.senduser .modal-body form input[type="text"], .senduser .modal-body form textarea {
			height: 45px;
			font-size: 16px;
			font-family: 'Museo Sans 300';
		}
		/* .same_profile_tab_style form .form-group input {
			height: 40px;
		} */
		.same_profile_tab_style form .form-group {
			margin-bottom: 30px;
		}
		.profile-form_btn {
			margin-top: 0px;
		}
		.floating-label_group input:not(:placeholder-shown) ~ label, .floating-label_group input:focus ~ label, .floating-label_group input:not(:placeholder-shown) ~ label {
			top: -22px;
			font-size: 18px;
		}
		.floating-label_group .error-main {
			position: static;
		}
		div#sendUser .modal-dialog .send-left .form-group {
			margin-bottom: 22px;
		}
		div#sendUser .modal-dialog .send-left .form-group .custom-errorMsg {
			bottom: -19px;
		}
		.front_layout.home .tab-content.tab_background #profile {
			padding-top: 50px;
		}
		div#sendUser .modal-dialog .pass_layout {
			width: 100%;
			max-width: 330px;
			margin: 0 auto;
		}
		.status_main {
			float: left;
			width: 100%;
			overflow-x: hidden;
			padding-bottom: 10px;
		}
		.modal.myOrderPopup .modal_form_field {
			margin-bottom: 35px;
		}
		#myOrderPopup.modal .modal_form_field.form_field_spacing i {
			top: 9px;
		}
		.payment_method_form.sign_up_form .form-group-btn input[type="submit"] {
			max-width: initial;
		}
		.my_pass_templates.no-dashboard .order_pass.ordered_pending_passes {
		margin-top: 0;
		width: 34%;
		margin-right: 2%;
		min-height: 540px;
	}
	.modal .modal-body {
		padding: 0 30px 10px;
	}
	#deleteUser .modal-title, #deleteScanner .modal-dialog .modal-header h4, .adduser .modal-title, #deleteHolderList .modal-dialog .modal-header h4, #templateTypeLimitPopup .modal-dialog .modal-header h4 {
		font-size: 28px;
		padding-right: 50px;
		margin-bottom: 20px;
	}
	.swiper-container::after {
		display: none;
	}
	.banner-slider .swiper-wrapper .swiper-slide {
		width: 300px !important;
	}
	.my_pass_main_section.slider_with_pending_temp section.banner-slider .swiper-container {
		overflow: hidden;
		padding-bottom:20px;
	}
	.my_pass_main_section.slider_with_pending_temp section.banner-slider.bigger-slider {
		padding-top: 50px;
	}
	.my_pass_main_section.slider_with_pending_temp section.banner-slider.bigger-slider {
		width: 100%;
		margin: 0 auto;
		max-width: 360px;
		justify-content: center;
		text-align: center;
		float: none;
	}
	.my_pass_templates .order_pass p {
		font-size: 21px;
		line-height: 1.2;
	}
	.my_pass_templates .order_pass.ordered_pending_passes span.order_dated {
		font-size: 16px;
		margin-bottom: 20px;
	}
	.my_pass_templates .order_pass.ordered_pending_passes {
		padding: 48px 0 50px;
	}
	/* .no-dashboard .order_fornew_passes .demo-pass-template .pass-design-layout {
		min-height: 500px;
	} */
	.main_logo_heading_padding.start_ordering_passes.my_pass_templates.padding_container .pass-design-content h3 {
		font-size: 70px;
	}
	.my_pass_templates.no-dashboard .order_fornew_passes .pass-design-layout .pass-template-hover span {
		font-size: 32px;
	}
	#members .modal-footer {
		padding-bottom: 30px;
	}
	#deleteUser .modal-header {
		padding-left: 30px;
	}
	.modal.myOrderPopup .modal_form_field {
		margin-bottom: 45px;
	}
		.adduser.modal .modal-body {
			padding: 0 30px 10px;
        }
        .my_pass_main_section.slider_with_pending_temp section.banner-slider.bigger-slider {
            width: 100%;
		}
		.modal.myOrderPopup #orderform .floating-label_group input:not(:placeholder-shown) ~ label, .modal.myOrderPopup #orderform .floating-label_group input:focus ~ label, .modal.myOrderPopup #orderform .floating-label_group input:not(:placeholder-shown) ~ label {

			top: -25px;
		
		}
		.my_pass_templates .order_fornew_passes .dashboard-order.order_pass.no-template span.add_order {
			margin-top: 0px;
			margin-bottom: 40px;
		}
		.status_of_passses .status_gauge svg.speedometer {
			left: -20px;
			transform: scale(0.8);
		}
		#sendUser .modal-header h3 {
			margin: 0px 0 0;
			font-size: 18px;
		}
		#members div#sendUser .modal-dialog .modal-header label {
			font-size: 30px;
		}
		.senduser .modal-body form textarea#mailtextarea, #sendLicense .modal-body form textarea#mailtextarea {
			height: 183px !important;
		}
        /* .status_of_passses .status_graph div[type="bar"] {
            overflow-x: auto;
            width: 100%;
        }
        .status_of_passses .status_graph div[type="bar"] .apexcharts-canvas {
            width: 700px !important;
        }
        .status_of_passses .status_graph div[type="bar"] .apexcharts-canvas svg {
            width: 100% !important;
		} */
		.same_status_section.codes_section .codes_right {
			padding-left: 0;
			margin: 0 auto;
			align-items: center;
			float: none;
		}
		.term-popup .modal-header {
			margin-bottom: 0px;
			flex-direction: column;
		}
		.term-popup .modal-header h4 {
			line-height: 1;
			margin-bottom: 0;
		}
		.term-popup .modal-header {
			padding-left: 30px;
		}
		.term-popup .modal-dialog .modal-body p {
			font-size: 14px;
		}
		tfoot.jss197 td {
			padding: 0px 14px 0px 14px!important;
		}
		.status_main table tfoot td div {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
		}
        .my_pass_main_section section.banner-slider.bigger-slider .owl-nav .owl-prev {
            left: -32px;
        }
        .my_pass_main_section section.banner-slider.bigger-slider {
            padding-left: 0;
            padding: 0 20px;
        }
        .single-dashboard .my_pass_main_section.slider_with_pending_temp .order_pass {
            margin-right: 0;
            width: 100%;
            min-height: 330px;
            margin-top: 20px;
        }
        .my_pass_templates .dashboard-order.order_pass.no-template {
            min-height: 340px;
            width: 100%;
            margin-right: 0;
            margin-top: 7px;
        }
		.sending_passes_heading h3 {
			font-size: 20px;
			line-height: 25px;
		}
		.order_fornew_passes .demo-pass-template {
			width: 100%;
        }
        .my_pass_main_section.slider_with_pending_temp .order_pass.ordered_pending_passes {
            margin-right: 0;
            margin-left: 1%;
            width: 49%;
        }
        .my_pass_main_section.slider_with_pending_temp section.banner-slider .owl-stage-outer {
            padding-left: 0;
        }
        .main_logo_heading_padding.start_ordering_passes.my_pass_templates.padding_container h3 {
            margin-bottom: 7px;
			margin-top: 20px;
			font-size: 24px;
        }
        .owl-nav .owl-next {
            height: 30px;
            width: 30px;
            right: 0px;
            background-position: 1px 7px !important;
        }
        .owl-nav .owl-prev {
            height: 30px;
            width: 30px;
            left: 0px;
            background-position: 0px 7px !important;
        }
        .owl-stage-outer::after {
             box-shadow: none;
        }
        .item.current_template::before {
            bottom: -15px;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-bottom: 15px solid #57c0d9;
        }
        .my_pass_main_section.slider_with_pending_temp .order_pass {
            margin-right: 1%;
            width: 49%;
            min-height: 330px;
            margin-top: 20px;
        }
        .my_pass_main_section.slider_with_pending_temp section.banner-slider {
            width: 100%;
        }
		.my_pass_templates .order_pass.no-template {
			margin-top: 7px;
			min-height: 340px;
			margin-bottom: 26px;
			width: 100%;
			margin-right: 0;
		}
		.order_fornew_passes {
			margin-bottom: 30px;
			float: left;
			width: 100%;
		}
		.order_fornew_passes .pass-design-layout .pass-template-hover span {
			font-size: 25px;
			line-height: 34px;
		}
		.order_fornew_passes .demo-pass-template .pass-design-layout {
			width: 100%;
			min-height: 410px;
			max-width: 100%;
		}
		.subscription_table table tbody .table-price td .price_kr {
			font-size: 18px;
			line-height: 24px;
		}
		.subscription_table table tbody .table-price td .free_subsription_btns {
			padding: 4px 16px;
			font-size: 16px;
		}
		.subscription_table table tbody .table-price td .price_cutoff {
			font-size: 14px;
		}
		.modal .subscription_table table tbody tr.table-action-tab td.active-plan span {
			font-size: 20px;
			line-height: 24px;
		}
		.subscription_table table tr td:first-child {
			width: 240px;
		}
		.subscription_table table th {
			font-size: 17px;
			padding: 11px 22px;
		}
		.subscription_table table td {
			padding: 12px 22px;
		}
		.main_logo_heading_padding .sending_passes_heading h3 {
			margin: 10px 0 10px;
		}
        .segment-value {
            font-size: 10px !important;
        }
        .status_of_passses .status_gauge svg.speedometer {
            width: 300px !important;
        }
        .status_of_passses .status_gauge {
            width: 100%;
        }
        .codes_section .codes_left {
            float: left;
            width: 100%;
            padding-right: 0;
            padding-bottom: 20px;
        }
        .modal .receipt_section .thakyou_msg {
            font-size: 20px;
        }
        .codes_right .self-code {
            min-width: 100%;
            width: 100%;
        }
        footer .footer_content .footer-left {
            float: left;
            width: 100%;
        }
        .status_of_passses .status_gauge {
            margin: 0 auto 20px;
        }
        .copyright p{
            margin:0;
        }
        .passes_more_details .passess-details:nth-child(2)::after, .passes_more_details .passess-details:nth-child(3)::after, .passes_full_more_details .passess-details:nth-child(2)::after, .passes_full_more_details .passess-details:nth-child(3)::after {
            content: "";
            position: absolute;
            background: #dae4e4;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 94%;
            top: 0;
            display: block;
            height: 2px;
        }
        .passes_more_details, .passes_full_more_details {
            max-width: 100%;
            margin-top: 0px;
			border: 2px solid  #dae4e4;
			border-radius: 12px;
		}
		.footer_content span {
			color: rgb(253, 251, 239);
		}
		footer .footer_content .footer_rgt {
			padding-top: 13px;
		}
		.copyright.padding_container.block_element {
			padding: 0;
		}
        .passes_more_details .passess-details, .passes_full_more_details  .passess-details {
            width: 100%;
        }
        .passes_more_details .passess-details:nth-child(2), .passes_full_more_details .passess-details:nth-child(2) {
            border-bottom-left-radius: 0px;
			border-top-right-radius: 0;
			border: none;
		}
		.passes_more_details .passess-details:nth-child(3), .passes_full_more_details .passess-details:nth-child(3) {
            border-bottom-left-radius: 12px;
            border-top-right-radius: 0;
        }
        .passes_more_details .passess-details:nth-child(1), .passes_full_more_details .passess-details:nth-child(1){
            border-top-right-radius: 12px;
			border-bottom-left-radius: 0;
			border: none;
		}
		.passes_more_details, .passes_full_more_details {
			margin-bottom: 20px;
		}
		.passes_more_details .passess-details:last-child, .passes_full_more_details .passess-details:last-child{
			border-bottom-left-radius: 12px !important;
			border: none;
		}
        .passess-details {
            width: 100%;
            padding: 20px 20px 15px;
            margin-bottom: 20px;
        }
        .passes_more_details .passess-details:nth-child(2)::before, .passes_full_more_details .passess-details:nth-child(2)::before {
            display: none;
        }
        .confirmation.text p {
            font-size: 14px;
            margin-top: 15px;
		}
		div#uploadUser .form-btn-btm {
			margin-bottom: 30px;
		}
        .thanku_page {
            padding: 80px 0;
        }
        .thanku_page h2 {
			margin-top: 24px;
			margin-bottom: 7px;
			font-size: 30px;
		}
		.thanku_page {
			height: auto;
		}
		.wrapper.block_element {
			min-height: 400px;
		}
        .confirmation.text .circle {
            width: 25px;
            height: 25px;
        }
        .confirmation.text .circle i {
            font-size: 14px;
        }
        .copyright {
            padding-bottom: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 12px;
		}
		.my_pass_templates.my_profile_section .nav.nav-tabs {
			margin-bottom: 4px;
		}
        .confirmation.text span {
			font-size: 20px;
		}
		.thanku_page .confirmation.text p {
			font-size: 16px;
			line-height: 22px;
		}
		.sign_up_page h2 {
			line-height: 40px;
		}
        footer {
            padding-top: 28px;
        }
        footer .footer_logo img {
            width: 300px;
        }
        footer .footer_content .footer_rgt {
            float: left;
            width: 100%;
            padding-left: 0;
            padding-bottom: 10px;
        }
        .sign_up_page.forgot_page h2 {
            margin-bottom: 10px;
        }
        .forgot_page .write_down_email, .icelandic_body .forgot_page .write_down_email {
            margin-bottom: 20px;
            font-size: 15px;
            line-height: 19px;
        }
		
		.adduser .single-form-group {
			margin-bottom: 10px;
        }
        .sign_up_page.forgot_page {
            min-height: auto;
        }
        header.header_login .header_logo img, .thankyou_back img {
            max-width: 250px;
		}
		.thankyou_back {
			padding: 15px;
		}
        .comming_soon .topleft img {
            max-width: 400px;
        }
        .comming_soon .middle {
            padding: 250px 100px;
        }
        .user_account .user_current span {
            padding-right: 8px;
        }
        .user_account .user_current img {
            width: 20px;
        }
        .header_login {
            padding-top: 14px;
            padding-bottom: 18px;
        }
        div.rtc-dropdown .rtc-dropdown-toggle {
            font-size: 14px;
            padding: 4px 2px;
        }
        .local_language_picker div.rtc-dropdown .rtc-dropdown-menu {
            left:0;
        }
        .user_account .user_current i {
            font-size: 15px;
            margin-top: 1px;
        }
		.tab_background .tab-pane {
			padding: 15px;
		}
		#password h3 {
			font-size: 20px;
		}
		.same_profile_tab_style form h3 {
			margin-bottom: 24px;
		}
		.profile-form_btn {
			margin-bottom: 10px;
		}
		.subscription_sec .subsription_heading.padding_container {
			padding: 10px 0 12px 0;
		}
		.main_logo_heading_padding h3 {
			margin: 10px 0 30px;
		}
		.tab_background .tab-pane {
			padding: 15px;
		}
		#password h3 {
			font-size: 20px;
		}
		.same_profile_tab_style form h3 {
			margin-bottom: 18px;
		}
		.profile-form_btn {
			margin-bottom: 10px;
		}
		.subscription_sec .subsription_heading.padding_container {
			padding: 10px 0 12px 0;
		}
		.main_logo_heading_padding.start_ordering_passes.my_pass_templates.padding_container h3 {
			margin-top: 0;
		}

		.upgrade_subsirption {
			margin-bottom: 20px;
		}
		.bg_color_section.block_element .nav.nav-tabs li a {
			font-size: 16px;
		}
		.my_pass_templates .nav.nav-tabs li a::before {
			height: 2px;
		}
		.tab_background {
			margin-bottom: 0;
		}
		.nav.nav-tabs li {
			padding-right: 30px;
		}
		.my_pass_templates .nav.nav-tabs li a {
			margin-right: 0;
		}
		.sign_up_form .form-group-btn input[type="submit"] {
			padding: 6px 2px;
			font-size: 28px;
			max-width: 150px;
		}
		form .error-main {
            min-height: 15px;
        }
        .error {
            font-size: 12px;
			min-height: auto;
		}
		.payment_method_form.sign_up_form {
			margin-top: 20px;
		}
		.distribution_limit {
			margin-bottom: 10px;
		}
		.upgrade_subsirption .cancel_subscription {
			padding: 15px 14px;
			line-height: 22px;
		}
		.upgrade_subsirption button {
			font-size: 15px;
			line-height: 22px;
			display: table;
			margin: 0 auto 10px !important;
			width: 100%;
			max-width: 230px;
			min-width: 230px;
			text-align: center;
			float: none;
		}
		.subscription_sec .subsription_heading.padding_container h3 {
			font-size: 22px;
			padding-bottom: 8px;
		}
		.subscription_sec .subsription_heading.padding_container {
			padding: 10px 0 5px 0;
		}
		.upgrade_subsirption {
			flex-wrap: wrap;
		}
		.subscription_sec .subsription_heading.padding_container h3 {
			font-size: 22px;
			padding-bottom: 8px;
		}
		.growing_section .template_types {
			margin-bottom: 10px;
		}
		.growing_section .growing_heading {
			font-size: 20px;
			margin-bottom: 5px;
			line-height: 24px;
		}
		.growing_section .template_types span {
			font-size: 15px;
		}
		.growing_section .remaining_days {
			font-size: 16px;
			margin-bottom: 10px;
			line-height: 20px;

        }
        .modal.cancel_subscription .submit_btn {
            max-width: 75px;
            padding:3px 0px !important;
        }
        .cancel_subscription p br {
            display: none;
        }
        .modal button.close {
            font-size: 32px;
        }
        .modal .submit_btn {
            font-size: 16px;
        }

		button.upgrade_subscription_btn {
			font-size: 24px;
			margin-bottom: 10px;
        }
        .modal .modal-body .modal_body_haeding {
            font-size: 20px;
            line-height: 25px;
        }
        .modal .receipt_section {
            padding-top: 8px;
        }
		.adduser .image-input input {
			font-size: 16px;
		}
		.adduser .image-input::before {
			width: 116px;
        }
        .sign_up_page {
            padding-top: 55px;
            padding-bottom: 50px;
        }
        .sign_up_form .form-group-btn input[type="submit"] {
            padding: 4px 2px;
            font-size: 18px;
            max-width: 110px;
		}
		.modal-open {
			overflow: hidden!important;
			position: fixed;
		}
		.adduser .image-input::before {
			display: none;
		}
		.sign_up_page h2, .icelandic_body .sign_up_page h2 {
			margin-bottom: 5px;
			font-size: 35px;
		}
		.forgot_password a {
			font-size: 15px;
		}
		.create_account span, .create_account span a {
			font-size: 15px;
		}
        h2 {
            font-size: 25px;
            line-height: 28px;
        }
        .same_profile_tab_style form label {
            font-size: 18px;
        }
        .floating-input ~ label {
            font-size: 18px;
            top: 6px;
        }
        .myOrderPopup input[type="submit"], .myOrderPopup input.btn-danger, .myOrderPopup input[type="submit"] {
            font-size: 18px;
		}
        .modal .submit_btn {
            padding: 5px 18px !important;
            max-width: 120px;
        }
		.adduser.modal .submit_btn {
			font-size: 17px;
			max-width: 120px;
        }
        .modal .toatl_vat_price .toatl_vat_price_left {
            float: left;
            width: 65%;
            padding-right: 15px;
		}
		.user_account .users {
			width: 180px;
			right: 0px;
		}
		#myOrderPopup #orderform .isNotActive {
			max-width: initial;
			height: auto;
			width: auto;
			padding: 10px 38px;
			font-size: 19px;
		}
		.user_account .users li a {
			font-size: 14px;
		}
        .modal .toatl_vat_price .toatl_vat_price_rgt {
            float: left;
            width: 35%;
            text-align: right;
        }
        .modal .template_build_inner span:nth-child(2) {
            float: right;
            width: 30%;
            text-align: right;
        }
        .my_pass_templates .tab_background {
            padding: 20px;
        }
        .modal .template_build_inner span {
            float: left;
            width: 70%;
        }
        .my_pass_templates .nav.nav-tabs {
            margin-bottom: 30px;
        }
        .my_pass_templates .nav.nav-tabs li a {
            padding-bottom: 10px;
        }
        .my_pass_templates.start_ordering_passes .nav.nav-tabs li {
            padding-right: 13px;
        }
        .status_of_passses .status_gauge svg.speedometer {
			width: 420px!important;
			height: 240px !important;
		}
        .status_of_passses .status_gauge{
            display: block;
        }
        
		.my_pass_templates.no-dashboard .dashboard-order.order_pass.no-template {
			width: 100%;
			margin-right: 0;
			min-height: 250px;
		}
		.my_pass_templates.no-dashboard .order_pass.ordered_pending_passes {
			margin-top: 0;
			width: 100%;
			margin-right: 0;
			min-height: 250px;
			margin-bottom: 30px;
		}
		.no-dashboard .order_fornew_passes .demo-pass-template {
			/* width: 100%; */
			margin: 0 auto;
			text-align: center;
			/* float: none; */
			display: flex;
			align-items: center;
			justify-content: center;
			float: none;
		}
        .modal .toatl_vat_price span {
            font-size: 15px;
            line-height: 16px;
		}
		div#sendUser .modal-dialog .send-left {
			width: 100%;
		}
		.MuiPaper-elevation4-16 {
			clear: both;
		}
		
		.no_user_listed.no_and_search_samestyle .main_distribution_btn {
			display: flex;
			flex-wrap: wrap;
		}
		.senduser .modal-body form textarea {
			height: 150px;
		}
		div#sendUser .modal-dialog .send-right {
			padding-left: 0px;
			padding-right: 0px;
			width: 100%;
			padding-top: 15px;
		}
		div#sendUser .modal-dialog .send-right .allSelectedMailList {
			width: 100%;
		}
		div#sendUser .modal-dialog .send-right .form-btn-btm {
			float: left;
			width: 100%;
			padding-left: 0;
			margin-top: 20px;
		}
		div#sendUser .send-right .form-btn-btm input.submit_btn.no_to_passes {
			margin-right: 4%;
			flex: 0 0 46%;
		}
		div#sendUser .send-right .form-btn-btm input.submit_btn {
			margin-right: 0;
			flex: 0 0 50%;
			max-width: initial;
        }
        .my_pass_main_section.slider_with_pending_temp section.banner-slider div#main-slider .owl-item {
            max-width: 100%!important;
		}
		.same_status_section.codes_section.codes-scaaner_two .codes-image_section img {
			max-width: initial;
			/* width: 255px; */
		}
		.status_of_passses.block_element {
			flex-direction: column;
		}
		.status_of_passses .status_graph {
			width: 100%;
		}
		.status_main table th {
			font-size: 15px !important;
			padding: 4px 15px 4px 12x;
		}
		footer.padding_container {
			padding-top: 40px;
			padding-bottom: 40px;
		}
		.footer_block {
			padding: 0 30px;
		}
		footer .footer_logo img {
			width: 300px;
			max-width: 100%;
		}
		.copyright.padding_container.block_element p {
			text-align: left;
		}
		.copyright.padding_container.block_element {
			text-align: left;
			display: block;
			padding-left: 0;
			padding-top: 16px;
		}
		.sign_up_form {
			margin-top: 20px;
		}
		#addUser .modal-dialog {
			max-width: 90%;
			width: 100%;
			margin: 0 auto;
		}
		#termsCondition h3 {
			font-size: 16px;
		}
		#termsCondition p {
			line-height: 25px;
			font-size: 15px;
		}
		.term-popup .modal-header {
			margin-bottom: 10px;
			padding-bottom: 7px;
		}
		.term-popup .modal-header .modal-title {
			padding-top: 0px;
		}
		#termsCondition h2 {
			font-size: 20px;
		}
		.term-popup .modal-footer {
			padding: 10px 15px 30px;
		}
		#OrderConfirmation1 .modal-dialog .modal-content p.modal_body_haeding, #OrderConfirmation2 .modal-dialog .modal-content p.modal_body_haeding, #OrderConfirmation3 .modal-dialog .modal-content p.modal_body_haeding, #OrderConfirmation4 .modal-dialog .modal-content p.modal_body_haeding, .modal-dialog .modal-content p.modal_body_haeding {
			font-size: 30px;
			line-height: 34px;
			margin-bottom: 30px;
		}
		#termsCondition .modal-header h4.modal-title {
			max-width: calc(100% - 20px);
		}
		#OrderConfirmation1 .modal-dialog .modal-content p, #OrderConfirmation2 .modal-dialog .modal-content p, #OrderConfirmation3 .modal-dialog .modal-content p, #OrderConfirmation4 .modal-dialog .modal-content p {
			font-size: 18px;
		}
		#OrderConfirmation2 .receipt_section .thakyou_msg, .modal .receipt_section .thakyou_msg, #OrderConfirmation1 .modal-dialog .modal-content p.thakyou_msg, #OrderConfirmation2 .modal-dialog .modal-content p.thakyou_msg, #OrderConfirmation3 .modal-dialog .modal-content p.thakyou_msg, #OrderConfirmation4 .modal-dialog .modal-content p.thakyou_msg {
			font-size: 30px;
		}
		.myOrderPopup .btn.btn-default.submit_btn, #OrderConfirmation1 .btn.btn-default.submit_btn, #OrderConfirmation2 .btn.btn-default.submit_btn, #OrderConfirmation3 .btn.btn-default.submit_btn, #OrderConfirmation4 .btn.btn-default.submit_btn {
			font-size: 25px;
			max-width: 210px;
			width: 100%;
			height: auto;
		}
		#OrderConfirmation2 .receipt_section .thakyou_msg, .modal .receipt_section .thakyou_msg, #OrderConfirmation1 .modal-dialog .modal-content p.thakyou_msg, #OrderConfirmation2 .modal-dialog .modal-content p.thakyou_msg, #OrderConfirmation3 .modal-dialog .modal-content p.thakyou_msg, #OrderConfirmation4 .modal-dialog .modal-content p.thakyou_msg {
			margin: 10px 0 30px;
		}
		.modal-header .close {
			right: 23px;
			top: 11px;
		}
		#cancel_subscription .modal-dialog .receipt_section p {
			font-size: 18px;
		}
		#cancel_subscription .modal-dialog form .btn.btn-default.submit_btn {
			width: 215px;
			height: auto;
			max-width: 214px;
			font-size: 21px;
		}
		.same_profile_tab_style form label {
			font-size: 24px;
		}
		#password.same_profile_tab_style form h3 {
			font-size: 26px;
			margin-bottom: 40px;
		}
		.bg_color_section.block_element .nav.nav-tabs li a {
			font-size: 18px;
		}
		.bg_color_section.block_element.main_logo_heading_padding.my_pass_templates.my_profile_section.padding_container h3 {
			font-size: 30px;
			margin-bottom: 20px;
			margin-top: 20px; 
		}
		.profile-form_btn button, .profile-form_btn input[type="submit"] {
			padding: 11px 20px;
			font-size: 20px;
			min-width: 220px;
		}
		.sign_up_form .form-group-btn input[type="submit"] {
			padding: 4px 2px;
			font-size: 26px;
			max-width: 200px;
			margin-top: 0;
		}
		.floating-input ~ label {
			font-size: 25px;
			top: 0px;
		}
		.sign_up_page .sign_up_form .form-group {
			margin-bottom: 27px;
		}
		.sign_up_form .inputText {
			font-size: 20px;
			padding-bottom: 0px;
			width:100%;
		}
		/* .sign_up_form .inputText {
			height: 30px;
		} */
		.modal-footer {
			padding-bottom: 30px;
		}
		form.sign_up_form.block_element label.container-s {
			margin-top: 10px;
			margin-bottom: 10px;
		}
		.pass_single_detail .passess-details:last-child {
			width: 100%;
		}
		.bg_color_section {
			padding-bottom: 100px;
		}
		header.bg_color_section {
			padding-bottom: 18px;
		}
		.owl-item .item .pass_layout {
			height: 360px;
		}
		.bg_color_section.block_element.main_logo_heading_padding.start_ordering_passes.my_pass_templates.padding_container {
			padding-top: 30px;
			padding-bottom: 30px;
		}
		div#uploadUser.modal .modal-body {
			padding: 10px 30px 60px;
		}
		.adduser .modal-header, .addLicenseHolderModal .modal-header {
			padding: 30px 30px 10px;
		}
		#uploadUser .modal-dialog .modal-content .modal-title, .adduser .modal-header h1, #sendLicense .modal-dialog .modal-header label, #members div#sendUser .modal-dialog .modal-header label, .addLicenseHolderModa .modal-dialog .modal-header h1{
			font-size: 24px;
			font-family: 'Museo Sans 300';
			line-height: 25px;
			margin-bottom: 0px;
		}
		.my_scanner_page .my_scanner.start_ordering_scanner .tab_background h3 {
			margin: 10px 0 10px;
		}
		.main_logo_heading_padding h3 {
			font-size: 24px;
		}
		.my_scanner_page .my_scanner.start_ordering_scanner .tab_background .no_and_search_samestyle span {
			letter-spacing: 0;
			font-size: 16px;
			line-height: 23px;
			font-family: 'Museo Sans 300';
			float: left;
			width: 100%;
		}
		#scanner_distribution .main_distribution_btn {
			margin-bottom: 5px;
		}
		.browsedCsvContainer .react-file-reader button {
			padding: 14px 11px;
			font-size: 17px;
		}
		.modal #addUserForm .modal_form_field input {
			border-radius: 0;
		}
		.modal .modal_form_field label {
			font-size: 22px;
		}
		.sign_up_page .sign_up_form .form-group.passwordformGroup {
			margin-bottom: 10px;
		}
		#addUser.modal .modal-dialog {
			overflow-y: auto;
		}
		div#uploadUser .modal-body h4 {
			font-size: 18px;
			margin-bottom: 25px;
		}
		section.uploadUser-sec {
			min-height: 210px;
		}
		section.uploadUser-sec p {
			font-size: 18px;
		}
		.codes_section .codes_left span {
			font-size: 17px;
		}
		.same_profile_tab_style form label {
			font-size: 20px;
			top: 15px;
		}
		.same_profile_tab_style form .floating-input {
			padding: 11px 0px;
		}
		.error {
			margin-bottom: 0;
		}
		#DowngradePlan .modal-dialog .modal-content .modal-body .modal_body_haeding {
			font-size: 32px;
			line-height: 48px;
		}
		.modal.myOrderPopup p.card_number_text_value button {
			font-size: 16px;
			padding: 7px 13px;
		}
		.modal.myOrderPopup .modal_form_field button {
			top: -3px;
		}
		span.card_number_value {
			margin-top: -18px;
		}
		img.cardTypePng {
			bottom: 10px;
		}
		.floating-input ~ label {
			font-size: 25px;
			top: -10px;
		}
		.floating-input ~ label {
			font-size: 20px;
			top: 0px;
		}
		.floating-label_group input:not(:placeholder-shown) ~ label, .floating-label_group input:focus ~ label, .floating-label_group input:not(:placeholder-shown) ~ label {
			font-size: 15px;
		}
		#DowngradePlan .modal-dialog .modal-content .modal-body .btn.btn-default.submit_btn, #OrderConfirmation1 .modal-dialog .modal-body .btn.btn-default.submit_btn {
			max-width: 195px;
			width: 100%;
			font-size: 26px;
			height: auto;
			margin-top: 10px !important;
			padding: 8px 16px !important;
		}
		#OrderConfirmation2 .receipt_section .thakyou_msg, .modal .receipt_section .thakyou_msg, #OrderConfirmation1 .modal-dialog .modal-content p.thakyou_msg, #OrderConfirmation2 .modal-dialog .modal-content p.thakyou_msg, #OrderConfirmation3 .modal-dialog .modal-content p.thakyou_msg, #OrderConfirmation4 .modal-dialog .modal-content p.thakyou_msg {
			font-size: 26px;
		}
		.modal.myOrderPopup .modal_form_field label {
			font-size: 22px;
		}
		.modal.myOrderPopup .modal_form_field input:focus ~ label, .modal.myOrderPopup .modal_form_field input:not(:placeholder-shown) ~ label {
			font-size: 14px;
		}
		#cancel_subscription .modal-dialog form .btn.btn-default.submit_btn.no_to_passes {
			margin-right: 10px;
			margin-left:0;
		}
		.sign_up_form.block_element .forgot_password.block_element {
			margin-bottom: 25px;
			margin-top: 0px;
		}
		.sign_up_form .form-group-btn input[type="submit"] {
			padding: 4px 2px;
			font-size: 25px;
			max-width: 180px;
			margin-top: 0;
		}
		.modal.myOrderPopup #orderform .modal_form_field > label {
			font-size: 14px;
		}
		.sign_up_page .sign_up_form .form-group.confPassGroup {
			margin-bottom: 10px;
		}
		.floating-input {
			padding: 4px 0px;
		}
		.senduser .modal-body form input[type="text"], .senduser .modal-body form textarea {
			margin-bottom: 0;
		}
		.senduser .modal-body form textarea#mailtextarea, #sendLicense .modal-body form textarea#mailtextarea {
			height: 143px !important;
		}
		#sendUser.modal form {
			padding-top: 10px;
		}
		#sendUser .modal-header, #sendLicense .modal-header {
			padding-top: 30px;
		}
		#deleteUser p, #deleteHolderList .modal-dialog .modal-body p {
			font-size: 18px;
			padding-top:0;
		}
		#DowngradePlan .modal-dialog .modal-content .modal-body .modal_body_haeding {
			font-size: 26px;
			line-height: 26px;
		}
		#DowngradePlan .modal-dialog .modal-content .modal-body .receipt_section p {
			font-size: 18px;
		}
		.payment_method_form.sign_up_form .form-group.form-group-half {
			padding-right: 20px;
		}
		.sign_up_form .form-group.card-detail span.card_number_value {
			margin-top: -28px;
		}
		#deleteUser .modal-footer #deletePopupDelete {
			height: auto;
			line-height: 1;
			width:100%;
			margin-bottom:8px;
		}
		#termsCondition .modal-dialog .modal-body {
			padding: 0 25px 10px 30px;
		}
		.term-popup .modal-header {
			padding-top: 40px;
		}
		#termsCondition .modal-header h4.modal-title {
			font-size: 22px;
		}
		#termsCondition h3 {
			font-size: 18px;
		}
		#cancel_subscription .modal-dialog form .btn.btn-default.submit_btn {
			max-width: initial;
			min-width: initial;
			width: auto;
		}
		.tab_background #password.tab-pane {
			padding: 10px 15px 200px;
		}
		#password form {
			padding-top: 0;
		}
		.payment_method + .payment_method_form.sign_up_form .form-group {
			margin-bottom: 58px;
		}
		.payment_method + .payment_method_form.sign_up_form .form-group .inputText.pre-filled-form {
			font-size: 22px;
			line-height: 24px;
			padding-bottom: 10px;
		}
		.payment_method + .payment_method_form.sign_up_form .form-group.card-detail .inputText.pre-filled-form {
			font-size: 22px;
			line-height: 48px;
			padding-left: 220px;
		}
		.payment_method_form.sign_up_form .form-group.card-detail span.card_number_value {
			margin-top: -10px;
		}
		.payment_method_form.sign_up_form .form-group.card-detail span.card_number_value {
			padding-left: 60px;
		}
		.payment_method_form.sign_up_form input:focus ~ .floating-label, .payment_method_form.sign_up_form input:not(:focus):valid ~ .floating-label {
			font-size: 17px;
		}
		#subscription.tab-pane.subscription_sec div > .subsription_heading.padding_container.payment_method {
			padding: 30px 0 25px;
			margin-bottom: 48px;
		}
		.my_pass_templates.no-dashboard .order_pass.ordered_pending_passes, .my_pass_templates.no-dashboard .dashboard-order.order_pass.no-template {
			margin-top: 20px;
		}
		.same_status_section.codes_section {
			display: flex;
			flex-direction: column;
		}
		.same_status_section {
			max-height: inherit;
		}
		.my_pass_main_section.slider_with_pending_temp .add_order_disable.dashboard-order.order_pass{
			width: 100%;
		}
		.my_pass_main_section.slider_with_pending_temp .order_pass.ordered_pending_passes {
			margin-left: 0;
			width: 100%;
		}
		.my_pass_main_section.slider_with_pending_temp .order_pass {
			margin-right: 0%;
			width: 100%;
			min-height: 250px;
		}
		.adduser .modal-dialog .modal-body h4 + h2 {
			margin-top: 14px;
			font-size:18px;
		}
		.uploadUser .browsedCsvContainer .react-file-reader button {
			padding: 10px 11px;
		}
		.my_pass_main_section.slider_with_pending_temp section.banner-slider {
			width: 100%;
			max-width: 320px;
			padding-right: 0;
			margin: 0 auto;
			justify-content: center;
			text-align: center;
			float: none;
			padding-top: 30px;
		}
		.modal-open-again .modal {
			padding-top: 20px;
		}
		.payment_method + .payment_method_form.sign_up_form .form-group .inputText.pre-filled-form {
			padding-left: 0;
		}
		p.alert.alert-danger.expiry-note {
			flex-direction: column;
		}
		 .modal.myOrderPopup #orderform .modal_form_field label {
			top: 10px;
		}
		.upgrade_subscription_btn + .modal.myOrderPopup #orderform .modal_form_field label {
			top: -4px;
		}
		img.cardTypePng {
			max-width: 40px;
			bottom: 6px;
		}
		.payment_method_form.sign_up_form .form-group.card-detail span.card_number_value {
			margin-top: -18px;
		}  
		form.payment_method_form.sign_up_form .form-group {
			margin-bottom: 60px;
		}    
		.sign_up_form .form-group.card-detail input.inputText.pre-filled-form {
			font-size: 30px;
			line-height: 46px;
		}
		.myOrderPopup .btn.btn-default.submit_btn, #OrderConfirmation1 .btn.btn-default.submit_btn, #OrderConfirmation2 .btn.btn-default.submit_btn, #OrderConfirmation3 .btn.btn-default.submit_btn, #OrderConfirmation4 .btn.btn-default.submit_btn {
			font-size: 21px;
			max-width: 180px;
			width: auto;
			height: auto;
			min-width: 170px;
			padding:17px 40px !important
		}
		/* .modal.myOrderPopup #orderform .floating-label_group input:not(:placeholder-shown) ~ label, .modal.myOrderPopup #orderform .floating-label_group input:focus ~ label, .modal.myOrderPopup #orderform .floating-label_group input:not(:placeholder-shown) ~ label {
			top: -14px;
		} */
		#OrderTempPopup.modal.myOrderPopup .modal_form_field {
			margin-bottom: 40px;
		}
		.myOrderPopup form#orderform .modal_main_field.form-group.valid-thru-field {
			margin-bottom: 45px;
		}
		.myOrderPopup input[type="submit"], .myOrderPopup input.btn-danger, .myOrderPopup input[type="submit"] {
			font-size: 21px;
		}
		.myOrderPopup form#orderform input[type="submit"] {
			font-size: 21px;
		}
		.adduser .image-input .fileInput {     
			padding-bottom: 3px;
		}
		.modal .modal-body .modal_body_haeding {
			max-width: calc(100% - 20px);
		}
		.order_fornew_passes .demo-pass-template {
			width: auto;
		}
		.order_fornew_passes .demo-pass-template .pass-design-layout {
			width: 100%;
			min-height: initial;
			max-width: 300px;
		}
		.order_fornew_passes .demo-pass-template {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			float: none;
			max-width: 330px;
			margin: 20px auto;
		}
		.order_fornew_passes .order_pass {
			padding: 73px 0 70px;
		}
		.my_pass_templates .order_fornew_passes .dashboard-order.order_pass.no-template span.add_order {
			margin-bottom: 0;
		}
		.my_pass_templates .dashboard-order.order_pass.no-template {
			min-height: initial;
		}
		.adduser .modal-header h1, #uploadUser .modal-dialog .modal-content .modal-title, .addLicenseHolderModal .modal-header h1 {
			max-width: calc(100% - 30px);
		}
		.modal-header .close {
			right: 25px;
			top: 24px;
		}
		.browsedCsvContainer {
			padding: 10px 0 10px;
		}
		#myOrderPopup0 .modal-header {
			padding: 30px 30px 0px;
			clear: both;
		}
		#myOrderPopup0 .modal-header #closebtn {
			top: 26px;
		}
		.dummy_data-content .dummy_data-content-logo img {
			margin-bottom: 30px;
		}
		#cancel_subscription .modal-header, #DowngradePlan .modal-header, #myOrderPopup .modal-header{
			padding: 30px 30px 0px;
		}
		#cancel_subscription .modal-header .close, #DowngradePlan .modal-header .close, #myOrderPopup .modal-header .close{
			right: 27px;
			top: 30px;
		}
		.subsription_heading + form.payment_method_form.sign_up_form .form-group {
			margin-bottom: 40px;
		}
		.modal.myOrderPopup #orderform .xpry.modal_form_field label {
			font-size: 16px;
		}
		#addUserForm .floating-label_group select, .addLicenseHolderModal .floating-label_group select{
			border: none;
			font-size: 24px;
			border-bottom: 1px solid #b2afa6;
			background: transparent;
			padding: 0 !important;
			margin-top: 27px;
			line-height: normal;
		}
		.status_main table th {
			padding-right: 5px;
			padding-left: 5px;
		}
		#UpgradeSubscription .sending_passes_heading h3 {
			font-size: 23px;
		}
		#UpgradeSubscription.select_subscription .modal-header .sending_passes_heading {
			width: calc(100% - 14px);
		}

		.payment_method_form .upgrade_subscription_btn + .modal.myOrderPopup #orderform .modal_form_field {
			margin-bottom: 30px;
		}
		.payment_method_form .myOrderPopup form#orderform .modal_main_field.form-group.valid-thru-field {
			margin-bottom: 25px;
		}
		.payment_method_form .myOrderPopup form#orderform .modal_main_field.form-group.valid-thru-field .xpry.modal_form_field {
			position: static;
			margin-bottom: 0;
		}
		.payment_method_form .upgrade_subscription_btn + .modal.myOrderPopup #orderform .modal_form_field:first-child {
			margin-bottom: 31px;
		}

		.payment_method_form  .modal.myOrderPopup #orderform .modal_form_field label {
			font-size: 20px;
		}
		.payment_method_form .upgrade_subscription_btn + .modal.myOrderPopup #orderform .modal_form_field label {
			top: 6px;
		}
		.payment_method_form .upgrade_subscription_btn + .modal.myOrderPopup #orderform .floating-label_group input:not(:placeholder-shown) ~ label, .payment_method_form .upgrade_subscription_btn +  .modal.myOrderPopup #orderform .floating-label_group input:focus ~ label, .payment_method_form .upgrade_subscription_btn +  .modal.myOrderPopup #orderform .floating-label_group input:not(:placeholder-shown) ~ label {
			top: -17px;
		}
		.payment_method_form .myOrderPopup form#orderform .modal_main_field.form-group.valid-thru-field .xpry.modal_form_field label {
			font-size: 16px;
		}
		.payment_method_form .myOrderPopup form#orderform .modal_main_field.form-group.valid-thru-field {
			margin-bottom: 45px;
		}
		.payment_method_form .upgrade_subscription_btn + .modal.myOrderPopup #orderform .modal_form_field:first-child label {
			font-size: 16px;
		}
		.payment_method_form .upgrade_subscription_btn + .modal.myOrderPopup #orderform .modal_form_field:first-child #company_name {
			padding-top: 3px;
		}
		.modal.myOrderPopup #orderform .modal_form_field input {
			padding: 10px 0 5px;
		}
		#myOrderPopup1 input.btn-danger#testback {
			margin-right: 0px;
			height: auto;
			font-size: 21px;
		}
		.modal.myOrderPopup #orderform .xpry.modal_form_field > label {
			top: 1px;
		}
		#orderform .modal_form_field.form-group:nth-child(3) {
			margin-bottom: 45px;
		}
		.myOrderPopup form#orderform input[type="submit"] {
			max-width: 180px;
			min-width: initial;
		}
		#myOrderPopup1 input.btn-danger {
			min-width: 170px;
		}
		.myOrderPopup form#orderform input#orderConfirm {
			padding: 8px 25px;
		}
		.modal.myOrderPopup #orderform .modal_form_field input {
			padding: 10px 0 5px;
		}
		.payment_method_form .modal.myOrderPopup #orderform > .modal_form_field:first-child > label {
			top: 0px !important;
		}
		.myOrderPopup input[type="submit"], .myOrderPopup input.btn-danger {
			padding: 8px 25px;
		}
		.modal.myOrderPopup .modal_form_field.floating_validthru.form-group .modal_form_field.floating_cvc.form-group .floating-label_group {
			margin: 0;
		}
		.modal_form_field.floating_cvc.form-group {
			width: 100%;
			float: left;
			padding-left: 0;
		}
		.modal.myOrderPopup .modal_form_field.floating_validthru.form-group .floating-label_group {
			padding-right: 0px;
		}
		.modal.myOrderPopup .modal_form_field.floating_validthru.form-group {
			width: 100%;
		}
		.my_pass_templates .order_pass {
			height: auto;
		}
		#OrderTempPopup.modal.myOrderPopup .modal_form_field.form_field_spacing.hide-input-field, .modal.myOrderPopup .modal_form_field.form_field_spacing.hide-input-field {
			margin-bottom: 29px;
		}
		.banner-slider .swiper-wrapper .swiper-slide.template_without_image .pass_layout {
			max-height: 406px;
		}
		.same_status_section.codes_section .codes_left {
			width: 100%;
		}
		.same_status_section.codes_section .codes_right {
			max-width: 100%;
			width: 100%;
		}        
		.codes_main_section .codes_section:nth-child(2) {
			flex-direction: column;
		}
		.same_status_section.codes_section .codes_left {
			width: 100%;
		}
		.same_status_section.codes_section.codes-scaaner_two .codes-image_section img {
			max-width: 100%;
			width: 100%;
		}
		.same_status_section.codes_section .react-contextmenu-wrapper {
			width: 100%;
		}
		.container {
			max-width: 100%;
		}
		footer .footer_content .footer_rgt {
			display: none;
		}
		.header_login.padding_container.bg_color_section {
			padding-left: 0px;
			padding-right: 0px;
			padding-bottom: 5px;
			padding-top: 5px;
		}
		footer.padding_container {
			padding-left: 20px;
			padding-right: 20px;
			padding-top: 47px;
		}
		.footer_content span, .footer_content span a {
			font-size: 18px;
			line-height: 1.75;
		}
		.copyright p {
			font-size: 18px;
			line-height: 36px;
		}
		footer .footer_content .footer-left {
			margin-bottom: 22px;
		}
		.main_logo_heading_padding.start_ordering_passes.my_pass_templates.padding_container {
			padding: 0 15px;
		}
		#UpgradeSubscription.select_subscription .have-subscription .modal-header {
			padding-bottom: 0px;
		}
		.modal .subscription_table table tbody tr.table-price, .modal .subscription_table table tbody tr.table-price div {
			min-height: 103px;
		}
		.sending_passes_heading span {
			font-size: 15px;
		}
		#subscription.tab-pane.subscription_sec div > .subsription_heading.padding_container .pay_with_invoice h4 {
			font-size: 24px;
			line-height: 30px;
			margin-bottom: 5px;
		}
		#subscription.tab-pane.subscription_sec div > .subsription_heading.padding_container .pay_with_invoice span {
			font-size: 18px;
			line-height: 23px;
		}
		.no-dashboard .order_fornew_passes .demo-pass-template .pass-design-layout img {
			height: auto;
			min-height: 295px;
		}
		#contactus-form .form-group-btn input[type="submit"] {
			max-width: 160px;
			font-size: 21px;
			padding: 7px;
		}
		#contactus-form textarea {
			min-height: 265px;
		}
		#modalcontactus h1 {
			font-size: 32px;
		}
		#modalcontactus .modal-body h3 {
			font-size: 18px;
			line-height: 22px;
		}
		#modalcontactus .modal-header {
			padding: 30px 25px 10px;
		}
		#contactus-form {
			padding: 6px 0 0;
		}
		.passes_more_details .passess-details:nth-child(2)::before, .passes_more_details .passess-details:nth-child(3)::before, .passes_full_more_details .passess-details:nth-child(2)::before, .passes_full_more_details .passess-details:last-child::before {
			display:none;
		}
		.passes_full_more_details {
			margin-top: 20px;
		}
		.passes_full_more_details {
			margin-top: 20px;
			flex-direction: column;
		}
		.passes_more_details .passess-details, .passes_full_more_details .passess-details, .passes_more_details .passess-details:nth-child(3), .passes_full_more_details .passess-details:nth-child(3) {
			width: 100%; 
		}
		.passes_more_details .passess-details:nth-child(3), .passes_full_more_details .passess-details:nth-child(3) {
			width: 100%;
		}
		.status_of_passses.block_element {
			padding-top: 0;
		}
		.day_status_of_passses {
			margin-top: 20px;
		}
		.day_status_of_passses, .time_status_of_passses {
			margin-top: 20px;
		}
		.time_status_of_passses.block_element p {
			font-size: 16px;
			line-height: 19px;
			margin-bottom: 30px;
		}
		.my_scanner_page .my_scanner.start_ordering_scanner .tab_background #updateScannerForm {
			flex: 0 0 100%;
		}
		#scanner .scanner_update_form {
			flex-direction: column;
		}
		#updateScannerForm .form_btn_group {
			margin-bottom: 30px;
		}
		 #updateScannerForm .form_btn_group .addscanner_add_btn, .scanner_tab_delete_btn .scanner_btn.delete_scanner {
			font-size: 22px;
			height: 55px;
			width: 235px;
		}
		#updateScannerForm .form_btn_group {
			margin-bottom: 20px;
		}
		#updateScannerForm .form_btn_group {
			margin-top: 20px;
		}
		.my_scanner_page .my_scanner.start_ordering_scanner .tab_background {
			padding: 20px 20px 20px;
		}
		.adduser .modal-dialog .modal-body h4 + h2, .addLicenseHolderModal .modal-dialog .modal-body h4 + h2 {
			font-size: 20px;
			margin-top: 14px;
		}
		
		/* #addLicenseHolder .addLicenseHolderForm .floating-label_group input:not(:placeholder-shown) ~ label {
			top: -17px;
			font-size: 14px;
			color: #97937f;
			font-family: 'Museo Sans 300';
		} */
		.start_ordering_passes .dashboard-order.order_pass.no-template p{
			margin-top: 22px;
			font-size: 21px;
			line-height: 1.2;
		}
		.start_ordering_scanner .dashboard-order.order_scanner.no-scanner p {
			margin-top: -72px;
			font-size: 21px;
			line-height: 1.2;
		}
		.no-scanner span.add_order {
			height: 60px;
			width: 60px;
		}
		 #scanner_distribution .addLicenseHolderModal .modal-dialog .modal-body h3 {
			font-size: 18px;
		}
		#addLicenseHolder .form-group {
			margin-bottom: 60px;
		}
		#deleteScanner .modal-header .close {
			right: 27px;
			top: 30px;
		}
		#updateScannerForm .form_btn_group .addscanner_add_btn, .scanner_tab_delete_btn .scanner_btn.delete_scanner {
			width: 100%;
		}
		#deleteHolderList .modal-footer, #deleteScanner {
			padding-right: 0;
		}
		#addLicenseHolderConfirm .modal-dialog .modal-content.bg_color_section .modal-body span, #sendLicenseConfirm .modal-dialog .modal-content.bg_color_section .modal-body span {
			font-size: 30px;
			line-height: 36px;
		}
		#sendLicense .send-right .form-btn-btm input.submit_btn.no_to_passes {
			margin-right: 10px;
			max-width: 115px;
			float: left !important;
			margin-right: 4%;
			width: 48% !important;
			max-width: none;
			max-width: initial;
		}
		#sendLicense .send-right .form-btn-btm input.submit_btn {
			max-width: 186px;
			margin-right: 0;
			width: 48% !important;
			max-width: none;
			max-width: initial;
			height: 56px;
			font-size:20px;
		}
		#deleteHolderList .modal-footer{padding-right:30px;}
		.MuiTableCell-root-155 {
			padding: 4px 36px 4px 24px;
		}
		.status_main table button.active {
			padding: 0 3px;
		}
		#sendLicense .allSelectedMailList, #sendLicense .send-right {
			float: left;
			width: 100%;
		}
		#sendLicense .send-right {
			margin-top:15px;
		}
		#deleteScanner .modal-dialog .modal-footer #cancelDeleteUser, #deleteHolderList .modal-dialog .modal-footer #cancelDeleteUser, .adduser .modal-footer #cancelDeleteUser, #deleteHolder.adduser .deleteBtns #deleteUserBtn, #changeHolderStatus.adduser .deleteBtns #deleteUserBtn{padding: 10px 25px;
			width: 100%;
			margin: 0 0 10px;
			max-width: 100%;
		}
		#deleteScanner .deleteBtns, #deleteHolderList .deleteBtns,  #deleteHolder .deleteBtns, #changeHolderStatus  .deleteBtns{
			width: 100%;
			flex-direction: column;
		}
		#changeHolderStatus .modal-footer, #deleteHolder .modal-footer, #changeHolderStatus .modal-footer{
			padding-left: 30px;
			padding-right: 30px;
		}
		.active-plan.pachageArea {
			border: 4px solid #57c0d9;
			background: #D8E2E3;
		}
		.active-plan > div {
			text-align: center;
		}
		.active_user_account {
			padding: 0 15px 60px;
		}
		.userPermission .modal-dialog .modal-content .modal-body .user_permissions .slider::before {
			position: absolute;
			content: "";
			height: 24px;
			width: 24px;
			left: 4px;
			bottom: 3px;
		}
		#AssociatedDelete .modal-title {
			font-size: 30px;
			line-height: 36px;
		}
		.status_of_passses.block_element .status_gauge.same_status_section div {
			width: 500px;
			height: auto;
			margin: 0;
		}
		.status_of_passses .status_gauge svg.speedometer {
			width: 650px !important;
			height: 370px !important;
		}
		#userPermission .deleteBtns {
			width: 100%;
		}
		#userPermission .deleteBtns .btn.btn-danger.btn_delete {
			max-width: 48%;
			min-width: 48%;
		}
		#userPermission .deleteBtns.permission_delete_btn .btn.btn-danger.btn_delete {
			margin-right: 4%;
		}
		#templateTypeLimitPopup .modal-dialog .modal-footer .Btns {
	width: 100%;
}
	}
	@media only screen and (min-device-width: 320px) and (max-device-width: 479px) and (orientation: portrait) {
		#UpgradeSubscription.select_subscription.modal .modal-body{
		height: 450px;
		overflow-y: auto;
		}
	}
	@media only screen and (max-width:640px){
	.status_of_passses .status_gauge svg.speedometer {
		width: 520px !important;
		height: 300px !important;
	}
	.my_scanner_page .my_scanner.start_ordering_scanner .tab_background #updateScannerForm p{
		font-size: 20px;
	}
}

 @media only screen and (max-width:575px){
	/* header.header_login .header_logo img {
		max-width: 230px !important;
	} */
	.suportedAreaFooter{
		margin-right: 0px;
	}
	.suportedAreaFooter span {
		font-size: 17px;
	}
	.suportedAreaFooter img {
		margin-left: 4px;
		width: 270px!important;
	}
	.my_scanner_page .my_scanner.start_ordering_scanner .tab_background #updateScannerForm p{
		font-size: 19px;
	}
	#templateTypeLimitPopup .modal-dialog .modal-header h4 {
    font-size: 24px;
    padding-right: 30px;
    margin-bottom: 20px;
    line-height: 26px;
}
#deleteUser .modal-footer #cancelDeleteUser, #AssociatedDelete .modal-footer #cancelDeleteUser, .adduser .modal-footer #cancelDeleteUser {
	height: 44px;
}
#templateTypeLimitPopup .modal-dialog .modal-body p {
    padding-top: 0;
}
	.dummy_data-content .dummy_data-content-logo {
		width: 90%;
	}
	.dummy_data-content .dummy_data-content-logo img {
		max-width: 70px;
	}
	#AssociatedDelete .modal-footer #deleteUserBtn, .adduser .modal-footer #deleteUserBtn, #AssociatedDelete .modal-footer #cancelDeleteUser {
		margin-left: 0%;
		margin-right: 4%;
		padding: 15px 25px;
	}
	#AssociatedDelete .modal-footer #deleteUserBtn {
		margin-right: 4%;
	}
	#AssociatedDelete .modal-footer #cancelDeleteUser {
		margin-left: 0%;
		margin-right: 0%;
	}
	.main_logo_heading_padding.start_ordering_passes.my_pass_templates.padding_container h3 {
		margin-top: 0px;
		font-size: 24px;
	}
	.term-popup .modal-header {
		padding-right: 40px;
	}
	.term-popup .modal-footer {
		padding-right: 30px;
	}

	.dummy_data-content .dummy_data-content-logo {
		padding: 60px 10px 37px;
	}
	.payment_method_form.sign_up_form .form-group.card-detail span.card_number_value {
		margin-top: -15px;
	}
	.sign_up_form .form-group.card-detail input.inputText.pre-filled-form {
		padding-left: 213px;
	}
.modal-dialog {
        margin: 0 15px;
}

.comming_soon .middle {
    padding: 180px 30px;
}
.passes_more_details .passess-details:nth-child(2)::after, .passes_more_details .passess-details:nth-child(3)::after {
	width: 90%;
}
.deleteBtns {
	width: 100%;
	float: left;
	display: flex;
	flex-wrap: wrap;
}
#deleteUser .modal-title, #deleteScanner .modal-dialog .modal-header h4, .adduser .modal-title, #deleteHolderList .modal-dialog .modal-header h4 {
	font-size: 24px;
	padding-right: 20px;
	margin-bottom: 10px;
	line-height: 25px;
}
#deleteScanner .modal-dialog .modal-footer .deleteBtns {
	flex-direction: column;
}
#deleteScanner .modal-dialog .modal-footer .deleteBtns #deleteUserBtn, #deleteScanner .modal-dialog .modal-footer #cancelDeleteUser {
	max-width: 100%;
	width: 100%;
}
#deleteUser .modal-footer {
	margin-top: 0px;
	margin-bottom: 10px;
}
#deleteUser .modal-footer #deletePopupDelete {
	width: 100%;
	margin-bottom: 10px;
}
#deleteUser .modal-footer {
	padding: 0 16px 20px;
}
.modal form {
    padding-top: 20px;
}
.modal .modal_form_field button {
	top: 22px;
	padding: 2px 9px;
}
p.card_number_text_value {
    font-size: 20px;
}
span.card_number_value {
	padding-left: 59px;
	font-size: 28px;
	margin-top: -7px;
}
.comming_soon .topleft {
    padding-top: 16px;
}
.comming_soon .topleft img {
    max-width: 320px;
}
.apexcharts-yaxis, .apexcharts-inner{
height:300px;
}
.sign_up_form .form-group.card-detail span.card_number_value {
	margin-top: -22px;
	font-size: 36px;
}
.subscription_sec .subsription_heading.padding_container h3 {
	font-size: 26px;
}
.payment_method_form.sign_up_form button.upgrade_subscription_btn {
	padding: 17px 25px;
	font-size: 22px;
	width: 290px;
}
.profile-form_btn button, .profile-form_btn input[type="submit"], .upgrade_subsirption button.cancel_subscription {
	font-size: 21px;
	min-width: 270px;
	padding: 15px 9px;
}
.upgrade_subsirption .upgrade_subscription_btn {
	padding: 15px 18px;
	font-size: 21px;
}
#subscription.tab-pane.subscription_sec div > .subsription_heading.padding_container {
	padding: 10px 0 30px;
}
.modal-dialog {
	margin: 0 auto;
}
#DowngradePlan .modal-dialog .modal-content .modal-body .modal_body_haeding {
	font-size: 30px;
	line-height: 37px;
}
#DowngradePlan .modal-dialog .modal-content .modal-body .receipt_section p {
	font-size: 18px;
}
#myOrderPopup1 .isNotActive, #myOrderPopup1 .isActive, #myOrderPopup1 input.btn-danger {
	height: auto;
	min-width: 150px;
	font-size: 24px;
}
#deleteUser .modal-footer #deletePopupDelete, #deleteUser .modal-footer #deleteUserBtn, #deleteUser .modal-footer #cancelDeleteUser {
	padding: 10px 25px;
}
.modal.myOrderPopup .modal_form_field button {
	top: -15px;
}
#OrderTempPopup.modal.myOrderPopup .modal_form_field button {
	top: 10px;
}
.myOrderPopup form .modal_form_field.form_field_spacing.hide-input-field {
	margin-bottom: 10px;
}
#OrderTempPopup.modal.myOrderPopup .modal_form_field button {
    top: 12px;
}
#OrderTempPopup.modal.myOrderPopup .modal_form_field.orderWithoutEdit {
	margin-bottom: 0px;
}
.modal .template_build.single_build {
	padding-bottom: 4px;
}
#OrderTempPopup.modal.myOrderPopup .modal_form_field {
	margin-bottom: 55px;
}
.myOrderPopup form#orderform .modal_main_field.form-group.valid-thru-field .xpry.modal_form_field {
	margin-top: 0;
}
#myOrderPopup2 span.card_number_value {
	margin-top: -7px;
}
#myOrderPopup2.modal.myOrderPopup .modal_form_field button {
	top: 13px;
}
#myOrderPopup2 img.cardTypePng {
	bottom: 14px;
}
.myOrderPopup form#orderform input[type="submit"] {
	max-width: 178px;
	width:auto;
}
.subscription_sec .subsription_heading + form.payment_method_form.sign_up_form .form-group {
	margin-bottom: 50px;
}
#users .no_and_search_samestyle span, #members .no_and_search_samestyle span {
	font-size: 16px;
	line-height: 23px;
	float: left;
	width: 100%;
}
.modal.myOrderPopup .modal_form_field button {
	top: 10px;
}


.icelandic_body .adduser .browsedCsvContainer input#addbrowsedCsvName, .icelandic_body .addLicenseHolderModal .browsedCsvContainer input#addbrowsedCsvName {
	width: calc(100% - 114px);
}
.icelandic_body  .browsedCsvContainer .react-file-reader button {
	padding: 14px 7px;
	font-size: 16px;
}
 }   

@media only screen and (max-width:479px){
.search_section input.serach {
        height: 44px;
        max-width: 100%;
        width: 100%;
        padding: 0 20px 0 37px;
        border-radius: 8px;
}
.suportedAreaFooter span {
	line-height:36px
}
.suportedAreaFooter img {
	width: 215px !important;
}
header.header_login .header_logo img, .thankyou_back img {
	max-width: 228px;
}
.icelandic_body .adduser .browsedCsvContainer input#addbrowsedCsvName, .icelandic_body .addLicenseHolderModal .browsedCsvContainer input#addbrowsedCsvName {
	width: 100%;
}
.mobile_header .header_logo {
	padding: 13px 27px 13px;
}
.icelandic_body .browsedCsvContainer .react-file-reader button {
	padding: 10px 7px;
	font-size: 16px;
}
.icelandic_body  #OrderConfirmation .submit_btn {
	width: auto !important;
	font-size: 24px;
	max-width: inherit;
}
.icelandic_body .upgrade_subsirption .upgrade_subscription_btn {
	max-width: 100%;
	width: 100%;
}
.icelandic_body  .active_user_account {
	padding: 0 10px 100px;
}
.icelandic_body .multi_user_section h2 {
	font-size: 24px;
}
.icelandic_body .modal .receipt_section p {
	font-size: 18px;
}
#contactUsConfirm .modal-dialog, #addUserConfirm .modal-dialog, #sendPassConfirm .modal-dialog, #inviteUserConfirm .modal-dialog {
	max-width: 90%;
}
#contactUsConfirm .modal-dialog .modal-content.bg_color_section img, #addUserConfirm .modal-dialog .modal-content.bg_color_section img, #sendPassConfirm .modal-dialog .modal-content.bg_color_section img, #inviteUserConfirm .modal-dialog .modal-content.bg_color_section img {
	max-width: 150px;
}
 #inviteUserConfirm .modal-dialog .modal-content.bg_color_section span {
    font-size: 36px;
}
.icelandic_body .payment_method_form.sign_up_form button.upgrade_subscription_btn {
	width: auto;
}
.tab-content .active_user_account .multi_user_section button.upgrade_subscription_btn {
	height: 57px;
	font-size: 21px;
	max-width: 300px;
}
#AssociatedDelete .modal-title {
	font-size: 26px;
	line-height: 32px;
}
.upgrade_subsirption button {
	max-width: 100%;
}
#userPermission .deleteBtns .btn.btn-danger, #userPermission .deleteBtns button {
	padding: 15px 20px !important;
}
#deleteScanner .modal-dialog .modal-footer .deleteBtns #deleteUserBtn, #deleteScanner .modal-dialog .modal-footer #cancelDeleteUser {
	padding: 10px 20px !important;
}
#deleteScanner .modal-header {
	padding: 30px 30px 0px;
}
#deleteScanner .modal-dialog .modal-header h4 {
	font-size: 24px;
	line-height: 26px;
	margin-bottom: 10px;
	padding-right: 0px;
}
.no_scanner_listed.no_and_search_samestyle {
    margin-top: 0px;
}
#scanner_distribution h3 {
	font-size: 23px;
	line-height: 26px;
}
/* .mobile_menu_list.show, .mobile_menu_list {
	inset: 80px 0px 0px;
} */

.active_user_account {
	padding: 0 15px 100px;
}
.multi_user_section .user_invitation_container .form-group-btn input {
	font-size: 21px;
	max-width: 210px;
	height: 57px;
}
.profile_sec.same_profile_tab_style .profile-form_btn input[type="submit"] {
	font-size: 21px;
	height: 57px;
	max-width: 210px;
	min-width: 210px;
	padding: 14px 30px;
}
#deleteScanner .modal-dialog .modal-body p, #deleteHolderList .modal-dialog .modal-body p {
	padding-top: 10px;
}
.active_user_account .multi_user_section .upgrade_subscription_btn {
	font-size: 21px;
	height: 57px;
}
.active_user_account {
	padding-bottom: 80px;
}
.multi_user_section h4 {
	font-size: 22px;
}
.multi_user_section h2 {
	font-size: 28px;
	line-height: 30px;
	margin-bottom: 14px;
	padding-bottom: 10px;
}
.multi_user_section span {
	font-size: 16px;
}
.modal-dialog .modal-content p.modal_body_haeding {
    padding-right: 30px;
}
.modal.cancel_subscription .modal-body .modal_body_haeding {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 10px;
}
.modal.myOrderPopup .modal_form_field {
	margin-bottom: 38px;
}
.same_status_section.codes_section .codes_right {
	max-width: initial;
}
#OrderTempPopup.modal.myOrderPopup .modal_form_field.form_field_spacing.hide-input-field, .modal.myOrderPopup .modal_form_field.form_field_spacing.hide-input-field {
    margin-bottom: 0px;
}
.modal.myOrderPopup .modal_form_field.floating_validthru.form-group {
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
}

.modal.myOrderPopup .modal_form_field.floating_validthru.form-group .modal_form_field.floating_cvc.form-group {
	width: 100%;
	float: right;
	padding-left: 0;
}
.modal.myOrderPopup .modal_form_field.floating_validthru.form-group .floating-label_group {
	margin-bottom: 45px;
}
.modal.myOrderPopup .modal_form_field.floating_validthru.form-group .floating-label_group {
	width: 100%;
	float: left;
	padding-right: 0;
}
#myOrderPopup3.modal.myOrderPopup #orderform .floating-label_group input:not(:placeholder-shown) ~ label, #myOrderPopup3.modal.myOrderPopup #orderform .floating-label_group input:focus ~ label, #myOrderPopup3.modal.myOrderPopup #orderform .floating-label_group input:not(:placeholder-shown) ~ label {
	top: -25px;
}
#myOrderPopup3.modal.myOrderPopup #orderform .modal_form_field label {
	top: 4px;
}
#myOrderPopup3.modal.myOrderPopup .modal_form_field button {
	top: 7px;
}
#myOrderPopup3 span.card_number_value {
	margin-top: -9px;
}
#myOrderPopup3.modal.myOrderPopup #orderform .xpry.modal_form_field > label {
    top: -2px;
}
.modal.myOrderPopup #orderform .modal_form_field > label {
	font-size: 16px;
}
#myOrderPopup3.modal.myOrderPopup p.card_number_text_value {
	margin-bottom: 18px;
	position: relative;
	padding-bottom: 0px;
}
.modal.myOrderPopup #orderform .xpry.modal_form_field > label {
	top: -2px;
}
.banner-slider .swiper-wrapper .swiper-slide .template_image_coupon::after, .banner-slider .swiper-wrapper .swiper-slide .template_image_coupon::before {
	max-width: 236px;
}
.status_main table th {
	padding-left: 13px;
}
.payment_method_form.sign_up_form button.upgrade_subscription_btn {
	width: 250px;
	min-width: initial;
}
div#uploadUser .form-btn-btm button#uploadCancel {
	margin: -4px 15px 0 0;
}
.browsedCsvContainer .react-file-reader button {
    padding: 9px 11px;
    font-size: 17px;
}
.browsedCsvContainer .react-file-reader {
	width: 100%;
}
.browsedCsvContainer .react-file-reader button {
	width: 100%;
}
.confirmation.text.thank_text, .confirmation.text.thank_text span{
	display: flex;
}

.banner-slider img {
	max-height: 345px;
}
.banner-slider .swiper-wrapper .swiper-slide {
width: 310px !important;
}
.my_pass_main_section.slider_with_pending_temp section.banner-slider.bigger-slider {
max-width: 315px;
padding-right:0
}

#password.same_profile_tab_style form h3 {
    line-height: 32px;
}
.tab_background .tab-pane {
    padding: 15px 15px;
}
.bg_color_section.block_element.main_logo_heading_padding.my_pass_templates.my_profile_section.padding_container {
    padding-left: 15px;
    padding-right: 15px;
}
.tab_background #password.tab-pane {
    padding: 10px 15px 200px;
}
.my_profile_section .nav.nav-tabs li {
    padding-right: 20px;
}
.my_profile_section .nav.nav-tabs li:last-child {
    padding-right: 0px;
}
.modal .modal-body {
    padding: 0 25px 10px;
}
.search_section img {
	top: 11px;
	left: 12px;
	width: 20px;
	height: 20px;
}
.myOrderPopup input[type="submit"], .myOrderPopup input.btn-danger {
	margin-left: 5px;
}
#myOrderPopup1 input.btn-danger {
	margin-right: 5px;
}
#myOrderPopup1 .isNotActive, #myOrderPopup1 .isActive, #myOrderPopup1 input.btn-danger {
	min-width: 100px;
	font-size: 16px;
}
.profile-form_btn button, .profile-form_btn input[type="submit"], .upgrade_subsirption button.cancel_subscription {
	min-width: 260px;
}
.sending_passes_heading h3 {
	font-size: 24px;
}
.modal .receipt_section {
	padding-top: 20px;
}
.modal.myOrderPopup .modal_form_field label {
	font-size: 20px;
}
.floating-label_group input:not(:placeholder-shown) ~ label, .floating-label_group input:focus ~ label, .floating-label_group input:not(:placeholder-shown) ~ label {
	font-size: 15px;
}
#DowngradePlan .modal-dialog .modal-content .modal-body .btn.btn-default.submit_btn, #OrderConfirmation1 .modal-dialog .modal-body .btn.btn-default.submit_btn {
	max-width: 185px;
	width: 100px;
	font-size: 26px;
	height: auto;
}
.myOrderPopup .btn.btn-default.submit_btn, #OrderConfirmation1 .btn.btn-default.submit_btn, #OrderConfirmation2 .btn.btn-default.submit_btn, #OrderConfirmation3 .btn.btn-default.submit_btn, #OrderConfirmation4 .btn.btn-default.submit_btn {
	font-size: 20px;
	max-width: 170px;
	width: 100%;
	height: auto;
}
.bg_color_section.block_element.main_logo_heading_padding.my_pass_templates.my_profile_section.padding_container h3 {
	font-size: 25px;
	margin-bottom: 10px;
}
#addUserConfirm .modal-dialog .modal-content.bg_color_section img, #sendPassConfirm .modal-dialog .modal-content.bg_color_section img {
	max-width: 100px;
}
.status_main table td {
	font-size: 14px;
}
.codes_right .codes-image_section span br {
	display: none;
}
.main_logo_heading_padding.start_ordering_passes.my_pass_templates.padding_container h3 {
	margin-bottom: 10px;
	font-size: 23px;
	line-height: 26px;
}
.my_pass_templates .order_pass.ordered_pending_passes span.order_dated {
	font-size: 14px;
}
.codes_right .codes-image_section {
	width: 100%;
}
#sendUser .modal-header h3, #sendLicense .modal-header h3 {
	font-size: 16px;
	margin:0;
}
.main_distribution_btn .choosefile .choosefilebtn {
	width: 100%;
	max-width: 100%;
}
.main_distribution_btn .choosefile {
	margin-right: 0;
}
.main_distribution_btn .choosefile {
	width: 100%;
}
#OrderConfirmation2 .receipt_section .thakyou_msg, .modal .receipt_section .thakyou_msg, #OrderConfirmation1 .modal-dialog .modal-content p.thakyou_msg, #OrderConfirmation2 .modal-dialog .modal-content p.thakyou_msg, #OrderConfirmation3 .modal-dialog .modal-content p.thakyou_msg, #OrderConfirmation4 .modal-dialog .modal-content p.thakyou_msg {
	font-size: 24px;
}
#OrderConfirmation1 .modal-dialog .modal-content p.modal_body_haeding, #OrderConfirmation2 .modal-dialog .modal-content p.modal_body_haeding, #OrderConfirmation3 .modal-dialog .modal-content p.modal_body_haeding, #OrderConfirmation4 .modal-dialog .modal-content p.modal_body_haeding, .modal-dialog .modal-content p.modal_body_haeding {
	font-size: 26px;
	line-height: 32px;
	margin-bottom: 20px;
}

.main_logo_heading_padding.start_ordering_passes.my_pass_templates.padding_container .pass-design-content h3 {
	font-size: 40px;
}
.my_pass_templates.no-dashboard .order_fornew_passes .pass-design-layout .pass-template-hover span {
	font-size: 24px;
}
/* .no-dashboard .order_fornew_passes .demo-pass-template .pass-design-layout {
    min-height: 430px;
} */
.main_distribution_btn button.pass_limit_reached.distribution_btns {
	margin: 0px auto 10px;
	line-height: 24px;
}
/* header.header_login .header_logo img, .thankyou_back img {
	max-width: 200px !important;
} */
.status_of_passses .status_gauge svg.speedometer {
    width: 410px!important;
    height: 200px !important;
}
.MuiTypography-root-51.MuiTypography-caption-61.MuiTypography-colorInherit-80.MuiTablePagination-caption-201 {
    display: none;
}
.sign_up_page h2, .icelandic_body .sign_up_page h2 {
    font-size: 28px;
    line-height: 1.2;
}
.owl-nav .owl-prev {
    height: 23px;
    width: 23px;
    left: -5px;
    background-position: 2px 6px !important;
    background-size: 15px !important;
}
.owl-nav .owl-next
{
	height: 23px;
    width: 23px;
    right: -5px;
    background-position: 2px 6px !important;
    background-size: 15px !important;
}
.status_of_passses .status_graph img {
    width: 100%;
}
.status_of_passses .status_gauge{
    display: block;
}
/* .my_pass_templates .dashboard-order.order_pass.no-template {
    min-height: 310px;
} */
.choosefile button.choosefilebtn {
    left: 0;
    top: -11px;
    max-width: 230px;
    right: 0;
    margin: 0 auto;
    font-size: 14px;
}
.modal button.close {
    font-size: 35px;
}
.user-listing {
	margin-top: 20px;
}
.choosefile {
	display: table;
   margin: 20px auto 5px;
   max-width: 100%;
   width: 100%;
}
.main_distribution_btn .distribution_btns {
	display: table;
	float: none;
	margin: 10px auto 10px;
    width: 100%;
    max-width: 230px;
}
.main_logo_heading_padding .no_user_listed h3 {
    margin: 10px 0 13px;
}
.no_user_listed {
	margin-top: 20px;
}
.search_section .fa.fa-search {
	left: 15px;
	top: 12px;
	font-size: 16px;
}

div#sendUser .modal-dialog .send-right {
    padding-left: 0px;
    padding-right: 0px;
}
.status_of_passses .status_gauge {
    overflow-x: hidden;
}
.confirmation.text {
    display: flex;
}
.thanku_page {
    padding: 150px 20px 100px;
}
.subscription_exceeded.block_element .subscription_exceeded_btn span {
	font-size: 14px;
}
.main_logo_heading_padding h3 {
	margin: 10px 0 20px;
}
.main_logo_heading_padding h3 {
	font-size: 26px;
	line-height: 33px;
}
.main_distribution_btn .distribution_btns {
	width: 100%;
	max-width: initial;
}
.no_user_listed .main_distribution_btn .distribution_btns {
	margin-right: 0;
	margin-left:0;
}
.modal .modal_main_field .xpry.modal_form_field {
	flex: 0 0 50%;
}
.adduser.modal .modal-body {
	padding: 0 30px 10px;
}
.adduser .browsedCsvContainer {
	display: flex;
	flex-direction: column;
}
.adduser .browsedCsvContainer input#addbrowsedCsvName, #addLicenseHolder .browsedCsvContainer input#addbrowsedCsvName {
	height: 48px;
	padding: 7px 24px;
	width: 100%;
	margin: 0 0 10px;
} 
.modal .modal_form_field {
	margin-top: 20px;
}
.modal-footer {
	padding-left: 30px;
	padding-right: 30px;
}
.my_pass_templates .order_pass.ordered_pending_passes span.order_dated {
	font-size: 16px;
}
#subscription.tab-pane.subscription_sec div > .subsription_heading.padding_container {
	margin-bottom: 0px;
}
.modal.myOrderPopup .modal_form_field.floating_validthru.form-group .floating-label_group {
	margin-bottom: 30px;
}
.upgrade_subscription_btn + #myOrderPopup #orderform .modal_form_field.form-group:nth-child(3) {
	margin-bottom: 30px;
}
#password.same_profile_tab_style form h3 {
    font-size: 26px;
    margin-bottom: 30px;
}

#DowngradePlan .modal-dialog .modal-content .modal-body .modal_body_haeding {
	font-size: 23px;
	line-height: 31px;
	padding-right: 10px;
}
#DowngradePlan .modal-dialog .modal-content .modal-body .btn.btn-default.submit_btn, #OrderConfirmation1 .modal-dialog .modal-body .btn.btn-default.submit_btn {
	max-width: 140px;
	width: 100px;
	font-size: 20px;
	height: auto;
}
.myOrderPopup .card_number_text_value span.card_number_value {
	margin-top: -7px;
}
.myOrderPopup .card_number_text_value .modal_form_field button {
	top: 13px;
}
.myOrderPopup .card_number_text_value img.cardTypePng {
	bottom: 13px;
}
 .addLicenseHolderModal .modal-header h1 {
	font-size: 24px;
	line-height: 25px;
	margin-bottom: 0;
	font-family: 'Museo Sans 300';
}
.modal.myOrderPopup .modal_form_field.floating_validthru.form-group .floating-label_group {
	margin-bottom: 0;
}
#OrderTempPopup.modal.myOrderPopup .modal_form_field {
	margin-bottom: 39px;
}
.modal.myOrderPopup .modal_form_field.floating_validthru.form-group {
	margin-bottom: 38px;
}
.payment_method_form .upgrade_subscription_btn + .modal.myOrderPopup #orderform .floating-label_group input:not(:placeholder-shown) ~ label, .payment_method_form .upgrade_subscription_btn + .modal.myOrderPopup #orderform .floating-label_group input:focus ~ label, .payment_method_form .upgrade_subscription_btn + .modal.myOrderPopup #orderform .floating-label_group input:not(:placeholder-shown) ~ label {
	top: -9px;
}
.payment_method_form .upgrade_subscription_btn + .modal.myOrderPopup #orderform .modal_form_field label {
	top: 15px;
}
.modal .modal_form_field input {
	font-size: 20px;
}
.modal.myOrderPopup #orderform .modal_form_field input {
	padding: 10px 0 0px;
}
.upgrade_subscription_btn + #myOrderPopup #orderform .modal_form_field.form-group:nth-child(3) {
	margin-bottom: 25px;
}
#password.same_profile_tab_style form .form-group {
	margin-bottom: 37px;
}
.same_profile_tab_style form label {
	font-size: 20px;
	top: 11px;
}
.profile-form_btn button, .profile-form_btn input[type="submit"], .upgrade_subsirption button.cancel_subscription {
	min-width: 210px;
}
.payment_method_form.sign_up_form button.upgrade_subscription_btn {
	width: 220px;
	font-size: 21px;
}
 .addLicenseHolderModal .browsedCsvContainer {
	display: flex;
	flex-direction: column;
}
.main_logo_heading_padding.start_ordering_scanner.my_scanner.padding_container .slider_with_pending_temp #banner-slider {
	width: 100%;
}
.main_logo_heading_padding.start_ordering_scanner.my_scanner.padding_container .slider_with_pending_temp #banner-slider .swiper-slide.item {
	width: 310px !important;
}
.main_logo_heading_padding.start_ordering_scanner.my_scanner.padding_container .slider_with_pending_temp #banner-slider .codes_right .codes-image_section {
	width: 260px;
	height:285px;
}
.main_logo_heading_padding.start_ordering_scanner.my_scanner.padding_container .codes_right .codes-image_section a {
	margin-top: 1px;
}
#userPermission .deleteBtns .btn.btn-danger, #userPermission .deleteBtns button {
	padding: 15px 15px !important;
	font-size: 18px;
}
#userPermission .deleteBtns .btn.btn-danger.btn_delete {
	font-size: 18px;
	max-width: 48%;
	min-width: 48%;
}
#userPermission .deleteBtns button {
	min-width: 48%;
}

}
@media only screen and (max-width:449px){
	.suportedAreaFooter img {
		width: 229px !important;
	}
	.suportedAreaFooter span {
		line-height: 42px;
	}
}
@media only screen and (max-width:413px){
    .status_of_passses .status_gauge svg.speedometer {
        left: -32px;
	}
	#addScannerForm .form_btn_group .addscanner_cancel_btn, #scanner .empty_add_scanner_tab .no_scanner_listed .form_btn_group .addscanner_cancel_btn {
	width: 120px;
	margin-right: 15px;
	font-size: 20px;
	margin-right: 9px;
}
.my_scanner_page .my_scanner.start_ordering_scanner .tab_background #updateScannerForm p{
	font-size: 17px;
}
#userPermission h4 {
	font-size: 24px;
}
.suportedAreaFooter span {
	font-size: 16px;
	line-height:36px
}
.suportedAreaFooter img {
	margin-left: 2px;
	width: 210px !important;
}
#addScannerForm .form_btn_group .addscanner_add_btn {
	font-size: 20px;
	width: 170px;
}
	.select_subscription .modal-header {
		padding-right: 35px;
	}
	.pachageArea .AreaFull {
		padding: 15px 10px 15px 12px;
	}
	.confirmation.text span {
		font-size: 16px;
	}
	.standard-fee-note p {
		line-height: 26px;
		font-size: 21px;
	}
	.tab_background .tab-pane {
		padding: 15px 8px;
	}
	#myOrderPopup1 input.btn-danger#testback {
		font-size: 19px;
		line-height: 1.5;
	}
	#cancel_subscription .modal-dialog .modal_body_haeding {
		font-family: 'Museo Sans 300';
		max-width: 540px;
		width: 100%;
		font-size: 24px;
	}
	button.btn.btn-default {
		padding: 8px 18px;
	}
	.myOrderPopup form#orderform input[type="submit"] {
		font-size: 19px;
		padding: 8px 22px;
	}
	.myOrderPopup input[type="submit"], .myOrderPopup input.btn-danger, .myOrderPopup input[type="submit"] {
		font-size: 19px;
	}
	.payment_method_form .upgrade_subscription_btn + .modal.myOrderPopup #orderform .modal_form_field {
		margin-bottom: 25px;
	}
	.myOrderPopup input[type="submit"], .myOrderPopup input.btn-danger {
		padding: 8px 22px;
	}
	.modal.myOrderPopup p.card_number_text_value button {
		font-size: 14px;
		padding: 5px 8px;
	}
	.modal.myOrderPopup .modal_form_field button {
		top: 14px;
	}
	.my_pass_templates .nav.nav-tabs li a::before {
		bottom: -2px;
	}
	.browsedCsvContainer input#browsedCsvName {
		padding: 8px 14px;
	}

	.adduser .browsedCsvContainer {
		flex-direction: column;
	}
	.browsedCsvContainer input#browsedCsvName {
		width: 100%;
		margin-right: 0;
		margin-bottom: 7px;
	}
    .status_of_passses .status_gauge div{
        width: 250px;
    }
    .user_account {
        padding: 8px;
	}
	.banner-section .owl-item .item {

		max-width: 285px;
	
	}
	.bg_color_section.block_element .nav.nav-tabs li a {
		font-size: 16px;
	}
	.same_status_section.codes_section.codes-scaaner_two .codes-image_section img {
		width: auto;
		max-height: 80px;
	}
	.sign_up_form .inputText {
		font-size: 18px
	}
/* header.header_login .header_logo img {
    max-width: 180px;
} */
#addUser #addBtn {
    padding: 20px 30px !important;
}
#addUser #uploadCancel {
	padding: 20px 21px !important;
}
}
@media only screen and (max-width:386px){
	.suportedAreaFooter img {
		width: 195px !important;
	}
}
@media only screen and (max-width:374px){
	.same_status_section.codes_section.codes-scaaner_two .codes-image_section img {
		width: 100%;
	}
	.my_scanner_page .my_scanner.start_ordering_scanner .tab_background #updateScannerForm p{
		font-size: 15px;
	}
	.suportedAreaFooter img {
		width: 190px !important;
		margin-left: 1px;
	}
	.suportedAreaFooter span {
		font-size: 15px;
		line-height: 30px;
	}
	.main_logo_heading_padding.start_ordering_scanner.my_scanner.padding_container .slider_with_pending_temp #banner-slider .swiper-slide.item {
    width: 299px !important;
}
	.myOrderPopup input[type="submit"], .myOrderPopup input.btn-danger {
		margin-left: 9px;
	}
	/* header.header_login .header_logo img {
		max-width: 150px;
	} */
	#OrderConfirmation1 .modal-dialog .modal-content p.modal_body_haeding, #OrderConfirmation2 .modal-dialog .modal-content p.modal_body_haeding, #OrderConfirmation3 .modal-dialog .modal-content p.modal_body_haeding, #OrderConfirmation4 .modal-dialog .modal-content p.modal_body_haeding, .modal-dialog .modal-content p.modal_body_haeding {
		font-size: 25px;
		margin-bottom: 10px;
	}
	.banner-slider .swiper-wrapper .swiper-slide {
		width: 298px !important;
	}
}

@media only screen and (max-width:359px){
		.nav.nav-tabs li {
			padding-right: 20px;
        }
        .my_pass_templates.start_ordering_passes .nav.nav-tabs li {
            padding-right: 13px;       
		}
		.dummy_data-content .dummy_data-content-logo {
			min-width: 100%;
		}
        .bg_color_section.block_element .nav.nav-tabs li a {
            font-size: 15px;  
        }
        .owl-item .item {
            padding: 10px;
        }
        .comming_soon .topleft img {
            max-width: 280px;
		}
		.confirmation.text span {
			font-size: 15px;
		}
		header.header_login .header_logo img {
			max-width: 170px !important;
		}
		.subscription_exceeded.block_element .subscription_exceeded_btn span {
			font-size: 12px;
		}

	}

	