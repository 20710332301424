@font-face {
    font-family: 'Museo Sans 500';
    src: url('../fonts/MuseoSans-500Italic.eot');
    src: url('../fonts/MuseoSans-500Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MuseoSans-500Italic.woff2') format('woff2'),
        url('../fonts/MuseoSans-500Italic.woff') format('woff'),
        url('../fonts/MuseoSans-500Italic.ttf') format('truetype'),
        url('../fonts/MuseoSans-500Italic.svg#MuseoSans-500Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Museo Sans 700';
    src: url('../fonts/MuseoSans-700.eot');
    src: url('../fonts/MuseoSans-700.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MuseoSans-700.woff2') format('woff2'),
        url('../fonts/MuseoSans-700.woff') format('woff'),
        url('../fonts/MuseoSans-700.ttf') format('truetype'),
        url('../fonts/MuseoSans-700.svg#MuseoSans-700') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans 700';
    src: url('../fonts/MuseoSans-700Italic.eot');
    src: url('../fonts/MuseoSans-700Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MuseoSans-700Italic.woff2') format('woff2'),
        url('../fonts/MuseoSans-700Italic.woff') format('woff'),
        url('../fonts/MuseoSans-700Italic.ttf') format('truetype'),
        url('../fonts/MuseoSans-700Italic.svg#MuseoSans-700Italic') format('svg');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Museo Sans 100';
    src: url('../fonts/MuseoSans-100.eot');
    src: url('../fonts/MuseoSans-100.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MuseoSans-100.woff2') format('woff2'),
        url('../fonts/MuseoSans-100.woff') format('woff'),
        url('../fonts/MuseoSans-100.ttf') format('truetype'),
        url('../fonts/MuseoSans-100.svg#MuseoSans-100') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans 100';
    src: url('../fonts/MuseoSans-100Italic.eot');
    src: url('../fonts/MuseoSans-100Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MuseoSans-100Italic.woff2') format('woff2'),
        url('../fonts/MuseoSans-100Italic.woff') format('woff'),
        url('../fonts/MuseoSans-100Italic.ttf') format('truetype'),
        url('../fonts/MuseoSans-100Italic.svg#MuseoSans-100Italic') format('svg');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Museo Sans 300';
    src: url('../fonts/MuseoSans-300Italic.eot');
    src: url('../fonts/MuseoSans-300Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MuseoSans-300Italic.woff2') format('woff2'),
        url('../fonts/MuseoSans-300Italic.woff') format('woff'),
        url('../fonts/MuseoSans-300Italic.ttf') format('truetype'),
        url('../fonts/MuseoSans-300Italic.svg#MuseoSans-300Italic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Museo Sans 500';
    src: url('../fonts/MuseoSans-500.eot');
    src: url('../fonts/MuseoSans-500.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MuseoSans-500.woff2') format('woff2'),
        url('../fonts/MuseoSans-500.woff') format('woff'),
        url('../fonts/MuseoSans-500.ttf') format('truetype'),
        url('../fonts/MuseoSans-500.svg#MuseoSans-500') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans 300';
    src: url('../fonts/MuseoSans-300.eot');
    src: url('../fonts/MuseoSans-300.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MuseoSans-300.woff2') format('woff2'),
        url('../fonts/MuseoSans-300.woff') format('woff'),
        url('../fonts/MuseoSans-300.ttf') format('truetype'),
        url('../fonts/MuseoSans-300.svg#MuseoSans-300') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Museo 700';
    src: url('../fonts/Museo-700.eot');
    src: url('../fonts/Museo-700.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Museo-700.woff2') format('woff2'),
        url('../fonts/Museo-700.woff') format('woff'),
        url('../fonts/Museo-700.ttf') format('truetype'),
        url('../fonts/Museo-700.svg#Museo-700') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans 900';
    src: url('../fonts/MuseoSans-900.eot');
    src: url('../fonts/MuseoSans-900.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MuseoSans-900.woff2') format('woff2'),
        url('../fonts/MuseoSans-900.woff') format('woff'),
        url('../fonts/MuseoSans-900.ttf') format('truetype'),
        url('../fonts/MuseoSans-900.svg#MuseoSans-900') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans 900';
    src: url('../fonts/MuseoSans-900Italic.eot');
    src: url('../fonts/MuseoSans-900Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MuseoSans-900Italic.woff2') format('woff2'),
        url('../fonts/MuseoSans-900Italic.woff') format('woff'),
        url('../fonts/MuseoSans-900Italic.ttf') format('truetype'),
        url('../fonts/MuseoSans-900Italic.svg#MuseoSans-900Italic') format('svg');
    font-weight: bold;
    font-style: italic;
}
/* @font-face {
    font-family: 'dotsfont';
    src: url('../dotsfont-master/dist/dotsfont.eot');
    src: url('../dotsfont-master/dist/dotsfont.eot?#iefix') format('embedded-opentype'),
         url('../dotsfont-master/dist/dotsfont.woff') format('woff'),
         url('../dotsfont-master/dist/dotsfont.ttf') format('truetype'),
         url('../dotsfont-master/dist/dotsfont.svg#dotsfontregular') format('svg');
  } */

body{
	font-family: 'Museo Sans 500';
	margin: 0;
	padding: 0;
}
input:focus,a:focus,button:focus{
	outline: none;
}
button{
	background-color: #57c0d9;
	color: #fff;
	border:1px solid #57c0d9;
}
button,input[type="submit"]{
	cursor: pointer;
}
/* .padding_container{
	padding-left: 100px;
	padding-right: 100px;
} */
.block_element{
	float: left;
	width: 100%;
}
h1 {
	font-size: 55px;
	color: #fff;
	font-family: 'Museo Sans 500';
	line-height: 67px;
}
h2 {
	font-size: 50px;
	font-family: 'Museo Sans 500';
	color: #061f39;
	line-height: 60px;
	letter-spacing: 1px;
}
h3 {
	font-size: 25px;
	color: #061f39;
	letter-spacing: 1px;
	font-family: 'Museo Sans 300';
}
/*  Global Style */
.landing_page{
	position: relative;
	background-image: url(../images/contact.jpg);
	background-size: cover;
	height: 100vh;
}
.landing_page .header_logo_section {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.landing_page header {
	padding-top: 60px;
}
.landing_page header .header_top_btns button {
	font-size: 17px;
	border-radius: 30px;
	padding: 5px 44px;
	text-align: center;
	line-height: 20px;
	font-family: 'Museo Sans 700';
}
.landing_page header .header_top_btns button span {
	float: none;
	width: 100%;
	max-width: 80px;
	margin: 0 auto;
	display: table;
	font-size: 14px;
}
.landing_page header .header_top_btns {
	display: flex;
	align-items: center;
}
.landing_page header .header_top_btns button.sign_in_btn {
	background: #333;
	border: 2px solid #fff;
	padding: 13px 25px;
	margin-left: 30px;
}
.landing_page header .navbar ul li:first-child a {
	padding-left: 0;
}
.landing_page header .navbar ul li {
	padding-right: 50px;
}
.landing_page header .navbar {
	padding: 50px 0 0;
}
.landing_page header .navbar::after {
	content: "";
	position: absolute;
	border-bottom: 1px solid #fff;
	width: 100%;
	bottom: 0;
	left: 0;
}
.landing_page header .navbar ul li a::after {
	content: "";
	position: absolute;
	background: #57c0d9;
	width: 100%;
	height: 7px;
	left: 0;
	top: 20px;
	max-width: 60px;
	margin: 0 auto;
	text-align: center;
	right: 0;
	opacity: 0;
}
.navbar-expand-md .navbar-nav .nav-link {
	padding-right: 0;
	padding-left: 0;
}
.landing_page header .navbar ul li a {
	text-transform: uppercase;
	color: #fff;
	font-family: 'Museo Sans 700';
	font-size: 16px;
	text-align: center;
	position: relative;
	padding: 0;
	padding-bottom: 20px;
}
.landing_page header .navbar ul li a:hover::after,.landing_page header .navbar ul li.active a::after {
	top: 44px;
	transition: all .5s;
	opacity: 1;
}
.landing_content p {
	color: #fff;
	font-size: 30px;
	line-height: 35px;
	font-family: 'Museo Sans 700';
	margin: 30px 0 70px;
}
.landing_content {
	padding-top: 130px;
}
.landing_content button {
	font-size: 27px;
	padding: 15px 45px;
	border-radius: 50px;
	font-family: 'Museo Sans 700';
	letter-spacing: 1px;
}
button:hover {
	background: #fff;
	border: 1px solid #57c0d9;
	color: #57c0d9;
	transition: all .5s;
}
.landing_page header .header_top_btns button.sign_in_btn:hover {
    background: #fff;
    border: 2px solid #fff;
    transition: all .5s;
}
/* landing page css end */
.bg_color_section {
	background-color: #fffcf7;
	padding-bottom: 200px;
}
.modal-content.bg_color_section {
	padding-bottom: 0;
}
.sign_up_form input:focus ~ .floating-label,.sign_up_form input:not(:focus):valid ~ .floating-label{
  top: -20px;
  bottom: 10px;
  left: 0px;
  font-size: 11px;
  opacity: 1;
}
.signupForm.sign_up_form{
    max-width: 560px;
}
.signupForm.sign_up_form.inputText{
    max-width: 560px;
}
.signupForm.sign_up_form .error-main .error.email_error_margin{
    margin: '2px 0 -40px';
}
.sign_up_form .inputText {
	font-size: 22.5px;
	width: 460px;
	height: 39px;
	border: none;
	border-bottom: 1px solid #666256;
	background: transparent;
    color: #061f39;
    border-radius: 0;
    font-family: 'Museo Sans 500';
    margin-bottom: 4px;
    padding-bottom: 14px;
}
.sign_up_form .floating-label {
	position: absolute;
	pointer-events: none;
	left: 0px;
	top: -3px;
	transition: 0.2s ease all;
	color: #97937f;
	font-size: 20px;
	line-height: 30px;
}
.sign_up_form .form-group {
	position: relative;
	margin-bottom: 40px;
}
.sign_up_page {
	padding-top: 160px;
	padding-bottom: 265px
}
.modal-open .header_login, .modal-open-again .header_login, .modal-open-again-order .header_login{
    padding-right: 15px;
}
.header_login {
    padding-top: 30px;
    padding-bottom: 30px;
	display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: space-between;
}
.sign_up_page h2 {
	margin-bottom: 90px;
}
.Dummy-Data .same_status_section.codes_section {
    min-height: 397px;
}




.custom_checkbox {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 14px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #061f39;
}
/* Hide the browser's default checkbox */
.custom_checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: -3px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #e4e0d0;
   border-radius: 3px;
}
form.sign_up_form.block_element span.checkmark {
	position: absolute;
	top: -3px;
	/* left: 0; */
	height: 32px;
	width: 32px;
	background-color: #E4E0D0;
	margin-left: 4%;
	right: 0;
}
/* When the checkbox is checked, add a blue background */
.custom_checkbox input:checked ~ .checkmark {
  background-color: #2196F3;
}
/* Create the checkmark/indicator (hidden when not checked) */
.custom_checkbox::after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.custom_checkbox input:checked ~ .checkmark::after {
  display: block;
}
/* Style the checkmark/indicator */
.custom_checkbox .checkmark::after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.newHeader{
    overflow:hidden;
    padding-right:12px;
}
.error {
	font-size: 14px;
	color: #f16772;
	margin-top: 2px;
	font-family: 'Museo Sans 700';
}
#addUserForm .floating-label_group p.error {
    font-size: 14px;
}
.success{
    font-size: 14px;
	color: #10bb27;
	margin-top: 2px;
}
.container-s a {
	color: #061f39;
	text-decoration: underline;
}
.sign_up_form .form-group-btn input[type="submit"] {
	background: #E4E0D0; 
	border: 1px solid #e4e0d0;
	color: #FDFBEF;
	padding: 15px 65px;
	border-radius: 50px;
	font-size: 27px;
	font-family: 'Museo Sans 500';
	margin-top: 45px;
    max-width: 330px;
    margin-top: 20px;
    display: inline-block;
    width: 100%;
    cursor:not-allowed;
}

.sign_up_page h2 {
	margin-bottom: 85px;
}
.sign_up_form .form-group-btn input.isActive[type="submit"]{
    background: #57c0d9;
    border: 1px solid #57c0d9;
    cursor: pointer;
}
form.sign_up_form.block_element span.checkmark {
    margin: 0;
}
/* .sign_up_form .form-group-btn input[type="submit"]:hover{
	background:#57c0d9;
	border:1px solid #57c0d9;
	transition: all .5s;
} */
footer,.copyright{
	background-color: #061f39;
}
.copyright p {
	text-align: center;
	color: rgb(253, 251, 239);
	font-size: 20px;
	line-height: 40px;
	margin-bottom: 0;
}
footer .footer_logo img {
	width: 384px;
}
footer {
	padding-top: 50px;
}
footer .footer_content {
	display: flex;
	justify-content: space-between;
	padding-top: 20px;
}
.footer_content span, .footer_content span a {
	color: rgb(253, 251, 239);
	float: left;
	width: 100%;
	font-size: 20px;
	line-height: 36px;
}
.footer_content span a {
	text-decoration: none;
}
footer.padding_container {
	padding-top: 35px;
	padding-bottom: 30px;
	padding-left: 0;
	padding-right: 0;
}
footer.padding_container .footer_block {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 15px;
}
footer .footer_content .footer_rgt {
	display: flex;
	flex-direction: column;
}

.container-s {
	display: block;
	position: relative;
	padding-left: 43px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 17px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
/* Hide the browser's default checkbox */
.container-s input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: -3px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #e4e0d0;
}

.container-s input:checked ~ .checkmark {
  background-color: #e4e0d0;
}

.checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

.container-s input:checked ~ .checkmark::after {
  display: block;
}

.container .container-s .checkmark::after {
	left: 10px;
	top: 4px;
	width: 12px;
	height: 20px;
	border: solid #57c0d9;
	border-width: 0 5px 5px 0;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}
.thanku_page {
	text-align: center;
	padding: 200px 0;
}
.confirmation.text .circle {
	display: inline-block;
	width: 30px;
	height: 30px;
	border: 1px solid #57c0d9;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	display: flex;
	font-size: 18px;
	margin-left: 14px;
}
.confirmation.text span {
	display: flex;
	color: #1b3f71;
	font-size: 25px;
	font-family: 'Museo Sans 700';
}
.thanku_page a img {
	width: 80px;
}
.confirmation.text .circle i {
	color: #57c0d9;
}
.confirmation.text p {
	float: left;
	width: 100%;
	color: #061f39;
	font-size: 15px;
	font-family: 'Museo Sans 500';
	line-height: 18px;
	margin-top: 50px;
}
.confirmation.text {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}
.thanku_page h2 {
	margin-top: 50px;
	margin-bottom: 15px;
}
.forgot_password a {
	color: #061f39;
	font-family: 'Museo Sans 500';
    font-size: 20px;
    line-height: 24px;
    text-decoration: none;
    border-bottom: 1.4px solid #061f39;
}
.create_account span a{
	border-bottom: 1.4px solid #061f39;
}
.create_account span, .create_account span a {
	color: #061f39;
	font-family: 'Museo Sans 500';
    font-size: 20px;
    text-decoration: none;
}
.create_account {
	padding-top: 40px;
}
.forgot_page .write_down_email {
	color: #061f39;
	margin-bottom: 100px;
	float: left;
	width: 100%;
	font-family: 'Museo Sans 500';
	font-size: 30px;
}
.sign_up_page.forgot_page h2 {
	margin-bottom: 38px;
}
.forgot_page  .sign_up_form .form-group-btn input[type="submit"] {
	margin-top: 25px;
}
/* .modal-dialog {
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	top: 50%;
	transform: translateY(-50%) !important;
} */

.modal-footer,.modal-header{
	border:none;
}
.modal button.close:hover {
	border: none;
}
.modal button.close {
	font-size: 45px;
	color: #061f39;
	opacity: 1;
	padding: 0;
	margin-right: 0px;
    margin-top: 0px;
    z-index: 9;
    cursor: pointer !important;
}
.modal .modal-body {
	padding: 0 60px 10px;
}
.modal .modal-body .modal_body_haeding {
	color: #061f39;
	font-size: 40px;
    line-height: 48px;
    margin-bottom: 45px;
}
.modal-header{
    padding: 60px 60px 0px;
}
.modal-footer {
	padding-left: 60px;
	padding-right: 60px;
    padding-top: 0;
    padding-bottom: 50px;
}
.modal .submit_btn {
    padding: 7px 28px !important;
    border-radius: 50px;
    font-family: 'Museo Sans 500';
    font-size: 20px;
    background: #57c0d9 !important;
    color: #fff !important;
    float: right !important;
    max-width: 160px;
    border: 1px solid #57c0d9;
    margin-top: 15px !important;
    width: 100% !important;
}
.modal .modal_form_field {
    float: left;
    width: 100%;
}
.modal .submit_btn:hover {
    background: #fff !important;
    color: #57c0d9 !important;
}
#myOrderPopup.modal .modal_form_field.form_field_spacing i {
    bottom: 40px;
    top:0;
}
.status_main table button.cmn-btn {
    padding: 4px 10px;
}
/* .user-listing .status_main table thead{
    box-shadow: 0px 2px 0px 0px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
} */
.modal form .submit_btn:hover{
	background:#fff;
	color: #57c0d9;
	transition: all .5s;
}
.modal .template_build_inner {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding-bottom: 15px;
	align-items: center;
}
.modal .template_build_inner span {
	color: #061f39;
    font-size: 20px;
    line-height: 24px;
}
.modal .template_build_inner span:first-child {
	font-family: 'Museo Sans 500';
}
.modal .template_build_inner span {
	font-family: 'Museo Sans 300';
}
.modal .template_build.single_build {
	padding-bottom: 24px;
}
.modal .template_build {
	border-bottom: 2px solid #E4E0D0;
	margin-bottom: 13px;
}
.modal .toatl_vat_price span {
	color: #061f39;
	font-family: 'Museo Sans 700';
    font-size: 30px;
    line-height: 36px;
}
.modal .toatl_vat_price .vat_price {
	font-size: 13px;
}
.modal .toatl_vat_price {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 29px;
}
.modal form {
	padding-top: 50px;
}
.modal .modal_form_field {
	float: left;
		margin-top: 40px;
		float: left;
		width: 100%;
    }
.user-listing {
	width: 100%;
	position: relative;
	margin-bottom: 15px;
}
.modal .modal_form_field label {
	color: #97937f;
	font-size: 30px;
	font-family: 'Museo Sans 300';
	margin-bottom: 0px;
}
.modal .modal_form_field input {
	border: none;
	border-bottom: 1px solid #b2afa6;
	display: block;
	width: 100%;
	background: transparent;
	color: #061f39;
	font-size: 30px;
	padding-left: 0;
    font-family: 'Museo Sans 300';
    border-radius: 0;
    line-height:36px;
}
.adduser #addUserForm .image-input input, .addLicenseHolderModal .addLicenseHolderForm .image-input input  {
	border-bottom: 1px solid #b2afa6;
}
.modal #addUserForm .modal_form_field input, .modal .addLicenseHolderForm .modal_form_field input  {
	height: auto;
	line-height: 35px;
}
#addLicenseHolder .form-group .inputText {
	width: 100%;
}
#addLicenseHolder .form-group {
	float: left;
	width: 100%;
}
#addLicenseHolder .form-group .inputText  {
	border: none;
	border-bottom: 1px solid #b2afa6;
	display: block;
	width: 100%;
	background: transparent;
	color: #061f39;
	font-size: 30px;
	padding-left: 0;
	font-family: 'Museo Sans 300';
	border-radius: 0;
    line-height: 35px;
    height:auto;
    padding-bottom: 0;
    margin-bottom: 0;
}
#addUserForm .floating-label_group select, .addLicenseHolderForm .floating-label_group select  {
	border: 1px solid #b2afa6;
	padding: 10px 10px;
	height: auto;
	font-size: 30px;
	color: #97937f;
	font-size: 30px;
	font-family: 'Museo Sans 300';
}
.modal .modal_form_field button {
	position: absolute;
	right: 0;
	top: 23px;
	background: transparent;
	border-radius: 5px;
	color: #294b79;
	padding: 4px 11px;
	line-height: 21px;
}
.modal .modal_form_field.form_field_spacing i {
	position: absolute;
	left: 0;
	top: 0px;
	color: #d62637;
	font-size: 16px;
}
.modal.myOrderPopup .modal_form_field.form_field_spacing i {
    top: 5px;
}
.modal .modal_form_field.form_field_spacing input ~ label {
	padding-left: 32px;
}
.modal.myOrderPopup .modal_form_field label {
    font-size: 30px;
}
.modal .modal_form_field.form_field_spacing input {
	padding-left: 32px;
}
.modal .modal-content {
	border-radius: 0px;
}
.modal .modal_main_field {
	float: left;
	width: 100%;
}
.modal .modal_main_field .modal_form_field:nth-child(2) {
	float: right;
	padding-right: 0;
}
.modal .modal_main_field .modal_form_field {
	width: 48%;
	padding-right: 10px;
}
.modal.order_confirmation .modal-body .modal_body_haeding {
	margin-bottom: 28px;
}
.modal.order_confirmation .modal-body .toatl_vat_price {
	margin-bottom: 20px;
}
.modal .receipt_section p {
    color: #061f39;
    font-size: 20px;
    font-family: 'Museo Sans 500';
    line-height: 24px;
}
.modal .receipt_section p span {
	font-style: italic;
}

.modal .receipt_section .thakyou_msg {
        /*font-size: 25px;
        font-family: 'Museo Sans 300';*/
}
/*--22-10-2019 */
.main_logo_heading_padding h3 {
    margin: 70px 0 30px;
    font-size: 40px;
    font-family: 'Museo Sans 100';
}
.receipt_section p.thakyou_msg {
    font-family: 'Museo Sans 500';
}
.order_pass, .order_scanner {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	border: 2px dashed #666256;
	padding: 122px 0 60px;
	margin-bottom: 60px;
	border-radius: 5px;
}
#uploadUser .browsedCsvContainer input#browsedCsvName {
	font-size: 20px;
	font-family: 'Museo Sans 300';
	color: #061F39;
}
.droppable.headeField .headeFieldInner .drag-input.value {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}
.my_pass_main_section section.banner-slider div#main-slider.owl-carousel .owl-item {
    float: left;
}
.same_status_section.codes_section .codes_right a {
    word-break: break-all;
}
.order_pass span.add_order, .order_scanner span.add_order {
	display: flex;
	border: 1px solid #57c0d9;
	height: 107px;
	width: 107px;
	border-radius: 100%;
	float: left;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.order_pass span.add_order i, .order_scanner span.add_order i {
	float: left;
	color: #57c0d9;
}
.order_pass span.add_order img, .order_scanner span.add_order img {
	width: 20px;
}
.order_pass p, .order_scanner p {
	float: left;
	width: 100%;
	text-align: center;
	padding-top: 20px;
	margin: 0;
	font-size: 35px;
	color: #061f39;
}
.bg_color_section.block_element .nav.nav-tabs li a {
	color: #000;
	font-size: 30px;
	font-family: 'Museo Sans 300';
	line-height: 36px;
}
.bg_color_section.block_element .nav.nav-tabs li a.active {
    position:relative;
}

.nav.nav-tabs li {
	padding-right: 30px;
}
.tab_background {
	background: #ebefec;
	padding: 25px;
}
.start_ordering_passes .welocme_order_msg {
	display: flex;
	padding-top: 50px;
	padding-bottom: 50px;
	margin-bottom: 190px;
	margin-top: 40px;
	align-items: center;
	flex-wrap: wrap;
}
.tab_background {
	background: #ebefec;
	padding: 30px;
	border-radius: 10px;
}
.welocme_order_msg p {
	float: left;
	width: 100%;
	font-size: 28px;
	color: #061f39;
	font-family: 'Museo Sans 300';
	letter-spacing: 1px;
	margin-bottom: 5px;
}
.welocme_order_msg span {
	color: #061f39;
	letter-spacing: 1px;
}
.nav.nav-tabs {
	border: none;
}
.nav.nav-tabs li a {
	text-decoration: none;
}
.my_pass_templates .nav.nav-tabs li a, .my_scanner .nav.nav-tabs li a{
	margin-right: 80px;
	position: relative;
	padding-bottom: 24px;
	color: #061f39;
	font-size: 18px;
}
.my_pass_templates .nav.nav-tabs li a.active, .my_scanner .active_nav_tabs.nav.nav-tabs li a.active {
	font-family: 'Museo Sans 700';
}
.my_pass_templates .nav.nav-tabs , .my_scanner .nav.nav-tabs{
	margin-bottom: 61px;
}
.my_pass_templates .nav.nav-tabs li a::before, .my_scanner .active_nav_tabs.nav.nav-tabs li a::before {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	background: #57c0d9;
	width: 100%;
	max-width: 68px;
	height: 6px;
	bottom: 0;
	margin: 0 auto;
	display: none
}
.my_pass_templates .nav.nav-tabs li a.active::before, .my_scanner .active_nav_tabs.nav.nav-tabs li a.active::before{
	display: block;
}
.passess-details p {
    font-size: 20px;
    line-height: 24px;
	color: #061f39;
	font-family: 'Museo Sans 500';
	margin-bottom: 10px;
}
.passess-details {
	background: #fffcf7;
	border: 2px solid #dae4e4;
	float: left;
	width:433px;
	padding: 34px 36px 29px;
	border-radius: 12px;
	margin-right: 27px;
}
.passess-details .passes_counting .pass_number {
	color: #061f39;
    font-size: 50px;
    line-height: 60px;
	font-family: 'Museo Sans 500';
}
.passess-details .passes_counting .pass_percantage {
	color: #9e9a87;
	padding-left: 8px;
	font-size: 20px;
	font-family: 'Museo Sans 500';
}

.passes_more_details {
	float: left;
	width: 100%;
	margin-top: 30px;
	/* border: 2px solid #dae4e4; */
    /* max-width: 892px; */
    max-width: 100%;
	border-radius: 12px;
}
.pass_single_detail {
    float: left;
    width: 100%;
    display: flex;
}
.pass_single_detail .passess-details {
	width: 32%;
	margin-right: 2%;
	box-sizing: border-box;
}
.pass_single_detail .passess-details:last-child {
    margin-right: 0;
}
#sendLicense #sendForm .form-group-cont, #sendUser #sendForm .form-group-cont{
	min-height: 330px;
}
.passes_more_details .passess-details, .passes_full_more_details .passess-details {
	margin: 0;
	border: 0;
	width: 444px;
}
.passes_more_details .passess-details:nth-child(1), .passes_full_more_details .passess-details:nth-child(1){
	border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 2px solid #dae4e4;
    border-right-color: rgb(218, 228, 228);
    border-right-style: solid;
    border-right-width: 2px;
    border-right: 0;
}
.passes_more_details .passess-details:nth-child(2), .passes_full_more_details .passess-details:nth-child(2){
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
    position: relative;
     border-top-right-radius: 0;
    border-bottom-right-radius: 0; 
    border: 2px solid #dae4e4;
    border-left: 0;
    border-right: 0;
    padding: 34px 36px 29px 54px;
}
.passes_more_details .passess-details:last-child, .passes_full_more_details .passess-details:last-child{
    position: relative;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px; 
    border: 2px solid #dae4e4;
    border-left: 0;
    padding: 34px 36px 29px 60px;
} 
.passes_more_details .passess-details:nth-child(2), .passes_full_more_details .passess-details:nth-child(2) {
	width: 33.5%;
} 
.passes_more_details .passess-details:nth-child(2)::before, .passes_more_details .passess-details:last-child::before, .passes_full_more_details .passess-details:nth-child(2)::before, .passes_full_more_details .passess-details:last-child::before {
	content: "";
	position: absolute;
	background: #dde5e5;
	height: 50%;
	width: 2px;
	left: 0;
	top: 0;
	bottom: 0;
	margin: auto;
}
.passes_full_more_details {
	display: flex;
}
#userPermission h4 {
	font-family: 'Museo Sans 300';
	color: #061f39;
	font-size: 40px;
	line-height: 48px;
	margin-bottom: 45px;
	max-width: 540px;
	width: 100%;
}
#userPermission .deleteBtns .btn.btn-danger {
	border: 2px solid #F16772;
	background-color: transparent !important;
	color: #061F39 !important;
	height: auto;
	max-width: 200px;
	font-size: 27px;
	line-height: 1;
	padding: 15px 40px !important;
	min-width: 200px;
	border-radius: 50px;
	font-family: 'Museo Sans 500';
    margin-left: 28px;
    float:right;
}
#userPermission .deleteBtns .btn.btn-danger.btn_delete {
    max-width: 230px;
}
#userPermission .deleteBtns.permission_delete_btn .btn.btn-danger.btn_delete {
	float: left;
	margin-left: 0;
	margin-right: 28px;
}
#userPermission .deleteBtns .btn.btn-danger:hover {
    background-color: #F16772 !important;
    color: #fff !important;
}
/* #userPermission .deleteBtns .btn.btn-danger.btn_delete:hover {
	border: 2px solid #F16772;
    background-color:  transparent !important;
    color: #061F39 !important;
} */
#userPermission .deleteBtns button {
	height: auto;
	max-width: 200px;
	font-size: 27px;
	line-height: 1;
	padding: 15px 40px !important;
	min-width: 200px;
	border-radius: 50px;
	font-family: 'Museo Sans 500';
	border: 2px solid #57c0d9;
}
.user_detail .user_name span, .user_detail .user_email span  {
	float: left;
	line-height: 1.2;
	font-size: 22px;
    line-height: 24px;
    font-family: 'Museo Sans 500';
}
.user_detail .user_name h6, .user_detail .user_email h6 {
	float: left;
	font-size: 22px;
    line-height: 24px;
    font-family: 'Museo Sans 500';
	margin: 0 6px 0 0;
}
.user_permissions table {
	width: 100%;
}
.associated_user_list {
	max-height: 370px;
	overflow-y: auto;
}
.user_permissions tr th:nth-child(2) > span {
	float: left;
	margin-right: 20px;
}
.user_permissions tr td:nth-child(2), .user_permissions tr th:nth-child(2) {
	text-align: right;
	float: right;
	display: flex;
	flex-direction: row;
}
.user_permissions tr td:nth-child(2), .user_permissions tr th:nth-child(2) {
	text-align: right;
}
#userPermission .deleteBtns {
	margin-top: 40px;
}
.user_permissions {
	float: left;
	width: 100%;
	border-top: 1px solid #dadada;
	margin: 15px 0 0;
	padding-top: 25px;
}
.user_permissions td, .user_permissions th {
	padding: 5px 0;
}
.user_detail .user_name, .user_detail .user_email {
	float: left;
	width: 100%;
	margin-bottom: 10px;
}
.same_status_section p {
    font-size: 20px;
    line-height: 24px;
	color: #061f39;
	font-family: 'Museo Sans 500';
	margin-bottom: 30px;
}
.assocated_delete_btn {
	margin-left: 15px;
}
.status_of_passses .status_gauge {
	float: left;
	width: 434px;
	margin-right: 26px;
}
.passess-details .passes_counting .pass_number {
	max-width: 85%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	float: left;
}
.passess-details .passes_counting {
	display: flex;
}
.passess-details .passes_counting .pass_percantage {
	align-items: flex-end;
	display: flex;
	padding-bottom: 5px;
}
.passess-details .passes_counting {
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.same_status_section {
	padding: 35px 47px 45px 45px;
	background: #fffcf7;
	border-radius: 12px;
	min-height: 342px;
	border: 2px solid #dae4e4;
	max-height: 506px;
}
.reached-end .swiper-container::after{display: none;}
.status_of_passses .status_gauge svg.speedometer {
	height: 230px !important;
	width: 442px !important;
	-webkit-transform: scale(0.8);
	transform: scale(0.8);
	position: relative;
	left: -36px;
}
.status_of_passses .status_gauge img {
	text-align: center;
	margin: 0 auto;
	display: table;
	max-width: 180px;
    width: 100%;
    
}
.add_order_disable {
    background: #dadada!important;
    cursor: not-allowed;
}
.add_order_disable_span{
    cursor: not-allowed!important;
    background: #dadada!important;
}

.status_of_passses .status_graph {
	float: left;
    width: calc(100% - 450px);
    margin-left: 0;
}
.status_of_passses.block_element {
    padding-top: 30px;
    display: flex;
}
.status_of_passses .status_gauge svg.speedometer {
    height: 250px!important;
}
.status_of_passses .status_gauge .status_upgrade {
	background: transparent;
	border-radius: 6px;
	padding: 12px 20px;
	display: table;
	margin: 60px auto 0;
	color: #1B3F71;
	font-family: 'Museo Sans 500';
    letter-spacing: 1px;
    font-size: 20px;
}
/* .status_of_passses .status_gauge .status_upgrade:hover {
	background: #F16772;
	color: #fff;
} */
.my_pass_templates .tab-content {
	margin-bottom: 180px;
}
.main_logo_heading_padding .same_status_section.codes_section h3 {
	margin: 0 0 30px;
	font-size: 40px;
	line-height: 48px;
	letter-spacing: 0.007em;
	font-family: 'Museo Sans 100';
}
.codes_section {
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
}
.modal-content {
    border: none;
}
.codes_section .codes_left span {
	color: #061f39;
	font-size: 20px;
	line-height: 24px;
	float: left;
	width: 100%;
	font-family: 'Museo Sans 500';
}
.codes_right .codes-image_section a {
	font-size: 14px;
    line-height: 17px;
    font-family: 'Museo Sans 500';
}
.codes_main_section .codes_section:nth-child(2) {
	margin-bottom: 0;
}
.same_status_section.codes_section {
	min-height: auto;
}
.codes_right .codes-image_section span {
	float: left;
	width: 100%;
	color: #061f39;
	line-height: 22px;
	font-family: 'Museo Sans 500';
	font-size: 18px;
	margin-bottom: 0px;
}
.codes_right {
	display: flex;
	justify-content: center;
}
.codes_right .codes-image_section {
	padding: 14px 10px;
	border: 2px solid #81cfe0;
	border-radius: 12px;
	text-align: center;
	width: 400px;
    min-height: 270px;
}
.codes_right .codes-image_section a {
	font-size: 14px;
	color: #061f39;
	font-family: 'Museo Sans 500';
	margin-top: 12px;
	float: left;
	text-align: center;
	width: 100%;
	line-height: 17px;
}
.codes-scaaner_two .codes_right .codes-image_section span {
	margin-bottom: 30px;
}
.codes-scaaner_two .codes_right .codes-image_section .code-name {
	font-size: 11px;
	margin: 0;
}
.search_section input.serach {
	border: 2px solid #dae4e4;
	background: #fffcf7;
	height: 60px;
	padding: 0 30px 0 49px;
	border-radius: 10px;
	color: #061f39;
	max-width: 400px;
	width: 100%;
	font-size: 20px;
	font-family: 'Museo Sans 300';
}
.search_section input.serach::placeholder {
	color: #C9C4A9;;
}
.search_section {
	position: relative;
}
.search_section .fa.fa-search {
	position: absolute;
	left: 15px;
	top: 21px;
	color: #57c0d9;
	font-size: 20px;
}
.main_distribution_btn .distribution_btns {
	padding: 14px 22px;
	background: #fffcf7;
	color: #061f39;
	letter-spacing: 1px;
	border-width: 2px;
	border-radius: 8px;
	margin-right: 20px;
    font-family: 'Museo Sans 500';
    font-size: 20px;
    line-height: 24px;
    margin-bottom:13px;
}
.no_user_listed {
	margin-top: 40px;
}
.no_and_search_samestyle h3 {
	margin: 30px 0 8px;
}
#members .no_and_search_samestyle h3 {
	margin: 10px 0 8px;
}
.insideScanner .no_scanner_listed.no_and_search_samestyle .no_listing_text  h3, .insideScanner .no_scanner_listed.no_and_search_samestyle .no_listing_text span {
    color:#061F39;
}
.no_and_search_samestyle span {
	color: #061f39;
	font-size: 14px;
}
.no_searchresult {
	margin-top: 40px;
}
.user-listing {
	margin-top: 40px;
}
.main_distribution_btn .distribution_btns.active {
	border: 2px solid #8ec976;
}
.table_checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.table_checkbox input {
 position: absolute;
opacity: 0;
cursor: pointer;
height: 25px;
width: 25px;
left: 0;
top: -7px;
}
.table_checkbox .checkmark {
	position: absolute;
	top: -7px;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: #fff;
	border: 1px solid #b1ae9f;
}
/* .my_pass_main_section.slider_with_pending_temp section.banner-slider.bigger-slider {
    max-width: 340px;
    padding-left: 0;
    padding-right: 0;
} */
.table_checkbox input:checked ~ .checkmark {
  background-color: #fffcf7;
}
.table_checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.table_checkbox input:checked ~ .checkmark:after {
  display: block;
}
.table_checkbox .checkmark::after {
	left: 8px;
	top: 2px;
	width: 9px;
	height: 16px;
	border: solid #57c0d9;
	border-width: 0 4px 4px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
.status_table {
	float: left;
	width: 100%;
	margin-top: 30px;
	background: #fffcf7;
	border-radius: 10px;
	padding-bottom: 30px;
}
.status_table thead tr th:first-child, .status_table tbody tr td:first-child {
	width: 28px;
	padding-left: 20px;
	padding-right: 3px;
}

.Dummy-Data.distribution._section .status_table th:nth-child(3), .Dummy-Data .user-listing .status_table thead tr th:first-child {
	font-family: 'Museo Sans 500';
}
.status_table tbody tr:nth-child(2n+1) {
	background: #f1eee3;
}
.status_table tbody td {
	font-family: 'Museo Sans 500';
}
.status_table tbody .actiavted_row td {
	color: #97937f;
}

.status_table tbody .deactiavted_row td {
	color: #061f39;
}
.status_table td {
	padding: 15px 10px;
}
.status_table th {
	padding: 25px 48px;
	color: #061f39;
	font-family: 'Museo Sans 300';
	font-size: 18px;
}

.status_table tbody .deactiavted_row td .deactivated_status {
	display: inline-block;
	height: 18px;
	width: 18px;
	background: #afcd7e;
	border-radius: 100%;
}
.status_table tbody .actiavted_row td .activated_status {
	display: inline-block;
	background: #f16772;
	height: 18px;
	width: 18px;
	border-radius: 100%;
}
.status_table .deactivate_btn {
	padding: 2px 10px;
	border-radius: 6px;
	border: 1px solid #f16772;
	background: transparent;
	color: #061f39;
	text-transform: capitalize;
	letter-spacing: 1px;
}
.activate_btn {
	background: #f16772;
	border: 1px solid #f16772;
	padding: 2px 10px;
	border-radius: 6px;
}
.status_table tbody tr td:last-child {
	float: right;
	padding-right: 20px;
}
.status_table th span.name::before {
	content: "";
	position: absolute;
	border-left: 9px solid transparent;
	height: 10px;
	width: 10px;
	/*border-radius: ;*/
	border-right: 9px solid transparent;
	border-top: 9px solid #57c0d9;
	left: auto;
	right: -28px;
	top: 12px;
}
.status_table th span.name{
	position: relative;
	display: inline-block;
}
.my_pass_templates.my_profile_section .nav.nav-tabs {
	margin-bottom: 14px;
	margin-top: 10px;
}
.my_profile_section .tab_background{
	border-radius: 0;
}
.same_profile_tab_style form label {
	float: left;
	width: 100%;
	color: #97937f;
	font-size: 20px;
}
.same_profile_tab_style form label {
	float: left;
	width: 100%;
	color: #97937f;
	font-size: 30px;
    font-family: 'Museo Sans 300';
    top:3px;
}
.same_profile_tab_style form .form-group input {
	background: transparent;
	border: none;
	border-bottom: 1px solid #666256;
	width: 100%;
	color: #061f39;
	height: 35px;
}
.same_profile_tab_style form .form-group {
	margin-bottom: 75px;
}
.same_profile_tab_style form {
	margin: 50px auto 100px;
	max-width: 1260px;
}

.profile-form_btn button,.profile-form_btn  input[type="submit"]{
	background: #e4e0d0;
	border: 1px solid #e4e0d0;
	padding: 7px 20px;
	padding: 7px 38px;
	border-radius: 50px;
	font-size: 25px;
	font-family: 'Museo Sans 700';
}
#password form {
	padding-top: 8px;
}
#password .profile-form_btn {
	padding-top: 30px;
}
.profile-form_btn  input[type="submit"]{
    color: #fff;
    margin-left: 15px;
}
.profile-form_btn {
	display: flex;
	justify-content: right;
	padding-top: 20px;
	float: right;
    margin-bottom: 40px;
}
/* .profile-form_btn button:hover,.profile-form_btn  input[type="submit"]:hover{
	background: #57c0d9;
	color: #fff;
	border:1px solid #57c0d9;
	transition: all .5s;
} */
.same_profile_tab_style form h3 {
	margin-bottom: 70px;
}
.subscription_sec .padding_container {
	padding-left: 70px;
	padding-right: 70px;
}
.subscription_sec .subsription_heading.padding_container h3 {
	font-size: 40px;
	padding-bottom: 37px;
	border-bottom: 2px solid #D8E2E3;
	line-height: 48px;
	font-family: 'Museo Sans 100';
	color: #061F39;
}
.subscription_sec .subsription_heading.padding_container {
	padding: 50px 0 20px 0;
	max-width: 960px;
	margin: 0 auto;
	display: table;
    width: 100%;
}
.subscription_table table {
	width: 100%;
	margin: 0 auto;
}
.subscription_table table th {
	font-size: 30px;
	font-family: 'Museo Sans 700';
	padding: 20px 20px 28px;
	color: #1b3f71;
	line-height: 36px;
}
#subscription .modal .subscription_table table tbody .table-price:last-child td {
	padding-left: 0px;
	vertical-align: top;
}
.tollTipTrigger img {
	width: 13px;
}
.subscription_table table td {
	padding: 14px 20px;
	color: #1b3f71;
	font-family: 'Museo Sans 500';
    border-right: 2px solid #d0cbb3;
    font-size: 18px;
}
.subscription_table table tbody tr:nth-child(2n+1) td {
	background: #e4e0d0;
}
.subscription_table table tbody tr:nth-child(2n+2) td{
	background: #fffcf7;
}
.subscription_table table tr td:first-child {
	font-family: 'Museo Sans 700';
	width: 300px;
}
.subscription_table table tbody tr:first-child td:first-child {
    border-top-left-radius: 20px;
}
.subscription_table table tbody tr:first-child td:last-child {
	border-top-right-radius: 20px;
}
.subscription_table table tbody tr:nth-child(8) td:last-child {
	border-bottom-right-radius: 20px;
}
.subscription_table table tbody tr:nth-child(8) td:first-child {
	border-bottom-left-radius: 20px;
}
.subscription_table table tbody .table-price td {
	background: transparent !important;
}
#subscription.subscription_sec tr td {
    text-align: center;
    /* padding:14px 30px */
}
.subscription_sec .padding_container table {
	max-width: 1440px;
	width: 100%;
}
#subscription.subscription_sec tr td:first-child {
	text-align: left;
}
.subscription_table table td.professional span, .subscription_table table td.starter span {
	position: absolute;
	right: -4px;
	top: -2px;
}
.subscription_table table td.professional, .subscription_table table td.starter {
	position: static;
}
.subscription_table table tr td:last-child {
	border: none;
}
.subscription_table table .check_service i {
	color: #fff;
	font-size: 22px;
}
.subscription_table table .check_service {
	margin: 0 auto;
	display: flex;
	width: 30px;
	height: 30px;
	background: #8ec976;
	border-radius: 100%;
	justify-content: center;
	align-items: center;
}
.subscription_table table tbody tr.table-price table tbody tr {
	background: transparent !important;
}
.subscription_table table tbody tr.table-price table tbody tr:nth-child(2n+1),.subscription_table table tbody tr.table-price table tbody tr:nth-child(2n+2) {
	background: #ebefec !important;
}
.subscription_table table tbody .table-price {
	background: #ebefec !important;
}
.subscription_table table tbody .table-price td .price_cutoff {
	float: left;
	width: 100%;
	font-size: 18px;
	text-decoration: line-through;
    padding-top: 5px;
    font-family: 'Museo Sans 300';
}
.subscription_table table tbody .table-price td .price_kr {
	float: left;
	width: 100%;
	font-size: 30px;
    font-family: 'Museo Sans 700';
    line-height:1.1;
}
.subscription_table table tbody .table-price td .month_validity {
	float: left;
	width: 100%;
	font-size: 16px;
	font-family: 'Museo Sans 300';
	line-height: 1.2;
    padding-top: 2px;
    padding-bottom:2px;
}
.subscription_table table tbody .table-price td {
	border: none;
}
.sticky_thankyou{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 1px 8px rgba(0,0,0,0.5);
}
.thankyou_back {
	padding: 30px 100px;
	display: inline-block;
	background: #fffcf7;
	width: 100%;
}
div#sendUser .modal-dialog .send-left .form-group .custom-errorMsg {
	position: absolute;
	bottom: 0;
}
div#sendUser .modal-dialog .send-left .form-group {
	position: relative;
	float: left;
	width: 100%;
}
div#sendUser .send-right .form-btn-btm input.submit_btn, #addUser #addBtn.btn-default.submit_btn, .addLicenseHolderForm #addLicenseHolderBtn {
	border: 2px solid #E4E0D0;
	color: #97937F !important;
	border-radius: 8px !important;
	font-size: 20px;
    height: 56px;
    background-color: transparent!important;
}

.thanku_page h2 {
	margin-top: 50px;
	margin-bottom: 25px;
	font-size: 70px;
}
.thanku_page .confirmation.text p {
	font-size: 18px;
	line-height: 22px;
}
.thanku_page .confirmation span {
	font-size: 36px;
}
.thankyou_back img {
    max-width: 400px;
}
.thankyou_back {
	padding-top: 61px;
	display: inline-block;
	background: #fffcf7;
	width: 100%;
}
.subscription_table table tbody .table-price td .free_subscription_text {
	font-size: 22.5px;
	font-family: 'Museo Sans 500';
	text-align: center;
	display: flex;
	line-height: 28px;
    position: relative;
    justify-content: center;
    align-items: center;
}
.subscription_table table tbody .table-price td .free_subscription_text::before {
	content: "";
	position: absolute;
	border-top: 12px solid transparent;
	border-bottom: 12px solid transparent;
	border-left: 12px solid #8ec976;
	right: -5px;
	top: 4px;
}
#subscription .subscription_table table tbody .table-price:last-child td:first-child {
	padding-left: 20px;
}
#subscription .subscription_table table tbody .table-price:last-child td {
	padding-left: 40px;
}
#subscription .subscription_table table tbody .table-price:last-child td {
	text-align: left;
	/* padding-top: 15px; */
}
#subscription .subscription_table table tbody .table-price:last-child td div{padding-top:15px;}
.start_ordering_passes .dashboard-order.order_pass.no-template {
	margin-top: 17px;
}
.subscription_table table tbody .table-price td .free_subsription_btns {
	background: transparent;
	color: #1b3f71;
	padding: 10px 10px;
	border-radius: 50px;
	font-size: 30px;
	font-family: 'Museo Sans 500';
    border-width: 4px;
    min-width:180px;
}
.payment_method_form .form-group input {
	width: 100%;
}
.payment_method_form.sign_up_form {
	margin: 0 auto;
	display: table;
	max-width: 960px;
	float: none;
}
.payment_method_form.sign_up_form .form-group-btn {
	display: flex;
	justify-content: right;
	float: right;
}
.payment_method_form.sign_up_form .form-group-btn input[type="submit"] {
	padding: 8px 28px;
}
.payment_method_form.sign_up_form .form-group.form-group-half {
	width: 50%;
	padding-right: 40px;
	float: left;
}
.payment_method_form.sign_up_form .form-group.form-group-half.padding_right_zero{
	padding-right: 0;
}
.payment_method_form.sign_up_form .form-group-btn input[type="submit"] {
	margin-top: 0px;
}
.growing_section {
	margin: 0 auto;
	max-width: 960px;
	width: 100%;
}
.same_profile_tab_style .profile-form_btn {
	padding-top: 15px;
}
.growing_section .growing_heading {
	color: #061f39;
	font-size: 40px;
	font-family: 'Museo Sans 500';
	letter-spacing: 1px;
	line-height: 48px;
	padding-bottom: 7px;
}
.growing_section .remaining_days {
	font-size: 40px;
	letter-spacing: 1px;
	color: #1b3f71;
	margin-bottom: 16px;
	float: left;
    width: 100%;
    font-family: 'Museo Sans 300';
}
.growing_section .template_types span {
	float: left;
	width: 100%;
	color: #97937f;
	font-size: 30px;
	font-family: 'Museo Sans 300';
}
.growing_section .template_types {
	float: left;
	width: 100%;
	margin-bottom: 25px;
}
.distribution_limit p {
	color: #061f39;
	font-size: 20px;
	margin-bottom: 10px;
	font-family: 'Museo Sans 300';
}
.subscription_exceeded span {
	background: #f16772;
	color: #fff;
	padding: 5px 20px;
	font-size: 20px;
	font-family: 'Museo Sans 700';
	width: 100%;
	float: left;
}
.distribution_limit {
	margin-bottom: 40px;
}
.upgrade_subsirption {
	display: flex;
	justify-content: flex-end;
}
.upgrade_subsirption .cancel_subscription {
	background: transparent;
	border: none;
	color: #1b3f71;
	padding: 10px 20px;
    margin-right: 40px;
    border-radius: 50px;
    border:1px solid transparent;
    
}
.upgrade_subsirption button {
	font-size: 27px;
	font-family: 'Museo Sans 500';
	letter-spacing: 1px;
}
.upgrade_subsirption .upgrade_subscription_btn {
	padding: 14px 20px;
	border-radius: 40px;
	letter-spacing: 0;
	max-width: 316px;
    width: 100%;
}
.range_slider {
	margin-bottom: 10px;
}
.range_slider  .progress {
	height: 48px;
	font-size: 20px;
    border-radius: 0;
    background: rgb(39,113,153); /* Old browsers */
    background: -moz-linear-gradient(left, rgba(38, 111, 153, 1) 0%, rgba(51, 157, 145, 1) 20%, rgba(175, 205, 126, 1) 40%, rgba(242, 201, 76, 1) 60%, rgba(242, 128, 81, 1) 80%, rgba(241, 103, 114, 1)100% ); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(38, 111, 153, 1) 0%, rgba(51, 157, 145, 1) 20%, rgba(175, 205, 126, 1) 40%, rgba(242, 201, 76, 1) 60%, rgba(242, 128, 81, 1) 80%, rgba(241, 103, 114, 1)100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(241, 103, 114, 1) 0%, rgba(242, 128, 81, 1) 20%, rgba(242, 201, 76, 1) 40%, rgba(175, 205, 126, 1) 60%, rgba(51, 157, 145, 1) 80%, rgba(38, 111, 153, 1)100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#277199', endColorstr='#f28651',GradientType=1 );
	font-family: 'Museo Sans 700';
}
.range_slider .progress-bar {
	justify-content: left;
	text-align: left;
    padding-left: 0px;
    background-color: #d8e2e3;
}
.range_slider {
    transform: rotate(180deg);
}
.modal.cannot_change_subscription form {
	padding-top: 0px;
}
.modal.cannot_change_subscription .submit_btn {
	padding: 9px 18px;
	max-width: 130px;
}
.cancel_subscription form {
	padding-top: 14px;
	float: right;
}
.cancel_subscription .submit_btn.no_to_passes {
	float: left;
	margin-right: 20px;
	background: #fdfbef;
	color: #061f39;
}
.modal.cancel_subscription  .submit_btn {
	padding: 7px 28px;
	max-width: 120px;
	border-width: 2px;
}
.sending_passes .modal-dialog {
	max-width: 908px;
}
.modal .modal-body .send_passses_left {
	float: left;
	width: 50%;
	padding-right: 15px;
}
.modal .modal-body .send_passses_right {
	float: left;
	width: 50%;
	padding-left: 15px;
}
.sending_passes .modal-dialog {
	max-width: 908px;
}
.sending_passes .modal-header,.upload_list .modal-header{
	padding-left: 60px;
	padding-right: 60px;
	padding-top: 30px;
}
.sending_passes_heading span {
	font-size: 25px;
	color: #061f39;
	letter-spacing: 1px;
	font-family: 'Museo Sans 100';
}
.modal .send_passses_left form {
	padding-top: 0;
}
.modal .send_passses_left form .form-group input, .modal .send_passses_left form .form-group textarea {
    font-size: 15px;
    background: transparent;
    border: 2px solid #dde5e5;
    width: 100%;
    padding: 10px 15px;
    border-radius: 9px;
    color: #061f39;
}
.modal .send_passses_left form .form-group input:placeholder,.modal .send_passses_left form .form-group textarea:placeholder, .modal.myOrderPopup #orderform .modal_form_field input:placeholder {
   color: #C9C4A9;;	
}
.modal .send_passses_left form .form-group textarea {
    resize: none;
	height: 260px;
}
.modal.myOrderPopup .modal_form_field.floating_validthru.form-group {
	display: flex;
    margin-bottom: 50px;
    width: 50%;
}
.modal.myOrderPopup #orderform .modal_form_field input::-webkit-input-placeholder { 
    color: #C9C4A9;
    opacity:1;
  }
  .modal.myOrderPopup #orderform .modal_form_field input::-moz-placeholder {
    color: #C9C4A9;
    opacity:1;
  }
  .modal.myOrderPopup #orderform .modal_form_field input:-ms-input-placeholder { 
    color: #C9C4A9;
    opacity:1;
  }
  .modal.myOrderPopup #orderform .modal_form_field input:-moz-placeholder { 
    color: #C9C4A9;
    opacity:1;
  }
  .modal.myOrderPopup .modal_form_field.floating_validthru.form-group .modal_form_field.floating_cvc.form-group {
	width: 50%;
	float: right;
	padding-left: 15px;
}
.modal.myOrderPopup .modal_form_field.floating_validthru.form-group .modal_form_field.floating_cvc.form-group .floating-label_group {
	width: 100%;
}
.modal.myOrderPopup .modal_form_field.floating_validthru.form-group .floating-label_group {
	width: 100%;
	float: left;
	padding-right: 15px;
}
.send-pass_image img {
	max-width: 100%;
	width: 100%;
	height: 320px;
	object-fit: cover;
}
.sending_passes .modal-body {
	padding-top: 20px;
}
.upload_list .modal-body{
	padding-top: 5px;
}
.upload_list form {
	padding-top: 0;
}
.send_pass_btns {
	display: flex;
	justify-content: right;
	padding-top: 30px;
}
.send_pass_btns button {
	background: transparent;
	color: #1b3f71;
	font-size: 16px;
	font-family: 'Museo Sans 700';
	letter-spacing: 1px;
	padding: 9px 15px;
	border-radius: 9px;
}
.send_pass_btns .cancel_pass_btn {
	border: 2px solid #f16772;
	margin-right: 10px;
}
.send_pass_btns .send_pass_btn {
	border: 2px solid #57c0d9;
}
.pass_received {
	color: #061f39;
	font-size: 12px;
	letter-spacing: 1px;
}
.modal .modal-body .send_passses_left .form-group {
	margin-bottom: 25px;
}
.upload_file {
	font-family: 'Museo Sans 700';
}
.upload_list form input {
	float: left;
	width: 80%;
	background: transparent;
	font-size: 15px;
	background: transparent;
	border: 2px solid #dde5e5;
	width: 71%;
	padding: 7px 15px;
	border-radius: 9px;
	color: #061f39;
}
.upload_list form .form_group {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.upload_list form .form_group .browse_btn {
	margin-left: 20px;
	color: #1b3f71;
	background: transparent;
	padding: 6px 21px;
	border-radius: 6px;
	border-width: 2px;
	font-family: 'Museo Sans 700';
	font-size: 16px;
}
.csv_text {
	color: #061f39;
	letter-spacing: 1px;
	font-size: 13px;
	padding-top: 5px;
}
.drag_file_section {
	height: 200px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	background: #ebefec;
	margin-top: 40px;
	border-radius: 10px;
}
.csv_text a {
	color: #57c0d9;
	font-family: 'Museo Sans 700';
}
.add_new_user .modal-body > form {
	padding-bottom: 30px;
}
.sign_up_form.add_newuser_form.block_element {
	padding: 40px 0 10px !important;
}
.sign_up_form.add_newuser_form .form-group{
	float: left;
	width: 100%;
}
.sign_up_form.add_newuser_form .send_pass_btns {
	width: 100%;
	padding-top: 0;
}
.sign_up_form.add_newuser_form .inputText {
	border-radius: 0;
	width: 100%;
}
.change_payment_method .modal-dialog {
	max-width: 908px;
}
.change_payment_method .modal-body .form-group-half .form-group{
	float: left;
	width: 50%;
	padding-right: 20px;
}
.change_payment_method .modal-body .form-group-half .form-group:nth-child(2) {
	padding-right: 0;
}
.change_payment_method .modal-body .form-group-btn {
	display: flex;
	float: left;
	width: 100%;
	justify-content: right;
}
.change_payment_method .modal-body .form-group-btn input[type="submit"] {
	margin-top: 10px;
	width: auto;
	padding: 8px 55px;
}
.banner-slider {
	margin-bottom: 50px;
}
.owl-nav .owl-next{
	background-image: url("../images/arrow-right.png") !important;
	background-size: 30px 20px !important;
	background-repeat: no-repeat !important;
	height: 55px;
	width: 55px;
	position: absolute;
	top: 40%;
	display: flex;
	align-items: center;
	border: 2px solid #57c0d9 !important;
	border-radius: 100%;
	background-position: 12px 15px !important;
	right: 9px;
    background-color: #fff !important;
    z-index:99;
}
.send-right .allSelectedMailList div {
    background: #FFFCF7;
    box-shadow: 0px 0px 0px 0px #FFFCF7;
}
.status_main div{
    border-radius: 12px
}

.time_status_of_passses canvas {
    padding: 0 0 50px 0;
}
.owl-next.disabled, .owl-prev.disabled {
    display: none!important;
    }
section.banner-slider.bigger-slider .owl-item .item {
    margin: 0 auto;
    float: none;
}
.owl-nav button span , .owl-nav div span{
	display: none;
}
.Toastify{position: relative; z-index: 9999999999;}

.owl-nav .owl-prev {
	background-image: url("../images/arrow-left.png") !important;
	background-size: 30px 20px !important;
	background-repeat: no-repeat !important;
	height: 55px;
	width: 55px;
	position: absolute;
	top: 40%;
	display: flex;
	align-items: center;
	border: 2px solid #57c0d9 !important;
	border-radius: 100%;
	background-position: 12px 15px !important;
    left: -70px;
    z-index:99;
}
.user_account .user_current span {
	color: #061f39;
	font-size: 20px;
	font-family: 'Museo Sans 500';
	padding-right: 14px;
}
.user_account .user_current {
	display: flex;
	align-items: center;
	cursor: pointer;
}
.user_account .user_current img {
	width: 25px;
	margin-right: 9px;
}
.user_account .user_current i {
	color: #061f39;
	font-size: 18px;
}
.user_account .users {
    margin: 0;
    padding: 0;
    list-style: none;
    background: #fffcf7;
    margin-top: 10px;
    box-shadow: 0 0 20px 3px rgba(156, 156, 156, 0.4);
    border-radius: 10px;
    width: 200px;
    right: 10px;
}
.user_account .users li {
	color: #061f39;
    cursor: pointer;
    float: left;
    width: 100%;
}
.user_account{
	padding: 15px;
	position: relative;
}
.user_account .users .active,.user_account .users li:hover {
	color: #fff;
	background:#57c0d9;
}
.user_account .users li:first-child {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}
.users.show{
	display: block;
}
.users {
	position: absolute;
	top: auto;
	display: none;
}
.select_subscription .have-subscription {
	max-width: 1136px;
}
 .select_subscription .unsbscribed_account{max-width:1500px}
.select_subscription .modal-header {
	padding-left: 60px;
    padding-right: 60px;
    padding-top: 60px;
    padding-bottom: 50px;
}
.modal .unsbscribed_account .subscription_table table tbody .table-price td .month_validity {
    font-size: 16px;
}
.modal.select_subscription .unsbscribed_account table tbody tr.table-price td > div {
    padding: 16px 5px 0px 20px;
}
.modal.select_subscription .unsbscribed_account table tbody tr.table-price.table-action-tab td > div {
    padding: 16px 20px 0px;
}
#UpgradeSubscription .unsbscribed_account .subscription_table  table tbody .table-price td .price_kr {
    font-size: 30px;
}
.modal.select_subscription .unsbscribed_account table tbody tr.table-price td:first-child div {
    padding-top: 39px;
}
#UpgradeSubscription  .unsbscribed_account  .subscription_table table tbody .table-price td .free_subsription_btns {
    height: auto;
    font-size: 30px;
    padding: 10px 15px;
}
header .header_logo_section .logo img {
	width: 350px;
}
div#loader {
	text-align: center;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 100000;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,.77);
	border-radius: 0px;
}
.modal.select_subscription table tbody tr.table-price td:first-child div {
    padding-top: 18px;
}
#loader img {
    width: 200px;
    height: 200px;
    position: fixed;
    top: 45%;
    left:45%;
    background: transparent;
    z-index: 99999;
}
.pass-design-layout {
    background: #f2c94c;
    position: relative;
    float: left;
    width: 100%;
    min-height: 500px;
    /* border-right: 1px solid #cab99c;
    border-left: 1px solid #cab99c; */
}

.order_fornew_passes .demo-pass-template .pass-design-layout {
    background: transparent;
}
.pass-design-banner .pass-design-title img {
	width: 100%;
}

.pass-design-layout .pass-design-title .pass-design-content {
    text-align: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-50%);
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sending_passes_heading h3 {
	font-size: 40px;
	font-family: 'Museo Sans 100';
}
.pass-design-layout .pass-design-title .pass-design-content h3 {
    color: #fff;
    font-size: 54px;
    font-family: 'Museo Sans 700';
}
.pass-design-layout .pass-design-title {
    position: relative;
}
.passes-design-table {
    float: left;
    width: 100%;
    padding: 20px;
}
.passes-design-table .passes-design-left {
    float: left;
    width: 45%;
}
.passes-design-table .passes-design-right {
    float: right;
    text-align: right;
    width: 50%;
}
.passes-design-table p {
    float: left;
    width: 100%;
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    font-family: 'Museo Sans 700';
}  
.passes-design-table p span {
    float: left;
    width: 100%;
    text-transform: capitalize;
    font-size: 18px;
}
.passes-design-bar_code img {
    width: 100%;
    max-width: 340px;
}
.passes-design-bar_code {
    float: left;
    width: 100%;
    text-align: center;
    padding: 50px 0 20px;
}
.pass-design-layout .pass-design-layout-logo {
    padding: 2px 20px 30px;
}
.pass-design-layout .pass-design-layout-logo img {
    max-width: 290px;
    width: 100%;
}
.user_account .users li a {
    color: #061f39;
    text-decoration: none;
    padding:10px 20px;
    float: left;
    width: 100%;
}
.user_account .users li.active a {
    color: #fff;
}
.pass-design-banner_boxes .pass_design_box {
    float: left;
    width: 16%;
    background: #fff;
    height: 42px;
    margin-right: 5%;
    margin-bottom: 15px;
    border-radius: 10px;
    border: 2px solid #cab99c;
}
.pass-design-banner_boxes .pass_design_box:nth-child(5n+5) {
    margin-right: 0;
}
.pass-design-banner_boxes {
    float: left;
    width: 100%;
    padding: 0 20px;
}
.pass-design-layout.pass_design_universal {
    background: #339d91;
}
.pass-design-layout.pass_design_universal::after,.pass-design-layout.pass_design_universal::before{
	display: none;
}
.pass-design-layout.pass_design_universal {
    background: #339d91;
    border-radius: 20px;
    border: none;
}
.pass_design_universal_logo {
    float: left;
    width: 100%;
    padding: 15px 20px 30px;
}
.pass-design-layout.pass_design_universal .passes-design-table p {
    margin-bottom: 7px;
    line-height: 17px;
}
.pass-design-layout.pass_design_universal .passes-design-table span {
    color: #fff;
    font-family: 'Museo Sans  700';
    font-size: 20px;
    line-height: 21px;
}
.pass-design-layout.pass_design_universal .passes-design-table {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
   padding: 10px 20px;
}
.pass-design-layout.pass_design_universal .passes-design-right.passes_design_right_image img {
    width: auto;
    float: right;
}
.pass-design-layout.pass_design_universal .pass_design_universal_logo img {
    width: auto;
    float: left;
}
 .owl-stage-outer {
    padding: 10px 0;
}
.my_pass_templates .order_pass {
    float: left;
    width: 280px;
    margin: 10px 0;
    margin-right: 80px;
    height: 406px;
}
.my_pass_templates .banner-slider {
    float: left;
    width: calc(100% - 310px);
}
.my_pass_templates  .order_pass p, .my_scanner .order_scanner p {
    padding-top: 0;
    margin: 0;
    margin-top: -80px;
}
.my_pass_templates  .order_pass span.add_order, .my_scanner .order_scanner span.add_order{
    background: #fff;
}
.my_pass_main_section.my_passtwo_main_section section.banner-slider {
    float: left;
    width: 62%;
    padding-left: 50px;
}
.my_pass_two_sections {
    float: left;
    width: 38%;
}
.my_pass_templates .my_pass_two_sections .order_pass {
    margin-right: 5%;
    width: 45%;
}
.my_pass_templates .order_pass.ordered_pending_passes {
    padding: 77px 0 59px;
}
.my_pass_main_section.my_passtwo_main_section .owl_carousel_1 {
    position: relative;
}
.my_pass_main_section.my_passtwo_main_section .owl_carousel_1 .owl-stage-outer{
	overflow: hidden;
	padding: 10px 0;
}
.my_pass_main_section.my_passtwo_main_section section.banner-slider .owl-item {
    float: left;
}
.myOrderPopup form#orderform input#orderConfirm {
    height: auto;
    padding: 12px 35px;
    line-height: 1.5;
    margin-top: 0;
}
form.payment_method_form.sign_up_form .modal .form-group {
	margin-bottom: 60px;
}
.my_pass_templates .order_pass.ordered_pending_passes span.order_dated {
    float: left;
    width: 100%;
    text-align: center;
    color: #000000;
    font-family: 'Museo Sans 300';
    font-size: 18px;
    margin-bottom: 40px;
    line-height: 22px;
}
.my_pass_templates .add_order_disable.order_pass p {
    margin-top: 0;
}
.my_pass_templates .order_pass.ordered_pending_passes p {
    font-size: 20px;
    line-height: 24px;
    color: #000;
    margin-top: 35px;
}
.my_pass_templates .order_pass span.add_order i , .my_scanner .order_scanner span.add_order i {
    font-size: 28px;
}
.owl-dots {
    display: none;
}
.owl-nav button {
    background-color: #fff;
}
footer .footer_rgt ul li {
    margin-bottom: 8px;
    font-size: 16px;
}
footer .footer_rgt ul{
	list-style: none;
	padding: 0;
	margin: 0;
}
footer .footer_rgt ul li a {
	color: #fff;
	text-decoration: none;
}
.user_account .users li:hover a{
	color: #fff;
}
.user_account .users li a{
    font-size: 18px;
}
.user_account .users li:last-child {
	border-radius: 0 0px 10px 10px;
}

.subscription_table table td.professional span, .subscription_table table td.starter span {
	position: absolute;
	right: -4px;
	top: -2px;
}
.subscription_table table td.professional, .subscription_table table td.starter {
	position: static;
}

#UpgradeSubscription .subscription_table table tr.table-price td.active-plan > div {
	border-left: 2px solid #57C0DB;
	border-right: 2px solid #57C0DB;
	float: left;
	width: calc(100% + 2px);
	height: 100%;
	margin: 0 0px;
}
.modal.select_subscription table tbody tr.table-price td {
	padding: 0;
}
.modal.select_subscription table tbody tr.table-price td > div {
	padding: 0px 20px 0px;
	float: left;
	width: 100%;
	text-align: left;
}
.modal.select_subscription table tbody tr.table-price td > div.extra_space{
    padding: 41px 20px 0px;
}
.modal.select_subscription table tbody.with_discount tr.table-price td > div.extra_space{
    padding: 0px 20px 0px;
}
.have-subscription .subscription_table table tbody .table-price td .price_kr.active-plan_span{
    padding-top:19px;
}
.have-subscription .subscription_table table tbody.with_discount .table-price td .price_kr.active-plan_span{
    padding-top:0px;
}
.modal.select_subscription table tbody tr.without_disscount.table-price td .starter_price{
    padding: 37px 20px 0px;
}
.have-subscription .subscription_table table tbody .without_disscount.table-price td .price_kr.active-plan_span{
    padding-top:0px;
}
.modal.select_subscription table tbody tr.table-price.table-action-tab div {
	padding: 0 0 0px;
}
#UpgradeSubscription .subscription_table table tr.table-price.table-action-tab td.active-plan > div{
    min-height: 105px;
    border-radius: 0 0 18px 18px;
    padding-top:14px;
}
.subscription_table table tbody .table-price td .free_subscription_text br {
	display: none;
}
.modal .subscription_table table tbody .table-price td .month_validity {
	padding-top: 6px;
}
.comming_soon{
	background-color: #fff;
    width: 100%;
    height: 100vh;
    text-align: center;
}
.comming_soon .middle{
	padding: 250px;
}
.comming_soon .bottomleft{
padding-top: 50px;
}
.comming_soon a{
	color: #fff;
}

.header-right-alignment .user_account {
    padding-top: 0;
    padding-bottom: 0;
}
.header-right-alignment {
	width: 25%;
	display: flex;
	justify-content: flex-end;
	position: relative;
	z-index: 9;
}
header.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 1px 8px rgba(0,0,0,0.5);
}
header.sticky .header_logo img {
    max-width: 300px;
}
.local_language_picker .rtc-dropdown .rtc-dropdown-menu .rtc-btn img {
    width: 25px;
    height: 17px;
    margin: 0 auto;
}
.local_language_picker .rtc-dropdown button.rtc-dropdown-toggle img {
    width: 25px;
    height: 17px;
}
.local_language_picker  div.rtc-dropdown .rtc-dropdown-toggle::after {
    margin-left: 2px;
    border-top: 6px solid;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    margin-top: 4px;
}
.rtc-dropdown .rtc-dropdown-menu .rtc-btn:hover, .rtc-dropdown .rtc-dropdown-menu .rtc-btn[data-selected="true"],.rtc-dropdown .rtc-dropdown-menu .rtc-btn[data-selected="false"] {
    background-color: #fff;
    padding-left: 10px;
}
.local_language_picker div.rtc-dropdown .rtc-dropdown-menu {
    border-radius: 0;
    position: absolute;
    will-change: transform;
    top: 0;
    left: 12px;
    -webkit-transform: translate3d(0, 30px, 0);
    transform: translate3d(0, 30px, 0);
    min-width: 43px;
    padding: 0;
    border-color: #9aa2aa;
    margin: 0;
    text-align: center;
    border: none;
    box-shadow: 0px 2px 8px 0 #9aa2aa;
    background: #fff;
}
.local_language_picker .rtc-dropdown .rtc-dropdown-menu .rtc-btn {
    padding: 5px 10px;
    width: 100%;
    justify-content: center;
    text-align: center;
    border-bottom: 1px solid #9aa2aa;
    align-items: center;
}
.local_language_picker .rtc-dropdown .rtc-dropdown-menu .rtc-btn:last-child {
    border-bottom: none;
}
.local_language_picker .rtc-dropdown.toggle{
    overflow: visible;
}
#scroll {
    position:fixed;
    right:10px;
    bottom:10px;
    cursor:pointer;
    width:50px;
    height:50px;
    background-color:#3498db;
    text-indent:-9999px;
    display:none;
    -webkit-border-radius:60px;
    -moz-border-radius:60px;
    border-radius:60px
}
#scroll span {
    position:absolute;
    top:50%;
    left:50%;
    margin-left:-8px;
    margin-top:-12px;
    height:0;
    width:0;
    border:8px solid transparent;
    border-bottom-color:#ffffff;
}
#scroll:hover {
    background-color:#e74c3c;
    opacity:1;filter:"alpha(opacity=100)";
    -ms-filter:"alpha(opacity=100)";
}

.term-popup .modal-dialog {
    max-width: 1250px;
}
.term-popup .modal-header, .term-popup .modal-footer {
    padding-left: 60px;
    padding-right: 60px;
}
.term-popup .modal-footer button.btn.btn-danger {
    background: #57c0d9;
    border: 1px solid #57c0d9;
    padding: 7px 17px;
}
.term-popup .modal-footer {
    padding-top: 10px;
}
#termsCondition.term-popup .modal-footer button {
	font-size: 27px;
	line-height: 1;
	padding: 16px 42px;
	border-radius: 50px;
}

.user_account .users {
    margin: 0;
    padding: 0;
    list-style: none;
    background: #fffcf7;
    margin-top: 10px;
    box-shadow: 0 0 20px 3px rgba(156, 156, 156, 0.4);
    border-radius: 10px;
    width: 200px;
    right: 10px;
}

.user_account .users li a {
    color: #061f39;
    text-decoration: none;
}
.user_account .users .active, .user_account .users li:hover {
	color: #000;
    background:"#57C0D9"
}

.floating-label_group{ 
    position:relative; 
    margin-bottom:0px; 
  }
  .floating-input {
    display:block;
    width:100%;
    background-color: transparent;
    border:none;
    border-bottom:1px solid #757575;
  }
  .floating-input ~ label, .floating-label_group label {
	color: #97937f;
	font-size: 30px;
	font-weight: normal;
	position: absolute;
	pointer-events: none;
	left: 0px;
	top: -12px;
	transition: 0.2s ease all;
	-moz-transition: 0.2s ease all;
	-webkit-transition: 0.2s ease all;
	font-family: 'Museo Sans 300';
}
.signup_with_placeholder .floating-input ~ label, .signup_with_placeholder .floating-label_group label {
    top: -34px;
    font-size: 14px;
}
.signup_with_placeholder input::placeholder{
    color:#97937f;
}
 .floating-input:focus ~ label, .floating-input:not(:placeholder-shown)  ~ label, .floating-input:-webkit-autofill ~ label{
    top:-18px;
    font-size:12px;
    color:#C9C4A9;;
  }
  .floating-label_group input:focus ~ label, .floating-label_group input:not(:placeholder-shown) ~ label {
    top: -46px;
    font-size: 20px;
    color: #97937f;;
}
.profile_sec.same_profile_tab_style .profile-form_btn input[type="submit"] {
	font-size: 36px;
	height: 80px;
	max-width: 370px;
	width: 100%;
	margin-left: 0;
	min-width: 370px;
	padding: 20px 50px;
}
  .highlight {
    position:absolute;
    height:50%; 
    width:100%; 
    top:15%; 
    left:0;
    pointer-events:none;
    opacity:0.5;
  }
  .floating-input:focus  .highlight  {
    -webkit-animation:inputHighlighter 0.3s ease;
    -moz-animation:inputHighlighter 0.3s ease;
    animation:inputHighlighter 0.3s ease;
  }
  .xpry.modal_form_field label {
    margin-top: -0px;
}
.myOrderPopup p.error {
    margin-bottom: 0;
    position: absolute;
}
.modal_main_field.form-group.valid-thru-field {
    display: flex;
    align-items: flex-end;
    margin-bottom: 28px;
}
@-webkit-keyframes inputHighlighter {
	from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}
@-moz-keyframes inputHighlighter {
	from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}
@keyframes inputHighlighter {
	from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}

/***  floating-Lable style end ***/


/**   daniel - Fork me friend - style   **/
.floating-credit { position:fixed; bottom:10px;right:10px; color:#aaa; font-size:13px;font-family:'Museo Sans 500'; }
.floating-credit a { text-decoration:none; color:#000000; font-weight:bold; }
.floating-credit a:hover { border-bottom:1px dotted #f8f8f8; }
.floating-heading { position:fixed; color:#aaa; font-size:20px; font-family:'Museo Sans 500'; }
/**  daniel - Fork me friend - style  **/



.no-template {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border: 2px dashed #8c887e;
    padding: 100px 0;
    margin-bottom: 60px;
    border-radius: 5px;
}
.no-template span.add_order img, .no-scanner span.add_order img {
    width: 20px;
}
.no-template span.add_order, .no-scanner span.add_order {
    display: flex;
    border: 1px solid #57c0d9;
    height: 100px;
    width: 100px;
    border-radius: 100%;
    float: left;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.no-template p {
    float: left;
    width: 100%;
    text-align: center;
    padding-top: 20px;
    margin: 0;
    font-size: 35px;
    color: #061f39;
}
.my_pass_main_section section.banner-slider {
    float: left;
    width: 61%;
    position: relative;
}
section.banner-slider{
    position: relative;
}
.my_pass_main_section .order_pass.ordered_pending_passes {
    width: 280px;
}
.my_pass_main_section.slider_with_pending_temp .order_pass {
    margin-right: 29px;
    cursor: pointer;
}
 .owl-stage-outer::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 95%;
    top: 0;
    right: -12px;
    box-shadow: 10px -4px 24px 60px #fffcf7;
}
.owl-stage-outer {
    padding-left: 10px;
    padding-bottom: 30px;
    overflow: hidden;
}
.item.current_template {
    position: relative;
}
.item.current_template::after {
    content: "";
    position: absolute;
    left: -26px;
    top: -26px;
    background: #ebefec;
    width: 115%;
    height: 110%;
    z-index: -1;
    border: 2px solid #57c0d9;
    border-radius: 30px;
    margin: auto;
}
.owl-item {
    padding: 0px;
    float: left;
}
.owl-item .item{
    float: left;
    padding: 24px;
}
.owl-nav .owl-prev {
    background-color: #ffffff !important;
}
.item.current_template::before {
    content: "";
    position: absolute;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #57c0d9;
    top: auto;
    bottom: -43px;
    z-index: 99;
    left: 50%;
    transform: translateX(-50%);
}
.my_pass_templates .order_pass {
    margin-top: 31px;
}
ul.nav.nav-tabs {
    float: left;
    width: 100%;
    margin-bottom: 30px;
}
/* 13-11 */
.codes_right .codes-image_section img {
    width: 100%;
    max-width: 178px;
}
.codes_right .codes-image_section a {
    word-break: break-all;
}
.same_status_section.codes_section.codes-scaaner_two .codes-image_section img {
	max-width: 370px;
	width: 100%;
}
.codes-scaaner_two .codes_right .codes-image_section .code-name {
    padding-top: 0px;
}
.Dummy-Data .codes_right .codes-image_section span {
	margin-bottom: 8px;
}
/* 14-11 */
nav.react-contextmenu.custom-context-item:hover {
    background: #fff;
    color: #81cfe0;
    transition: all .5s;
    cursor: pointer;
}
nav.react-contextmenu.custom-context-item {
    background: #81cfe0;
    z-index: 999999;
    padding: 3px 11px;
    border-radius: 3px;
    color: #fff;
    font-weight: 600;
    border: 1px solid #81cfe0;
}
nav.react-contextmenu.custom-context-item i {
    font-size: 15px;
    margin-left: 3px;
}
.modal .template_build_inner.template_bulid_green span {
    color: #8EC976;
}

.modal .template_build_inner.template_bulid_green span:nth-child(1) {
    max-width: 500px;
}
.profile_form_group_select .floating-label_group label{
    font-size: 20px;
    top: -31px;
}
.profile_form_group_select .floating-label_group select{
    padding:0px;
}
.status_main table button.cmn-btn.activate:hover {
    color: #339d91;
    background-color: #fff;
}
.status_main div div thead {
    box-shadow: 0 8px 6px -6px black;
}
.status_main table button.cmn-btn.activate {
    border-color: #339d91;
    width:100%;
}
.status_main table button.cmn-btn {
    background-color: #cc0000;
    width: 100%;
    border-color: #cc0000;
}
.status_main table button.cmn-btn:hover {
    background-color: #ffffff;
    color: #cc0000;
}
div#react-confirm-alert .react-confirm-alert-body h1 {
    color: #000;
    font-size: 30px;
    font-weight: 500;
    font-family: 'Museo Sans 500';
    line-height:1.7;
}
.browsedCsvContainer input#browsedCsvName {
    padding: 5px 10px;
}
div#react-confirm-alert .react-confirm-alert-body {
    padding: 24px 30px 40px;
}
#react-confirm-alert .react-confirm-alert-overlay {
    z-index: 999;
    background: rgba(113, 113, 113, 0.9);
}
.pre-filled-form{
    pointer-events: none;
}
.my_pass_main_section section.banner-slider {
    float: left;
    width: calc(100% - 270px);
    padding-left: 20px;
}
.pass-design-layout {
    max-width: 380px;
}
div#users .search_section {
    margin-bottom: 40px;
}
.codes_right .self-code span {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}
.codes_right .self-code {
    padding: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    border: 2px solid #81cfe0;
    min-width: 400px;
}
.modal .toatl_vat_price sup {
    top: -1px;
    font-size: 16px;
    left: 7px;
    font-style: normal;
}
form .error-main {
    min-height: 17px;
}
form .error-main .error{
    margin:0;
}
.range_slider .progress-bar span {
    transform: rotate(180deg);
    position: absolute;
    left: auto;
    right: 14px;
    top: 8px;
}
.upgrade_subsirption .cancel_subscription:hover {
    background: #fff;
    border-radius: 50px;
    color: #57c0d9;
    border: 1px solid #57c0d9;
    transition: all .5s;
}
button.upgrade_subscription_btn {
    padding: 10px 25px;
    border-radius: 50px;
    font-family: 'Museo Sans 500';
    font-size: 27px;
}
.modal.select_subscription table tbody tr.table-price {
    background: #fff !important;
}
.modal .have-subscription .subscription_table table tbody tr:nth-child(5) td:nth-child(1) {
    border-bottom-left-radius: 20px;
}
.modal .have-subscription .subscription_table table tbody tr:nth-child(5) td.active-plan:last-child {
    border-bottom-left-radius: 0px;
}   
.modal .have-subscription .subscription_table table tbody tr:nth-child(5) td:last-child{
    border-bottom-right-radius: 20px;
} 
#UpgradeSubscription .subscription_table .table-price.table-action-tab td.active-plan {
    border-radius: 0 0 20px 20px;
}
#UpgradeSubscription .modal.select_subscription table tbody tr.table-price td {
	min-height: 92px;
}
.modal .subscription_table table  th.active-plan {
    background: #ebefec;
    border: 2px solid #57c0d9;
    border-bottom: none;
    border-top-left-radius: 20px;
    float: left;
    width: 100%;
    border-top-right-radius: 20px;
}
.modal .subscription_table table tbody tr td.active-plan {
    /* background: #ebefec !important; */
    /*border-right: 2px solid #57c0d9;
    border-left: 2px solid #57c0d9;*/
}
.modal .subscription_table table tbody tr.table-action-tab td.active-plan {
    min-height: 94px;
    padding-bottom: 50px;
    border-bottom: 2px solid #57c0d9;
    border-bottom-left-radius: 20px;
    float: left;
    width: 100%;
    border-bottom-right-radius: 20px;
}
.modal .subscription_table table tbody tr.table-price td.active-plan {
    background: #ebefec !important;
    /* border-right: 2px solid #57C0DB;
    border-left: 2px solid #57c0d9; */
    float: left;
    width: 100%;
    /* padding: 25px 30px; */
}
.modal .subscription_table table tbody tr.table-action-tab td.active-plan span {
    font-size: 25px;
    float: left;
    width: 100%;
    text-align: center;
    line-height: 29px;
}

.modal .subscription_table tr.table-price.table-action-tab td button {
    margin: 0 auto;
    display: table;
}
span.card_number_value {
    padding-left: 62px;
    font-size: 40px;
    margin-top: -26px;
    float: left;
    font-weight: 600;
}
p.card_number_text_value {
    font-size: 18px;
    float: left;
    margin: 0;
    border-bottom: 1px solid #b2afa6;
    width: 100%;
    padding-top: 7px;
}
.modal.myOrderPopup p.card_number_text_value {
	margin-bottom: 20px;
	position: relative;
	padding-bottom: 10px;
}
.modal.myOrderPopup .modal_form_field {
    margin-top: 0;
    margin-bottom: 68px;
}
.modal_main_field.form-group.valid-thru-field .modal_form_field {
    margin-bottom: 0;
}
#orderform .modal_form_field.form-group:nth-child(3) {
	margin-bottom: 30px;
}
.modal.myOrderPopup .modal_form_field button {
    top: 0;
}
.myOrderPopup .modal_form_field.form_field_spacing.hide-input-field input#card_number {
    display: none;
}
img.cardTypePng {
    max-width: 50px;
    left: 0;
    position: absolute;
    bottom: 9px;
}
p.date-selection {
    margin: 0 0 5px;
}
.modal .receipt_section {
    padding-top: 30px;
    float: left;
    width: 100%;
}
.modal .receipt_section p span {
    float: left;
    width: 100%;
}
.modal .receipt_section > p {
    float: left;
    width: 100%;
}
/*.modal .subscription_table table tbody tr.sub-row td.active-plan {
    border: none !important;
}*/
.myOrderPopup p.error {
    color: #F16772;
}
.myOrderPopup input.btn-danger {
    background: #dc3545;
    border-color: #dc3545;
    padding: 8px 28px;
    border-radius: 50px;
    font-size: 20px;
    font-family: 'Museo Sans 700';
    width: auto;
    float: right;
}
.myOrderPopup input[type="submit"], .myOrderPopup input.btn-danger {
	font-size: 27px;
    margin-left: 15px;
    padding:  12px 35px;
    font-family: 'Museo Sans 500';
}
.modal.myOrderPopup .modal_form_field.floating_validthru.form-group .modal_form_field.floating_cvc.form-group {
	margin-bottom: 30px;
}
.sign_up_form .form-group.card-detail input.inputText.pre-filled-form {
    padding-left: 43px;
}
form.sign_up_form.block_element label.container-s {
    display: inline-block;
    width: auto;
    float: left;
}
form.sign_up_form.block_element .form-group-btn {
    clear: both;
}
.modal .modal_main_field .modal_form_field:nth-child(2) {
    margin-bottom: 0;
}
.modal .subscription_table table tbody tr.sub-row:nth-child(2) td.active-plan {
    background: #fffcf7 !important;
}
.modal .subscription_table table tbody tr.sub-row:nth-child(3) td.active-plan {
    background: #e4e0d0 !important;
}
.modal .subscription_table table tbody tr.sub-row td.active-plan:last-child {
    border-radius: 0;
    border-right: 2px solid #57c0d9 !important;
}
/* .modal .subscription_table table tbody tr.table-price td.active-plan {
    border-right: 2px solid #57c0d9 !important;
    border-left: 2px solid #57c0d9 !important;
} */

/* #subscription .modal .subscription_table table tbody.with_discount .table-price:last-child td div, #subscription .modal .subscription_table table tbody.with_discount .table-price td.active-plan div {
	padding-top: 19px;
} */
.modal.select_subscription table tbody tr.table-price td.active-plan > div.extra_space{
    padding-top: 19px;
}
.modal.select_subscription table tbody.with_discount tr.table-price td.active-plan > div.extra_space{
    padding-top: 0px;
}
.template-logoText {
	padding-right: 8px;
}
.modal .subscription_table table tbody tr.table-price, .modal .subscription_table table tbody tr.table-price div{min-height: 103px;}
.modal .subscription_table table tbody tr.sub-row td[data-class="active-plan"]:last-child {
    border-radius: 0;
    border-right: 2px solid #57c0d9 !important;
}
#addUser .empty_loading_div, #modalcontactus .empty_loading_div{
    min-height: 450px;
}
p.expiray-now {
    color: #63b985;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 13px;
}
p.alert.alert-danger.expiry-note {
    /* display: flex; */
    align-items: center;
    line-height: 22px;
}
p.expiry-note img {
    width: 23px;
    margin-right: 10px;
}
.standard-fee-note {
    max-width: 1350px;
    width: 100%;
    padding: 22px 20px;
    margin: 90px auto 30px;
    text-align: center;
    border: 4px solid #8ec976;
    border-radius: 18px;
}
.standard-fee-note p {
	font-size: 29px;
	color: #061F39;
	line-height: 36px;
	letter-spacing: 0.03em;
	margin: 0;
	font-family: 'Museo Sans 700';
}
.modal .subscription_table table tbody .table-price td .month_validity {
	font-size: 11px;
}
.order_fornew_passes .order_pass, .order_fornew_scanner .order_scanner{
    float: left;
    width: calc(100% - 435px);
    margin-right: 50px;
    height: 406px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border: 2px dashed #8c887e;
    padding: 143px 0 70px;
    margin-bottom: 60px;
    border-radius: 10px;
    box-sizing: border-box;
}
.order_fornew_scanner .order_scanner {
        width: 100%!important;
}
.order_fornew_passes .demo-pass-template {
    float: left;
    width: 372px;
    position: relative;
    padding: 29px;
}
.order_fornew_passes .demo-pass-template::before {
    content: "";
    background: linear-gradient(0deg, rgba(87, 192, 217, 0.05), rgba(87, 192, 217, 0.05)), #F3F1ED;
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    left: 0;
    border: 2px solid #57c0d9;
    border-radius: 25px;
    z-index: 9;
}
.order_fornew_passes .demo-pass-template::after{
content: "";
position: absolute;
border-left: 20px solid transparent;
border-right: 20px solid transparent;
border-bottom: 20px solid #57c0d9;
top: auto;
bottom: -20px;
z-index: 99;
left: 50%;
-webkit-transform: translateX(-50%);
transform: translateX(-50%);
}
.order_fornew_passes .demo-pass-template .pass-design-layout {
    position: relative;
    z-index: 99;
}
.pass-design-layout {
    background: #f2c94c;
    position: relative;
    float: left;
    width: 100%;
    min-height: 403px;
    /* border-right: 1px solid #cab99c;
    border-left: 1px solid #cab99c; */
}
.pass-design-layout .pass-design-layout-logo {
    padding: 2px 20px 30px;
}
.pass-design-layout .pass-design-layout-logo img {
    max-width: 290px;
    width: 100%;
}
.pass-design-layout .pass-design-title {
    position: relative;
}
.pass-design-banner .pass-design-title img {
    width: 100%;
}
.pass-design-layout .pass-design-title .pass-design-content {
    text-align: center;
    position: absolute;
    height: 100%;
    transform: translateX(-50%);
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pass-design-layout .pass-design-title .pass-design-content h3 {
    color: #fff;
    font-size: 54px;
    font-family: 'Museo Sans 700';
}
.passes-design-table {
    float: left;
    width: 100%;
    padding: 20px;
}
.order_fornew_passes .pass-design-layout .pass-template-hover {
    width: 100%;
    position: absolute;
    left: -20px;
    text-align: center;
    transform: rotate(-45deg);
    z-index: 9;
    top: 30%;
}
.order_fornew_passes .pass-design-layout .pass-template-hover span {
    font-size: 40px;
    line-height: 54px;
    font-family: 'Museo Sans 700';
    text-align: center;
    color: #666256;
}
.no-dashboard .order_fornew_passes .demo-pass-template::after{
    content: "";
    position: absolute;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #57c0d9;
    top: auto;
    bottom: -19px;
    z-index: 99;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);

}
.custom-errorMsg {
	color: #F16772;
	font-size: 14px;
	width: 100%;
	clear: both;
}
.sign_up_form .form-group.card-detail input.inputText.pre-filled-form {
    padding-left: 233px;
}
.sign_up_form .form-group.card-detail span.card_number_value {
    margin-top: -25px;
    position: absolute;
} 
.dummy_data-content .dummy_data-content-logo h3 {
	margin: 60px 0 44px;
	color: #061f39;
	font-size: 40px;
	line-height: 48px;
	text-transform: uppercase;
	font-family: 'Museo Sans 100';
	margin-bottom: 20px;
}
.dummy_data-content .dummy_data-content-logo img {
    max-width: 120px;
}
.dummy_data-content .dummy_data-content-logo {
	background: #FFFCF7;
	display: inline-block;
	padding: 110px 10px 87px;
	width: 474px;
	border-radius: 20px;
	box-shadow: 0px 20px 40px rgba(5, 31, 56, 0.4);
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	top: 50%;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.dummy_data-content {
    position: absolute;
    top: 0%;
    -moz-transform: translateY(0%);
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    left: 0;
    right: 0;
    bottom:0;
    margin: 0 auto;
    text-align: center;
    z-index: 9;
}
.Dummy-Stats p, .Dummy-Stats .passess-details .passes_counting .pass_number, .Dummy-Stats .passess-details .passes_counting .pass_percantage, .Dummy-Stats .status_of_passses .status_gauge .status_upgrade, .Dummy-Data h3, .Dummy-Data p, .Dummy-Data .codes_section .codes_left span, .Dummy-Data .codes_right .codes-image_section span, .Dummy-Data .codes_right .codes-image_section a {
    color: #C9C4A9;
}
.Dummy-Stats .status_of_passses .status_gauge .status_upgrade {
    border: 2px solid #bf9a2a;
}
.Dummy-Data {
    position: relative;
    float:left;
    width:100%;
}
.Dummy-Data:after {
    position: absolute;
    content: "";
    background: #e0e0e073;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 10px;
    background:linear-gradient(0deg, rgba(87, 192, 217, 0.05), rgba(87, 192, 217, 0.05)), #F3F1ED;
    z-index: -1;
}
.tab_background {
    padding: 0;
    margin-bottom: 40px;
}
.tab_background .tab-pane {
    padding: 38px 30px 50px;
    position: relative;
    float: left;
    width: 100%;
}
#sign_up_form.signup_page .form-group .error-main .error{
    margin: 2px 0 -16px;
}
.distribution._section.Dummy-Data .status_table thead tr th:first-child, .Dummy-Data .status_table tbody tr td:first-child {
    width: 66px;
}

.Dummy-Data .search_section .fa.fa-search, .Dummy-Data .no_and_search_samestyle span, .Dummy-Data .status_table tbody .actiavted_row td, .Dummy-Data .status_table tbody .deactiavted_row td, .Dummy-Data .status_table th {
	color: #C9C4A9;
}
.Dummy-Data .user-listing .status_table tbody tr td:first-child, .Dummy-Data .user-listing .status_table thead tr th:first-child  {
    padding-left: 48px;
    width:auto;
}
.Dummy-Data.distribution._section .status_table tbody tr td:first-child, .Dummy-Data.distribution._section .status_table thead tr th:first-child{ padding-left: 20px; padding-right:18px}
.Dummy-Data .main_distribution_btn button, .Dummy-Data .main_distribution_btn .distribution_btns.active {
    border-color: #C9C4A9;
    color: #C9C4A9;
}
.Dummy-Data .status_table th span.name::before {
    border-top: 9px solid #C9C4A9;
}
.Dummy-Data .status_table .deactivate_btn {
    border: 2px solid #C9C4A9;
    color: #C9C4A9;
}
#myOrderPopup3.modal.myOrderPopup #orderform > .modal_form_field:first-child > label, #myOrderPopup0.modal.myOrderPopup #orderform > .modal_form_field:first-child > label,
#myOrderPopup2.modal.myOrderPopup #orderform > .modal_form_field:first-child > label, #myOrderPopup4.modal.myOrderPopup #orderform > .modal_form_field:first-child > label {
    top: 45px !important;
}
.Dummy-Data  .activate_btn {
    background: #C9C4A9;
    border: 1px solid #C9C4A9;
}
.Dummy-Data .table_checkbox .checkmark {
    border: 1px solid #97937F;
    border-radius: 4px;
}
.Dummy-Data .table_checkbox .checkmark::after {
    border-color: #C9C4A9;
}
.Dummy-Data .status_table tbody .deactiavted_row td .deactivated_status, .Dummy-Data .status_table tbody .actiavted_row td .activated_status {
	background: #C9C4A9;
}
.Dummy-Data .status_table tbody tr td:first-child{padding-left:22px;}
.bg_color_section.block_element.main_logo_heading_padding.my_pass_templates.my_profile_section.padding_container {
    background: #fffcf7;
}
.start_ordering_passes .dashboard-order.order_pass.no-template p, .start_ordering_scanner .dashboard-order.order_scanner.no-scanner p, .start_ordering_scanner .dashboard-order.order_scanner p {
    margin-top: 26px;
}
.banner-slider .swiper-button-prev::after{display: none} 
.user-listing {
    float: left;
    width: 100%;
}
/* .MuiPaper-root-8 {
    float: left;
    width: 100%;
} */
/* .Dummy-Data .qrcodeImg::after {
    content: "";
    position: absolute;
    background: rgba(201, 196, 169, 0.5);
    top: 52px;
    bottom: 0;
    left: 2px;
    right: 0;
    width: 165px;
    height: 164px;
    margin: 0 auto;
} */
.selfservicecodeImg {
	clear: both;
	position: relative;
}
.has-logo-image .template-logoText .grab-input .template-logoTextInner {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
/* .selfservicecodeImg:after {
    content: "";
    position: absolute;
    background: rgba(201, 196, 169, 0.5);
    top: 5px;
    bottom: 0;
    left: 0px;
    right: 0;
    width: 100%;
    margin: 0 auto;
    max-width: 255px;
} */
.Dummy-Data .status_table th:last-child, .Dummy-Data .status_table td:last-child {
    width: 205px;
    text-align: right;
    padding-left: 10px;
}
.template-logoText_innerText, .template-logoTextInner {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.template-logoText .grab-input {
	width: 100%;
}
.template-logoText .grab-input .logoTextInner {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.qrcodeImg {
	position: relative;
}
.main_distribution_btn {
    float: left;
    width: 100%;
}
.main_distribution_btn button {
    float: left;
}
.choosefile button.choosefilebtn {
    float: left;
    width: 100%;
    background: #fffcf7;
    color: #061f39;
    font-family: 'Museo Sans 700';
    padding: 8px 22px;
    border-radius: 8px;
    border-width: 2px;
    position: absolute;
    left: 0;
}
.choosefile input.distribution_btns {
    float: left;
	width: 100%;
	z-index: 1;
    position: relative;
	opacity: 0;
	cursor: pointer;
}
.choosefile {
    float: left;
    width: 170px;
    position: relative;
    margin-right: 14px;
}
.adduser .modal-body form {
    padding-top: 12px;
}
.adduser .modal-body form .mailinput {
    float: left;
	width: 100%;
	margin-bottom: 20px;
}
.adduser .modal-body input[type="button"] {
    margin-left: 10px;
}
.senduser .modal-body form input[type="text"],.senduser .modal-body form textarea {
    width: 100%;
    float: left;
    border: 1px solid #cacaca;
    height: 40px;
    padding-left: 12px;
    color: #8e8e8e;
    background: #fff;
}
.senduser .modal-body form textarea{
	height: 80px;
	padding: 10px 15px;
}
.senduser .modal-body label {
    float: left;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4px;
}
.senduser label {
    margin-bottom: 0;
}
.senduser form > div {
    float: left;
    width: 100%;
    margin-bottom: 15px;
}
.senduser form  ul {
    float: left;
    width: 100%;
}
.senduser form  ul li {
    float: left;
    width: 100%;
}
.senduser form input.btn {
    -webkit-border-radius: 50px !important;
    border-radius: 50px !important;
    margin-right: 10px;
    margin-top: 0 !important;
}
.senduser .modal form {
    padding-top: 20px;
}
.adduser label {
    float: left;
    width: 100%;
    border: 0;
	font-family: 'Museo Sans 500';
	margin-bottom: 4px;
	margin-bottom: 4px;
    font-size: 16px;
}
.adduser input, .adduser select, .addLicenseHolderModal input, .addLicenseHolderModal select {
    width: 100%;
    float: left;
    border: 1px solid #cacaca;
    height: 40px;
	padding-left: 12px;
	color: #8e8e8e;
	background: #fff;
}
#addLicenseHolder .addLicenseHolderForm .floating-label_group input:not(:placeholder-shown) ~ label {
	top: -46px;
	font-size: 20px;
	color: #97937f;
	font-family: 'Museo Sans 300';
}
.addLicenseHolderForm .floating-label_group .error-main {
	position: absolute;
	bottom: -62px;
}
/* .adduser button.close {
	position: absolute;
	top: 10px;
	right: 4px;
	background: transparent;
	border-radius: 100px;
	width: 30px;
	height: 30px;
	font-size: 31px;
	color: #000;
} */
.adduser .browsedCsvContainer input#addbrowsedCsvName, .addLicenseHolderModal .browsedCsvContainer input#addbrowsedCsvName {
	width: 90%;
}
.adduser .browsedCsvContainer, .addLicenseHolderModal .browsedCsvContainer {
	display: flex;
}
.adduser .modal-header, .addLicenseHolderModal .modal-header {
	padding: 55px 60px 11px;
}
/* #addUser .modal-dialog .modal-body {
	max-height: 500px;
	overflow-y: auto;
} */
.upgrade_subscription_btn + .modal.myOrderPopup #orderform .modal_form_field label {
    top: 4px;
}
.upgrade_subscription_btn + .modal.myOrderPopup #orderform .modal_form_field > label {
	font-size: 20px;
}
.upgrade_subscription_btn + .modal.myOrderPopup .modal_form_field label {
	font-size: 30px;
}
.single-form-group {
    width: 100%;
    float: left;
    margin-bottom: 20px;
}
.form-btn-btm {
    width: 100%;
    float: left;
    text-align: center;
}
.adduser .modal-body .form-btn-btm input {
    margin: 0 4px;
    padding: 0;
    float: none;
}
.adduser .image-input input {
    border: 0;
    font-size: 18px;
}
/*.adduser .image-input::before {
    position: absolute;
    width: 129px;
    height: 40PX;
    content: "Choose file";
    left: 0px;
    top: 26px;
    background: #000;
    color: #FFF;
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: center;
    pointer-events: none;
}*/
.adduser .image-input {
    width: auto;
    position: relative;
}
span.apexcharts-legend-text {
    white-space: nowrap;
    width: 163px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* border: 1px solid #000000; */
}
.apexcharts-legend-series {
    display: flex;
    align-items: center;
    margin-bottom: 5px !important;
    float: left;
    width: 100%;
}
.adduser .img-preview {
    width: 100%;
    float: left;
    height: 120px;
}.senduser button.close {
    position: absolute;
    top: -5px;
    right: -16px;
    background: #57c0d9;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    font-size: 29px;
    color: #fff;
}.senduser button.close {
    position: absolute;
    top: -5px;
    right: -16px;
    background: #57c0d9;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    font-size: 29px;
    color: #fff;
}
.senduser button.close {
    position: absolute;
    top: -5px;
    right: -16px;
    background: #57c0d9;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    font-size: 29px;
    color: #fff;
}
section.uploadUser-sec {
	background: #EBEFEC;
	border-radius: 8px;
	padding: 40px;
	margin: 26px auto 30px;
	text-align: center;
	min-height: 316px;
	display: flex;
	align-items: center;
	justify-content: center;
}
/* section.uploadUser-sec:hover {
    background: #57c0d9;
} */
section.uploadUser-sec img {
    max-width: 80px;
    margin-bottom:15px;
}
.modal {
	z-index: 999999999;
}

div#uploadUser .form-btn-btm {
    text-align: right;
}
div#uploadUser .form-btn-btm button#uploadCancel {
	border: 2px solid #F16772;
	background: transparent;
	color: #061f39;
	text-transform: capitalize;
	font-weight: 500;
	display: inline-block;
	margin: -4px 20px 0 0;
}
div#uploadUser .form-btn-btm button#uploadUpload.dragndrop-Upload_Active:hover {
    border-color: #57c0d9;
    color: #fff;
    background-color: #57c0d9;
}
div#uploadUser span#browsedCsvError {
    position: absolute;
    bottom: -24px;
    color: #ff0000;
    font-size: 12px;
    left: 0;
    width: 100%;
}
div#uploadUser .form-btn-btm button#uploadCancel:hover {
    background: #F16772;
    color: #fff;
}

div#uploadUser .form-btn-btm button.dragndrop-Upload_Active{
    border-color: #57c0d9 !important;
    color: #fff !important;
    background-color: #57c0d9 !important;
    cursor: pointer !important;
}
div#uploadUser .form-btn-btm button#uploadCancel {
	border: 2px solid #F16772;
	background: transparent;
	color: #1B3F71;
	text-transform: capitalize;
	font-weight: 500;
	display: inline-block;
	margin: -4px 20px 0 0;
	font-size: 20px;
}
div#uploadUser .form-btn-btm button#uploadUpload {
	background: transparent;
	border: 2px solid #C9C4A9;
	color: #c1b9b9;
	padding: 8px 25px;
	font-size: 20px;
    border-radius: 5px;
    cursor:not-allowed;
	display: inline-block;
}
.pass_layout {
    width: 345px;
    height: 500px;
    position: absolute;
}
.pass_layout.punch-pass .template-headerSection, .pass_layout.member-pass .template-headerSection {
	padding: 5px 10px;
	height: 65px;
}

.template-stripSection {
    width: 100%;
    height: 133px;
    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.template-stripSection.template-stripSectionPunchPass {
    height: 125px !important;
}
.template-logo {
    min-width: auto;
    max-width: initial;
    height: 48px;
    float: left;
    background-position: center center;
    background-repeat: no-repeat;
}
.template-logoText {
    margin-left: 0px;
    padding-left: 0px;
}
.template-logoText  {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: auto;
    margin-left: 3px;
    float: right;
    font-size: 16px;
    text-align: left;
    line-height: 28px;
    padding-left: 5px;
	font-weight: 600;
	min-width: 50px;
	height: 51px;
}
.template-logoText .template-logoText_inside {
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 100px;
}
.pass_layout .droppable.headeField {
	min-width: 60px !important;
	width: 70px !important;
}
.headeField {
    height: 50px;
    float: right;
    display: inline-block;
    position: relative;
}
.drag-input.label, .drag-input-back-section-label {
    text-transform: uppercase;
}
.drag-input.label {
    font-weight: 600;
}
.suportedAreaFooter {
	display: flex;
	width: auto;
	align-items: center;
    justify-content: center;
    float: right;
    margin-top: 10px;
    margin-right: 95px;
}
.suportedAreaFooter.suportedAreaFooterIs {
    margin-right: 35px;
}
.suportedAreaFooter span {
	font-size: 20px;
	line-height: 36px;
	color: #A8A4A4;
	float: left;
}
.suportedAreaFooter img {
	max-width: 253px;
	width: 100%;
	margin-left: 10px;
	margin-bottom: 0;
}
footer .footer_content .footer-left span a:hover{
    opacity: 0.7;
    transition: all 0.4s ease 0s;
}
footer .footer_content .footer_rgt span:hover{
    opacity: 0.7;
    transition: all 0.4s ease 0s;
}
.droppable.headeField .drag-input.label + .drag-input.value {
    line-height: 1.4;
    margin-top: -5px;
    padding-left: 3px;
    float: none;
}
.pass_layout .logo-section .hower-body .drag-input.value, .pass_layout.member-pass .headeFieldInner .drag-input.value {
    width: 100%;
}
.drag-input {
    background-color: transparent;
    height: 20px;
    white-space: nowrap;
    border-width: 0px;
    border-style: solid;
    border-color: initial;
    border-image: initial;
    outline: none !important;
    overflow: hidden;
}

.pass_layout.ticket.ticket-with-strip .ticketCircleImgMain img.ticketCircleImg {
    width: auto;
    display: inline-block;
}
/*--pass-layout--*/

.map{
    width:70%!important; 
    overflow-y: scroll;
    overflow-x: hidden;
}

.row {
    padding:10px;
}

.canvas_front {
    float:left;
    padding:5px;
}  

.canvas_backs {
    float:left;
    padding:5px;
}  

.control {
    float:left;
    padding:15px;
    border-style: solid;
    border-width: thin;
    height: 491px;
    margin-top: 1%;
}   

.canvasImg {
    display:none;
}     

.divStyle {
    /*height:710px;*/
    margin-top: 45%;
}

.divPad {
    padding:10px;
}

.label {
    padding: 0px !important;
    width:100%;
    padding-top:2px !important;
    margin-left: 1%;
    font-weight: lighter;
}
.text_entry{
    float:left;
    padding:15px;
    border-style: solid;
    border-width: thin;
    margin-top: 1%;
    width:30%
}

.sec-input{
    width:100%;
    padding:10px;   
}
.submit_btn{
    color: #fff;
    background-color: #2196f3;
    width: 10%;
    padding: 1px;
}
/**POP UP STYLE ***/
h1 {
    margin: 0;
    padding: 0;
}
html, body, .designpic {
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    height: 100vh;
}
.popup {
    position: fixed;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
}
.same_profile_tab_style form .form-group input {
    background: transparent;
    border: none;
    border-bottom: 1px solid #666256;
    width: 100%;
    color: #061f39;
    height: 50px;
    border-radius: 0;
    font-size: 30px;
}
.popup_inner {
    position: absolute;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    background: white;
}
.restriction{
    padding:15px;
    border-style: solid;
    border-width: thin; 
    width:50%;
    float:left;
    margin-left: 1%;
}
.payment{
    padding:15px;
    border-style: solid;
    border-width: thin; 
    width:30%; 
    float: left;
    margin-left: 1%;
}
/**POP UP STYLE ***/

.passFeatures{
    border-style: solid;
    border-width: thin;
    float: left;
    margin-top: 25px;
    height: auto;
    background: #d8d8d8;
    padding: 25px;
    border: none !important;
    width: 32%;   
}
#register, #login {
    width: 300px;
    border: 1px solid #d6d7da;
    padding: 0px 15px 15px 15px;
    border-radius: 5px;
    font-family: 'Museo Sans 500';
    line-height: 16px;
    color: #333333;
    font-size: 14px;
    background: #ffffff;
    margin: 100px auto;
}

form label, form input {
    display: block;
}

form input {
    /*  padding: 10px;*/
    /* border: solid 1px #BDC7D8;*/

}

.button {
    background-color: #00BFFF;
    border-color: #3ac162;
    font-weight: bold;
    padding: 12px 15px;
    color: #ffffff;
}

.errorMsg {
    color: #cc0000;
    margin-bottom: 12px;
}

.sucessMsg {
    color: #6B8E23;
    margin-bottom: 10px;
}

/* Pass Template */

div#root canvas {
    /* border: 1px solid #000; */
}



div#root .label h2 {
    text-align: center;
    font-size: 40px;
    color: #000;
    font-weight: 600;
    margin-bottom: 40px;
    margin-top: 17px;
}
div#root .control {
    padding: 23px;
    border-style: solid;
    border-width: thin;
    height: 750px;
    margin-top: 0;
    float: left;
    background: #d8d8d8;
    width: 32%;
    overflow-y: auto;
    border: none !important;
}
div#root .designpic > div {
    margin-bottom: 15px;
    font-size: 20px;
    color: #000;
    float: left;
    font-weight: 600;
    width: 100%;
}
div#root .designpic > div > span {
    font-size: 25px;
    color: #000;
    padding-left: 15px;
    width: 160px;
    float: right;
    text-align: right;
    margin-bottom: 10px;
    display: inline-block;
}
div#root .designpic{
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    height: auto;
    background: #d8d8d8;
}
div#root  form .row {
    width: 100%;
    padding: 10px;
    margin-top: 50px;
    float: left;
}


div#root .drop_files {
    float: left !important;
    width: 33% !important;
    margin-top: 20px;
}
div#root .drop_files section p {
    text-align: center;
    float: left;
    width: 100%;
}
div#root .drop_files  section > div {
    border: none !important;
}
div#root .drop_files .label {
    float: left;
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
    text-align: center;
}
div#root .drop_files section {
    width: 100%;
    text-align: center;
    float: left;
    border: none !important;
}



div#root .payment,div#root .restriction {
    border-style: solid;
    border-width: thin;
    float: left;
    margin-top: 25px;
    height: 735px;
    background: #d8d8d8;
    padding: 25px;
    border: none !important;
    width: 32%;
}
div#root .sec-input {
    width: 32%;
    margin-right: 24px;
    float: left;
    margin-top: 25px;
    padding: 0;
}
div#root .text_entry .label h3 {
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    margin-top: 20px;
    color: #000;
}
div#root form{
    position: relative;
}
div#root .divPad.templates-design div {
    width: 100% !important;
    height: 500px!important;
}

div#root .divPad.templates-design{
    padding: 0 !important;
}
div#root span.select-issuer {
    position: absolute;
    top: 29px;
    font-size: 22px;
    left: 5px;
}
div#root .text_entry {
    float: left;
    padding: 25px;
    border-style: solid;
    border-width: thin;
    margin-top: 0;
    height: 735px;
    background: #d8d8d8;
    width: 100%;
    border: none;
}
div#root .text-inputs {
    float: left;
    width: 100%;
}
div#root .text-inputs .divPad {
    float: left;
    width: 33.33%;
}
div#root .divStyle {
    margin-top: 0;
}
div#root .map {
    height: 100% !important;
    width: 1000% !important;
}
div#root .map#map-grid  .map {
    height: 390px!important;
    overflow-y: auto;
}
div#root .map#map-grid {
    height: 600px !important;
    overflow-y: auto;
}
div#root form[name="templateSlider"] .submit_btn {
    margin-top: 0;
}
div#root .submit_btn input[type="submit"] {
    background: #2196f3;
    color: #fff;
    border-color: #2196f3;
    font-size: 19px;
    height: 46px;
    box-shadow: none;
    cursor: pointer;
    outline: none;
    border: none;
    border-radius: 4px;
    box-shadow: 0 0 10px 1px #B0B0B0;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
div#root .submit_btn input[type="submit"]:hover {
    background: #000000;
}	
div#root .submit_btn input[type="submit"]:hover {
    background: #000;
}
div#root .submit_btn {
    color: #fff;
    background-color:transparent;
    width: 10%;
    float: left;
    padding: 1px;
    margin-top: 0;
}
div#root .text_entry label,div#root .payment label,div#root .restriction label,div#root  .text-inputs label {
    color: #333 !important;
    font-size: 20px;
}
/*07-02-19*/
form[name="userRegistrationForm"] .slider-content, form[name="userRegistrationForm"] .menu-item {
    width: 100%;
}
.templates-list {
    float: left;
    margin-right: 10px;
}
.divPad > div {
    width: 100% !important;
}
.templates-list {
    float: left;
    width: 46%;
}
.slider-container .menu-item {
    cursor: pointer;
    display: inline-block;
    float: none;
    padding: 22px 10px !important;
    text-transform: inherit !important;
    width: 33.3%; 
}

div#root span.select-issuer {
    opacity: 0;
}
.sec-input .divPad {
    float: left;
    width: 100%;
}
.divPad span {
    color: #000000;
}
#undefined-undefined-objectObject-21055 button {
    fill: #808080;	
}
div#root .control {
    overflow-y: auto;
}
/*End 07*/

.component-slider .slider-content {
    width: 1500px;
}


div#root .payment, div#root .restriction {
    overflow-y: auto;
}
.body > div {
    width: 86%;
    overflow: hidden;
}
.custom_class table {
    max-width: 100% !important;
}
.custom_class td {
    max-width: 100%!important;
}
.jss2 td, table td {
    max-width: 100% !important;
}
.divPad {
    float: left;
    width: 32%;
}
.fillForm, .blockSection, .divPad.all-templates {
    float: left; 
    width: 100%;  
}  
.location {
    margin-top: 30px;
    float: left;
    width: 100%;
}
.pass-materials {
    float: left;
    width: 30% !important;
    margin-top: 25px!important;
    padding: 23px!important;
    margin-right: 25px!important;
}
.pass-materials:nth-child(3n) {
    margin-right: 0 !important;
}
.sign_up_form #password, .sign_up_form #passwordd{
    -webkit-text-security: disc !important;
    -moz-text-security: disc !important;
}
.signupForm #password, .signupForm #passwordd{
    -webkit-text-security: none !important;
    font-size: 22.5px;
}
.main_distribution_btn .distribution_btns.deleteAllUser{
    border: 1px solid #b50c0c;
}
.sign_up_form .form-group.confPassGroup {
    margin-bottom: 22px;
}
.sign_up_form .form-group.confPassGroup .errorMatched.error-main p.error {
    margin: 10px 0 0 0;
}
.myOrderPopup form#orderform input[type="submit"] {
    float: right;
}
.form-group-messages p.error {
    width: 100%;
    font-size: 15px;
}
.form-group-messages {
    float: left;
    width: 100%;
    text-align: center;
    margin: 8px 0;
}
.codeLinkSection span a {
    color: #061f39;
    text-decoration: none;
}
.codeLinkSection span {
    font-size: 20px;
    line-height: 24px;
    color: #061f39;
}
.codeLinkSection ,.statisticsUpgrade {
    float: left;
    width: 100%;
    text-align: center;
    padding: 90px 0 0px;
}
.statisticsUpgrade span {
	font-size: 36px;
	line-height: 100%;
	color: #061f39;
	margin-right: 33px;
}
.statisticsUpgrade button {
	padding: 14px 20px;
	border-radius: 40px;
	max-width: 316px;
	width: 100%;
	font-size: 25px;
	height: 80px;
	letter-spacing: 0.03em;
	color: #FDFBEF;
}
.statisticsUpgrade button:hover {
color:#57c0d9;
}
.modal-header .close {
    position: absolute;
    right: 60px;
    top: 57px;
}
.modal-header {
    position: relative;
}
.modal-dialog {
	max-width: 908px;
}
.sign_up_form.block_element .forgot_password.block_element {
	margin-bottom: 50px;
	margin-top: 25px;
}
.sign_up_form .form-group.passwordformGroup {
	margin-bottom: 12px;
}
.my_pass_templates .order_pass.no-template p, .my_scanner .order_scanner.no-scanner p, .start_ordering_scanner.my_scanner .order_scanner p {
	font-size: 50px;
	line-height: 60px;
}
.my_scanner_page .disabled-link{
    pointer-events: none;
}
.signinEmailErrorMsg span {
	font-size: 30px;
	color: #F16772;
	float: left;
	width: 100%;
	margin: 0 0 35px;
}
.modal-dialog #loader {
    left: 0;
    right: 0;
    margin: 0 auto;
    background: rgba(0,0,0,.5);
    border-radius: 19.5px;
    position: absolute;
}
.modal-dialog #loader img {
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 100px;
	height: 100px;
}
.signinEmailErrorMsg {
	position: absolute;
	right: 0;
	width: 575px;
	text-align: left;
	padding: 67px 60px 96px;
	border: 4px solid #F16772;
	border-radius: 16px;
	top: 176px;
	right: 243px;
}
.sign_in {
	position: relative;
}
.sign_in {
    padding-bottom: 228px;
    padding-top: 160px;
}
.signinEmailErrorMsg span a {
    color: 
    #57c0d9;
    text-decoration: underline;
}
#deleteUser .modal-footer #deletePopupDelete:hover {
	color: rgb(0, 188, 212);
	background: transparent;
    border: 1px solid #57c0d9;
    transition: all .5s;
}
.main_distribution_btn .distribution_btns:hover {
	background: #57c0d9;
	color: #fff;
}
.choosefile button.choosefilebtn:hover {
	background: #57c0d9;
	color: #fff;
}
.main_distribution_btn .distribution_btns.deleteAllUser:hover {
	background: #F16772;
}
.main_distribution_btn .distribution_btns.active:hover {
	background: #8ec976;
}
input[type="submit"].isActive:hover {
	color: #57c0d9;
    background: transparent;
    cursor: pointer;
}
.subscription_table table tbody .table-price td .free_subsription_btns:hover {
	background: #57c0d9;
	color: #fff;
}
.sign_up_form .form-group-btn input.isActive[type="submit"]:hover {
	color: #fff;
}
.sign_up_form .form-group-btn input.isActive[type="submit"]:hover {
	background: transparent;
	color: #57c0d9;
}

.map{
    position: relative !important;
    float: left !important;
}
.maincontent{
    background-color: rgb(255, 255, 255);
    padding: 2%;  
}
.banner-section.owl-carousel .owl-item .template-headerSection img {
    width: auto;
}
.pad{margin:2% 2% 2% 2%}

div#root .coupon_front canvas{
    border-top:none !important;
    border-bottom:none !important;    
    border-right: 1px solid black;   
    border-left: 1px solid black;  
    border-radius: 0px !important;

}

div#root .coupon_back canvas{     
    border-radius: 0px !important;

}


.messageFlash{top: 99%;
              left: 37%;
              position: absolute;
}

.messageAllPasses{top: 20%;
                  left: 47%;
                  position: absolute;
}

.backside{
    border: 1px solid black;
    float: left;
    width: 90%;
    text-align: left;
    padding: 23px;
    background: #d8d8d8;
    margin-right: 25px;
    margin-left: 4%;
    min-height: 73%;
    overflow-y: auto;
    height: 73%;

}

.container, .from_date_picker {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.from_date_picker {
    cursor: pointer;
}

.from_date_picker input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    z-index:9;
}

.checkmark {
    position: absolute;
    top: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    margin-left: 4%;
    right: 0;
}
/* .container:hover input ~ .checkmark, .from_date_picker:hover input ~ .checkmark {
    background-color: #ccc;
} */                                  

.container input:checked ~ .checkmark, .from_date_picker input:checked ~ .checkmark {
	background-color: #F1EEE3;
}
.restriction .row .col-sm-11 {
    padding-right: 0;
    padding-left: 0;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container input:checked ~ .checkmark:after, .from_date_picker input:checked ~ .checkmark:after {
    display: block;
}
.valid-from-to input[type="checkbox"] {
    width: 30px;
    height: 27px;
}

.container .checkmark:after, .from_date_picker .checkmark:after{
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}


.from_date_picker {
    margin-top: 39px;
    padding-left: 13px;
}
.allowed {
    margin-left: 4%;
    margin-top: 7%;
}
.from_date_picker.show-time-checkbox {
    margin-top: 72px;
}


div#root .back h2 {
    text-align: center;
    font-size: 40px;
    color: #000;
    font-weight: 600;
    margin-bottom: 0px !important;
    margin-top: 17px;
}


/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
 /* border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  opacity: 1;
  
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

 
  /* Position the tooltip text - see examples below! */
  position: absolute;

}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.BackAnchor::before {
  content: "<";
}

/***************** added style alignment fixing *******************/

.custom_class thead th {
    padding-left: 5px !important;
}
.custom_class td {
    padding-left: 20px !important;
}
.custom_class td.column-company_name,.custom_class td.column-company_town,.custom_class td.column-company_address,.custom_class td.column-contact_name,.custom_class td.column-name {
    width: 400px !important;
    overflow: visible !important;
    max-width: 400px !important;
    white-space: pre-wrap !important;
    min-width: 400px !important;
}
.rt-tbody .rt-td .tooltip {
    z-index: 0;
}
.search-row .col-sm-8 {
    padding-left: 0;
}
.search-row .col-sm-4 {
    padding-right: 0;
}
.search-row{
    width: 100% !important;
    padding: 10px 0 !important;
    margin: 0 auto !important;
}
p.BackAnchor {
    color: #000;
}
.back-download-row .col-sm-4 span:nth-child(2) .pad {
    border: 1px solid #bdbcbc;
    padding-top: 4px;
    height: 30px;
    background: #f3f3f3;
}
.back-download-row .col-sm-4 span:nth-child(2) .pad svg {
    height: 20px !important;
}
.back-download-row .col-sm-4 span:nth-child(1) {
    margin-left: 0 !important;
}
.back-download-row .col-sm-4 span:nth-child(2) {
    margin-left: 4% !important;
}
.control .control-text {
    margin: 0;
}


.pass_layout{
width: 345px;
height: 500px; 
position: absolute;
}

.pass_input_type{
position: relative;
width: 100%;
background-color: #f5f5f5;
-webkit-border-radius: 10px;
-moz-border-radius: 10px;
border-radius: 10px;
border: 2px solid #eee;
height: auto;
padding: 3%;
}

.pass_color_block{
    display: block;
float: left;
width: 100%;
min-height: 30px;
margin-left: 2.564102564102564%;
*margin-left: 2.5109110747408616%;
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
box-sizing: border-box;
}

.color-block-label{
  width:34%;  
  float:left;
}

.color-block-value{
   width:33%;  
  float:left; 
}
.color-block-strip{
    width:33%;  
   float:left; 
 }
.color-block-input{
    width:33%;  
  
}
.color-block{
    margin: 0 auto 15px;
    float: left;
    width: 100%;
}


.factory-container{
   margin: 30px 10px 10px 10px;
width: 312px;
height: 80px;
background-color: #f5f5f5;
-webkit-border-radius: 10px;
-moz-border-radius: 10px;
border-radius: 10px;
border: 2px solid #eee; 
}

.drag-items{
float: right;
width: 109px;
height: 48px;
padding: 10px;
margin: 10px 10px 0 0;
-webkit-border-radius: 5px;
-moz-border-radius: 5px;
border-radius: 5px;
border: 1px solid #ddd;
background: #fefefe;
margin-top: -53px;
}

.details {
font-size: 15px;
width: 33%;
color: #bbb;
text-align: left;
}

.drag-input.value{
    display: inline-block;
    width: auto;
}

.drag-input{
    background-color: transparent;
    border: 0px solid;
    height: 20px;
    /*font-size: 13px;*/
    white-space: nowrap;
    outline: none !important;
    white-space: nowrap;
}
.drag-input:focus{
    overflow: visible;
}
.removehandle{
 display : none;     
position: absolute;
right: 0px;
width: 24px;
height: 12px;
overflow: hidden;
    top: -11px;
background-color: red;
border-top: 1px solid #CCC;
border-left: 1px solid #CCC;
border-right: 1px solid #CCC;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
line-height: 10px;
text-align: center;
color: #fff; 
cursor: pointer;
}
.addhandle{
display : none;    
position: absolute;
right: 22px;
top: -13px;
width: 24px;
height: 12px;
overflow: hidden;
top: -11px;
background-color: green;
border-top: 1px solid #CCC;
border-left: 1px solid #CCC;
border-right: 1px solid #CCC;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
line-height: 10px;
text-align: center;
color: #fff;
cursor: pointer;
}

.dragHandler{
display : none;    
position: absolute;
right: 12;
width: 24px;
height: 12px;
overflow: hidden;
top: -11px;
border-top: 1px solid #CCC;
border-left: 1px solid #CCC;
border-right: 1px solid #CCC;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
line-height: 10px;
text-align: center;
color: #fff;     
}
.template-logo{
    min-width: auto;
    max-width: initial;
    height: 48px;
    float: left;
    background-position: center center;
    background-repeat: no-repeat;
}
.hower-body {
   float:left; 
   position: relative;
   padding: 0;
   overflow: hidden;
   /*text-overflow: ellipsis;*/
}


.hower-body:hover{
 border: 1px solid #dad6d6;   
 overflow: visible;
}

.hower-body:hover .dragHandler{
 display : inline-block;
}

.hower-body:hover .addhandle{
 display : inline-block;
}

.hower-body:hover .removehandle{
 display : inline-block;
}

.hower-body-bottom:hover .dragHandler{
 display : inline-block;
}

.template-stripSection{
width: 100%;
height: 133px;
position: relative;
background-size: cover;
background-position: center center;
background-repeat: no-repeat;
}
.template-stripSection:hover .field-cover {
  border: 3px solid #dad6d6;  
}

.template-logoText {
    font-size: 14px;
    height: 50px;
    line-height: 50px;
    float: left;
    min-width: 50px;
    width: 70px;
    /* overflow: hidden; */
    text-overflow: ellipsis;
    color: rgb(0, 0, 0);
    font-size: 14px;
    text-align: left;
    height: 51px;
    line-height: 30px;
}
.pass_layout .logo-section .hower-body {
    /* width: 50px; */
    margin: 0 0 -1px;
}
.grab-input .hower-body .drag-input.value {
    height: auto;
    min-width: 20px;
    /*max-width: 320px;*/
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 2px;
    /*padding-left: 2px;*/
}
.grab-input .hower-body .drag-input.label-value-pair.value {
    height: 30px;
}
.drag-input.label-value-pair.value{
    line-height: 10px;
}
.drag-input.label {
    font-weight: 600;
}
.logo-section .template-logoText {
    width: 82px;
}
.primary-field {
    height: inherit;
    margin: 0 10px;
}

.template-headerSection{
    position: relative;
    white-space: nowrap;
    height: 65px;
    width: 100%;
    padding: 1px 10px 5px 15px;
}
.pass_layout.ticket .template-headerSection.logo-section{
padding-top:5px;

}
.template-stripSection.with-thumb-image .primary-field.primary-section .field-cover {
    width: calc(100% - 86px);
} 
.template-logoText {
    font-size: 16px;
    text-align: left;
    line-height: 28px;
    padding-left: 5px;
    font-weight: 600;
}

.strip-input {
  text-align: right;   
}

.field-strip1{
    position: relative;
    line-height: 80px;
    font-weight: 100;
    font-size: 30px;
    /*text-align: center;*/
    white-space: nowrap;
    overflow: hidden;
    /* color:#ffffff; */
    outline: none !important;
    text-overflow: ellipsis;
}
.field-strip1:focus{
    overflow: visible;
}
.field-strip2:focus{
    overflow: visible;
}

.field-strip2{
white-space: nowrap;
overflow: hidden;
font-size: 14px;
/*font-weight: 300;*/
text-overflow: ellipsis;
text-transform: uppercase;
/*text-align: center;*/
margin-top: -10px;
outline: none !important;
line-height: 1.3;
}
.pass_layout.member-pass .template-stripSection {
    display: inline-block;
}
.field-strip1:hover{
background-color: rgba(255,255,0,0.5);
}

.field-cover{ 
 float: left;
 -moz-box-sizing: border-box;
box-sizing: border-box;
width: 100%;
height: inherit;
}
.primary-field.primary-section .field-cover{ 
   width: 100%;
   }

.pop-up-form .custom-errorMsg {
    color: #F16772;
    margin-bottom: 0;
    position: absolute;
    font-size: 13px;
    left: 140px;
    margin-top: 14px;
    /* top: 2px; */
}

.headeField{
height: 50px;
float: right;
display: inline-block;
position: relative;
}
.pop-up-main {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    text-align: center;
    background: #000000ab;
    z-index: 999999;
}
.pop-up-main .pop-up-wrapper .pop-up-form input {
    width: calc(100% - 110px);
    float: right;
    padding: 5px;
    margin-bottom: 15px;
    border: 1px solid #dadada;
}
.pop-up-main .pop-up-wrapper .pop-up-form textarea {
    width: calc(100% - 110px);
    border: 1px solid #dadada;
    padding: 5px;
    resize: none;
    float: right;
}
.pop-up-main .pop-up-wrapper .pop-up-form input[type="button"]{
    width: auto;
    display: inline;
    margin:0px 0px 0px 10px;
    padding: 11px 45px !important;
    line-height: 1;
    font-size: 16px;
}
.pop-up-main .pop-up-wrapper .pop-up-form > div {
    float: left;
    width: 100%;
    margin-bottom: 14px;
}
.pop-up-main.closed {
    display: none;
}
.pop-up-main .pop-up-wrapper {
    width: 700px;
    background: #fff;
    padding: 30px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50%;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.pop-up-main .pop-up-wrapper .pop-up-form input[value="Update"] {
    background: #00bcd4;
    border: 1px solid #00bcd4;
    color: #fff;
    padding: 6px;
    margin-bottom: 3px;
}
.pop-up-main .pop-up-wrapper .pop-up-form input[value="Cancel"] {
    background: #333333;
    border: 1px solid #333333;
    color: #fff;
    padding: 6px;
    margin-bottom: 3px;
}
.template-secondary{
height: 60px;
margin: 5px 13px; 
}

.template-info{
position: absolute;
bottom: 10px;
right: 8px; 
}
.pass_layout.punch-pass .template-headerSection {
    height: 65px;
    padding: 5px 10px 5px 15px;
}
.template-info-left{
position: absolute;
bottom: 10px;
left: 10px; 
}

.pass_layout.member-pass .template-headerSection {
    height: 65px;
    padding: 5px 10px 5px 15px;
}

.template-infoButton{
font-family: none;    
display: block;
width: 20px;
height: 20px;
font-size: 16px;
font-weight: 100;
line-height: 16px;
text-align: center;
text-decoration: none;
color: #000;
-webkit-border-radius: 10px;
-moz-border-radius: 10px;
border-radius: 10px;
border: 1px solid #000;
   /* border-top-color: rgb(255, 255, 255);
    border-right-color: rgb(255, 255, 255);
    border-bottom-color: rgb(255, 255, 255);
    border-left-color: rgb(255, 255, 255);*/
}

.template-barcodeSection{
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center; 
    
}

/*.droppable.headeField {
    width: 55px !important;
}*/
.template-barcode{
    display: inline-block;
    padding-top: 9px;
    padding-left: 9px;
    padding-right: 9px;
    padding-bottom: 4px;
    background-color: white;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;  
    margin-bottom: 19px;
    max-width: 262px;
}

.template-image{
 width: 100%;
 height: 50px;
 display: inline-block;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: auto auto;
    /*max-width: 282px;*/
}

.template-altText {
    font-size: 14px;
    line-height: 15px;
    color: #000;
    height: 16px;
    font-weight: 200;
    outline: none !important;
    /* max-width: 235px; */
    white-space: nowrap;
    /* overflow: hidden; */
    padding-top: 0px;
}
.template-image canvas.template-barcode-canvas {
    width: 100%;
}
.pass-container{
    position: relative;
    height: 500px;
    width: 345px;
}
.template-back{
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    height: 7px;
    min-height: 7px;
    background-size: 7px 7px,7px 7px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    bottom: -7px;
}
.template-background{
    position: absolute;
    top: 0;
    left: 0;
    width: 345px;
    height: 500px;
}

.template-backSection{
    position: absolute;
    left: 6px;
    top: 40px;
    width: 332px;
    height: 420px;
    background: #ffffff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 1px solid #c9c9c9;
    overflow-y: auto;
    padding: 14px;
}

.template-backSection .hower-body{
    max-width: 100%;
    border-bottom: 1px solid #ddd;
}

.template-backSection .hower-body:last-child{
    border-bottom: none;
}

.done{
    width: 50px;
    height: 30px;
    position: absolute;
    top: 5px;
    right: 7px;
    font-size: 15px;
    font-weight: normal;
    background: transparent;
    border: 0;
    color: #fff;
}
.close{
  display: 'none';
}

.drag-input-back-section{
margin: 0 0 13px 0;
color: #000000;
font-weight: 300;
font-size: 16px;
line-height: 20px;
word-wrap: break-word;
max-width: 266px;
background-color: transparent;
outline: none !important;
}

.hower-body-bottom{
 float: right;
 position: relative;

}
.drag-input-back-section span {
    width: 100%;
    float: left;
    padding: 2px 0;
}
.drag-input-back-section-label{
margin: 0 0 13px 0;
color: #7f7f7f;
font-weight: 300;
font-size: 13px;
line-height: 20px;
word-wrap: break-word;
max-width: 266px;
background-color: transparent;
outline: none !important;  
}

.closehandle{
display : none;    
position: absolute;
right: 0;
top: -13px;
width: 24px;
height: 12px;
overflow: hidden;
top: -11px;
background-color: green;
border-top: 1px solid #CCC;
border-left: 1px solid #CCC;
border-right: 1px solid #CCC;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
line-height: 10px;
text-align: center;
color: #fff;      
}

.label:hover{
 background-color: rgba(0,0,0,0);
}

.value:hover{
 background-color: rgba(255,255,0,0.5);
}

.cropper-bg{
  background-color: #ffffff !important;
  background-image:none !important  
}

.cropper-modal {
    opacity: 0;
    background-color: #ffffff!important;
}
.crop-box-button{
width: 100% !important;
float: right;
background-color: #ffffff;
padding: 13px;
}

.template-thumbnail{
position: absolute;
top: 75px;
right: 17px;
background-size: contain;
background-position: center;
background-repeat: no-repeat;
width: 70px;
height: 100px;
/* border: 4px solid #fff; */
/* border: 1px solid #00000024; */
background-size: cover;
}
.droppable.headeField .drag-input.label + .drag-input.value {
    line-height: 14px;
    padding-top: 1px;
    float: left;
}
.template-thumbnail.event{
    height: 93px;
    top:65px;
}
.field-strip1.primaryField-thumb-image {
    height: 35px!important;
    text-overflow: unset;
    overflow: hidden;
    white-space: normal;
    margin-top: 0px;
}
.droppable.template-secondary.ticket-pass {
    display: block;
    float: left;
    width: 100%;
    box-sizing: border-box;
    margin-left: 0;
    margin-right: 0;
    padding: 0 15px;
    margin-top: 4px;
}
.pass_layout.ticket.strip-image-fixed.ticket-without-thumbnail .d-flex.justify-content-between.bd-highlight.mb-3 {
    width: 100%;
}
.pass_layout.ticket.strip-image-fixed.ticket-without-thumbnail .droppable.template-secondary.ticket-pass {
    margin-top: 96px;
}
.pass_layout.ticket.strip-image-fixed.ticket-without-thumbnail .droppable.headeField {
    min-width: 40px;
}
.hower-bodys .drag-input.value, .ticket-with-thumbnail .grab-input  .hower-body .drag-input.value {
    white-space: initial;
    word-break: break-all;
    overflow: hidden;
    text-overflow: unset;
}
.pass_layout.ticket.ticket-with-strip .droppable.template-secondary-inner .d-flex.justify-content-between.bd-highlight.mb-3 {
    width: 100%;
}
.templates-list .pass_layout.ticket.ticket-with-strip .template-headerSection {
    padding-left: 5px;
    padding-right: 0;
}
.pass_layout.ticket.ticket-with-strip .template-headerSection .droppable.headeField {
    min-width: 50px;
}
.pass_layout.ticket.ticket-with-strip .droppable.template-secondary.ticket-pass {
    margin-top: 10px;
}
.react-card-front .pass_layout.ticket.ticket-with-strip .template-headerSection {
    padding-left: 8px;
    padding-right: 0;
}
.react-card-front .pass_layout.ticket-with-thumbnail .template-headerSection {
    padding-left: 15px;
}
.pass_layout.ticket.ticket-with-strip .droppable.headeField .d-flex.justify-content-between.bd-highlight.mb-3 {
    flex-direction: column;
}
.pass_layout.ticket.strip-image-fixed.ticket-without-thumbnail .template-headerSection.logo-section {
    padding-left: 8px;
}
.templates-list .ticket-with-strip .droppable.template-secondary.ticket-pass {
    margin-top: 10px;
}
.ticket-with-thumbnail .primary-field {
    margin: 0 10px 0 15px;          
}
.ticket-with-thumbnail .headeField {
    min-width: 51px;
}
.ticket-with-thumbnail .template-headerSection.logo-section {
    padding-left: 0;
    padding-right: 0;
}
.pass_layout.ticket.ticket-with-thumbnail .template-headerSection .template-logo {
    margin-right: 9px;
}
.droppable.template-secondary.ticket-pass .d-flex.justify-content-between.bd-highlight.mb-3 {
    width: calc(100% - 82px);
}
.template-stripSection .field-cover .field-strip2 {
    text-transform: uppercase;
}
.pass_layout.ticket.strip-image-fixed .template-stripSection .field-cover {
    padding-top: 13px;
}
.droppable.template-secondary.ticket-pass .mb-3 {
    margin: 0 !important;
}
.droppable.template-secondary.ticket-pass {
    margin-bottom: -1px;
}
.fullColumnWidthHeavyMachine, .fullColumnWidthAdr {
	max-height: 200px;
	overflow-y: scroll;
}
.template-headerseperator{
    margin-top: 2px;
    margin-bottom: 25px;
    /* height: 1px;
    margin: 0 15px 5px 15px;
    background: rgba(0,0,0,0.2);
    border-top: 1px solid rgba(0,0,0,0.1) !important;
    border-bottom: 1px solid rgba(255,255,255,0.25) !important; */
}
.droppable.template-auxiliary .hower-bodys {
    padding: 0;
}
.react-card-front .pass_layout.ticket.ticket-with-strip .hower-bodys .drag-input.value {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}
.template-auxiliary{
    position: relative;
    height: 40px;
    min-height: 1.2em;
    outline: 0;
    margin: 9px 15px 5px;
    clear: both;
}

.overflowEl{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.issuer-input{
 border: 1px solid #3d586f;
 width: 72%;
 min-height: 100px;   
 margin-left: 1%;
}

.hower-bodys {
   float:left; 
   position: relative;
   padding: 10px 0;
   overflow: hidden;
   /*text-overflow: ellipsis;*/
}
.pass_layout.generic-pass-layout.strip-image-fixed .grab-input .hower-body .drag-input.value {
    max-width: 170px;
}
 .droppable.headeField .headeFieldInner .hower-body .drag-input, .pass_layout .droppable.headeField .headeFieldInner .hower-body .drag-input + .drag-input.label-value-pair.value {
    max-width: 70px;
}
 .droppable.headeField .headeFieldInner .hower-body {
    max-width: 70px;
    text-overflow: ellipsis;
}
.ticket::before {
    content: "";
    position: absolute;
    width: 70px;
    height: 35px;
    background: #fff;
    z-index: 2;
    border-radius: 0 0 35px 35px;
    left: 50%;
    top: 0;
    margin: -18px 0 0 -35px;
}


.cancel{
 color: #000000;
border-color: #2196f3;
font-size: 19px;
height: 46px;
box-shadow: none;
cursor: pointer;
outline: none;
border: none;
border-radius: 4px;
box-shadow: 0 0 10px 1px #B0B0B0;
transition: all 0.5s ease;
-webkit-transition: all 0.5s ease;
}


.submit_btn_cancel {
    color: #000000;
    background-color: transparent;
    width: 10%;
    float: left;
    padding: 1px;
    margin-top: 6%;
}
.react-card-front .strip-image-fixed .template-secondary.ticket-pass {
    margin-top: 110px;
}
.form_date_picker
{
  margin-left:14px;
 }   
.stop_label {
    margin-left: 0;
    margin-bottom: 15px;
    width: 100%;
    padding-left: 0;
}
._sectionIsfalse {
    display: none;
}
.stop_label svg.issue-date, .form_date_picker svg.issue-date, .form_date_picker_sec .issue-date{
    width: 20px !important;
    height: 20px !important;
}
.text-in-box-replicated span {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    float: left;
    text-overflow: ellipsis;
    line-height: 1;
}
.droppable.headeField{
    width: 70px !important;
    /* overflow: hidden !important; */
}
.restriction .container {
    padding-left: 0px;
}
.form_date_picker, .form_date_picker_sec {
    margin-left: 0;
    margin-bottom: 15px;
    width: 100%;
    padding-left: 0;
}

div#root form .row.valid-from-to{
    margin: 0 auto !important;
    padding: 0px !important;
}
.blank-hr-section {
    padding-bottom: 1rem;
}
.submit_btn_cancel a.cancel {
    width: 129px;
    background: #ff0000;
    color: #fff;
    line-height: 33px;
}
.submit_btn_cancel {
    width: auto;
    margin-top: 0%;
}
.render-checkboxes {
	width: 100%;
    float: left;
    max-width: 900px;
    clear: both;
    padding: 10px 20px;
    border-radius: 5px;
    background: #FFF;
}
.render-checkboxes:nth-child(2n + 2)
{
    background: #eee;
}
.render-checkboxes input, .render-checkboxes span {
	width: auto;
	display: inline-block;
    float: left; 
    font-size: 17px;
}
.render-checkboxes span{
    width: 85%;
}
.redeemblock-class-false{
    display:none;
}
.render-checkboxes input {
    float: right;
    margin-top: 8px;
}
.redeemblock-class-true {
    float: left;
    width: 100%;
    margin: 15px 0px;
}
.maincontent {

    float: left;
}
.allowed {
    margin-left: 0;
    margin-top: 0%;
}
.allowed label.container {
    padding: 0;
    background: #eee;
    float: left;
    padding: 15px;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column-reverse;
}
.select_subscription .modal-dialog .modal-content.bg_color_section {
    border-radius: 20px;
}


.self-redeem-tabledata .ReactTable.-striped.-highlight .rt-tr-group .rt-td {
    max-height: 110px;
    overflow-y: auto;
}





.data-text-here {
    background: #cbcbcb;
    float: left;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    padding: 5px 10px;
}
.ReactTable .rt-thead .rt-resizable-header:last-child {
    text-align: center!important;
}
.data-text-here p{
    float: left;
    width: auto;
    display:inline-block;
    margin-bottom:0px;
}
.data-text-here  input {
    float: left;
    display: inline-block;
    margin-top: 8px;
    margin-right: 15px;
    
}
div#address-field {
    float: left;
    width: 100%;
}
.allowed {
    
    margin-top: 3%;
    float: left;
    width: 98%;
}

.all-templates-passes{
    width: 100%;
}

.download-pkpass {
    display: block;
    float: left;
    width: 100%;
    text-align: center;
    padding-top: 32px;
}

.download-pkpass button {
    background-color: rgb(0, 188, 212);
    color: #fff;
    border: 0px;
    padding: 11px 15px;
    border-radius: 5px;
    cursor: pointer;
}

a.download-qrcode {
    background-color: rgb(0, 188, 212);
    color: #fff;
    border: 0px;
    padding: 11px 15px;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    text-align: center;
    max-width: 190px;
    margin-bottom: 15px;
}
.pass_layout.generic-pass-layout::before, .template-background.generic-pass-layout::before {
	content: "";
	background: url("../images/top.png");
	position: absolute;
	top: auto;
	width: 100%;
	left: 0;
	height: 9px;
	background-repeat: no-repeat;
	background-position: left;
    bottom: -1px;
    background-size: 100%;
    margin:0 ;
    border-radius:0 !important;
}
.pass_layout.generic-pass-layout:after, .template-background.generic-pass-layout::after
{
    content:"";
    background:url("../images/down.png");
    position: absolute;
    bottom: auto;
    width: 100%;
    left: 0;
    height: 9px;
    background-repeat: no-repeat;
    background-position: left;  
    top:-2px;
    background-size:100%;
}

.field-strip1:hover {
    background-color: transparent;
}

.pass_layout .hower-body 
{
   /* width: 100%;*/
   width: auto;
}

/* .drag-input.label, .drag-input.value{    text-align: right!important;} */

.template-logoText {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: auto;
    margin-left:3px;
    float:right;
}
.pass_layout.member-pass.member-pass-layout.member-with-logo.has-logo-image .template-headerSection.logo-section .template-logoText {
    float: left;
}
.pass_layout.member-pass.member-pass-layout.member-with-logo.has-logo-image .template-headerSection.logo-section .template-logoText .grab-input {
    width: 100%;
}
.hower-body {
    max-width: 320px;
    width: 100%;
    /*border-bottom: 1px solid #ddd;*/
    margin-bottom: 10px;
}
.drag-input-back-section-label {
    margin: 0 0 5px 0;
}
.pass_layout.generic-pass-layout{
	height: 450px;
    padding-top: 7px;
    border-radius:0 !important;
}
.without-strip-image section {
    position: relative;
    background: lightgray;
    border-color: #aaaaaa;
}
.without-strip-image section > div:after {
    background: transparent;
    content: "";
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
}
.without-strip-image section > div {
    cursor: not-allowed;
}

.valid-from-to input {
    width: 100%;
}

.req_payment span.checkmark {
    left: 0;
    margin: 0;
}

.returning-user {
    width: 100%;
    padding: 3px;
    margin-top: 2px;
}

.template-headerSection.logo-section {
    padding: 9px 11px;
}
.primary-field.primary-section {
    margin-top: 9px;
}
.submit_btn .btn:hover {
    background: #000;
}

.submit_btn .btn {
    background: #2196f3;
    height: 46px;
    color: #fff;
    font-size: 19px;
    box-shadow: 0 0 10px 1px #b0b0b0;
}

.value.the_link ,.drag-input-back-section.the_link{
    color: #004ecc !important;
    text-decoration: underline;
    cursor: pointer;
}
.template-logo img {

    max-width: auto;
    max-width: 135px;
        height: 100%;
}
span.aaw {
    width: 136px;
    height: 29px;
    float: left;
    line-height: 28px;
    font-size: 15px;
    padding: 0px 5px;
    margin-left: 3px;
    border: 1px solid;
}
/*-- 21-06-2019 --*/

.droppable.template-secondary.secondary-section {
    margin-top: 10px;
}
.template-thumbnail {
    height: 90px;
}
.required{
    color:red
}
.input-group-addon{
    width: 1.0rem;
}

.sign-in {
	width: 20%;
	height: 20%;
	
	position: absolute;
	top:0;
	bottom: 0;
	left: 0;
	right: 0;
    text-align: center;
	margin: auto;
}

.under-line{
  text-decoration: underline;
}
.maincontent.sign-up{
    max-width: 700px;
    margin: 0 auto;
    display: table;
    width: auto;
    text-align: center;
    float: none;
    background: #f2f2f2;
    margin-top: 20px;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.sign-up-form form .input-group-addon {
    float: right;
    width: auto;
    text-align: right;
    position: absolute;
    right: 5px;
    top: 8px;
}
.sign-up-form form label {
    width: auto;
    float: right;
    padding-top: 9px;
}
.sign-up-form  .field-label-responsive {
    position: relative;
}
.sign-up-form .text-danger {
    font-size: 14px;
}
div#root .sign-up-form .row {
    margin-top: 0;
}
div#root .sign-up-form .form-control-feedback {
    text-align: left;
}
div#root .sign-up-form .row input {
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 0;
    padding-left: 0;
    font-size: 14px;
    background: transparent;
}
div#root .sign-up-form .btn.btn-default {
    border: 1px solid #222;
    padding: 6px 20px;
}
.row.sign-checkbox .checkbox-inout.col-md-4 {
	padding: 0;
	padding-top: 13px;
}
.row.sign-checkbox .form-group {
	text-align: right;
	float: right;
}
.row.sign-checkbox .checkbox-content label {
	text-align: left !important;
	width: 100%;
	text-transform: capitalize;
	font-size: 18px;
}
.iconSignin{
    background: rgb(255, 64, 129) none repeat scroll 0% 0%;
    padding: 41px 0px 0px 0px;
    border-radius: 40px;
}
.fst_intro_tp h2 {
    clear: both;
    font-size: 30px;
    padding: 50px 0px;
    margin: 0;
}
.fst_intro li {
		    display: inline;
    color: #f3160f;
    cursor: pointer;

}
.fst_intro li:first-child {
    padding-right: 40px;
}
.fst_intro li a {
    padding: 0px 2px;
}
.fst_intro_tp {
    padding: 0px 20px;
}
.intro_page_main_ft {
    border-bottom: 2px solid #000;
}
section.fst_intro{border-bottom:2px solid #000;}
.fst_intro_tp h2 {
    clear: both;
    font-size: 30px;
    padding: 25px 0px 90px;
    margin: 0;
}
.fst_intro ul {
    padding-top: 25px;
}
section.fst_intro.sc_intro h2.text-center {
    padding: 200px 0px;
}

section.passes_main {
    padding: 30px 20px;
}
section.passes_main h2 {
    font-size: 30px;
    margin-bottom: 50px;
}
.passes_tk_inner.text-center h3 {
    font-size: 25px;
    margin: 10px 0px;
    font-weight: 400;
}
section.mobile_android {
    padding: 20px 90px;
}
.abt_txt_bt {
    position: absolute;
    bottom: 0;
    width: 100%;
}
.app_icons {
    position: absolute;
    bottom: 0;
}
section.accordian_main_sec {
    padding: 20px 90px;
}

section.accordian_main_sec .col-md-6 h2 {
    font-size: 25px;
    padding: 15px;
    background: #f1f1f1;
	text-align:center;
}

.inner_ac_sec {
    border: 1px solid #ccc;
}

.panel-heading a {
    color: #000;
}

.accordian_inner h4.panel-title {
    font-size: 20px;
    margin: 0px;
    padding: 13px 40px;
}

.accordian_inner .panel-heading {
    border-bottom: 1px solid #f1f1;
}

.accordian_inner .panel-body {
    padding: 13px 40px;
}

section.why_ch {
    padding: 15px 90px;
}
.why_ch h2 {
    font-size: 30px;
    margin-bottom: 20px;
}

.why_ch li {
    line-height: 35px;
}

section.footer {
    padding: 0px 90px;
}

.footer p {
    color: #f3160f;
    margin: 0px!important;
}
.footer li {
display:inline;
color:#000
}
.footer li>a{
color:#000;
    padding: 0px 5px;
}

.accordian_inner .panel-heading a {
color: #000;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
}

.accordian_inner a.collapsed:before {
    content: "\f107";
	float:right;
}
.accordian_inner a:before{content: "\f106";
	float:right;}
	.passes_tk_inner.text-center img {
    width: 100%;
    max-width: 100%;
    height: 563px;
    object-fit: contain;
}
.app_txt{max-width: 100%;}
.show-bar{
    width: auto;
    float: left;
    margin-top: 5px;
    margin-right: 10px;
}



/*issuer backend*/
.issuer-sec {
 text-align: center;
}

.logBtn {
   float :left;
}
.border-less td {
    border-left: none;
    border-right: none;
}

.head-link > a {
    color:red;
    text-decoration: underline;
}

.head-link {
   margin-left: 2%;
}
.content-end {
    width:100%;
    text-align:end;
}

.center-content{text-align: center;
width: 100%;
}
.primary-content{
  margin:3%
}

.main-first {
 width:73%
}


.table-head{
    margin-top: 2%;
}

.content-left{
    float:left;
   
}
.content-right{
    float:right;
    
}
/* accordion */
  
  .answer .summary {
    display: inline-block;
  }
  
  .answer {
    background: #c6e5c6;
    color: #323b48;
  }
  
  .summary {
    font-family: 'Josefin Slab', serif;
    text-transform: uppercase;
    cursor: pointer;
    line-height: 25px;
    padding: 15px;
   
  }
  
  .folding-pannel {
    display: block;
    transition: all 0.2s ease-in;
    line-height: 40px;
    border-top: 2px solid black;
  }
  
  .active .summary {
    border-color: transparent;
    transition: border-color 0.2s ease-out;
  }
  
  .inactive .folding-pannel {
    transform-origin: 50% 0%;
    transform: perspective(250px) rotateX(-90deg);
    transition: all 0.4s ease-in-out;
    height: 0;
  
  }
  .active .folding-pannel {
    transform: perspective(350px) rotateX(0deg);
    transition: all 0.4s ease-in-out;
    height: 50px;
    line-height: 50px;
    text-indent: 40px;
  }
  .active-head {
    background: #76da53 !important;
  }
 .arrow-button {
      float:right;
      margin-right:1%;
  }
  /* accordion */

 /* payment pop up */
  .model-title {
   text-align: center;
   width:100%
  }
  .issuer-subinfo {
   width: 50%;

  }
  .issuer-payment-info {
  
    width: 50%;
  }
  .col-margin{
    padding-top:1%
  }
  .divPad input {
    border: 1px solid hsl(0,0%,80%);
    border-radius: 3px;
    padding: 6px;
    width: 100%;
}
.aloha-sidebar-inner select {
    width: 100%;
    padding: 3px;
    font-size: 14px;
}
.aloha-sidebar-inner .aloha-block-editor {
    margin-bottom: 10px;
}
.factory-container {
    height: auto;
    padding: 7px;
    margin: 30px 10px 10px 0px;
}
.row .passFeatures {
    margin-top: 7px;
}
.issuer-input input[type="text"] {
    border: 1px solid hsl(0,0%,80%);
    border-radius: 3px;
    padding: 6px;
    width: 100%;
}
.issuer-input input[type="checkbox"] {
    text-align: left;
    width: auto!important;
    margin-left: 12px;
}
.issuer-delete.col svg{
display: inline-block;
color: #ff0000!important;
fill: currentcolor;
height: 30px!important;
width: 30px!important;
user-select: none;
transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
float: right;
margin-top: -11%;
margin-right: 31%;
background: #e1e1e1;
border-radius: 50%;
padding: 5px!important;
}
button.btn.btn-default {
    background: rgb(0, 188, 212);
    color: #fff;
    padding: 8px 25px;
    font-size: 17px;
    text-transform: uppercase;
}
.issuer-input > div {
    padding: 10px 0 0;
}
.passFeatures select {
    width: 100%;
    padding: 5px;
}
.passFeatures-list {

    width: 100%;
    margin-bottom: 13px;
}
.passFeatures-list input[type="color"] {
    width: 100%;
    margin-top: 5px;
}
.passFeatures-list + .container {
    padding-left: 0;
}
.restriction .row .col-sm-12 {
    padding-right: 0;
    position: static;
}
.row.valid-from-to input[type="date"] {
    padding: 6px;
    border: 1px solid #d3d3d3;
}
div#root form .row.valid-from-to .col-sm-6 {
    padding-right: 0;
}
div#root form .row.valid-from-to svg.issue-date {
    float: left;
    margin-right: 4px;
}
div#root form .row.restriction-returning-user {
    padding: 10px 0!important;
}
.restriction-returning-user select.returning-user {
    padding: 6px;
}
label.col-sm-6.container.req_payment span.checkmark {
    left: auto;
    right: 0;
}
.component-slider .slider-container {
    overflow-x: auto !important;
    width: 100%;
    margin: 0 !important;
    overflow-y: hidden !important;
}
.menu-bar .slider-container .menu-item {
    min-height: 530px;
}
.payment-inside .row select {
    width: 100%;
    padding: 6px;
}.payment-inside .row input[type="Number"] {
    width: 100%;
    padding: 4px;
}
.payment-inside .row {
    width: auto!important;
    float: none!important;
}
.payment-inside {
    float: left;
    width: 100%;
}
.payment-inside .row input[type="Number"] + .errorMsg {
    margin: 0;
}
div#root form .payment-inside .row {
    width: auto!important;
    float: none!important;
    padding: 10px 0px 10px 10px !important;
}
.errorMsg {
    margin-bottom: 0!important;
    float: left;
    width: 100%;
    font-size: 14px;
}
.address input#add {
    padding: 6px;
}
.strip-image-fixed .template-secondary {
    margin-top: 140px;
}
.strip-image-fixed .template-stripSection {
    position: fixed;
}
.passFeatures-list section.passFeature-section .passFeature-delete-icon {
    position: absolute;
    right: 0;
    top: 0;
}
.passFeatures-list section.passFeature-section {
    position: relative;
}
.showShadow{
    box-shadow: 0 0 3px rgb(218,214,214);
    }
.same_profile_tab_style p.success {
    margin: 2px;
}
.my_pass_main_section.slider_with_pending_temp .order_pass.ordered_pending_passes {
    cursor: default !important;
    margin-right: 29px;
}
.my_pass_main_section.slider_with_pending_temp .order_pass.ordered_pending_passes .add_order {
    cursor: default !important;
}
.add_order_disable {
    cursor: not-allowed !important;
}
/*-------------------------------------------------------------------------------------------------*/

.tollTipMainSection {
    position: relative;
    display: inline-block;
    z-index: 99;
}
.tollTipTextSection {
    position: absolute;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
    font-size: 10.15px;
    line-height: 12px;
    max-width: 200px;
    width: 200px;
    text-align: center;
    top: 20px;
    left: 0px;
    background: rgb(87, 192, 217);
    border-radius: 10px;
    padding: 10px 10px;
    font-family: 'Museo Sans 500';
}
.tollTipTextSection .tollTipSubTextSection{
    display: block;
    font-size: 10.15px;
    margin-top: 0px;
    white-space: nowrap;
    font-weight: 300;
    margin-left: -5px;
}
div#toolTip_4.tollTipTextSection {
    max-width: 300px;
    width: 300px;
    padding: 10px 25px;
}
.tollTipTrigger {
	width: 12px;
	height: 12px;
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
	transform: translateY(-15px);
	margin-left: 4px;
	cursor: pointer;
	float: left;
}
#subscription .subscription_table table tbody .table-price td {
	text-align: left;
}
.pachageArea {
    width: 100%;
    margin-bottom: 55px;
    padding-bottom: 55px;
    border-width: 4px;
    border-style: solid;
    border-color: rgb(228, 224, 208);
    border-image: initial;
    border-radius: 16px;
}
.pachageArea h2 {
    font-size: 30px;
    color: rgb(27, 63, 113);
    font-weight: 700;
    line-height: 1;
    padding: 40px 20px;
}
.pachageArea .AreaFull {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 18px;
    color: rgb(27, 63, 113);
    font-weight: 300;
    background: rgb(228, 224, 208);
    padding: 15px 13px;
}
.pachageArea .AreaFull {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 18px;
    color: rgb(27, 63, 113);
    font-weight: 300;
    background: rgb(228, 224, 208);
    padding: 15px 13px;
}
.pachageArea .AreaFull.whiteBG {
    background: rgb(253, 251, 239);
}
.terms_condition_container{
    padding-left: 100px;
    padding-right: 100px;
}
.terms_condition_container h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 60px;
    letter-spacing: 0.03em;
    color: #1B3F71;
    margin-bottom: 30px;
}
.terms_condition_container h2{
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 1;
    color: #061F39;
    margin-bottom: 15px;
    font-family: 'Museo Sans 300';
}

.terms_condition_container h3 {
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 25px;
    color: #061F39;
    margin-bottom: 60px;
}
.terms_condition_container p{
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #061F39;
    margin-bottom: 45px;
}
.time_status_of_passses .status_graph {
min-height: 450px;
}


.my_scanner_page .my_scanner .nav.nav-tabs li{
    padding-right: 140px;
}
.userPermission .modal-dialog .modal-content .modal-body .user_permissions .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
 .userPermission .modal-dialog .modal-content .modal-body .user_permissions .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
.userPermission .modal-dialog .modal-content .modal-body .user_permissions  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
 .userPermission .modal-dialog .modal-content .modal-body .user_permissions .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
 .userPermission .modal-dialog .modal-content .modal-body .user_permissions input:checked + .slider {
    background-color: #14bd0a;
  }
  
 .userPermission .modal-dialog .modal-content .modal-body .user_permissions input:focus + .slider {
    box-shadow: 0 0 1px #14bd0a;
  }
  
.userPermission .modal-dialog .modal-content .modal-body .user_permissions input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
.userPermission .modal-dialog .modal-content .modal-body .user_permissions .slider.round {
    border-radius: 34px;
  }
  
.userPermission .modal-dialog .modal-content .modal-body .user_permissions .slider.round:before {
    border-radius: 50%;
  }
/* --------------------------------------------------------------------*/


   /* payment pop up */
/* issuer backend Media*/
@media screen and (max-width: 974px) {
    .sign-in {
        width: 31%;
        height: auto;
    }
    .my_scanner_page .my_scanner .nav.nav-tabs li{
        padding-right: 100px;
    }
  }

@media screen and (max-width: 600px) {
    .sign-in {
     width:85%;
     height: auto;
    }
    .my_scanner_page .my_scanner .nav.nav-tabs li{
        padding-right: 55px;
    }
  }

  /* ss */
  .template-infoButton , .template-header .done{
      cursor: pointer;
  }
 /* END ss */
 .maincontent{
     width:100%;
 }
 .buttonArea {
    width: 100%;
    float:left;
}
.buttonArea a, .buttonsMain button {
    background:rgb(0, 188, 212);
    color: #FFF;
    padding: 10px 25px;
    margin-bottom: 10px;
    float: left;
    border: 0 none;
    cursor: pointer;
}
.buttonsMain button svg{
    fill:#FFF !important;
}
.headingArea {
    float: left;
    text-align: center;
    width: 100%;
}
.headingArea h5 {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 20px;
}
.partfull, .template-styles .template-list {
    float: left;
    width: 100%;
    border-bottom: 1px solid #eee;
    padding: 10px 0px;
    display: flex;
    justify-items: center;
    align-items: center;
}    
.omr_main {
    clear: both;
    text-align: center;
    width: 100%;
}
.omr_main a.download-qrcode {
    margin: 0 auto 10px;
    text-decoration: none;
}
.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
    padding: 10px;
}
.ReactTable .-pagination .-btn[disabled] {
    background: rgb(0, 188, 212);
    color: #fff;
}
.ReactTable .-pagination .-btn {
    color: #333!important;
    background:rgba(0,0,0,0.3)!important;
}
.ReactTable .-pagination .-btn:hover {
    color: #fff!important;
    background: rgb(0, 188, 212)!important;
}
div#google-map-holder div#address-field label, div#google-map-holder .address {
    float: left;
}
div#google-map-holder {
    clear: both;
}
.location .location-list .allowed {
    width: 100%;
    border-top: 1px solid #dadada;
    padding-top: 25px;
    margin-top: 35px;
}
.slider-container .menu-item .templates-list {
    float: left;
    width: 100%;
}
.download-pkpass button {
    float: right;
    margin-right: 135px;
}
.download-pkpass .btn {
    background: rgb(0, 188, 212);
    border-color: rgb(0, 188, 212);
    padding: 8px 30px;
    float: right;
    margin-right: 140px;
}
.all-templates-passes .row {
    width:auto;
}
.pass_layout.ticket.ticket-with-thumbnail .template-stripSection .field-strip1 {
    text-overflow: unset;
    white-space: normal;
    word-break: break-all;
    overflow: visible;
}
.droppable.template-secondary.ticket-pass .hower-bodys {
    line-height: 1;
}    
.pass_layout.ticket.ticket-with-thumbnail .template-logoText {
    margin: 0;
    padding: 0;
}
.pass_layout.ticket.ticket-with-thumbnail .template-thumbnail {
    top: 67px;
}
.pass_layout.ticket-with-thumbnail .template-headerSection {
    padding-right: 0;
}
.location {
    margin-top: 30px;
    display: flex;
    margin-bottom: 30px;
}
.location .location-list .temp-allowed-locations {
    background: rgb(234, 234, 234);
    padding: 10px;
    float: left;
    width: 100%;
}
.location .location-list .allowed > div {
    background: rgb(234, 234, 234);
    float: left;
    width: 100%;
    padding: 10px;
}
.data-text-here p {
    font-size: 20px;
}
div#root .divStyle#map_canvas {
    height: 100%!important;
    margin-top: 0;
}
.location .location-list {
    float: left;
    width: 50%;
    margin-right: 3%;
    border: 1px solid #f3f3f3;
    padding: 15px;
}
.location div#map_canvas {
    width: 47%;
    float: left;
    background: #f3f3f3;
    padding: 15px;
}
[type=reset], [type=submit], button, html [type=button] {
    -webkit-appearance: button;
}
.drop_img section button {
    position: absolute;
    right: 0;
    padding: 0 4px;
}
.drop_img section {
    position: relative;
}
.drop_sec {
    float: left;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
}
div#map_canvas div#google-map-holder {
    clear: both;
}
.drop_img {
    float: left;
    width: 30%;
    margin-right: 3%;
}
.ReactTable .rt-thead .rt-tr .rt-th.my-favorites-column-header-group {
    width: 100%!important;
    background: #333;
    color: #fff;
    padding: 15px;
    font-size: 21px;
}
.ReactTable.-striped.-highlight .rt-th.rt-resizable-header.-cursor-pointer {
    padding: 15px 6px;
    background: rgba(0, 188, 212, 0.79);
    color: #fff;
    font-size: 19px;
}
.ReactTable.-striped.-highlight {
    margin-bottom: 60px;
}
.bg_color_section.block_element.main_logo_heading_padding.my_pass_templates.my_profile_section.padding_container h3 {
	font-size: 40px;
	font-family: 'Museo Sans 100';
}
.rt-td .pad {
    margin: 0 5px;
}
.ReactTable .rt-th, .ReactTable .rt-td {
    padding: 11px 10px!important;
}
.partfull:nth-child(2n), .template-styles .template-list:nth-child(2n){
    background:rgba(0, 0, 0, 0.02);
}
.partfull .labelMain, .partfull .labelsub, .template-styles .template-list .labelMain, .template-styles .template-list .labelsub {
    float: left;
    width: 50%;
}
.grab-input .hower-body .drag-input.value:focus, .grab-input .hower-body .drag-input.value:hover {
    overflow: visible;
    text-overflow: unset;
    display: inline-block;
    left: 0px;
    padding-left: 0px;
}
.grab-input .hower-body .drag-input.value {
    height: auto;
    min-width: 20px;
    max-width: 320px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 2px;
    padding-left: 2px;
    display: inline-block;
}
.template-styles ~ .menu-item {
    padding-left: 2%;
}
.template-styles {
    border: 1px solid #dadada;
    padding: 20px;
}
.qrcodearea .QrImage {
    border: 1px solid #eee;
    width: 100%;
    float: left;
}
.pass_layout.generic-pass-layout .template-headerSection .hower-body {
    width: auto;
    max-width: 170px;
}
/* .ReactTable .rt-tbody .rt-td button.pad + button.pad svg {
    fill: #fff!important;
} */
.ReactTable .rt-thead.-header {
    background: rgb(0, 188, 212);
    color: #fff;
    padding: 3px;
}
.template-back .template-backSection {
    overflow-y: auto;
}
.qrcodearea .QrImage img {
    max-width: 100%;
}
a.linkmain {
    padding: 10px 0;
    float: left;
    color: #545454;
    width: 100%;
    word-break: break-all;
}
.buttonsMain button.pad.deletebtn {
    margin: 0 auto;
    margin-left: 20px;
    padding: 9px 20px;
    line-height: 1;
}
.pop-up-main .pop-up-wrapper svg {
    position: absolute;
    right: 0;
    top: 0;
    fill: #fff!important;
    background: #f00!important;
   
}

p.back-section-label, .back-section-value{
    color: #7f7f7f;
}

.pass_layout.ticket-with-strip .template-info {
    right: 10px;
}
.main_distribution_btn .pass_limit_reached {
    background: #dadada!important;
    border-color: #d6d6d6!important;
    cursor: not-allowed;
}
.main_distribution_btn .pass_limit_reached:hover, .main_distribution_btn .pass_limit_reached.choosefilebtn:hover {
	color: #061f39;
}

/* / 30-08-19 / */
.ReactTable .rt-noData {
    top: 66% !important;
    background: transparent !important;
    padding: 0 !important;
}
.all-templates-passes .tooltip .tooltiptext {
    left: 0;
    top: auto;
    bottom: -31px;
}
.all-templates-passes .rt-td .tooltip:last-child span.tooltiptext {
    left: -53px;
    width: 140px;
}
.ScannerList table{
    overflow-x: auto !important;
    max-width: inherit !important;
    display: block !important;
}
.ScannerList table .column-undefined a {
    min-width: 120px!important;
}
.drag-input.label,.drag-input-back-section-label{
    text-transform: uppercase;
}
.strip-image-fixed .template-stripSection {
    margin-top: 1px;
}
.pass_layout.ticket-with-thumbnail .template-headerSection, .pass_layout.ticket-with-strip .template-headerSection
{
    height: 65px;
    padding-top: 5px;
}
.pass_layout.ticket-with-strip .template-stripSection .primary-field {
    padding-top: 10px;
}


.specific-day .css-1pcexqc-container .css-bg1rzq-control, .specific-day  .css-1szy77t-control{
width: 100%;
}
.specific-time .css-1pcexqc-container {
    width: 50%;
    float: left;
}
.restriction .row .col-sm-12 {
    padding-left: 0;
}
.specific-time .from-time-range {
    width: 50%;
    padding-right: 2%;
    float: left;
}
.specific-time .from-time-range:nth-child(4) {
    padding-left: 2%;
    padding-right: 0;
}

.css-kj6f9i-menu{
    position: absolute;
    top:100%;
    left:0;
    width:100%;
}

.sync-button {
    float: right;
}
.all-templates-tbl-custom .rt-td .tooltip:nth-child(2) span.tooltiptext {
    left: -10px;
}
.all-templates-tbl-custom .rt-td .tooltip:nth-child(3) span.tooltiptext {
    left: -76px;
}
.all-templates-passes .tooltip .tooltiptext {
    padding: 8px;
    font-size: 12px;
}
.valid-from-to input[type="checkbox"] {
    left: 0;
}
p.back-section-label, .back-section-value,.drag-input-back-section-label,.drag-input-back-section {
    color: #474747;
    word-break: break-word;
    white-space: normal;
    max-width: 300px;
}
.template-backSection {
    overflow-y: initial;
}
.template-backSection p.back-section-value {
    padding-bottom: 15px;
    border-bottom: 1px solid #ccc;
}
.self-redeem-tabledata.no-Data .ReactTable .rt-noData {
    top: 62% !important;
}
.self-redeem-tabledata.no-Data .pagination-bottom {
    margin-top: 50px;
}
.maincontent.all-templates-passes {
    text-align: left;
}
.create-redeem-code-btn a.btn.btn-primary {
    margin-bottom: 10px;
}
.create-redeem-code-btn {
    float: right;
    text-align: right;
    padding: 2% 2% 10px;
}
.react-search-field.test-class {
    float: right;
    width: 100%;
}
.maincontent.all-templates-passes h3 {
    font-size: 24px;
    font-weight: normal;
}
.searchInputTxtSection .react-search-field {
    max-width: 500px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 4px;
}
.searchInputTxtSection {
    float: right !important;
    padding: 0 !important;
    text-align: right;
    width: 100% !important;
}
.maincontent.all-templates-passes .sync-button {
    margin-bottom: 10px;
    
}
.searchInputTxtSection .react-search-field button.react-search-field-button {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.template-backSection > div > div:last-child p.back-section-value {
    border: none;
}
.component-slider .caret {
    background: #00000085 !important;
    height: 70px  !important;
    width: 50px  !important;
    color: #ffffff !important;
    z-index: 9999!important;
    border-radius: 5px;
}
.component-slider .caret span{
    color: #ffffff !important;
}
.component-slider {
    /* background: #edecec; */
    border-radius: 5px;
}
.component-slider .pass_layout:hover{
    border: rgb(95, 188, 212) 2px solid;
}
.ticket-with-thumbnail .headeField .d-flex.justify-content-between.bd-highlight.mb-3 {
    flex-direction: column;
}
.positioning-id{
    display: none;
}
.hower-body:hover .positioning-id {
    display: block;
}
.positioning-id input[type="number"] {
    display: inline-block;
    width: calc(100% - 170px);
    float: right;
    margin-left: 14px;
}
/* 2-12 */
.template-logoText{
  margin-left: 0;
  padding-left: 0;
}
.template-headerSection {
    padding: 1px 10px 5px 10px;
}
/* .generic-with-logo .template-logoText,.punch-with-logo  .template-logoText,.ticket-with-logo  .template-logoText,.member-with-logo  .template-logoText,.reward-with-logo .template-logoText{
    margin-left: 3px;
    padding-left: 5px;
} */
.has-logo-image .template-logoText{
    margin-left: 3px;
    padding-left: 5px;
}
.pass_layout.punch-pass .template-headerSection,.pass_layout.member-pass .template-headerSection {
    padding: 5px 10px 5px 10px;
}
.pass_layout .logo-section .hower-body{
    width: 100%;
}
.pass_layout .logo-section .hower-body .drag-input.value,.pass_layout.member-pass .headeFieldInner  .drag-input.value{
    width: 100%;
}
/*.pass_layout .headeFieldInner .drag-input.value {
    width: 100%;
}*/
.reward-pass .template-headerSection.logo-section {
    padding: 9px 10px;
}
.member-pass .template-thumbnail img.template-thumbnail-img-inner {
    width: 100%;
    height: 40px;
}
.member-pass .template-thumbnail {
    height: auto;
    width: 70px;
    top: 66px;
    right: 10px;
}
.member-pass .template-thumbnail.portrait-ththumbnail img.template-thumbnail-img-inner {
    height: 70px;
}
.member-pass .template-thumbnail.portrait-ththumbnail {
    width: 45px;
}
.member-pass  .template-thumbnail.square-ththumbnail img.template-thumbnail-img-inner {
    height: 50px;
}
.member-pass  .template-thumbnail.square-ththumbnail{
    width: 50px;
}
.pass_layout.ticket.strip-image-fixed .template-stripSection .field-cover {
    padding: 10px;
    height: 90px;
}
.react-card-front .pass_layout.ticket-with-thumbnail .template-headerSection {
    padding-left: 10px;
    padding-right: 10px;
}
.react-card-front .pass_layout.ticket.ticket-with-strip .template-headerSection {
    padding-left: 10px;
    padding-right: 10px;
}
.pass_layout.ticket .template-headerSection .ticketCircleImgMain {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    top: -11px;
}
.pass_layout.ticket .template-headerSection{
    position: relative;
}
.ticket::before{
    display: none;
}
/* .all-templates-passes .pass_layout.ticket .template-headerSection .ticketCircleImgMain {
    top: -6px;
} */
.pass_layout.ticket .template-headerSection .ticketCircleImgMain {
    top: -11px;
}
.floating-label_group input:not(:placeholder-shown) ~ label {
    top: -46px;
    font-size: 20px;
    color: #97937f;
    font-family: 'Museo Sans 300';
}
/*5-12-19*/

.pass_layout.ticket.ticket-with-thumbnail .template-stripSection .field-strip1 {
    line-height: 1.3!important;
}
.pass_layout.ticket.ticket-with-thumbnail .template-altText, .pass_layout.member-pass .template-altText, .pass_layout.punch-pass .template-altText, .pass_layout.generic-pass-layout.generic-with-logo .template-altText{
    padding-top: 3px;
}
.my_pass_templates .order_pass p, .my_scanner .order_scanner p {
	font-size: 24px;
	line-height: 30px;
    font-family: 'Museo Sans 300';
    color:#061F39;
}
/*End*/

/*10-12-19*/
.disableCheckMark input[type="checkbox"] {
    pointer-events: none;
}
.disableCheckMark .checkmark {
    cursor: not-allowed;
    background: lightgray;
    border: 1px solid;
    border-color: #aaaaaa;
}
.status_main table th {
    font-size: 20px !important;
    color: #061f39;
}
/*End*/
.pass_layout .droppable.headeField{
    min-width: 55px !important;
}
.strip-image-fixed.generic-without-logo .template-logo {
    display: none;
}
.generic-without-logo.pass_layout .headeFieldInner .hower-body {
    max-width: 145px;
}
.droppable.headeField .drag-input.label + .drag-input.value {
    float: none;
}
.strip-image-fixed .template-secondary {
    margin-top: 150px;
}
.droppable.headeField .drag-input.label + .drag-input.value {
    padding-left: 3px;
}
.pass_layout {
    position: relative;
}
div#sendUser .submit_btn, #members .modal .submit_btn{
    color: #fff;
    width: auto;
    padding: 10px 17px;
    font-size: 15px;
    background: #57c0d9;
}
/* .senduser .MUIDataTable-responsiveScroll-4 table tbody tr td:first-child {
    display: none;
} */
.senduser .MUIDataTable-responsiveScroll-4 table thead {
    display: none;
}
.senduser .allSelectedMailList table thead{
    display:none;
}
.myOrderPopup input[type="submit"] {
    width: auto;
    color: #fff;
    font-size: 20px;
    border-radius: 40px;
    background: #57c0d9;
    padding: 7px 28px;
    display: inline-block;
    float: right;
}
.myOrderPopup input[type="submit"].isNotActive {
    border: 1px solid #dddddd;
    background: #dddddd;
    color: #fff;
}
input.error{
    color:#f16772 !important;
}
input[type="submit"].isActive {
    background: #57c0d9;
    border: 1px solid #57c0d9;
    -webkit-transition: all .5s;
    transition: all .5s;
    cursor: pointer !important;
}
/* input[type="submit"].isNotActive {
   pointer-events: none;
} */
.status_main table button.cmn-btn {
    padding: 7px 13px;
    border-radius: 30px;
    font-size: 16px;
    background-color: #cc0000;
}
.status_main table button.cmn-btn.activate{
    background:#339d91
}
.same_profile_tab_style form .form-group input {
	border-radius: 0;
	padding-left: 0;
	font-family: 'Museo Sans 300';
	padding-bottom: 17px;
}
.same_profile_tab_style form .form-group input:focus {
    outline: none;
    box-shadow: none;
}
.cancel_subscription .modal-body form input.submit_btn.no_to_passes {
    margin: 0 0 0 10px;
}
.cancel_subscription form {
    float: right;
    width: 100%;
}
.comming_soon .topleft {
    padding-top: 20px;

}
/* 15-01-2020 */
.status_main table th {
    font-size: 20px !important;
    color: #061f39;
}
.status_main table td {
    font-size: 15px;
    color: #061f39;
}
.status_main table tfoot span {
    color: #061f39;
    font-size: 14px;
}
.status_main button svg {
    fill: #061f39 !important;
}
.my_pass_main_section.slider_with_pending_temp section.banner-slider {
    width: calc(100% - 532px - 29px);
    overflow: hidden;
    margin-top: 25px;
    overflow: hidden;
    padding-bottom: 26px;
    padding-left: 0;
}
.my_pass_main_section.slider_with_pending_temp .order_pass.ordered_pending_passes {
    margin-right: 29px;
}

.owl-item .item {
    width: 100%;
    padding:20px;
}
.owl-item .item .pass_layout {
    width: 100%;
}
.item.current_template::after {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.my_pass_templates .order_pass {
    margin-top: 55px;
}
.owl-item .item .template-barcode {
    max-width: 100%;
    margin: 0 12px 28px;
}
.my_pass_main_section.slider_with_pending_temp section.banner-slider div#main-slider {
    margin-top: 20px;
}
.item.current_template::before {
    bottom: -19px;
}
.start_ordering_passes .dashboard-order.order_pass.no-template {
    margin-top: 2px;
}

.my_pass_main_section section.banner-slider .owl-carousel .owl-item {
    padding-left: 0;
    padding-right: 0;
}
.my_pass_main_section section.banner-slider .owl-nav .owl-next {
    right: -33px;
}


/*15-1-2020*/
.droppable.headeField .grab-input, .droppable.headeField .headeFieldInner {
    flex-direction: column;
}
.react-card-flip .droppable.headeField {
    justify-content: center;
    flex-direction: column;
}
.template-logoText {
    align-items: center;
    justify-content: center;
    float: left;
}
/*END*/
/* 16-01-2020 */

/* 28-01-2020 */
/* .adduser .modal-header h1 {
    font-size: 30px;
    color: #061f39;
    line-height: 26px;
    font-weight: 400 !important;
    font-family: 'Museo Sans 300';
    letter-spacing: 1px;
} */
.adduser .modal-dialog, .addLicenseHolderModal .modal-dialog{
    max-width: 908px;
}
.browsedCsvContainer .react-file-reader {
    float: right;
}
.browsedCsvContainer .react-file-reader button:hover {
	background: #57C0D9;
	color: #fff;
}
.adduser .modal-dialog .modal-body h4 + h2, .addLicenseHolderModal .modal-dialog .modal-body h4 + h2{
	font-size: 20px;
	margin-top: 58px;
}
.browsedCsvContainer .react-file-reader button {
	background: transparent;
	color: #061f39;
	padding: 14px 15px;
border-radius: 7px;
border: 2px solid #57C0D9;
font-size: 20px;
line-height: 24px;
}
.browsedCsvContainer {
    float: left;
    width: 100%;
    padding: 20px 0 6px;
}
.browsedCsvContainer input#browsedCsvName {
	float: left;
	width: calc(100% - 122px);
	height: 56px;
	border: 2px solid rgba(5, 31, 56, 0.4);
	border-radius: 8px;
    padding: 17px 28px;
    background: #FFFCF7;
}
.browsedCsvContainer input#addbrowsedCsvName {
    float: left;
    width: 500px;
    height: 35px;
    border: 1px solid #ddd;
    border-radius: 8px;
}
.modal .modal-body h4 button {
    color: #57c0d9;
    background: transparent;
    border: none;
    padding: 0 6px;
    cursor: pointer;
    font-family: 'Museo Sans 700';
}
.modal .modal-body h4 {
    font-size: 18px;
    font-family: 'Museo Sans 300';
    line-height: 22px;
    color: #061f39;
}
.adduser .modal-dialog .modal-body h2, .addLicenseHolderModal .modal-dialog .modal-body h2 {
    font-size: 16px;
    line-height: 20px;
    margin-top: 40px;
    font-family: 'Museo Sans 700';
    margin-bottom: 25px;
}

.adduser .modal-dialog .modal-body h3, .addLicenseHolderModal .modal-dialog .modal-body h3 {
    margin: 0 0 10px;
    font-family: 'Museo Sans 700';
}

/* 28-01-2020 END */
.no-dashboard .order_fornew_passes .demo-pass-template .pass-design-layout {
    max-width: 100%;
    background: transparent;
}
.no-dashboard .order_fornew_passes .demo-pass-template {
    width: 372px;
    margin-top:28px;
}
.no-dashboard .order_fornew_passes .demo-pass-template .pass-design-layout img {
    width: 100%;
    max-height: 406px;
}
.no-dashboard .order_fornew_passes .demo-pass-template .pass-design-layout:before, .no-dashboard .order_fornew_passes .demo-pass-template .pass-design-layout:after {
    display: none;
}
.my_pass_templates.no-dashboard .order_pass.ordered_pending_passes {
    margin-top: 0;
    width: 311px;
    margin-right: 29px;
}
.my_pass_templates.no-dashboard .dashboard-order.order_pass.no-template {
    width: 191px;
    margin-right: 29px;
}
.my_pass_main_section section.banner-slider .owl-carousel .owl-item .item {
    max-width: 330px;
}
.senduser .modal-body form textarea {
    height: 210px;
    padding: 10px 15px;
}
.senduser .modal-body form input[type="text"], .senduser .modal-body form textarea {
    width: 100%;
    float: left;
    border: 1px solid #84e7ff;
    padding-left: 12px;
    color: #8e8e8e;
    background: #fff;
    border-radius: 8px;
}
.senduser .modal-body form textarea {
    height: 210px;
    padding: 10px 15px;
}
#termsCondition h3 {
	font-size: 18px;
	font-family: 'Museo Sans 700';
}
#termsCondition p {
	line-height: 38px;
}
.term-popup .modal-header {
	margin-bottom: 5px;
	display: block;
}
.subscription_exceeded.block_element .subscription_exceeded_btn {
	width: 100%;
	background: #f16772;
	border-color: #f16772;
	height: 48px;
	text-align: left;
}
/* div#sendUser .modal-dialog {
    top: 20px;
    max-width: 908px;
    -webkit-transform: translateY(0%) !important;
    transform: translateY(0%) !important;
} */
div#sendUser .modal-dialog .send-left {
    width: 100%;
    box-shadow: none;
}
div#sendUser .modal-dialog .send-left .form-group-cont {
	width: 56%;
	float: left;
	padding-right: 0px;
}
div#sendUser .modal-dialog .send-left .send_pass_with_image {
	width: 44%;
	clear: none;
	float: left;
	padding-left: 40px;
}
div#sendUser .modal-dialog .send-left .send_pass_with_image img {
	width: 100%;
	 max-height: 406px; 
}
div#sendUser .modal-dialog .send-right {
    width: 100%;
    padding-left: 0px;    
}
div#sendUser .modal-dialog .send-right .allSelectedMailList {
	width: 56%;
	float: left;
}
div#sendUser .modal-dialog .send-right .form-btn-btm {
	float: left;
	width: 44%;
	padding-left: 40px;
	margin-top: 20px;
}
div#sendUser .modal-dialog .send-left {
    width: 50%;
    box-shadow: none;
}
div#sendUser .send-right .form-btn-btm input.submit_btn, #sendLicense .send-right .form-btn-btm input.submit_btn {
	border: 2px solid #E4E0D0;
	background: transparent !important;
	color: #97937F !important;
	border-radius: 8px !important;
	padding: 11px !important;
}
div#sendUser .send-right .form-btn-btm input.submit_btn.no_to_passes, #sendLicense  .send-right .form-btn-btm input.submit_btn.no_to_passes  {
    border: 2px solid #57c0d9;
    background: transparent!important;
    color: #000!important;
    border-radius: 8px!important;
    padding: 11px!important;
}
.send-right .form-btn-btm {
    margin-top: 40px;
}
div#sendUser .modal-dialog .modal-header label {
    font-size: 30px;
}
.bg_color_section.block_element.main_logo_heading_padding.my_pass_templates.my_profile_section.padding_container {
	padding-bottom: 9px;
	padding-left: 0px;
	padding-right: 0px;
}
div#sendUser .modal-dialog .modal-header button.close {
    background: transparent;
    color: #000;
    font-size: 50px;
    border: none;
    box-shadow: none;
    font-weight: 500;
    right: 30px;
    top: 30px;
}
.MuiPaper-elevation4-16 {
    box-shadow: none;
}
.myOrderPopup input[type="submit"] {
    float: left;
}
.pass_layout.ticket .template-headerSection .ticketCircleImgMain img {
    max-width: 71px;
    margin: 0 auto;
}
#sendUser .modal-header h3 {
    width: 100%;
    float: left;
    margin: 8px 0 0;
    font-size: 18px;
}
#sendUser .modal-header {
    flex-direction: column;
}
#sendUser.modal form {
    padding-top: 25px;
}
#addUser #uploadCancel, .addLicenseHolderForm #addLicenseHolderCancel {
	border: 2px solid #F16772;
	background: transparent !important;
	color: #061f39 !important;
	text-transform: capitalize;
	font-weight: 500;
	display: inline-block;
	margin: -4px 10px 0 0;
	line-height: 0;
	padding: 20px 25px !important;
	font-size: 20px;
	height: auto;
	border-radius: 8px;
	width: auto !important;
	font-family: inherit;
	height: 56px;
}
    #addUser #uploadCancel:hover,  .addLicenseHolderForm #addLicenseHolderCancel:hover     {
    background:#F16772 !important;
    color:#fff !important;
    }
    #addUser #addBtn:hover {
        background:#57c0d9 !important;
        color:#fff !important;
        background-color: #57c0d9 !important;
        }
        .addLicenseHolderForm #addLicenseHolderCancel {
            margin-top: 15px;
            max-width: 160px;
        }
        #addLicenseHolder #addLicenseHolderBtn {
            max-width: 160px;
            margin-top: 15px;
            padding: 10px 27px;
            width: auto;
        }
        .addLicenseHolderForm #addLicenseHolderCancel {
            padding: 20px 23px !important;
            margin:15px 20px 0 0;
        }
        #addLicenseHolder .form-group {
            margin-bottom: 77px;
        }
        #addLicenseHolder .form-group:nth-last-child(2) {
            margin-bottom: 45px;
        }
        #addLicenseHolder .wrapper.block_element {
            min-height: inherit;
        }
        #addLicenseHolder #addLicenseHolderBtn.isNotActive{
            border: 2px solid #E4E0D0;
color: #97937F !important;
border-radius: 8px !important;
font-size: 20px;
height: 56px;
background-color: transparent !important;
        }
        #addLicenseHolder .inner-pages .container {
            padding: 0;
        }
        #addLicenseHolder .form-group-btn {
            display: flex;
            justify-content: flex-end;
        }
        #addLicenseHolder #addLicenseHolderBtn.isNotActive:hover {
            border: 2px solid #E4E0D0;
            color: #97937F !important;
            background-color: transparent!important;
            cursor: not-allowed;
        }
#addUser #addBtn {
    background: transparent!important;
    border: 1px solid  #c1b9b9;
    color: #c1b9b9!important;
    padding: 8px 25px;
    font-size: 17px;
    border-radius: 5px;
    display: inline-block;
    line-height: 0;
	padding: 20px 38px !important;
	font-size: 17px;
	height: auto;
    border-radius: 8px;
    font-weight: 500;
	width: auto !important;
	font-family: inherit;

}
/* div#sendUser #sendDone:hover {
	background: orange !important;
	color: #fff !important;
} */
div#sendUser #sendDone.sendpasses_activate{
    background: orange !important;
    color: #fff !important;
    cursor: pointer;
}
div#sendUser #sendDone.sendpasses_deactivate{
    cursor: not-allowed;
}
#sendForm #sendDone.sendpasses_deactivate{
    cursor: not-allowed;
}
div#sendUser #sendCancel:hover {
	background: #57c0d9 !important;
	color: #fff !important;
}
#deleteUser .modal-title, #AssociatedDelete .modal-title{
    font-size: 32px;
    padding-right: 230px;
    line-height: 44px;
    margin-bottom: 40px;
}
#deleteUser p, #AssociatedDelete p {
	color: #000;
	font-size: 17px;
}
#deleteUser .modal-footer, #AssociatedDelete .modal-footer{
    margin-top: 20px;
    display: block;
    margin-bottom: 20px;
}
#deleteUser .modal-footer #deletePopupDelete, #AssociatedDelete .modal-footer #deletePopupDelete, .adduser .modal-footer #deletePopupDelete{
    float: left;
    border-radius: 100px;
    padding: 10px 31px;
    font-size: 27px;
    text-transform: none;
    font-weight: 500;
}
.adduser .modal-footer .paymentFailedbtnInvoice #deletePopupDelete{
    width:90%;
    padding: 10px 31px;
}
.adduser .modal-footer .paymentFailedbtnCard #deletePopupDelete{
    padding: 10px 31px;
}


#deleteUser .modal-footer #cancelDeleteUser, #AssociatedDelete .modal-footer #cancelDeleteUser, .adduser .modal-footer #cancelDeleteUser{
	float: right;
	border: 1px solid #00bcd4;
	background: transparent;
	color: #061f39;
	text-transform: capitalize;
	font-weight: 500;
	display: inline-block;
	margin: 0px 28px 0 0;
	border-radius: 100px;
	padding: 22px 40px;
	line-height: 1;
    font-size: 27px;
    height:75px;
}
.associated_user_list .associated_user_list_table td:last-child {
	width: 270px;
}
.multi_user_section span.assocated_user_pending {
	width: auto;
	display: inline-block;
	margin-left: 15px;
}
#deleteUser .modal-footer #cancelDeleteUser:hover, #AssociatedDelete .modal-footer #cancelDeleteUser:hover, .adduser .modal-footer #cancelDeleteUser:hover
{
    background: #00bcd4 !important;
    color: #fff !important;
}
#deleteUser .modal-footer #deleteUserBtn:hover, #AssociatedDelete .modal-footer #deleteUserBtn:hover, .adduser .modal-footer #deleteUserBtn:hover
{   
    background: #F16772 !important;
    color: #fff !important;
}
#paymentFailed .modal-dialog .modal-content .modal-body p{
        color: #000;
}
#deleteUser .modal-footer #deleteUserBtn, #AssociatedDelete .modal-footer #deleteUserBtn, .adduser .modal-footer #deleteUserBtn{
	float: right;
	border: 2px solid #F16772;
	background: transparent;
	/* color: #061f39; */
	text-transform: capitalize;
	font-weight: 500;
	display: inline-block;
	margin: 0px 10px 0 0;
	border-radius: 100px;
	padding: 22px 40px;
	line-height: 1;
	font-size: 27px;
	color: #061F39;
}
#deleteUser .modal-header .close, #AssociatedDelete .modal-header .close, .adduser .modal-header .close{
	right: 67px;
}
#changeHolderStatus.adduser .deleteBtns, #deleteHolder.adduser .deleteBtns  {
	display: flex;
}
#deleteHolder.adduser .deleteBtns, #changeHolderStatus.adduser .deleteBtns {
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
}
.adduser .deleteBtns #deleteUserBtn.deactuvateStatus, #deleteHolder.adduser .deleteBtns #deleteUserBtn,  #changeHolderStatus.adduser .deleteBtns #deleteUserBtn{
	order: 2;
	margin-right: 0px;
	max-width: 190px;
	padding: 22px 20px;
}
#deleteHolder.adduser .deleteBtns #deleteUserBtn,  #changeHolderStatus.adduser .deleteBtns #deleteUserBtn{
    max-width: 180px;
    width:100%;
}
#changeHolderStatus.adduser .deleteBtns #deleteUserBtn.activeStatus{
    border: 2px solid #8EC976;
}
#changeHolderStatus.adduser .deleteBtns #deleteUserBtn.activeStatus:hover{
    border: 2px solid #8EC976;
    background-color: #8EC976!important;
}
#changeHolderStatus .modal-body, #deleteHolder .modal-body{
	margin-bottom: 30px;
}
.adduser .deleteBtns #cancelDeleteUser {
    order: 1;
    margin-right:28px;
}   
div#sendUser input#sendDone {
    margin-right: 0;
    width: 48%!important;
    max-width: initial;
}
div#sendUser .modal-dialog .send-left > div {
    clear: both;
}
div#sendUser form {
    float: left;
    width: 100%;
}
div#sendUser input#sendCancel {
    float: left!important;
    margin-right: 4%;
    width: 46%!important;
    max-width: initial;
}
#sendLicense .allSelectedMailList {
	float: left;
	width: 55%;
}
#sendLicense .send-right .form-btn-btm {
	margin-top: 0px;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	width: 45%;
}
#sendLicense .send-right {
	width: 100%;
	float: left;
}
#sendLicense .allSelectedMailList table thead tr {
    display: none;
}
.status_main button {
	border: 1px solid transparent;
}
.sign_up_page h2 {
    font-size: 50px;
    line-height: 84px;
    font-family: 'Museo Sans 500';
}
form.sign_up_form.block_element label.container-s {
    color: #061F39;
}
header.header_login .header_logo img {
	max-width: 400px;
}
.thankyou_back.sticky_thankyou {
	padding-top: 30px;
}
.header_login {
	padding-top: 31px;
}
.sign_up_page.sign_up h2 {
	margin-bottom: 176px;
}
#termsCondition h2 {
	font-size: 27px;
	line-height: 1.3;
	margin-bottom: 16px;
}
#termsCondition h4 i {
	font-size: 14px;
	padding-right: 33px;
}
#termsCondition .close {
	position: absolute;
	right: 0;
}    
#termsCondition .close {
	position: absolute;
	right: 20px;
	top: 20px;
}
.term-popup .modal-header {
	margin-bottom: 10px;
}
 .term-popup .modal-footer {
	margin-bottom: 0px;
	padding-top: 20px;
}
.Toastify__toast-body {
	font-family: 'Museo Sans 500';
}
.pass_layout.member-pass {
	font-family: 'Museo Sans 300';
}
.same_status_section.codes_section .codes_right {
	flex-direction: column;
}
.same_status_section.codes_section .codes_right {
	flex-direction: column;
	align-items: flex-end;
	float: right;
	max-width: 390px;
	width: 100%;
	text-align: center;
}
/* #addUser .modal-dialog {
	top: 50%;
	-webkit-transform: translateY(-50%) !important;
	transform: translate(0,0);
	transform: translateY(-50%) !important;
} */
.front_layout.home .tab-content.tab_background {
	margin-bottom: 0;
}

.profile-form_btn button, .profile-form_btn input[type="submit"] {
	font-family: 'Museo Sans 500';
	font-size: 27px;
	padding: 22px 50px;
	min-width: 275px;
	width: 100%;
	cursor: not-allowed;
	line-height: 1;
}


.my_pass_templates .nav.nav-tabs.emptynavTabs li a::before {
    background: #C9C4A9;
}
.my_scanner .nav.nav-tabs.emptynavTabs li a {
    pointer-events: none;
    cursor: 'default';
    color: #C9C4A9;
}
.sign_up_form.block_element .floating-label_group input:not(:placeholder-shown) ~ label {
	top: -34px;
	font-size: 14px;
}
.sign_up_form.block_element .floating-label_group input:focus ~ label, .sign_up_form.block_element .floating-label_group input:not(:placeholder-shown) ~ label {
	top: -34px;
	font-size: 14px;
}
.sign_up_page h2 {
	font-size: 70px;
}
form.sign_up_form.block_element label.container-s {
	margin-top: 30px;
	margin-bottom: 60px;
}
form.sign_up_form.block_element label.container-s {
	font-family: 'Museo Sans 300';
}
.myOrderPopup .modal_body_haeding {
	font-family: 'Museo Sans 300';
}
.modal.myOrderPopup .modal_form_field input:focus ~ label, .modal.myOrderPopup .modal_form_field input:not(:placeholder-shown) ~ label {
	font-size: 20px;
}
.modal.myOrderPopup #orderform .modal_form_field > label {
    font-size: 20px;
    line-height:24px;
}
.payment_method + .payment_method_form.sign_up_form .form-group {
	margin-bottom: 69px;
}
.payment_method + .payment_method_form.sign_up_form input:not(:focus):valid ~ .floating-label {
    top: -52px;
} 
.payment_method + .payment_method_form.sign_up_form .form-group .inputText.pre-filled-form {
	font-size: 30px;
	line-height: 36px;
	height: auto;
    font-family: 'Museo Sans 300';
    padding-bottom:10px;
}
.payment_method + .payment_method_form.sign_up_form .form-group.card-detail .inputText.pre-filled-form {
	font-size: 40px;
	line-height: 48px;
	padding-left: 239px;
}
form.payment_method_form.sign_up_form .form-group {
    margin-bottom: 76px;
}
.payment_method_form.sign_up_form .form-group.card-detail span.card_number_value {
	margin-top: -15px;
	position: absolute;
    padding-left: 70px;
    padding-bottom:7px;
}
.modal.myOrderPopup #orderform .modal_form_field label {
    top: 4px;
}
/* .myOrderPopup form#orderform .modal_main_field.form-group.valid-thru-field .xpry.modal_form_field input#exp_date {
    padding-bottom: 23px;
}
.myOrderPopup form#orderform .modal_main_field.form-group.valid-thru-field .xpry.modal_form_field label {
    margin-top: -30px;
    margin-bottom: 20px;
} */
.modal.myOrderPopup #orderform .modal_form_field label {
    font-size: 30px;
}
.modal.myOrderPopup #orderform .modal_form_field input:focus ~ label, .modal.myOrderPopup #orderform .modal_form_field input:not(:placeholder-shown) ~ label {
    font-size: 20px;
}
.sign_up_form .form-group.card-detail input.inputText.pre-filled-form {
    font-size: 40px;
    line-height: 48px;
    height: auto;
}
.payment_method_form.sign_up_form input:focus ~ .floating-label, .payment_method_form.sign_up_form input:not(:focus):valid ~ .floating-label {
	top: -44px;
	bottom: 10px;
	left: 0px;
	font-size: 20px;
	opacity: 1;
	font-family: 'Museo Sans 300';
}
.payment_method + .payment_method_form.sign_up_form .form-group.form-group-half.padding_right_zero + .form-group-btn {
	margin-top: -29px;
}
#subscription.subscription_sec .subsription_heading.padding_container {
	padding: 65px 0 30px 0;
}
.payment_method_form.sign_up_form button.upgrade_subscription_btn {
	padding: 22px 50px;
	font-size: 27px;
	/* width: 370px; */
	line-height: 1;
	min-width: 275px;
}
.payment_method_form.sign_up_form {
	margin: 0 auto 200px;
}
#password.same_profile_tab_style form h3 {
	margin-bottom: 134px;
	font-size: 40px;
	line-height: 48px;
	font-family: 'Museo Sans 100';
}
.my_pass_templates .order_pass.ordered_pending_passes p {
	font-size: 20px;
}

.my_pass_templates .order_pass p, .my_scanner .order_scanner p {
	font-size: 20px;
}
.my_pass_templates .order_pass p, .my_scanner .order_scanner p {
	margin-top: -30px;
}
.pass_layout {
	height: 450px;
}
.front_layout.home .tab-content.tab_background .tab-pane.fade.codes_main_section.in.active.show {
	padding: 0;
}
.front_layout.home .tab-content.tab_background .tab-pane.fade.distribution._section.in.active.show {
	padding: 0;
}
.main_distribution_btn .choosefile .choosefilebtn {
    padding: 11px 22px;
    font-family: 'Museo Sans 500';
}
.choosefile button.choosefilebtn {
	font-size: 20px;
}
.choosefile {
	margin-right: 20px;
}
#uploadUser .modal-dialog .modal-content {
	border-radius: 15px;
}
#uploadUser .modal-dialog .modal-content .modal-title {
	font-size: 40px;
	font-family: 'Museo Sans 100';
}
#uploadUser .modal-dialog form {
	padding-top: 0px;
	margin-top: -6px;
}

section.uploadUser-sec p {
	font-size: 20px;
}
div#uploadUser .modal-body h4 {
	font-size: 20px;
	margin-bottom: 60px;
}
#uploadUser.adduser .modal-dialog {
	border-radius: 15px;
}
.main_distribution_btn .choosefile {
	width: 200px;
}
.main_distribution_btn .distribution_btns.deleteAllUser {
	border: 2px solid #F16772;
}
div#uploadUser.modal .modal-body {
	padding: 0 60px 64px;
}
section.uploadUser-sec {
	margin: 26px auto 50px;
}
.browsedCsvContainer input#browsedCsvName {
	float: left;
	width: calc(100% - 122px);
	height: 56px;
	border: 2px solid rgba(5, 31, 56, 0.4);
    border-radius: 8px;
    margin-right: 30px;
}
#addUser.modal .modal-dialog , #addLicenseHolder.modal .modal-dialog{
	border-radius: 21px;
}
#addUser.modal .modal-content, #addLicenseHolder .modal-content{
	border-radius: 20px;
}

.adduser .browsedCsvContainer input#addbrowsedCsvName, .addLicenseHolderModal .browsedCsvContainer input#addbrowsedCsvName {
	width: calc(100% - 126px);
	border: 2px solid rgba(5, 31, 56, 0.4);
	height: 56px;
	padding: 17px 26px;
	font-size: 20px;
	color: #97937F;
	font-family: 'Museo Sans 300';
	background: #FFFCF7;
	margin-right: 30px;
}
.adduser .modal-dialog .modal-body h3, .addLicenseHolderModal .modal-body h3{
    font-size: 20px;
    line-height:24px;
}
.adduser .modal-header h1, #uploadUser .modal-dialog .modal-content .modal-title, .addLicenseHolderModal .modal-header h1{
	font-size: 40px;
	font-family: 'Museo Sans 100';
	line-height: 48px;
	color: #061f39;
	max-width: calc(100% - 80px);
	width: 100%;
}
.adduser.modal .modal-body h4, .addLicenseHolderModal.modal .modal-body h4 {
	font-size: 20px;
	clear: both;
}
.adduser .modal-dialog .modal-body h2, .addLicenseHolderModal .modal-body h2{
	font-size: 20px;
}
.adduser .modal-body form .mailinput label, .addLicenseHolderModal .modal-body form .mailinput label {
	font-size: 20px;
}
.adduser .modal-body form .mailinput input , .addLicenseHolderModal .modal-body form .mailinput input {
	border: 2px solid rgba(5, 31, 56, 0.4);
	height: 51px;
	border-radius: 8px;
	background: #FFFCF7;
	padding: 17px 28px;
	font-size: 20px;
	font-family: 'Museo Sans 300';
	color: #97937F;
}
#addUser #uploadCancel {
	border: 2px solid #F16772;
	background: transparent !important;
	color: #061f39 !important;
	text-transform: capitalize;
	font-weight: 500;
	display: inline-block;
	margin: -4px 15px 0 0;
	line-height: 0;
	padding: 20px 25px !important;
	font-size: 20px;
	height: auto;
	border-radius: 8px;
	width: auto !important;
	font-family: inherit;
	height: 56px;
}
#addUser #addBtn{
	background: #57C0D9 !important;
	border: 2px solid #57C0D9;
	color: #fff !important;
	padding: 8px 25px;
	font-size: 17px;
	border-radius: 5px;
	display: inline-block;
	line-height: 0;
	padding: 20px 38px !important;
	font-size: 20px;
	height: auto;
	border-radius: 8px;
	font-weight: 500;
	width: auto !important;
	font-family: inherit;
	color: #fff;
	height: 56px;
}
.Dummy-Data #members .user-listing .main_distribution_btn {
	margin-bottom: 27px;
}

.tab_background_usertab .distribution._section .user-listing {
    margin-top: 29px;
}
.distribution._section .user-listing {
    margin-top: 55px;
}
 #addUser  #addBtn.btn-default.submit_btn.adduserbtnActive,  #addLicenseHolder  #addLicenseHolderBtn {
    border: 2px solid #57C0D9;
    color:#57C0D9!important;
}
#addUser  #addBtn.btn-default.submit_btn.adduserbtnDeActive, #addLicenseHolder #addLicenseHolderBtn {
    cursor: not-allowed;
}
#addUser #addBtn.btn-default.submit_btn.adduserbtnActive:hover, #addLicenseHolder #addLicenseHolderBtn:hover {
    border: 2px solid #57C0D9;
    color:#ffffff!important;
    background-color: #57C0D9!important;
    cursor: pointer;
}
#addUser .form-btn-btm, #addLicenseHolder .form-btn-btm {
	margin-top: 18px;
}
.wrapper.block_element {
    min-height: 700px;
}
.tab-content.tab_background.block_element {
	border-radius: 0;
}
.tab-content.tab_background.insideProfile.block_element, .tab-content.tab_background.insideScanner.block_element {
	border-radius: 18px;
}
#subscription.tab-pane.subscription_sec .subsription_heading.padding_container {
	padding: 110px 0 90px;
}
#myOrderPopup #orderform .modal_form_field.form-group:nth-child(3) {
	margin-bottom: 23px;
}
.modal .modal_main_field .modal_form_field {
	width: 50%;
	padding-right: 23px;
}
#myOrderPopup #orderform .isNotActive {
	max-width: 238px;
	height: 80px;
}

#contactUsConfirm .modal-dialog, #addUserConfirm .modal-dialog, #sendPassConfirm .modal-dialog, #inviteUserConfirm .modal-dialog{
	max-width: 474px;
	border-radius: 20px;
}
.myOrderPopup form#orderform input[type="submit"] {
	float: right;
	max-width: 258px;
	font-size: 27px;
	padding: 8px 35px;
	cursor: not-allowed;
	font-family: 'Museo Sans 500';
}
.myOrderPopup form#orderform .modal-footer {
	padding-bottom: 45px;
}
.myOrderPopup form#orderform .modal_main_field.form-group.valid-thru-field {
	margin-bottom: 25px;
}
#contactUsConfirm .modal-dialog .close, #addUserConfirm .modal-dialog .close, #sendPassConfirm .modal-dialog .close, #inviteUserConfirm .modal-dialog .close {
	top: 22px;
	right: 30px;
}
#contactUsConfirm .modal-dialog .modal-content.bg_color_section,  #addUserConfirm .modal-dialog .modal-content.bg_color_section, #sendPassConfirm .modal-dialog .modal-content.bg_color_section, #inviteUserConfirm .modal-dialog .modal-content.bg_color_section {
	border-radius: 20px;
}
#contactUsConfirm .modal-dialog .modal-content.bg_color_section img, #addUserConfirm .modal-dialog .modal-content.bg_color_section img, #sendPassConfirm .modal-dialog .modal-content.bg_color_section img, #inviteUserConfirm .modal-dialog .modal-content.bg_color_section img {
	max-width: 200px;
}
#contactUsConfirm .modal-dialog .modal-content.bg_color_section span, #addUserConfirm .modal-dialog .modal-content.bg_color_section span, #sendPassConfirm .modal-dialog .modal-content.bg_color_section span, #inviteUserConfirm .modal-dialog .modal-content.bg_color_section span {
	font-size: 40px;
	color: #061F39;
	font-family: 'Museo Sans 100';
	margin-top: 30px;
	margin-bottom: 30px;
	width: 100%;
    float: left;
    line-height: 48px;
}
#contactUsConfirm .modal-dialog .modal-body, #addUserConfirm .modal-dialog .modal-body, #sendPassConfirm .modal-dialog .modal-body, #inviteUserConfirm .modal-dialog .modal-body {
	text-align: center;
	margin-top: 20px;
}
.my_scanner_page .my_scanner.start_ordering_scanner .tab_background #updateScannerForm .form-group {
	max-height: 540px;
    overflow-y: auto;
    position: relative;
    z-index: 1;
}
#scanner .form-group:nth-child(2) {
	max-height: 480px;
    overflow-y: auto;
    position: relative;
			z-index: 1;
}
div#sendUser .modal-dialog .modal-content.bg_color_section, #sendLicense .modal-dialog .modal-content.bg_color_section {
	border-radius: 20px;
	min-height: 630px;
}
div#sendUser .modal-dialog, #sendLicense .modal-dialog {
	border-radius: 20px;
}
#members div#sendUser .modal-dialog .modal-header label, #sendLicense .modal-dialog .modal-header label {
	font-size: 40px;
	font-family: 'Museo Sans 100';
	color: #061F39;
}
#sendUser .modal-header, #sendLicense .modal-header{
	flex-direction: column;
	padding-top: 50px;
}
div#sendUser .modal-dialog .modal-header button.close, #sendLicense.modal-dialog .modal-header button.close {
	right: 60px;
	top: 52px;
}
#sendUser .modal-header h3 , #sendLicense .modal-header h3 {
	width: 100%;
	float: left;
	margin: 8px 0 0;
	font-size: 20px;
	color: #061F39;
	font-family: 'Museo Sans 300';
}
div#sendUser .modal-dialog .send-left {
	width: 100%;
}

div#sendUser .pass_layout,  #sendLicense  .pass_layout{
	width: 100%;
}
.senduser .modal-body form input[type="text"], .senduser .modal-body form textarea,  #sendLicense .modal-body form input[type="text"],  #sendLicense .modal-body form textarea  {
	width: 100%;
	float: left;
	border: 2px solid rgba(5, 31, 56, 0.4);
	padding-left: 12px;
	color: #061F39;
	background: #fff;
	border-radius: 12px;
	font-size: 20px;
	font-family: 'Museo Sans 500';
	height: 68px;
	margin-bottom: 20px;
	clear: both;
}

.senduser .modal-body form input[type="text"]::-webkit-input-placeholder, .senduser .modal-body form textarea::-webkit-input-placeholder, #sendLicense .modal-body form input[type="text"]::-webkit-input-placeholder, #sendLicense .modal-body form textarea::-webkit-input-placeholder { 
    color:#97937F;
    opacity:1;
  }
  .senduser .modal-body form input[type="text"]::-moz-placeholder,  .senduser .modal-body form textarea::-moz-placeholder ,  #sendLicense .modal-body form input[type="text"]::-moz-placeholder ,  #sendLicense .modal-body form textarea::-moz-placeholder  { 
    color:#97937F;
    opacity:1;
  }
  .senduser .modal-body form input[type="text"]:-ms-input-placeholder, .senduser .modal-body form textarea:-ms-input-placeholder , #sendLicense .modal-body form input[type="text"]:-ms-input-placeholder , #sendLicense .modal-body form textarea:-ms-input-placeholder  { 
    color:#97937F;
    opacity:1;
  }
  .senduser .modal-body form input[type="text"]:-moz-placeholder,  .senduser .modal-body form textarea:-moz-placeholder ,  #sendLicense .modal-body form input[type="text"]:-moz-placeholder ,  #sendLicense .modal-body form textarea:-moz-placeholder  { 
    color:#97937F;
    opacity:1;
  }

#sendUser .modal-body form input[type="text"], #sendUser .modal-body form textarea,  #sendLicense .modal-body form input[type="text"],  #sendLicense .modal-body form textarea  {
	height: 60px;
	margin-bottom: 13px;
	background: #FFFCF7;
	font-family: 'Museo Sans 300';
}
#sendUser .modal-body, #sendUser .modal-body  {
	padding-top: 14px;
}
#sendUser  .pass_layout {
	height: 400px;
}
.senduser .modal-body form textarea#mailtextarea, #sendLicense .modal-body form textarea#mailtextarea  {
	height: 316px ;
	resize: none;
	float: left;
    width: 100%;
    padding:10px 15px;
}
div#sendUser .send-right .form-btn-btm input.submit_btn.no_to_passes, #sendLicense .send-right .form-btn-btm input.submit_btn.no_to_passes  {
	border: 2px solid #F16772;
	background: transparent !important;
	border-radius: 8px !important;
	padding: 11px !important;
	color: #1B3F71 !important;
	font-size: 20px;
	height: 56px;
}
div#sendUser .send-right .form-btn-btm input.submit_btn.no_to_passes#sendCancel:hover, #sendLicense .send-right .form-btn-btm input.submit_btn.no_to_passes#sendCancel:hover{
    background: #F16772 !important;
    color:#fff!important;
}
div#sendUser #sendDone.sendpasses_activate, #sendLicense #sendDone.sendpasses_activate {
	background: transparent !important;
	border: 2px solid #57C0D9;
	height: 56px;
	font-size: 20px;
    color: #1B3F71 !important;
    border-radius: 8px !important;
}
#sendLicense .modal-dialog .send-left .form-group {
	position: relative;
	float: left;
	width: 100%;
}
div#sendUser #sendDone.sendpasses_activate:hover, #sendLicense #sendDone.sendpasses_activate:hover {
	background: #57C0D9 !important;
	color: #fff !important;
}
#deleteUser .modal-dialog, #AssociatedDelete .modal-dialog, .adduser .modal-dialog{
	max-width: 908px;
	width: 100%;
	border-radius: 20px;
}
#deleteUser .modal-dialog .modal-content, #AssociatedDelete .modal-dialog .modal-content, .adduser .modal-dialog .modal-content{
	border-radius: 20px;
}
#deleteUser .modal-title, #AssociatedDelete .modal-title{
	font-size: 40px;
	padding-right: 270px;
	line-height: 48px;
	margin-bottom: 40px;
	font-family: 'Museo Sans 300';
}
.adduser .modal-title{
	font-size: 40px;
	line-height: 48px;
	margin-bottom: 40px;
	font-family: 'Museo Sans 300';
}
#deleteUser p, #AssociatedDelete p, .adduser p {
	font-size: 20px;
}
#deleteUser .modal-footer #deletePopupDelete, #AssociatedDelete .modal-footer #deletePopupDelete, .adduser .modal-footer #deletePopupDelete{
	padding:22px 15px;
	font-size: 27px;
    font-weight: 500;
    line-height: 1;
}
#sendLicense .send-right .form-btn-btm{
	margin-top: 0px;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}
#sendLicense .send-right .form-btn-btm input.submit_btn.no_to_passes {
	margin-right: 10px;
    max-width: 115px;
    margin-top:0;
}
#sendLicense .send-right .form-btn-btm input.submit_btn {
	max-width: 186px;
}
#sendLicense .modal-body form input[type="text"]{max-width:438px;}
.modal.myOrderPopup .modal_form_field label {
	color: #C9C4A9;
	margin-bottom: 0px;
	font-size: 20px;
	font-family: 'Museo Sans 500';
}
p.card_number_text_value {
	font-size: 40px;
	float: left;
	margin: 0;
	border-bottom: 1px solid #b2afa6;
	width: 100%;
	padding-top: 7px;
    font-family: 'Museo Sans 300';
    line-height:48px;
}
span.card_number_value {
	margin-top: -9px;
}

.modal.myOrderPopup .modal_form_field button {
	top: 7px;
}
.modal.myOrderPopup p.card_number_text_value button {
	font-size: 20px;
	color: #1B3F71;
	font-family: 'Museo Sans 500';
	border-radius: 8px;
	padding: 11px 17px;
	border: 2px solid #57C0D9;
}
#myOrderPopup1 .modal_form_field.form_field_spacing.hide-input-field {
	margin-bottom: 20px;
}
#myOrderPopup1 .btn.btn-default.submit_btn {
    font-size: 27.5px;
    min-width: 258px;
    height: auto;
    border-radius: 40px;
    font-family: 'Museo Sans 500';
}
#myOrderPopup1.modal .submit_btn {
	margin-top: 24px !important;
}
.modal.myOrderPopup p.card_number_text_value button:hover {
	background: #57C0D9;
	color: #fff;
}

#myOrderPopup1 .modal-dialog .modal-content, #OrderConfirmation2 .modal-dialog .modal-content, #cancel_subscription .modal-dialog .modal-content{
	border-radius: 20px;
}
#myOrderPopup1 .modal-dialog, #OrderConfirmation2 .modal-dialog, #cancel_subscription .modal-dialog{
	border-radius: 20px;
}
/* #myOrderPopup1 .isNotActive, #myOrderPopup1 .isActive, #myOrderPopup1 input.btn-danger {
	height: 80px;
	min-width: 259px;
	font-size: 36px;
} */
#myOrderPopup1 input.btn-danger {
	background: #F16772;
	border-color: #F16772;
}
#myOrderPopup1 input.btn-danger {
	background: #F16772;
	border-color: #F16772;
	margin-right: 30px;
}
#myOrderPopup1 .modal_main_field.form-group.valid-thru-field {
	margin-bottom: 80px;
}
#OrderConfirmation2.modal .modal-body .modal_body_haeding {
	color: #061f39;
	font-size: 40px;
	line-height: 48px;
	margin-bottom: 45px;
	font-family: 'Museo Sans 300';
}
#OrderConfirmation2 .receipt_section span {
	width: auto;
	float: none;
}

#OrderConfirmation2 .modal-body p {
	font-size: 20px;
	font-family: 'Museo Sans 500';
	line-height: 24px;
}
#OrderConfirmation2 .receipt_section {
	padding-top: 70px;
}
#OrderConfirmation2 .receipt_section .thakyou_msg, .modal .receipt_section .thakyou_msg, #OrderConfirmation1 .modal-dialog .modal-content p.thakyou_msg, #OrderConfirmation2 .modal-dialog .modal-content p.thakyou_msg, #OrderConfirmation3 .modal-dialog .modal-content p.thakyou_msg, #OrderConfirmation4 .modal-dialog .modal-content p.thakyou_msg  {
	font-size: 40px;
	font-family: 'Museo Sans 100';
	margin: 30px 0 50px;
}
#OrderConfirmation2 .modal-body .btn.btn-default.submit_btn {
	font-size: 36px;
	width: 215px !important;
	height: 80px;
	max-width: 215px;
	font-family: 'Museo Sans 500';
}
#cancel_subscription .modal-dialog .modal_body_haeding br {
	display: none;
}
#cancel_subscription .modal-dialog .modal_body_haeding {
	font-family: 'Museo Sans 300';
	max-width: 540px;
}
#cancel_subscription .modal-dialog .receipt_section p {
	font-size: 20px;
	line-height: 24px;
	font-family: 'Museo Sans 500';
}
#cancel_subscription .modal-dialog .receipt_section {
	padding-top: 20px;
}

#cancel_subscription .modal-dialog form {
	text-align: right;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}

#cancel_subscription .modal-dialog form .btn.btn-default.submit_btn.no_to_passes {
	float: left !important;
	margin-right: 28px;
}
#cancel_subscription .modal-dialog form .btn.btn-default.submit_btn {
	width: 215px;
	height: 80px;
	max-width: 215px;
	font-size: 36px;
}
#cancel_subscription .modal-dialog form .btn.btn-default.submit_btn[value="Yes!"] {
    border: 2px solid #F16772;
    background-color: transparent!important;
    color:#061F39!important;
}
#cancel_subscription .modal-dialog form .btn.btn-default.submit_btn[value="Yes!"]:hover {
    background-color: #F16772!important;
    color:#fff!important;
}
#cancel_subscription .modal-dialog form .btn.btn-default.submit_btn.cancel_yes {
    border: 2px solid #F16772;
    background-color: transparent!important;
    color:#061F39!important;
}
#cancel_subscription .modal-dialog form .btn.btn-default.submit_btn.cancel_yes:hover {
    background-color: #F16772!important;
    color:#fff!important;
}

.Dummy-Stats .status_of_passses .status_gauge .status_upgrade {
	border: 2px solid #C9C4A9 !important;
	color: #C9C4A9;
	font-size: 20px;
	padding: 12px 20px;
}
.Dummy-Stats .status_gauge.same_status_section {
	max-height: 506px;
}
.Dummy-Stats .status_graph.same_status_section {
	max-height: 506px;
}
.Dummy-Stats .status_of_passses .status_gauge img {
	text-align: center;
	margin: -10px auto 37px;
	display: table;
	max-width: 340px;
	width: 100%;
}
.Dummy-Stats .status_graph.same_status_section img {
	margin-top: -42px;
}
#termsCondition .modal-dialog {
	max-width: 1086px;
}
#termsCondition .modal-header h4.modal-title {
	font-size: 48px;
	line-height: 58px;
	color: #061F39;
	max-width: calc(100% - 80px);
}
.term-popup .modal-header {
	padding-left: 84px;
	padding-right: 60px;
	padding-top: 52px;
}
#termsCondition .close {
	position: absolute;
	right: 70px;
	top: 62px;
}
#termsCondition .modal-dialog .modal-body {
	padding: 0 185px 10px 86px;
}
#termsCondition h2 {
	font-size: 30px;
	line-height: 1.3;
	margin-bottom: 5px;
	font-family: 'Museo Sans 300';
}
#termsCondition .modal-body p br {
	display: none;
}
#termsCondition .modal-body p {
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 20px;
}
#termsCondition .modal-body p ol {
	margin: 10px 0;
}
.main_logo_heading_padding #UpgradeSubscription h3 {
	margin: 0px 0 30px;
	font-size: 40px;
	font-family: 'Museo Sans 100';
    line-height: 48px;
    color:#061F39;
}
#UpgradeSubscription .subscription_table table td {
    font-family: 'Museo Sans 500';
    text-align: center;
}
#UpgradeSubscription .subscription_table table td:first-child {
    font-family: 'Museo Sans 700';
    text-align: left;
    padding: 14px 12px 14px 20px;
}
#UpgradeSubscription .subscription_table table th {
	font-size: 20px;
	font-family: 'Museo Sans 700';
	padding: 30px 20px 20px;
    line-height: 24px;
    margin-bottom: -1px;
}
#UpgradeSubscription .subscription_table table tbody .table-price td .price_kr {
	float: left;
	width: 100%;
	font-size: 20px;
	font-family: 'Museo Sans 700';
}
.subscription_table table tbody .table-price td .price_kr.price_without_discount {
	padding-top: 14px;
}
#UpgradeSubscription .subscription_table table tbody .table-price td .free_subsription_btns {
	min-width: 144px;
    height: 60px;
    font-size: 20px;
}
#UpgradeSubscription .subscription_table table tbody tr.table-action-tab td.active-plan span {
	font-size: 20px;
	float: left;
	width: 100%;
	text-align: center;
	line-height: 22px;
	font-family: 'Museo Sans 500';
	padding: 10px 40px;
	letter-spacing: 0.03em;
}
.modal .subscription_table table th.active-plan, .modal .subscription_table table tbody tr.table-price td.active-plan, .modal .subscription_table table tbody tr.table-price td.active-plan {
	background: #D8E2E3!important;
}
.modal .subscription_table table th.active-plan, .modal .subscription_table table tbody tr.table-price td.active-plan {
	width: 101%;
    margin-left: -1px;
    margin-top:-1px;
}
#UpgradeSubscription.select_subscription .modal-header {
	padding-bottom: 20px;
}
#UpgradeSubscription.select_subscription.modal .modal-body {
	padding: 0 53px 40px;
}
#DowngradePlan .modal-dialog, #DowngradePlan .modal-dialog .modal-content {
	border-radius: 20px;
}
#DowngradePlan .modal-dialog .modal-content .modal-body .modal_body_haeding {
        color: #061f39;
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 20px;
        font-family: 'Museo Sans 300';
}

#DowngradePlan .modal-dialog .modal-content .modal-body .receipt_section p {
	font-size: 20px;
	line-height: 24px;
	font-family: 'Museo Sans 500';
}
#DowngradePlan .modal-dialog .modal-content .modal-body .btn.btn-default.submit_btn, #OrderConfirmation1 .modal-dialog .modal-body .btn.btn-default.submit_btn {
	max-width: 215px;
	width: 100px;
	font-size: 27px;
	height: auto;
	margin-top: 40px !important;
	padding: 13px 40px !important;
}
.my_pass_templates.no-dashboard .order_pass.ordered_pending_passes, .my_pass_templates.no-dashboard .dashboard-order.order_pass.no-template {
	margin-top: 55px;
}
.my_pass_templates .order_pass.no-template.add_order_disable  p {
	font-size: 20px;
	line-height: 24px;
}
.order_fornew_passes, .order_fornew_scanner {
	margin-bottom: 40px;
	float: left;
	width: 100%;
}
#OrderConfirmation1 .modal-dialog .modal-content .thakyou_msg {
	font-size: 40px;
	line-height: 48px;
	font-family: 'Museo Sans 100';
	margin-top: 30px;
}
.myOrderPopup form#orderform input[type="submit"] {
	float: right;
	font-size: 27px;
	width: 100%;
	max-width: 238px;
	height: 70px;
	margin-top: 52px;
	padding: 22px 50px;
	line-height: 1;
}
.modal-opening{overflow: hidden;}
#OrderConfirmation1 .modal-dialog, #OrderConfirmation1 .modal-dialog .modal-content , .myOrderPopup .modal-dialog, .myOrderPopup .modal-dialog .modal-content{
	border-radius: 20px;
}
#OrderConfirmation1 .modal-dialog .modal-content .date-selection, #OrderConfirmation2 .modal-dialog .modal-content .date-selection , #OrderConfirmation3 .modal-dialog .modal-content .date-selection , #OrderConfirmation4 .modal-dialog .modal-content .date-selection  {
	font-size: 20px;
	color: #061F39;
}
#OrderConfirmation1 .modal-dialog .modal-content p, #OrderConfirmation2 .modal-dialog .modal-content p, #OrderConfirmation3 .modal-dialog .modal-content p, #OrderConfirmation4 .modal-dialog .modal-content p {
	font-size: 20px;
	font-family: 'Museo Sans 500';
	line-height: 24px;
	margin-bottom: 20px;
}
#OrderConfirmation1 .modal-dialog .modal-content p.modal_body_haeding, #OrderConfirmation2 .modal-dialog .modal-content p.modal_body_haeding, #OrderConfirmation3 .modal-dialog .modal-content p.modal_body_haeding, #OrderConfirmation4 .modal-dialog .modal-content p.modal_body_haeding    {
	font-size: 40px;
	line-height: 48px;
	font-family: 'Museo Sans 300';
	margin-bottom: 40px;
}
#OrderConfirmation1 .modal-dialog .modal-content p span, #OrderConfirmation2 .modal-dialog .modal-content p span, #OrderConfirmation3 .modal-dialog .modal-content p span, #OrderConfirmation4 .modal-dialog .modal-content p span {
	float: none;
	width: auto;
}
.modal .receipt_section > p a {
	color: #061F39;
	text-decoration: none;
}
.bg_color_section.block_element.main_logo_heading_padding.my_pass_templates.my_profile_section.padding_container h3 {
	margin-bottom: 50px;
}
#subscription.tab-pane.subscription_sec div > .subsription_heading.padding_container {
	padding: 50px 0 25px;
}
.front_layout.home .tab-content.tab_background #profile {
	padding-top: 45px;
}
.my_pass_templates .tab_background .tab-pane, .my_scanner .tab_background .tab-pane {
	padding: 0
}
.bg_color_section.block_element.main_logo_heading_padding.start_ordering_passes.padding_container.my_pass_templates.no-dashboard {
	padding-bottom: 100px;
}
.myOrderPopup .btn.btn-default.submit_btn, #OrderConfirmation1 .btn.btn-default.submit_btn, #OrderConfirmation2 .btn.btn-default.submit_btn, #OrderConfirmation3 .btn.btn-default.submit_btn, #OrderConfirmation4 .btn.btn-default.submit_btn {
	font-size: 27px;
	min-width: 218px;
	width: 100%;
	height: auto;
	line-height: 1;
	padding: 22px 50px !important;
}
#OrderTempPopup.modal.myOrderPopup .xpry.modal_form_field {
	margin-bottom: 0;
}
.modal .modal-body .modal_body_haeding {
	color: #061f39;
	font-size: 40px;
	line-height: 48px;
	margin-bottom: 45px;
	max-width: calc(100% - 80px);
	width: 100%;
}
.status_table tbody .deactiavted_row td:last-child, .status_table tbody .actiavted_row td:last-child {
	font-size: 20px;
}
.status_main table td {
	font-size: 20px;
}
.user-listing .status_table {
	margin-top: 0;
}
.no_user_listed {
	margin-top: 50px;
}
.no_user_listed .main_distribution_btn .distribution_btns {
	margin-bottom: 0;
}
.search_section img {
	position: absolute;
	top: 17px;
	left: 12px;
}
.modal.select_subscription table tbody tr.table-price {
	background: #fffcf7 !important;
}
.subscription_table table tbody tr td {
	width: 19%;
}
#members .no_user_listed {
	margin-top: 0;
}
#users .no_and_search_samestyle span, #members .no_and_search_samestyle span,#scanner .no_and_search_samestyle span{
	color: #061f39;
	font-size: 20px;
    font-family: 'Museo Sans 300';
    line-height: 24px;
}
#members .no_listing_text {
	clear: both;
	margin-top: 24px;
	float: left;
	width: 100%;
}
.my_pass_templates .tab_background {
	padding: 40px 40px 25px;
}
.my_scanner .tab_background {
	padding: 0px 40px 25px;
}
.my_scanner .tab_background.tab_background_scanner_distribution{
    padding: 40px 40px 40px;
}
.floating-label_group .error-main {
	position: absolute;
	bottom: -22px;
}
.signupForm .floating-label_group .company_name_error {
	position: absolute;
	bottom: -39px;
}
.sign_up_form .form-group {
	margin-bottom: 66px;
}
 form .error-main .error {
	margin: 2px 0 0;
} 
.header_login.sticky {
	padding-top: 31px;
}
.forgot_page .form-group {
	margin-bottom: 75px;
}              
.forgot_page {
	padding-top: 160px;
}
.no_user_listed.no_and_search_samestyle, .no_scanner_listed.no_and_search_samestyle {
    margin-top: 0;
}
.codes_main_section .dummy_data-content .dummy_data-content-logo {
	top: 54.5%;
}
.status_main table td button.deactivate {
	max-width: 136px;
	float: right;
	border: 2px solid #F16772;
	background: transparent;
	color: #1B3F71;
	border-radius: 8px;
	height: 35px;
	line-height: normal;
	padding: 5px 7px;
}
.status_main table td button.deactivate:hover {
	background: #F16772;
	color: #fff;
}
.status_main table td button.deactivate[data-target="#deleteHolder"]{
    max-width: 136px;
	float: right;
	border: 2px solid #F16772;
	background: #F16772;
	color: #ffffff;
	border-radius: 8px;
	height: 35px;
	line-height: normal;
	padding: 5px 7px;
}
.status_main table td button.deactivate[data-target="#deleteHolder"]:hover{
	background: #fff;
	color: #1B3F71;
}
.status_main table td button.deactivate.disable, .status_main table td button.active.disable {
    cursor: not-allowed;
    color: #061f39;
    background: #dadada;
    border-color: #d6d6d6;
}

.status_main table td button.deactivate.disable:hover, .status_main table td button.activate.disable:hover {
    color: #061f39;
    background: #dadada;
    border-color: #d6d6d6;
}

.status_main table td button.active {
	background: #8EC976;
    color: #fff;
    max-width: 108px;
	float: right;
	border: 2px solid #8EC976;
	border-radius: 8px;
	height: 35px;
	line-height: normal;
	padding: 0;
}
.status_main table td  button.active:hover {
	background: #ffffff;
    color: #061f39;
}
.swiper-button-disabled {
    display: none !important;
}
.upgrade_subsirption .cancel_subscription_deactivate{
    background:#dadada !important;
    border-color:#d6d6d6 !important;
    cursor: not-allowed;
    color:#fff !important
}
.modal_form_field.floating_cvc.form-group {
    width: 50%;
    float: left;
    padding-left: 15px;
}
.banner-slider img {
	width: auto;
	max-height: 406px;
}
.banner-slider .swiper-wrapper .swiper-slide {
	width: 332px !important;
	text-align: center;
	padding: 31px 0;
}

.banner-slider .swiper-wrapper .swiper-slide .primary-field {
    text-align: left;
}
.swiper-container::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 95%;
    top: 0;
    right: -12px;
    box-shadow: 10px -4px 20px 138px #fbfbfb;
    z-index: 9;
}
.swiper-container{position:relative}
.my_pass_main_section.slider_with_pending_temp section.banner-slider.bigger-slider{
	width: calc(100% - 280px - 29px);
    margin-top: 25px;
    overflow: hidden;
    padding-bottom: 26px;
    padding-left:0;
}
.my_pass_main_section.slider_with_pending_temp section.banner-slider .swiper-container {
	overflow: visible;
}
.banner-slider .swiper-button-prev {
    background-image: url(../images/arrow-left.png);
    background-size: 36px 21px;
    background-repeat: no-repeat;
    height: 65px;
    width: 65px;
    position: absolute;
    top: 48%;
    display: flex;
    align-items: center;
    border: 2px solid #57c0d9;
    border-radius: 100%;
    background-position: 14px 19px;
    left: 10px;
    z-index: 99;
    background-color: #ffffff;
    justify-content: center;
}
.banner-slider .swiper-button-next {
    background-image: url(../images/arrow-right.png);
    background-size: 36px 21px;
    background-repeat: no-repeat ;
    height: 65px;
    width: 65px;
    position: absolute;
    top: 48%;
    display: flex;
    align-items: center;
    border: 2px solid #57c0d9 ;
    border-radius: 100%;
    background-position: 14px 19px ;
    right: 10px;
    background-color: #fff;
    z-index: 99;
}
.term-popup .modal-footer button.btn.btn-danger {
	background: #57c0d9;
	border: 2px solid #57c0d9;
	color: #ffffff;
}
.term-popup .modal-footer button.btn.btn-danger:hover {
	background: transparent;
	color: #57c0d9;
}
.banner-slider.swiper-button-prev::after, .banner-slider .swiper-button-next::after{display: none;}
header.sticky, .header_login {
	/* padding-left: 85px;
    padding-right: 85px; */
    background-color:#fffcf7;
}
.headerInside {
	width: 100%;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
    align-items: center;
    padding:0 0px;
}
.my_profile_section .nav.nav-tabs li {
	padding-right: 34px;
}
.same_profile_tab_style form {
	max-width: 960px;
}
.modal .modal_form_field input {
	border-bottom: 1px solid #666256;
}
#members .modal-footer {
	padding-bottom: 60px;
}
#uploadUser .modal-dialog .modal-content .modal-title {
    margin-bottom: 5px;
}
.modal-open-again .modal, .modal-open-again-order .modal {
	overflow-x: hidden;
	overflow-y: auto;
}
.modal-open-again, .modal-open-again-order{
    overflow: hidden;
    padding-right: 15px;
}
.modal-content {
	background-color: #FFFCF7;
}
#orderform {
	padding-top: 85px;
}
.modal.myOrderPopup #orderform .modal_form_field label {
	font-family: 'Museo Sans 300';
	top: 4px;
}
.modal.myOrderPopup #orderform .xpry.modal_form_field label {
	font-size: 20px;
}
.modal.myOrderPopup #orderform .modal_form_field input {
    padding: 10px 0 16px;
    height:auto;
}
#OrderTempPopup.modal.myOrderPopup .modal_form_field {
	margin-bottom: 55px;
}
.modal.myOrderPopup #orderform .floating-label_group input:not(:placeholder-shown) ~ label, .modal.myOrderPopup #orderform .floating-label_group input:focus ~ label, .modal.myOrderPopup #orderform .floating-label_group input:not(:placeholder-shown) ~ label {
	top: -43px;
}
#OrderTempPopup.modal.myOrderPopup .modal_form_field.orderWithoutEdit {
	margin-bottom: 29px;
}
.front_layout.home .tab-content.tab_background #profile {
	padding-bottom: 45px;
}
#subscription.tab-pane.subscription_sec div > .subsription_heading.padding_container.payment_method {
	padding: 90px 0 25px;
	margin-bottom: 68px;
}  
#password.same_profile_tab_style form .form-group p.error {
	position: absolute;
}
#password.same_profile_tab_style form .form-group {
	float: left;
	width: 100%;
	margin-bottom: 72px;
}
#password.same_profile_tab_style form .form-group input {
	height: auto;
	padding-bottom: 4px;
}
.tab_background #password.tab-pane {
	padding: 38px 30px 200px;
	position: relative;
	float: left;
	width: 100%;
}
#subscription.tab-pane.subscription_sec div > .subsription_heading.padding_container {
	margin-bottom: 28px;
}
#cancel_subscription .modal-dialog form .btn.btn-default.submit_btn {
	height: auto;
	max-width: 200px;
	font-size: 27px;
	line-height: 1;
	padding: 15px 40px !important;
	min-width: 200px;
	width: auto;
}
#addUser .modal-dialog.modal-dialog-centered #loader {
	position: absolute;
}
.distribution .dummy_data-content .dummy_data-content-logo {
    top: 57%;
}

#addUserConfirm .modal-header {
	padding: 70px 60px 0px;
}
#addUserConfirm .modal-dialog .modal-content.bg_color_section .modal-body span {
	margin-top: 48px;
}
div#sendUser .modal-dialog .send-right div > img {
	width: 100%;
}
#sendUser .modal-body form#sendForm .custom-errorMsg {
	bottom: -8px;
}
#members div#sendUser .modal-dialog #loader {
	border-radius: 19.5px;
	position: absolute;
}
.order_fornew_passes .demo-pass-template .pass-design-layout img {
    width: 100%;
}
.start_ordering_passes .dashboard-order.order_pass.no-template {
    margin-top: 55px;
    cursor: pointer;
}
.order_fornew_passes .demo-pass-template {
    margin-top: 27px;
}
p.date-selection {
    margin: 0 0 18px;
    font-size: 20px;
    color: #061F39;
    line-height: 24px;
}
.modal .receipt_section .thakyou_msg {
    margin: 40px 0 50px;
}
#OrderConfirmation .submit_btn {
    width: auto;
    font-size: 27px;
}
#OrderConfirmation.modal .modal-content {
    border-radius: 20px;
}

.order_fornew_passes .add_order_disable.order_pass {
    padding: 127px 0 101px;
}
.modal .subscription_table table tbody tr.table-action-tab td.active-plan {
	padding-bottom: 0px; 
}
.my_pass_main_section.slider_with_pending_temp .add_order_disable.dashboard-order.order_pass {
	width: 191px;
}
.myOrderPopup form#orderform .modal_main_field.form-group.valid-thru-field {
    margin-bottom: 80px;
}

.tab-content.tab_background.insideProfile.block_element #users .no_listing_text h3 {
	margin-top: 0px;
}
.myOrderPopup form#orderform .modal_main_field.form-group.valid-thru-field .modal_form_field.form-group {
	margin-bottom: 0;
}
#myOrderPopup #orderform .modal_form_field.form-group:nth-child(3) {
	margin-bottom: 68px;
}
.payment_method_form .modal .modal-body .modal_body_haeding {
	font-family: 'Museo Sans 100';
}
.payment_method_form .modal.myOrderPopup #orderform > .modal_form_field:first-child > label {
	top: -12px !important;
	position: relative;
}
#orderform .modal_form_field.form-group:nth-child(3){margin-bottom: 68px;}
.status_main table th div div span {
    position: absolute;
}
.no_user_listed .main_distribution_btn .distribution_btns {
	margin-bottom: 10px;
}
#myOrderPopup0.modal.myOrderPopup #orderform .modal_form_field:first-child label {
	font-size: 20px;
}

#addUserForm .floating-label_group select {
	border: none;
	padding: 10px 10px;
	font-size: 30px;
	border-bottom: 1px solid #b2afa6;
	background: transparent;
	padding: 0 0 7px !important;
	margin-top: 35px;
}
.user-listing .status_main button, #scanner_distribution_data .status_main button {
	border: 1px solid transparent;
}

/* .upgrade_subscription_btn + .modal.myOrderPopup #orderform .modal_form_field .floating-label_group label {
	top: -31px;
	font-size: 20px;
} */
.banner-slider .swiper-wrapper .swiper-slide .template_image_coupon{position: relative;}

div#sendUser .modal-dialog .send-right .send_pass_with_image.template_image_coupon::before,
.banner-slider .swiper-wrapper .swiper-slide .template_image_coupon::before{
    position: absolute; 
    content:"";
    background-image: url("../images/down.png");
    background-repeat: no-repeat;
    width: 100%;
    top:-2px;
    height: 9px;
    z-index: 9;
    left: 0;
    right: 0;
    max-width: 283px;
    margin: 0 auto;
}
.banner-slider .swiper-wrapper .swiper-slide.template_without_image .pass_layout{
	width: 100%;
	max-height: 406px;
}
.tab-pane.subscription_sec form.sign_up_form.block_element .form-group-btn {
    margin-top: -36px;
}
div#sendUser .modal-dialog .send-right .send_pass_with_image.template_image_coupon::after, 
.banner-slider .swiper-wrapper .swiper-slide .template_image_coupon::after{
    position: absolute;
    content:"";
    background-image: url("../images/top.png");
    background-repeat: no-repeat;
    width: 100%;
    bottom:0;
    height: 9px;
    z-index: 9;
    left: 0;
    right: 0;
    max-width: 283px;
    margin: 0 auto;
}
div#sendUser .modal-dialog .send-right .send_pass_with_image.template_image_coupon::before {
    width: 100%;
    max-width: 100%;
}
div#sendUser .modal-dialog .send-right .send_pass_with_image.template_image_coupon {
    position: relative;
}
div#sendUser .modal-dialog .send-right .send_pass_with_image.template_image_coupon::after {
    width: 100%;
    max-width: 100%;
}
.myOrderPopup .submit_btn_deactivate{
    background: #e4e0d0 !important;
    border: 1px solid #e4e0d0 !important;
}
.myOrderPopup .submit_btn_deactivate:hover{
    background: #e4e0d0 !important;
    border: 1px solid #e4e0d0 !important;
    color: #fff !important;
}
.banner-slider .swiper-wrapper .swiper-slide.template_without_image {
    padding: 31px 26px;
}
.template_without_image .pass_layout.ticket.ticket-with-strip {
    width: 100%;
    padding: 0;
    max-height: 407px;
}
.myOrderPopup input.btn-danger:hover {
	background: transparent;
	color: #dc3545;
}
.modal.myOrderPopup #orderform .xpry.modal_form_field > label {
	top: -16px;
	position: relative;
}
#single-form-group .floating-label_group .image-input label {
    top: -46px;
    font-size: 20px;
    color: #97937f;
    font-family: 'Museo Sans 300';
}
#myOrderPopup1 input.btn-danger#testback {
	margin-right: 0px;
}
#myOrderPopup1 input.btn-danger:hover {
	background: transparent;
	border-color: #F16772;
}
#single-form-group .floating-label_group .image-input input {
    padding-bottom: 5px;
}
.banner-slider .swiper-wrapper .swiper-slide.template_without_image .pass_layout.generic-pass-layout {
	width: 100%;
	max-height: 407px;
}
.modal.myOrderPopup #orderform > .modal_form_field:first-child > label {
	font-size: 20px;
	line-height: 24px;
	color: #C9C4A9;
	top: 45px !important;
	position: absolute;
}
.Dummy-Data .activate_btn {
    background: #E4E0D0;
    color: #C9C4A9;
}
.Dummy-Data .status_table tbody tr td{
    padding: 10px 26px 10px 48px;
}
.Dummy-Data.distribution._section .status_table tbody tr td,  .Dummy-Data.distribution._section  .status_table th {
    padding: 13px 0px 14px 0px;
}
.Dummy-Data.distribution._section .status_table th {
    padding: 25px 0px;
}
.no_user_listed.no_and_search_samestyle,.no_scanner_listed.no_and_search_samestyle {
    margin-top: 16px;
    display: block;
    float: left;
    width: 100%;
}
.payment_method_form .modal .modal-body #orderform {
	padding-top: 25px;
}
#OrderTempPopup.modal.myOrderPopup .modal_form_field.form_field_spacing.hide-input-field, .modal.myOrderPopup .modal_form_field.form_field_spacing.hide-input-field {
    margin-bottom: 29px;
}
.distribution #members .dummy_data-content .dummy_data-content-logo {
	top: 50.7%;
}
.hower-bodys .drag-input.value, .ticket-with-thumbnail .grab-input .hower-body .drag-input.value {
    white-space: nowrap;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}
.has-logo-image .template-logoText .grab-input {
	width: 100%;
}
.has-logo-image .template-logoText .logoTextInner {
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.banner-slider .template-barcodeSection img {
    width: 100%;
    max-height: initial;
}
.pass_layout.ticket.ticket-with-thumbnail .template-stripSection .primary-field .field-strip1 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.pass_layout.member-pass .template-stripSection .primary-field {
    width: calc(100% - 75px);
}
.pass_layout.member-pass .template-stripSection {
    width: 100%;
    margin-right: 0 !important;
}
.has-logo-image .template-logoText .template-logoText_innerText {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.pass_layout.ticket-with-thumbnail .template-headerSection {
	padding-right: 10px;
}
#modalcontactus .modal-content.bg_color_section {
	border-radius: 20px;
	max-width: 908px;
}
#modalcontactus h1 {
    color: #061F39;
    font-size: 40px;
    line-height: 48px;
    font-family: 'Museo Sans 100';
    margin-bottom: 6px;
}
#contactus-form textarea {
    border-radius: 12px;
    resize: none;
    min-height: 316px;
    width: 100%;
    border: 2px solid #d8e2e3;
    background: #FFFCF7;
    padding: 15px 19px;
    font-size: 20px;
    line-height: 24px;
    color: #061F39;
    font-family: 'Museo Sans 300';
}
#contactus-form .form-group-btn {
	text-align: center;
}
#modalcontactus  .modal-footer {
	padding-bottom: 40px;
}
#contactus-form .form-group-btn .contactus_btn_deactivate {
	background: #E4E0D0;
}
#contactus-form .form-group-btn .contactus_btn_active {
	background: #57C0D9;
}
#contactus-form .form-group-btn .contactus_btn_active:hover {
    border-color: #57C0D9;
    color:#57C0D9;
    background-color:transparent;
}
#modalcontactus .modal-body h3 {
	font-size: 20px;
	line-height: 24px;
}
footer .footer_content .footer_rgt span {
	cursor: pointer;
}
#contactus-form {
    padding: 26px 0 0;
}
#contactus-form textarea::-webkit-textarea-placeholder { 
    color: #97937F;
    opacity:1;
  }
  #contactus-form textarea::-moz-placeholder { 
    color: #97937F;
    opacity:1;
  }
  #contactus-form textarea:-ms-textarea-placeholder { 
    color: #97937F;
    opacity:1;
  }
  #contactus-form textarea:-moz-placeholder { 
    color: #97937F;
    opacity:1;
  }
  #contactus-form .form-group-btn input[type="submit"] {
	display: inline-block;
	max-width: 258px;
	width: 100%;
	text-align: center;
	letter-spacing: 0.03em;
	color: #FDFBEF;
	border-radius: 40px;
	font-size: 36px;
    box-shadow: none;
    border: 2px solid transparent;
    padding: 11px;
}
#contactus-form .form-group {
	margin-bottom: 12px;
}
body {
    padding-top: 120px;
}
.header_login {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}
.have-subscription .subscription_table table tbody .table-price td .price_cutoff {
	padding-bottom: 8px;
}
.have-subscription .subscription_table table tbody .table-price td .price_kr {
	padding-bottom: 2px;
}
.subscription_table table.subscription_single_table tr td:first-child{padding:14px 30px}
.subscription_table table.subscription_single_table tr td{padding:14px 10px 14px 20px}
#UpgradeSubscription .unsbscribed_account .subscription_table table td:first-child {
    padding: 14px 12px 14px 30px;
}
#UpgradeSubscription .unsbscribed_account .subscription_table table tbody .table-price td .free_subsription_btns {
    padding: 10px 10px;
}
#UpgradeSubscription.select_subscription .unsbscribed_account .modal-header {
    padding-bottom: 50px;
}
#UpgradeSubscription.select_subscription .unsbscribed_account span.price_cutoff {
    padding-bottom: 7px;
}
#UpgradeSubscription.select_subscription .have-subscription .modal-header {
    padding-bottom: 30px;
}
#UpgradeSubscription.select_subscription .unsbscribed_account table th {
    font-size: 30px;
    padding-bottom: 30px;
}
#UpgradeSubscription.select_subscription .have-subscription .modal-header span {
    padding-bottom: 20px;
    display: block;
}
#subscription.tab-pane.subscription_sec div > .subsription_heading.padding_container .pay_with_invoice span {
    font-size: 20px;
    line-height: 24px;
    color: #061F39;
    font-family: 'Museo Sans 300';
}
#subscription.tab-pane.subscription_sec div > .subsription_heading.padding_container .pay_with_invoice h4 {
    font-size: 30px;
    line-height: 37px;
    color: #061F39;
    font-family: 'Museo Sans 100';
    margin-bottom: 15px;
}
#subscription.tab-pane.subscription_sec div > .subsription_heading.padding_container .pay_with_invoice {
    padding-top: 20px;
}
.first_front_page {
	padding-top: 0;
}
.container{max-width:1650px; margin:0 auto; padding:0 15px;}
.inner-pages {
	background: #fffcf7;
}

.front_layout .inner-pages .bg_color_section.block_element.main_logo_heading_padding.my_pass_templates.my_profile_section.padding_container + .tab-content-inner {
    width: 100vw;
    margin-left: 50%;
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
.inner-pages{
    overflow-x: hidden;
}
.front_layout.home .tab-content.tab_background.tab_background_usertab {
	padding-bottom: 60px;
}
.privacy_policy_page {
	padding: 83px 0 0;
    background: #FFFCF7;
    float: left;
    width: 100%;
}
.privacy_policy_page .container > span {
	font-style: normal;
	font-weight: normal;
	font-size: 50px;
	line-height: 60px;
	letter-spacing: 0.03em;
	color: #1B3F71;
	margin-bottom: 30px;
	font-family: 'Museo Sans 500';
	display: block;
}
.privacy_policy_page h3 {
	font-style: normal;
	font-weight: 300;
	font-size: 22px;
	line-height: 25px;
	color: #061F39;
    margin-bottom: 60px;
    font-family: 'Museo Sans 500';
    letter-spacing: 0;
}
.privacy_policy_page h2 {
	font-style: normal;
	font-weight: 500;
	font-size: 30px;
	line-height: 1;
	color: #061F39;
	margin-bottom: 15px;
    font-family: 'Museo Sans 500';
    letter-spacing: 0;
}
.privacy_policy_table{
	width: 100%;
	overflow-x: auto;
}
.privacy_policy_page p {
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 24px;
	color: #061F39;
	margin-bottom: 45px;
}
.privacy_policy_page ol {
	margin-left: 30px;
	margin-bottom: 45px;
    list-style-type: decimal;
    padding-left:0;
}
.privacy_policy_page  table {
	width: 100%;
	margin-bottom: 45px;
}
.privacy_policy_page  table td {
	padding: 10px;
	border: 1px solid #545454;
	font-size: 20px;
	line-height: 24px;
	color: #061F39;
	margin-bottom: 45px;
}
.privacy_policy_page ol.privacy-list {
	list-style-type: lower-alpha;
	padding-left: 0;
}
.privacy_policy_page  h4 b {
	margin-bottom: 10px;
	display: block;
	font-size: 20px;
}
.privacy_policy_page table td a {
    color: #061F39;
    text-decoration: none;
}
.privacy_policy_page  ul {
	margin-left: 30px;
	margin-bottom: 45px;
    list-style-type: disc;
    padding-left:0;
}
.privacy_policy_page  ul li, .privacy_policy_page ol li {
	font-size: 20px;
	line-height: 24px;
	color: #061F39;
	margin-bottom: 5px;
}
.active_user_account .multi_user_section {
	max-width: 960px;
    margin: 0 auto;
    clear:both;
    width:100%;
}
.active_user_account {
	padding: 0 30px;
	clear: both;
	padding-bottom: 270px;
	float: left;
	width: 100%;
}

.multi_user_section h2 {
	font-size: 40px;
	line-height: 48px;
	font-family: 'Museo Sans 100';
	border-bottom: 2px solid #D8E2E3;
	padding-bottom: 18px;
    margin-bottom: 23px;
    color:#061F39;;
}
.multi_user_section h4 {
	font-size: 30px;
	line-height: 36px;
    font-family: 'Museo Sans 100';
    color: #061F39;
}
.multi_user_section span {
	font-size: 20px;
	line-height: 24px;
	font-family: 'Museo Sans 300';
	width: 100%;
    display: block;
    color: #061F39;
    margin-bottom: 6px;
}
.active_user_account .multi_user_section span:nth-child(3) {
	margin-bottom: 30px;
}
.multi_user_section form input#email {
	background: #FFFCF7;
	border-radius: 12px;
	width: 100%;
	border: 2px solid #D8E2E3;
	height: 60px;
	padding: 10px 20px;
    font-size: 21.75px;
    margin-bottom: 16px;
}
.active_user_account .multi_user_section .user_invitation_container {
	float: left;
	width: 100%;
}
.multi_user_section .user_invitation_container .form-group-btn input.isNotActive {
	background: #E4E0D0;
	color: #FDFBEF;
	border: 2px solid #E4E0D0;
}
.multi_user_section .user_invitation_container .form-group-btn input.isActive {
	background: #57C0D9;
	color:  #FDFBEF;
	border: 2px solid #57C0D9;
}
.multi_user_section .user_invitation_container .form-group-btn input {
	font-size: 36px;
	border-radius: 40px;
	max-width: 370px;
	width: 100%;
	height: 80px;
	float: right;
}
.active_user_account .multi_user_section .user_invitation_container .form-group-btn {   
	width: 100%;
	text-align: right;
}
.active_user_account .multi_user_section .upgrade_subscription_btn {
    margin-top: 5px;
    float: right;
    height:80px;
}
.associated_user_list .associated_user_list_table {
	width: 100%;
}
.associated_user_list .associated_user_list_table td {
	font-size: 30px;
	line-height: 36px;
	color: #061F39;
	letter-spacing: 0.07em;
	font-family: 'Museo Sans 300';
	padding-bottom: 26px;
}
.profiletab_section .active_user_account .multi_user_section .upgrade_subscription_btn {
	width: 370px;
	font-size: 31px;
}
.associated_user_list {
	margin-top: 21px;
	display: block;
	float: left;
	width: 100%;
	padding-bottom: 50px;
}
.assocated_delete_btn, .assocated_permission_btn {
	background: #F16772;
	border: 2px solid #F16772;
	box-sizing: border-box;
	border-radius: 8px;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 0.07em;
	color: #FFFCF7;
	padding: 4px 17px;
	font-family: 'Museo Sans 500';
}
.assocated_delete_btn:hover, .assocated_permission_btn:hover {
	background: #fff;
	border: 2px solid #F16772;
	text-align: center;
	letter-spacing: 0.07em;
	color: #F16772;
}
.assocated_permission_btn{
    background:#57C0D9;
    border: 2px solid #57C0D9;
}
.assocated_permission_btn:hover{
    color:#57C0D9;
    border:2px solid #57C0D9;
}

.assocated_user_pending {
	font-style: italic;
	font-family: 'Museo Sans 300';
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.07em;
	color: #061F39;
}
.multi_user_section form input#email::-webkit-input-placeholder { 
    letter-spacing: 0.03em;
    color: #C9C4A9;
    opacity: 1;
    font-family: 'Museo Sans 300';
  }
  .multi_user_section form input#email::-moz-placeholder { 
    letter-spacing: 0.03em;
    color: #C9C4A9;
    opacity: 1;
    font-family: 'Museo Sans 300';
  }
  .multi_user_section form input#email:-ms-input-placeholder { 
    letter-spacing: 0.03em;
    color: #C9C4A9;
    opacity: 1;
    font-family: 'Museo Sans 300';
  }
  .multi_user_section form input#email:-moz-placeholder { 
    letter-spacing: 0.03em;
    color: #C9C4A9;
    opacity: 1;
    font-family: 'Museo Sans 300';
  }
  .header_login.mobile_header {
	display: none;
}
.banner-slider .template_issue_date {
	position: absolute;
	bottom: 4px;
	font-size: 17px;
	text-align: center;
	width: 100%;
	left: 0;
	right: 0;
}
.banner-slider .swiper-wrapper{
	position: relative;
}

.mobile_header_toggle_menu span:not(:last-child) {
	margin-bottom: 6px;
}
.mobile_header_toggle_menu span:not(:last-child) {
	margin-bottom: 6px;
}
.mobile_header_toggle_menu span {
	display: block;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: rgb(6, 31, 57) none repeat scroll 0% 0%;
	transition: all 0.4s ease 0s;
}
.mobile_header .headerInside {
	display: block;
}
.mobile_header .header_logo {
	display: flex;
	justify-content: space-between;
	padding: 20px 30px 20px;
}
.mobile_header_toggle_menu.menu_toggled span:first-child {
    -webkit-transform: translateY(121px) rotate(45deg);
    -moz-transform: translateY(21px) rotate(45deg);
    transform: translateY(21px) rotate(45deg);
    width: 32px;
    height: 3px;
    border-radius: 0px;
}
.mobile_header_toggle_menu.menu_toggled span:nth-child(2) {
    -webkit-transform: translateY(12px) rotate(-45deg);
    -moz-transform: translateY(12px) rotate(-45deg);
    transform: translateY(12px) rotate(-45deg);
    width: 32px;
    height: 3px;
    border-radius: 0px;
}
.mobile_header_toggle_menu.menu_toggled span:last-child {
    opacity: 0;
    display: none;
}

.mobile_menu_list {
	padding: 75px 30px 35px;
	position: fixed;
	inset: 110px 0px 0px;
	pointer-events: none;
	z-index: 5;
	background: rgb(255, 252, 247) none repeat scroll 0% 0%;
	display: block;
    -moz-transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
	max-width: 0px;
	opacity: 0;
    visibility: hidden;
    -webkit-transform: translate3d(0px, 0px, 0px);
	-moz-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
    width:100%;
}
.mobile_subscription_table {
    display: none;
}

/**Mobile Subscription**/
.pachageArea {
    background: #FFFCF7;
}
.pachageArea .AreaFull {
    padding: 15px 10px 15px 25px;
}
.pachageArea .AreaFull .ValueMain.NegotiableVlaueMain {
    font-family: 'Museo Sans 500';
}
#tollTipMainSection_4 .tollTipTrigger {
	margin-left: 0;
}
._ExpertMobileServicePriceSection._MobileServicePriceSection {
    padding: 20px 20px;
}
.tollTipTextSection{
    z-index:9;
}
.pachageArea h2 {
    padding: 40px 27px;
    margin-bottom: 0;
}
.pachageArea .AreaFull .ValueMain {
    width: 75px;
    font-family: 'Museo Sans 300';
}
.pachageArea .AreaFull .ValueMain.NegotiableVlaueMain {
    width: 90px;
}
.mobile_subscription_table .AreaFull .tollTipTrigger {
    transform: translateY(-15px);
    margin-left:2px;
}
.mobile_subscription_table .tollTipTrigger {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    margin-left: 2px;
}
#tollTipMainSection_0 #toolTip_0 {
    width: 100%;
    max-width: 340px;
    padding: 10px 25px;
    margin-right: 8px;
}
.listingInfo li img {
width: 20px;
margin-right: 8px;
float: left;
margin-top: 5px;
}		.SubscriptionPlansBoxMobile__CancelText {
font-size: 18px;
line-height: 22px;
color: #061F39;
}
.mobile_subscription_table_inside {
    margin-bottom: 25px;
}
.SubscriptionPlansBoxMobile__Text {
    font-size: 18px;
    line-height: 22px;
    color: #1B3F71;
    font-family: "Museo Sans 300";
}
.standard-fee-note {
    margin: 45px auto 30px;
}
.mobile_subscription_table {
    padding-top: 20px;
}
.pachageArea button {
    max-width: 252px;
    text-align: center;
    width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    border: 4px solid #57C0D9;
    box-sizing: border-box;
    border-radius: 40px;
    background: transparent;
    color: #1B3F71;
    padding: 6px 5px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
.pachageArea {
    padding-bottom: 35px;
    margin-bottom: 50px;
}
._MobileServicePriceSection .SubscriptionPlansBoxMobile__StrikeThroughPrice {
    line-height: 22px;
    color: rgb(29, 62, 111);
    font-size: 18px;
    font-family: "Museo Sans 300";
    text-decoration: line-through;
}
.SubscriptionPlansBoxMobile__Price {
    font-size: 30px;
    line-height: 36px;
    color: #1B3F71;
    font-weight: 700;
    margin-top: 6px;
    margin-bottom: 6px;
}
span._MobileServicePriceSection{
    color: rgb(27, 63, 113);
line-height: 28px;
font-size: 18px;
display: block;
padding: 28px 20px;
}
._MobileServicePriceSection > div {
    padding: 0;
}
.mobile_subscription_table .listingInfo ul {
    margin: 0 0 88px;
    padding: 0;
    list-style: none;
}
.mobile_subscription_table .listingInfo ul li {
    font-size: 18px;
    color: #1B3F71;
}
#tollTipMainSection_0 #toolTip_0 {
    font-weight: 600;
}
.mobile_subscription_table .tollTipMainSection .tollTipTrigger img {
    width: 13px;
    height: 13px;
}
.pachageArea button:hover {
    background: #57C0D9;
    color: #fff;
}
.listingInfo li {
    position: relative;
    margin-bottom: 15px;
}
.listingInfo .listingInfo-list {
    float: left;
    margin-right: 0px;
}
.mobile_subscription_table_inside h2 {
    font-size: 36px;
    line-height: 43px;
    letter-spacing: 0.02em;
    color: #1B3F71;
    margin-bottom: 20px;
    padding-right:45px;
}
.mobile_subscription_table_inside h3 {
    font-size: 18px;
    line-height: 22px;
    color: #061F39;;
    font-family: 'Museo Sans 500';
}
.listingInfo p {
    font-size: 18px;
    line-height: 22px;
    color: #061F39;;
    font-family: 'Museo Sans 500';
}
.mobile_subscription_table .pachageArea .tollTipTextSection {
    position: absolute;
    font-size: 10.15px;
    line-height: 12px;
    max-width: 100%;
    width: 100%;
    top: 20px;
    left: 0px;
    border-radius: 10px;
    padding: 10px 10px;
    right: 0;
    z-index: 9;
}
.passes_more_details .passess-details, .passes_full_more_details .passess-details {
	width: 34.1%;
}
.passes_more_details .passess-details:nth-child(3), .passes_full_more_details .passess-details:nth-child(3) {
	width: 34%;
}
.passes_full_more_details .passess-details:last-child {
	margin-right: 0;
}
.passes_more_details {
	/* max-width: 892px; */
	max-width: 100%;
	border-radius: 12px;
	display: flex;
}
.passes_full_more_details {
	margin-top: 30px;
	float: left;
	width: 100%;
}
.day_status_of_passses, .time_status_of_passses {
	margin-top: 30px;
}
.time_status_of_passses p {
	font-size: 20px;
	line-height: 24px;
	color: #061f39;
	font-family: 'Museo Sans 500';
	margin-bottom: 58px;
}
.time_status_of_passses.block_element {
	padding: 30px 47px 45px 29px;
	background: #fffcf7;
	border-radius: 12px;
	min-height: 342px;
	border: 2px solid #dae4e4;
	max-height: 506px;
}
.time_status_of_passses.block_element .status_graph.same_status_section {
	padding: 0;
	border: none;
}
.time_status_of_passses .status_graph {
	min-height: auto;
}
.my_pass_templates .tab_background{
	padding: 40px 40px 40px;
}
.my_scanner .tab_background {
	padding: 0px 40px 40px;
}
.my_scanner .tab_background.tab_background_scanner_distribution{
    padding: 40px 40px 40px;
}
/**END**/



/**smart scanner**/
.my_scanner_page .main_logo_heading_padding.start_ordering_scanner.my_scanner > h3 {
	margin: 70px 0 85px;
	font-family: 'Museo Sans 100';
	letter-spacing: 0.07em;
}

#scanner .empty_add_scanner_tab .no_scanner_listed .form_btn_group .addscanner_cancel_btn{
    border: 2px solid #F16772;
    border-radius: 40px;
    font-size: 36px;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.03em;
    color: #061F39;
    height: 80px;
    width: 215px;
    float: left;
    padding: 17px;
    margin-right: 40px;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background: transparent;
}
.my_scanner_page .my_scanner .block_element a:hover {
    text-decoration: none;
}
#scanner .empty_add_scanner_tab .no_scanner_listed .form_btn_group .addscanner_cancel_btn:hover{
    background-color: #F16772;
    color: #FDFBEF;
}
.my_scanner_page .start_ordering_scanner.my_scanner .nav.nav-tabs li {
	padding-right: 55px;
}
.my_scanner_page .my_scanner.start_ordering_scanner .tab_background {
	padding: 24px 40px 83px;
}
.my_scanner_page .my_scanner.start_ordering_scanner .tab_background .no_and_search_samestyle span {
    letter-spacing: 0.07em;
}
#addScannerForm .add_scanner_checkbox_list {
	list-style: none;
}
#addScannerForm .add_scanner_checkbox_list input[type="checkbox"] {
	float: left;
}

.form-group-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .form-group-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .form-group-container .checkmark {
	position: absolute;
	top: -4px;
	left: 0;
	height: 41px;
	width: 41px;
	background-color: #E4E0D0;
	margin-left: 0;
}
  .form-group-container:hover input ~ .checkmark {
    background-color: #E4E0D0
  }
  .form-group-container input:checked ~ .checkmark {
    background-color: #E4E0D0;
  }
  .form-group-container .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .form-group-container input:checked ~ .checkmark:after {
    display: block;
  }
  .form-group-container .checkmark::after {
	left: 13px;
	top: 4px;
	width: 17px;
	height: 27px;
	border: solid #57C0D9;
	border-width: 0 6px 6px 0;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}
#addScannerForm .form-group input#nameofScannerInput, #updateScannerForm .form-group input#nameofScannerInput{
	border: none;
	border-bottom: 1px solid #666256;
	width: 788px;
	background: transparent;
	font-size: 30px;
	line-height: 36px;
	font-family: 'Museo Sans 300';
    padding-bottom: 10px;
    color:#061F39;
}
#addScannerForm .form-group input::-webkit-input-placeholder, #updateScannerForm .form-group input::-webkit-input-placeholder { 
    color: #97937F;
    opacity: 1;
    font-family: 'Museo Sans 300';
  }
  #addScannerForm .form-group input::-moz-placeholder , #updateScannerForm .form-group input::-moz-placeholder{ 
    opacity: 1;
    font-family: 'Museo Sans 300';
    color: #97937F;
  }
  #addScannerForm .form-group input:-ms-input-placeholder, #updateScannerForm .form-group input:-ms-input-placeholder { 
    color: #97937F;
    opacity: 1;
    font-family: 'Museo Sans 300';
  }
  #addScannerForm .form-group input:-moz-placeholder, #updateScannerForm .form-group input:-moz-placeholder { 
    color: #97937F;
    opacity: 1;
    font-family: 'Museo Sans 300';
  }
  .form-group-container {
	font-family: 'Museo Sans 300';
	font-size: 30px;
	line-height: 36px;
	padding-left: 55px;
	padding-bottom: 13px;
	margin-bottom: 25px;
}
.form-group-container:first-child {
	margin-top: 46px;
	margin-bottom: 34px;
}

#addScannerForm .form_btn_group .addscanner_cancel_btn, .scanner_tab_delete_btn .scanner_btn.delete_scanner {
	border: 2px solid #F16772;
	border-radius: 40px;
	font-size: 36px;
	line-height: 100%;
	text-align: center;
	letter-spacing: 0.03em;
	color: #061F39;
	height: 80px;
	width: 215px;
	float: left;
	padding: 17px;
	margin-right: 40px;
	display: flex;
	justify-content: center;
    align-items: center;
    text-decoration: none;
    background: transparent;
}
#addScannerForm .form_btn_group .addscanner_cancel_btn:hover, .scanner_tab_delete_btn .scanner_btn.delete_scanner:hover {
    background-color: #F16772;
    color: #FDFBEF;
}
#addScannerForm .form_btn_group .addscanner_add_btn, #updateScannerForm .form_btn_group .addscanner_add_btn{
	font-size: 35px;
	line-height: 100%;
	text-align: center;
	letter-spacing: 0.03em;
	color: #FDFBEF;
	border-radius: 40px;
	height: 80px;
    width: 316px;
    border:none;
    background-color: #57C0D9;
}
#addScannerForm .form_btn_group .addscanner_add_btn.add_btn_deactivate, #updateScannerForm .form_btn_group .addscanner_add_btn.add_btn_deactivate{
	color: #FDFBEF;
    background-color: #E4E0D0;
}
#addScannerForm .form_btn_group, #updateScannerForm .form_btn_group {
	margin-top: 169px;
	padding-left: 215px;
}

.scanner_tab_delete_btn .scanner_btn.delete_scanner {
	border: 1px solid #F16772;
	border-radius: 40px;
	font-size: 36px;
	line-height: 100%;
	text-align: center;
	letter-spacing: 0.03em;
	color: #061F39;
	height: 80px;
	width: 370px;
	float: left;
	padding: 17px;
	margin-right: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	background: transparent;
	margin-right: 0;
}
#scanner .scanner_update_form {
	display: flex;
	float: left;
	width: 100%;
	justify-content: space-between;
}
 #updateScannerForm .form_btn_group {
	margin-top: 90px;
	padding-left: 0;
}
.my_scanner_page .my_scanner.start_ordering_scanner .tab_background #updateScannerForm {
	padding-top: 39px;
}
/**END**/
#scanner_distribution .main_distribution_btn {
    margin-bottom: 42px;
}


.main_logo_heading_padding.start_ordering_scanner.my_scanner.padding_container .slider_with_pending_temp .dashboard-order.order_scanner {
	height: 305px;
	width: 191px;
	padding: 70px 0 50px;
	margin-right: 29px;
	margin-top: 55px;
}
.main_logo_heading_padding.start_ordering_scanner.my_scanner.padding_container .slider_with_pending_temp .dashboard-order.order_scanner p {
	font-size: 20px;
	line-height: 24px;
}
.main_logo_heading_padding.start_ordering_scanner.my_scanner.padding_container .slider_with_pending_temp {
	display: flex;
}
.main_logo_heading_padding.start_ordering_scanner.my_scanner.padding_container .slider_with_pending_temp .same_status_section.codes_section {
	flex-direction: column;
}
.main_logo_heading_padding.start_ordering_scanner.my_scanner.padding_container .slider_with_pending_temp #banner-slider {
	margin-top: 0;
	width: calc(100% - 192px - 29px);
}
.main_logo_heading_padding.start_ordering_scanner.my_scanner.padding_container .slider_with_pending_temp .same_status_section.codes_section {
	flex-direction: column;
    padding: 0;
    background-color: transparent;
    margin: 0;
    border: none;
	border-radius: 0;
}
.main_logo_heading_padding.start_ordering_scanner.my_scanner.padding_container .slider_with_pending_temp #banner-slider .swiper-slide.item {
	width: 373px !important;
	position: relative;
	padding: 56px 0 42px;
}
.main_logo_heading_padding.start_ordering_scanner.my_scanner.padding_container  .swiper-container::after {
	box-shadow: 10px -4px 20px 68px #fffcf7;
}
.main_logo_heading_padding.start_ordering_scanner.my_scanner.padding_container .slider_with_pending_temp #banner-slider .codes_right .codes-image_section {
	padding: 14px 10px;
	border: 2px solid #81cfe0;
	border-radius: 12px;
	text-align: center;
	width: 309px;
	height: 305px;
	background: #FFFCF7;
}
.codes_right .codes-image_section a {
	text-decoration: none;
}
.main_logo_heading_padding.start_ordering_scanner.my_scanner.padding_container  .same_status_section.codes_section .codes_right {
	flex-direction: column;
	align-items: center;
	float: none;
	max-width: initial;
	width: 100%;
	text-align: center;
}
.main_logo_heading_padding.start_ordering_scanner.my_scanner.padding_container .slider_with_pending_temp .same_status_section.codes_section h3 {
	margin: 0;
	font-size: 25px;
	line-height: 30px;
	color: #000;
	font-family: 'Museo Sans 500';
	text-transform: capitalize;
	position: absolute;
	left: 0;
	right: 0;
	top: 20px;
}
#deleteScanner .modal-dialog.modal-dialog, #deleteHolderList .modal-dialog.modal-dialog{
	border-radius: 20px;
}
#deleteScanner .modal-dialog .modal-content, #deleteHolderList .modal-dialog .modal-content, #templateTypeLimitPopup .modal-dialog .modal-content{
    border-radius: 20px;
}
#deleteScanner  .modal-dialog .modal-header h4{
	font-family: 'Museo Sans 300';
    max-width: 540px;
    color: #061f39;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 45px;
    width: 100%;
}
#deleteHolderList .modal-dialog .modal-header h4, #templateTypeLimitPopup .modal-dialog .modal-header h4{
	font-family: 'Museo Sans 300';
    color: #061f39;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 45px;
    width: 100%;
}


#deleteScanner .modal-dialog .modal-body p, #deleteHolderList .modal-dialog .modal-body p,  #deleteHolder .modal-dialog .modal-body  p, #templateTypeLimitPopup .modal-dialog .modal-body p{
	padding-top: 20px;
	font-size: 20px;
	line-height: 24px;
	font-family: 'Museo Sans 500';
    max-width: 100%;
    margin-bottom:0;
}
#deleteScanner .modal-dialog .modal-body p:last-child, #deleteHolderList .modal-dialog .modal-body p:last-child, #deleteHolder .modal-dialog .modal-body p{
	padding-top: 0px;
}
#deleteScanner  .modal-dialog .modal-body, #deleteHolderList .modal-dialog .modal-body,  #deleteHolder .modal-dialog .modal-body, #templateTypeLimitPopup .modal-dialog .modal-body {
	padding-bottom: 60px;
}
#deleteScanner .modal-dialog .modal-footer #cancelDeleteUser, #deleteHolderList .modal-dialog .modal-footer #cancelDeleteUser, #templateTypeLimitPopup .modal-dialog .modal-footer #cancelDeleteUser {
	order: 1;
	border-radius: 50px;
	font-family: 'Museo Sans 500';
	font-size: 27px;
	background: #fff !important;
	color: #061F39 !important;
	float: right !important;
	max-width: 190px;
	border: 1px solid #57c0d9;
	border-top-width: 1px;
	border-right-width: 1px;
	border-bottom-width: 1px;
	border-left-width: 1px;
	margin-top: 15px !important;
	width: 100% !important;
	padding: 22px 40px;
    margin-right: 28px;
    line-height: 1;
    text-transform: capitalize;
}
#templateTypeLimitPopup .modal-dialog .modal-footer .button_upgrade{
border: 1px solid #57C0D9;
box-sizing: border-box;
border-radius: 40px;
color: #1B3F71;
align-items: center;
text-align: center;
letter-spacing: 0.03em;
font-weight: normal;
font-size: 30px;
line-height: 100%;
background-color:transparent;
min-height:80px;
min-width:258px;
}
#templateTypeLimitPopup .modal-dialog .modal-footer .button_upgrade:hover{
background-color:#57C0D9;
color: #FDFBEF;	
}
#templateTypeLimitPopup .modal-dialog .modal-footer .button_order{
border: 1px solid #57C0D9;
box-sizing: border-box;
border-radius: 40px;
color: #FDFBEF;
align-items: center;
text-align: center;
letter-spacing: 0.03em;
font-weight: normal;
font-size: 30px;
line-height: 100%;
background-color:#57C0D9;
min-height:80px;
min-width:258px;
margin-left:22px;
}
#templateTypeLimitPopup .modal-dialog .modal-footer .button_order:hover{
color: #1B3F71;	
background-color:transparent;
}
#templateTypeLimitPopup .modal-dialog .modal-footer{
padding-right:42px;	
}
#deleteScanner .modal-dialog .modal-footer #cancelDeleteUser:hover, #deleteHolderList .modal-dialog .modal-footer #cancelDeleteUser:hover, #templateTypeLimitPopup .modal-dialog .modal-footer #cancelDeleteUser:hover {
    background: #57c0d9 !important;
    color: #fff !important;
}
#deleteScanner .deleteBtns , #deleteHolderList .deleteBtns, #templateTypeLimitPopup .deleteBtns {
	display: flex;
}
#templateTypeLimitPopup .modal-dialog .modal-header h4 {
    margin-bottom: 40px;
}
#templateTypeLimitPopup .modal-dialog .modal-body {
	max-width: 670px;
	padding-right: 0;
}
#deleteScanner .modal-header {
	flex-direction: column;
}
/* #deleteScanner .modal-dialog .modal-footer #cancelDeleteUser:hover, #deleteHolderList .modal-dialog .modal-footer #cancelDeleteUser:hover  {
    background: #fff !important;
    color: #57c0d9 !important;
} */
#deleteScanner .modal-dialog .modal-footer .deleteBtns #deleteUserBtn, #deleteHolderList .modal-dialog .modal-footer .deleteBtns #deleteUserBtn {
	order: 2;
	border: 2px solid #F16772;
	background-color: transparent !important;
	color: #061F39 !important;
	height: auto;
	max-width: 200px;
	font-size: 27px;
	line-height: 1;
	padding: 15px 40px !important;
	min-width: 170px;
	border-radius: 50px;
	font-family: 'Museo Sans 500';
	display: inline-block;
	height: auto;
	min-height: initial;
	margin-top: 15px;
}
#deleteScanner .modal-dialog .modal-footer .deleteBtns #deleteUserBtn:hover, #deleteHolderList .modal-dialog .modal-footer .deleteBtns #deleteUserBtn:hover  {
	background-color: #F16772 !important;
color: #fff !important;
}
#addLicenseHolderConfirm .modal-dialog, #sendLicenseConfirm .modal-dialog  {
	max-width: 474px;
	border-radius: 20px;
}
#addLicenseHolderConfirm .modal-dialog .modal-content.bg_color_section, #sendLicenseConfirm .modal-dialog .modal-content.bg_color_section  {
	border-radius: 20px;
}
#addLicenseHolderConfirm .modal-dialog .modal-content.bg_color_section .modal-body img, #sendLicenseConfirm .modal-dialog .modal-content.bg_color_section .modal-body img {
	max-width: 200px;
}
#addLicenseHolderConfirm .modal-dialog .modal-content.bg_color_section .modal-body, #sendLicenseConfirm .modal-dialog .modal-content.bg_color_section .modal-body  {
    text-align: center;
    padding:24px 22px 10px;
}
#addLicenseHolderConfirm .modal-dialog .modal-content.bg_color_section .modal-body span, #sendLicenseConfirm .modal-dialog .modal-content.bg_color_section .modal-body span {
	font-size: 40px;
	line-height: 48px;
	color: #061F39;
	font-family: 'Museo Sans 100';
	padding-top: 19px;
	float: left;
	width: 100%;
}
.start_ordering_scanner #scanner_distribution_data {
	float: left;
	width: 100%;
}
#addLicenseHolder.modal .modal-dialog .modal-content.bg_color_section {
	min-height: 560px;
}
#sendLicense .modal-body {
	min-height: 390px;
}


/***Iceland Language***/
.icelandic_body .sign_up_page h2 {
    font-size: 60px;
}
.icelandic_body #termsCondition .modal-header h4.modal-title {
    font-size: 44px;
}
.icelandic_body  .forgot_page .write_down_email {
	font-size: 28px;
}
.icelandic_body #UpgradeSubscription .unsbscribed_account .subscription_table table tbody .table-price td .free_subsription_btns, .icelandic_body .subscription_table table tbody .table-price td .free_subsription_btns {
    height: auto;
    font-size: 25px;
}
.icelandic_body .subscription_table table tbody .table-price td .free_subsription_btns {
    padding:10px 13px;
}
.icelandic_body .standard-fee-note p {
    font-size: 29px;
}
.icelandic_body  .adduser .browsedCsvContainer input#addbrowsedCsvName, .icelandic_body  .addLicenseHolderModal .browsedCsvContainer input#addbrowsedCsvName {
	width: calc(100% - 157px);
}
.local_language_picker select {
	border: 1px solid #333;
	min-width: 52px;
	line-height: 7px;
	height: 37px;
	padding: 0 6px;
}
.language_selector {
	position: relative;
}
.language_selector .current_language {
	padding: 0 0 0 12px;
	float: left;
}
.language_selector .users {
	padding: 0 5px;
	text-align: center;
	position: absolute;
	top: 29px;
	left: 6px;
	width: auto;
	margin: 0;
	background: #e4e4e4;
	width: 40px;
}
.language_selector .current_language img {
	max-width: 28px;
	height: 21px;
}
.language_selector .current_language i {
	font-size: 16px;
}
.language_selector .users li {
	list-style: none;
	float: left;
	width: 100%;
}
.language_selector .users li img {
	max-width: 28px;
	cursor: pointer;
	height: 18px;
}
/***END***/

.same_profile_tab_style form .form-group.payment_api_section h2 {
	color: #061F39;
	letter-spacing: 0.07em;
	font-family: 'Museo Sans 100';
	font-style: normal;
	font-weight: 100;
	font-size: 30px;
	line-height: 36px;
}
.same_profile_tab_style form .form-group.payment_api_section span {
	font-family: 'Museo Sans 300';
	font-style: normal;
	font-weight: 300;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.956px;
	color: #061F39;
}
.form-group.profile_form_group_select label {
	font-family: 'Museo Sans 300';
	font-style: normal;
	font-weight: 300;
	font-size: 20px;
	line-height: 24px;
	color: #97937F;
}
.profile_form_group_select .floating-label_group select {
	padding: 6px 0px;
	color: #061f39;
	font-size: 30px;
	line-height: 36px;
	font-family: 'Museo Sans 300';
	font-style: normal;
	font-weight: 300;
	height: auto;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
}
.profile_form_group_select .floating-label_group select.empty_select {
    color: #97937F;
}


@media only screen and (max-width:1900px){
    div#root .divPad.templates-design div {
        width: 974px!important;
        height: 350px!important;
    }
    div#root form .row {
        width: 100%;
        margin-top: 22px;
        float: left;
        padding: 10px 0px 10px 16px !important;
    }
 
    div#root .control {
        padding: 22px;
        border-style: solid;
        border-width: thin;
        height: 550px;
        margin-top: 0;
        float: left;
        background: #d8d8d8;
        width: 31.2%;
        border: none!important;
    }
    div#root .designpic>div {
        margin-bottom: 10px;
        font-size: 15px;
        color: #000;
        float: left;
        font-weight: 600;
        width: 100%;
        display: inline-block;
    }
    div#root .control input[type=color] {
        height: 40px;
        margin-top: 5px;
        width: 100%;
        margin-right: 0;
    }
    #tollTipMainSection_4 .tollTipTrigger {
	margin-left: 0px;
}
    div#root .text-inputs .divPad {
        float: left;
        width: 31.33%;
    }
    div#root .text_entry .label h3 {
        text-align: center;
        font-size: 27px;
        font-weight: 600;
        margin-top: 15px;
        color: #000;
    }
    div#root .text_entry {
        padding: 25px;
        margin-top: 0;
        height: 500px;
        background: #d8d8d8;
        border: none;
    }
    div#root .payment, div#root .restriction {
        border-style: solid;
        border-width: thin;
        float: left;
        margin-top: 7px;
        height: 650px!important;
        background: #d8d8d8;
        padding: 25px;
        border: none!important;
        width: 30%;
    }
    div#root .submit_btn {
        color: #fff;
        background-color: initial;
        width: 14%;
        float: left;
        padding: 1px;
        margin-top: 0%!important;
    }
    div#root .restriction {
        margin-right: 12px;
    }
    div#root .payment label, div#root .restriction label, div#root .text-inputs label, div#root .text_entry label {
        color: #333!important;
        font-size: 15px;
        float: left;
    }
    div#root .text-inputs, div#root .text_entry {
        float: left;
        width: 100%;
    }
    div#root .drop_files section p {
        text-align: center;
        float: left;
        font-size: 14px;
        width: 100%;
    }
    div#root .drop_files .label {
        float: left;
        width: 100%;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0px;
        text-align: center;
    }
    div#root .drop_files {
        float: left!important;
        width: 33%!important;
        margin-top: 2px;
    }
    div#root .designpic>div>span {
        font-size: 17px;
        color: #000;
        padding-left: 0;
        width: 71px;
        float: right;
        text-align: right;
        margin-bottom: 3px;
        display: inline-block;
    }
  
    div#root .label h2 {
        text-align: center;
        font-size: 27px;
        color: #000;
        font-weight: 600;
        margin-bottom: 23px;
        margin-top: 17px;
    }
    div#root .sec-input {
        width: 32%;
        margin-right: 24px;
        float: left;
        margin-top: 9px;
        padding: 0;
    }
    div#root .divStyle {
        height: 100%!important;
        margin-top: 0;
    }
    div#root .divPad.templates-design div {
        width: 987px!important;
        height: 350px!important;
    }
}
@media only screen and (max-width:1440px){
    .component-slider .templates-list {
        float: left;
        width: 43%;
    }
}

@media only screen and (max-width:1199px){
    .owl-stage-outer::after {
        display: none;
    }
    .fullColumnWidthHeavyMachine, .fullColumnWidthAdr {
        max-height: 150px;
    }
    .banner-section .owl-item .item {
        max-width: 330px;
        margin: 0 auto;
        float: none;
    }
    body {
        padding-top: 110px;
    }
    
}

@media only screen and (max-width:767px){
    .inner_subscripton_page, .desktop_subscription_table{
        display: none;
    }
    .mobile_subscription_table{
        display: block;
    }
    .fullColumnWidthHeavyMachine, .fullColumnWidthAdr {
        max-height: 130px;
    }
    .user_account .user_current span {
        padding-right: 8px;
        width: 100%;
        max-width: 90px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .subscription_table table tbody .table-price td .month_validity {
        font-size: 12px!important;
    }
    .browsedCsvContainer .react-file-reader {
        float: right;
        width: 25%;
    }
    .browsedCsvContainer input#browsedCsvName {
        width: 71%;
        margin-right: 1%;
    }
    .browsedCsvContainer input#addbrowsedCsvName {
        width: 71%;
        margin-right: 1%;
    }
    .codes_right .codes-image_section {
        margin: 0 auto;
    }
    .user_account .users {
        z-index: 99;
    }
    .codes_section .codes_right {
        float: none;
        margin: 0 auto;
    }
    .react-contextmenu-wrapper {
        width: 100%;
        margin: 0 auto;
    }
    .codes_section .codes_right {
        float: none;
        margin: 0 auto;
        width: 100%;
    }
    .subscription_table table tbody .table-price td .free_subscription_text {
        font-size: 16px!important;
        line-height: 20px!important;
    }
    .subscription_table table tbody .table-price td .free_subscription_text::before {
        right: 0!important;
    }
    }
    @media only screen and (max-width:479px){
       
    .confirmation.text, .confirmation.text span {
        display: block;
    } 
    .user_account .user_current span {
        max-width: 76px;
        font-size: 14px;
    }
    .subscription_table table tbody .table-price td .free_subscription_text {
        font-size: 16px;
        line-height: 20px;
    }
    .sign_up_form .form-group.card-detail span.card_number_value {
        margin-top: -10px;
    }
    .modal .modal_main_field .xpry.modal_form_field {
        flex: 0 0 60%;
    }
    .my_scanner_page .my_scanner .nav.nav-tabs li{
        padding-right: 30px;
    }
    }
    