@font-face {
    font-family: 'Museo Sans 500';
    src: url('MuseoSans-500Italic.eot');
    src: url('MuseoSans-500Italic.eot?#iefix') format('embedded-opentype'),
        url('MuseoSans-500Italic.woff2') format('woff2'),
        url('MuseoSans-500Italic.woff') format('woff'),
        url('MuseoSans-500Italic.ttf') format('truetype'),
        url('MuseoSans-500Italic.svg#MuseoSans-500Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Museo Sans 700';
    src: url('MuseoSans-700.eot');
    src: url('MuseoSans-700.eot?#iefix') format('embedded-opentype'),
        url('MuseoSans-700.woff2') format('woff2'),
        url('MuseoSans-700.woff') format('woff'),
        url('MuseoSans-700.ttf') format('truetype'),
        url('MuseoSans-700.svg#MuseoSans-700') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans 700';
    src: url('MuseoSans-700Italic.eot');
    src: url('MuseoSans-700Italic.eot?#iefix') format('embedded-opentype'),
        url('MuseoSans-700Italic.woff2') format('woff2'),
        url('MuseoSans-700Italic.woff') format('woff'),
        url('MuseoSans-700Italic.ttf') format('truetype'),
        url('MuseoSans-700Italic.svg#MuseoSans-700Italic') format('svg');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Museo Sans 100';
    src: url('MuseoSans-100.eot');
    src: url('MuseoSans-100.eot?#iefix') format('embedded-opentype'),
        url('MuseoSans-100.woff2') format('woff2'),
        url('MuseoSans-100.woff') format('woff'),
        url('MuseoSans-100.ttf') format('truetype'),
        url('MuseoSans-100.svg#MuseoSans-100') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans 100';
    src: url('MuseoSans-100Italic.eot');
    src: url('MuseoSans-100Italic.eot?#iefix') format('embedded-opentype'),
        url('MuseoSans-100Italic.woff2') format('woff2'),
        url('MuseoSans-100Italic.woff') format('woff'),
        url('MuseoSans-100Italic.ttf') format('truetype'),
        url('MuseoSans-100Italic.svg#MuseoSans-100Italic') format('svg');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Museo Sans 300';
    src: url('MuseoSans-300Italic.eot');
    src: url('MuseoSans-300Italic.eot?#iefix') format('embedded-opentype'),
        url('MuseoSans-300Italic.woff2') format('woff2'),
        url('MuseoSans-300Italic.woff') format('woff'),
        url('MuseoSans-300Italic.ttf') format('truetype'),
        url('MuseoSans-300Italic.svg#MuseoSans-300Italic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Museo Sans 500';
    src: url('MuseoSans-500.eot');
    src: url('MuseoSans-500.eot?#iefix') format('embedded-opentype'),
        url('MuseoSans-500.woff2') format('woff2'),
        url('MuseoSans-500.woff') format('woff'),
        url('MuseoSans-500.ttf') format('truetype'),
        url('MuseoSans-500.svg#MuseoSans-500') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans 300';
    src: url('MuseoSans-300.eot');
    src: url('MuseoSans-300.eot?#iefix') format('embedded-opentype'),
        url('MuseoSans-300.woff2') format('woff2'),
        url('MuseoSans-300.woff') format('woff'),
        url('MuseoSans-300.ttf') format('truetype'),
        url('MuseoSans-300.svg#MuseoSans-300') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Museo 700';
    src: url('Museo-700.eot');
    src: url('Museo-700.eot?#iefix') format('embedded-opentype'),
        url('Museo-700.woff2') format('woff2'),
        url('Museo-700.woff') format('woff'),
        url('Museo-700.ttf') format('truetype'),
        url('Museo-700.svg#Museo-700') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans 900';
    src: url('MuseoSans-900.eot');
    src: url('MuseoSans-900.eot?#iefix') format('embedded-opentype'),
        url('MuseoSans-900.woff2') format('woff2'),
        url('MuseoSans-900.woff') format('woff'),
        url('MuseoSans-900.ttf') format('truetype'),
        url('MuseoSans-900.svg#MuseoSans-900') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans 900';
    src: url('MuseoSans-900Italic.eot');
    src: url('MuseoSans-900Italic.eot?#iefix') format('embedded-opentype'),
        url('MuseoSans-900Italic.woff2') format('woff2'),
        url('MuseoSans-900Italic.woff') format('woff'),
        url('MuseoSans-900Italic.ttf') format('truetype'),
        url('MuseoSans-900Italic.svg#MuseoSans-900Italic') format('svg');
    font-weight: bold;
    font-style: italic;
}